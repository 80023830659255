import { Tooltip } from '@dayinsure/components';
import { FormRadioSelect } from '../../../../components';
import { getFormFieldAccessPath } from '../../../../helpers';
import { PaymentFrequencyDataEntry } from '../../../../types';
import { PaymentTypesEnum } from '../../../../constants/payments';

export const getAnnualMonthlyOptions = (testId: string): PaymentFrequencyDataEntry[] => [
  {
    id: PaymentTypesEnum.ANNUAL,
    name: 'Annually',
    testId: `${testId}_option-annually`,
  },
  {
    id: PaymentTypesEnum.MONTHLY,
    name: 'Monthly',
    testId: `${testId}_option-monthly`,
  },
];

type HowDoYouWantToPayRadioProps = {
  testId: string;
};
export const HowDoYouWantToPayRadio = ({ testId }: HowDoYouWantToPayRadioProps) => {
  const provideHowDoYouWantToPayRadioPath = getFormFieldAccessPath([
    'usualPaymentFrequency',
    'code',
  ]);
  return (
    <div className="my-6 md:my-16">
      <h2
        data-testid={`${testId}_how-do-you-want-to-pay-label`}
        className="relative w-full font-raleway font-normal form-subtitle-compact"
      >
        How do you want to pay for your{' '}
        <span className="inline-block">
          policy?
          <div className="inline-block ml-2 sm:!relative">
            <Tooltip
              ariaLabel="hint"
              className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
              position="relative"
              testId={`${testId}_how-do-you-want-to-pay`}
              variant="annual"
            >
              <h4 className="mb-2 font-bold">Paying for your policy</h4>
              <p>
                If you pay your insurance by monthly instalments, an interest charge and
                finance arrangement fee may be added. These are included in the monthly
                instalments quoted.
              </p>
              <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
                Got it
              </Tooltip.Close>
            </Tooltip>
          </div>
        </span>
      </h2>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          id={`${testId}_how-do-you-want-to-pay-radio`}
          testId={`${testId}_how-do-you-want-to-pay-radio`}
          name={provideHowDoYouWantToPayRadioPath}
          options={getAnnualMonthlyOptions(`${testId}_how-do-you-want-to-pay`)}
        />
      </div>
    </div>
  );
};
