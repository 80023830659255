import { ChangeEvent } from 'react';

export const bankSortCodeMask = (e: ChangeEvent<HTMLInputElement>) => {
  const sortCode = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);

  if (!sortCode) {
    e.target.value = '';
  } else {
    const parts = sortCode.match(/.{1,2}/g) ?? [];
    e.target.value = parts.join(' ');
  }
};
