import { useNavigateWithReferrer } from '@dayinsure/shared';
import { Button, Card } from '@dayinsure/components';
import { PoliciesPaths } from '../../../../routes';
import { PolicyDriverDto, PolicyHolderDto } from '../../../../api/v1';
import { getDriverAddress } from '../../../../helpers';
import { getDriverDescription } from '../../../../helpers/dataDisplay';

type PolicyInfoDriversProps = {
  testId?: string;
  drivers: Array<PolicyDriverDto>;
  policyHolder: PolicyHolderDto;
  noMoreButton?: boolean;
};

export const PolicyInfoDrivers = ({
  testId,
  drivers,
  policyHolder,
  noMoreButton,
}: PolicyInfoDriversProps) => {
  const navigate = useNavigateWithReferrer();
  const handleClick = () => {
    navigate(PoliciesPaths.Drivers);
  };

  return (
    <section>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_drivers`}
      >
        <h2 className="mb-4 font-raleway text-lg">Drivers</h2>
        {drivers.map((driver, index) => {
          const title = driver.isPolicyholder ? 'Policyholder' : 'Additional drivers';
          return (
            <div key={`driver-${driver.dateOfBirth}`}>
              <Card.CardListItem
                label={title}
                icon="user"
                testId={`policy-driver_list-item_name-${index.toString()}`}
              >
                {getDriverDescription(driver)}
              </Card.CardListItem>
              {driver.isPolicyholder && (
                <Card.CardListItem
                  label="Policyholder’s home address"
                  icon="location"
                  testId="policy-driver_list-item_name"
                >
                  {getDriverAddress(policyHolder?.address?.formattedAddress)}
                </Card.CardListItem>
              )}
            </div>
          );
        })}

        {!noMoreButton && (
          <Button
            id={`${testId}_drivers_moreinfo_button`}
            testId={`${testId}_drivers_moreinfo_button`}
            text="More info"
            variant="bordered"
            onClick={handleClick}
            classNames="mt-6 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full sm:w-fit "
          />
        )}
      </Card>
    </section>
  );
};
