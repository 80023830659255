import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';
import { useNavigateWithReferrer, useSignIn } from '@dayinsure/shared';
import { usePerson, useReferrer } from '../../hooks';

type CardWithLoginProps = {
  testId?: string;
};

export const CardWithLogin = ({ testId }: CardWithLoginProps) => {
  const referrer = useReferrer();
  const navigate = useNavigateWithReferrer();
  const { isGuest, isFullAccount } = usePerson();
  const { signIn } = useSignIn(referrer.name, isGuest);
  const handleYourAccountButton = () => {
    if (isFullAccount) {
      navigate('/dashboard');
    } else {
      signIn();
    }
  };
  return (
    <section>
      <Card
        classNames={clsx('flex items-center', 'mb-8', 'mt-8 lg:mt-12')}
        paddingLevel="small"
        testId={`${testId}_card`}
      >
        <Icon className="text-main-content-1" size="2rem" name="warning" />
        <div className="ml-6 text-main-content-1">
          The document links below will expire in 10 minutes. You can access all your
          documents, whenever you want, in{' '}
          <button
            type="button"
            data-testid={`${testId}-link-account`}
            onClick={handleYourAccountButton}
            className="font-semibold underline text-link"
          >
            your account
          </button>
          .
        </div>
      </Card>
    </section>
  );
};
