import { Card, ContentContainer, PageContainer } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { BackButton, MtaChangeButtons } from '../../../../../components';
import { NEW_DRIVER_ID } from '../../../../../helpers';
import { MtaJourneyFormData } from '../../../../../types/mtaJourneyForm';
import {
  AccidentOrClaim,
  DriveAnotherCar,
  DrivingConvictions,
  Licence,
  NonMotoringConvictions,
  NotifableMedicalConditions,
  Qualifications,
} from '../../../../Quote';
import { PolicyMtaPaths } from '../../../../../routes';

const testId = 'additional-driver-licence-and-history';

export const DriverLicenceAndHistory = () => {
  const { values } = useFormikContext<MtaJourneyFormData>();
  const driverToAddIndex = values.drivers.driversToAdd.findIndex(
    driver => driver.id === NEW_DRIVER_ID
  );
  const formNameSpaceKey = `drivers.driversToAdd[${driverToAddIndex}]`;

  return (
    <PageContainer className="font-raleway">
      <BackButton customBackLink={`../${PolicyMtaPaths.DriverDetails}`} />
      <h1
        className="my-6 text-xl text-center md:my-8 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Additional driver’s licence and history
      </h1>

      <ContentContainer>
        <Card
          testId={`${testId}_info_card`}
          paddingLevel="small"
          classNames="mb-6 lg:mb-8"
        >
          <span>We need some details about their driving licence and history.</span>
        </Card>

        <div className="mb-16 sm:mb-24">
          <Form>
            <Licence
              formNameSpaceKey={formNameSpaceKey}
              testId={testId}
              additionalDriver
            />
            <DriveAnotherCar
              formNameSpaceKey={formNameSpaceKey}
              additionalDriver
              testId={testId}
            />
            <Qualifications
              formNameSpaceKey={formNameSpaceKey}
              additionalDriver
              testId={testId}
            />
            <NotifableMedicalConditions
              formNameSpaceKey={formNameSpaceKey}
              additionalDriver
              testId={testId}
            />
            <AccidentOrClaim
              formNameSpaceKey={formNameSpaceKey}
              additionalDriver
              testId={testId}
            />
            <DrivingConvictions
              formNameSpaceKey={formNameSpaceKey}
              additionalDriver
              testId={testId}
            />
            <NonMotoringConvictions
              formNameSpaceKey={formNameSpaceKey}
              additionalDriver
              testId={testId}
            />
            <MtaChangeButtons />
          </Form>
        </div>
      </ContentContainer>
    </PageContainer>
  );
};
