import { Card, Button, PageContainer } from '@dayinsure/components';
import { Form, Formik } from 'formik';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { useContext } from 'react';
import { FormTextField } from '../../../../components';
import {
  ForgotPasswordFormData,
  forgotPasswordFormInitialData,
  ForgotPasswordValidationSchema,
} from '../../../../forms';
import { useSendPasswordResetMutation, useReferrer } from '../../../../hooks';
import { IAPIResult } from '../../../../api/v1';
import { trimStartMask } from '../../../../helpers';
import { AuthPaths } from '../../../../routes';

const testId = 'forgot-password';

export const ForgotPassword = () => {
  const referrer = useReferrer();
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);
  const navigate = useNavigateWithReferrer();
  const { mutateAsync, isLoading } = useSendPasswordResetMutation();

  const handleResetPasswordSuccess = (data: IAPIResult, email: string) => {
    if (data.isSuccess) {
      navigate(
        {
          pathname: `./${AuthPaths.PasswordSecureLink}`,
        },
        { state: { email } }
      );
    }
  };

  const handleResetPasswordError = (error: unknown) => {
    setGlobalError(error, {
      title: 'Sorry, there was a problem',
      message: 'There was a problem sending your password reset email',
      cta: 'Go back and try again',
      ctaId: `${testId}_error-dialog_cta-button`,
      onClose: clearGlobalError,
    });
  };

  const handleSubmit = (values: ForgotPasswordFormData) => {
    const payload = {
      resetPasswordUrl: `${window.origin}/auth/forgotten-password/reset`,
      returnUrl: `${window.location.href}`,
      referrer: referrer.name,
      tenant: referrer.identityProviderName,
    };

    mutateAsync({ email: values.emailAddress, requestBody: payload })
      .then(value => handleResetPasswordSuccess(value, values.emailAddress))
      .catch(handleResetPasswordError);
  };

  return (
    <PageContainer>
      <Formik<ForgotPasswordFormData>
        initialValues={forgotPasswordFormInitialData}
        validationSchema={ForgotPasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="flex flex-col mx-auto mb-16 w-full md:max-w-tablet-container lg:mb-24 lg:max-w-desktop-container text-main-content-1">
          <h1
            className="my-8 text-xl text-center sm:my-12 text-main-content-1"
            data-testid={`${testId}_title`}
          >
            Forgotten password
          </h1>
          <Card paddingLevel="large" classNames="bg-content-background-prominent">
            <Card classNames="mb-8 md:mb-12">
              <p data-testid={`${testId}_message`}>
                Enter your email address below and we will send you a secure link to reset
                your password.
              </p>
            </Card>

            <FormTextField
              label={{ icon: 'email', text: 'Email address' }}
              id={`${testId}_text-field`}
              testId={`${testId}_text-field`}
              name="emailAddress"
              placeholder="Enter your email address"
              spacingClassName="md:mb-12 mb-8"
              onInput={trimStartMask}
            />
            <Button
              id={`${testId}_send-link-btn`}
              testId={`${testId}_send-link-btn`}
              submit
              fullWidth
              loading={isLoading}
              text="Send a link"
            />
          </Card>
        </Form>
      </Formik>
    </PageContainer>
  );
};
