/* eslint-disable */
import React, { useEffect } from 'react';
import { action } from '@storybook/addon-actions';
import { MemoryRouter, useLocation } from 'react-router-dom';
import { makeDecorator } from '@storybook/addons';

const LocationChangeAction = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.key !== 'default') action('React Router Location Change')(location);
  }, [location]);

  return <>{children}</>;
};

type WithReactRouterOptions = {
  initialRoute?: string;
};

const withReactRouter = makeDecorator({
  name: 'withReactRouter',
  parameterName: 'initialRoute',
  wrapper: (storyFn, context, { options }: { options: WithReactRouterOptions }) => {
    const initialRoute = options?.initialRoute;

    return (
      <MemoryRouter
        initialEntries={initialRoute !== undefined ? [initialRoute] : undefined}
      >
        <LocationChangeAction>{storyFn(context) as React.ReactNode}</LocationChangeAction>
      </MemoryRouter>
    );
  },
});

export { withReactRouter };
