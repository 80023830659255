import { ReactNode } from 'react';
import {
  GlobalErrorWrapper,
  LazyPreloadContextWrapper,
  ReferrerWrapper,
} from '@dayinsure/shared';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import {
  ReferrerProvider,
  LazyPreloadContextProvider,
  PersonProvider,
} from '../contexts';
import { referrers } from '../constants/referrers';
import { preloadContextValue } from '../routes/preloadContextValue';
import { queryClient } from '../configuration';
import { AuthProvider } from '../AuthProvider';
import { PaymentProvider } from '../contexts/PaymentContext';
import { AuthEventsHandler } from '../components';

type ProvidersProps = {
  children: ReactNode;
};

export const Providers = ({ children }: ProvidersProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <PersonProvider>
          <AuthProvider>
            <PaymentProvider>
              <LazyPreloadContextWrapper
                ContextProvider={LazyPreloadContextProvider}
                context={preloadContextValue}
              >
                <ReferrerWrapper
                  ContextProvider={ReferrerProvider}
                  referrers={referrers}
                  defaultReferrerName="everyday"
                >
                  <GlobalErrorWrapper>
                    <AuthEventsHandler>{children}</AuthEventsHandler>
                  </GlobalErrorWrapper>
                </ReferrerWrapper>
              </LazyPreloadContextWrapper>
            </PaymentProvider>
          </AuthProvider>
        </PersonProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
