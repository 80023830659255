import { useLayoutEffect, useMemo, useRef } from 'react';
import { Button, FieldError, Label, SelectRadioOption } from '@dayinsure/components';
import {
  FormDateField,
  FormListBox,
  FormRadioSelect,
  FormFieldBox,
  FormNumField,
} from '../../../../../../../components';
import { useClaimTypeQuery, useFocusOnError } from '../../../../../../../hooks';
import {
  baseOptionDtoToViewOptions,
  digitOnlyMask,
  isYes,
  parseYupErrors,
} from '../../../../../../../helpers';
import {
  getClaimAmountOptions,
  getClaimFaultOptions,
  getClaimSettledOptions,
} from './AddAccidentOrClaimForm.const';
import { useAccidentOrClamiFields } from './useAccidentOrClaimFields';
import { accidentOrClaimSchema } from '../../../../../../../forms';

type AddAccidentOrClaimFormProps = {
  name: string;
  testId: string;
  onCancel: VoidFunction;
  shouldDisplayCancelButton: boolean;
  additionalDriver?: boolean;
};

export const AddAccidentOrClaimForm = ({
  name,
  testId,
  shouldDisplayCancelButton,
  onCancel,
  additionalDriver,
}: AddAccidentOrClaimFormProps) => {
  const fieldRef = useRef<HTMLDivElement | null>(null);
  const { data } = useClaimTypeQuery();
  const {
    accidentOrClaim,
    claimDate,
    selfDeclaredClaimType,
    fault,
    claimSettled,
    claimAmount,
    isSaved,
    knowClaimAmount,
    setFieldTouched,
    setFieldError,
    setFieldValue,
  } = useAccidentOrClamiFields(name);
  useFocusOnError({ fieldRef, name: isSaved.fieldName });
  const claimTypeOptions = useMemo(
    () => data?.map(baseOptionDtoToViewOptions(testId)) || [],
    [data, testId]
  );
  const displayClaimAmountInput = isYes(knowClaimAmount?.value?.id);
  const invalidateField = ({ path, message }: { path: string; message: string }) => {
    setFieldTouched(`${name}.${path}`, true);
    setFieldError(`${name}.${path}`, message);
  };
  const handleSave = () => {
    accidentOrClaimSchema
      .validate(accidentOrClaim.value, { abortEarly: false })
      .then(() => {
        setFieldValue(isSaved.fieldName, true);
      })
      .catch(errors => {
        const parsedErrors = parseYupErrors(errors);
        parsedErrors.forEach(invalidateField);
      });
  };

  const handleClaimAmountRadio = (answer: SelectRadioOption) => {
    const userDontKnowClaimAmount = answer?.id !== 'YES';
    if (userDontKnowClaimAmount) {
      setFieldValue(claimAmount.fieldName, null);
      setFieldTouched(claimAmount.fieldName, false);
    }
  };

  const hasFieldErrors =
    !!selfDeclaredClaimType.meta.error ||
    !!claimDate.meta.error ||
    !!fault.meta.error ||
    !!claimSettled.meta.error ||
    !!claimAmount.meta.error ||
    !!knowClaimAmount.meta.error;

  const isSavedError =
    isSaved.meta.touched && !!isSaved.meta.error && !hasFieldErrors
      ? isSaved.meta.error
      : undefined;

  useLayoutEffect(() => {
    setFieldTouched(isSaved.fieldName, hasFieldErrors);
  }, [hasFieldErrors, isSaved.fieldName, setFieldTouched]);

  return (
    <div data-testid={testId}>
      <FormFieldBox>
        <FormListBox
          unselectedMessage="Please select"
          name={selfDeclaredClaimType.fieldName}
          options={claimTypeOptions}
          id={`${testId}_type-of-claim-dropdown`}
          testId={`${testId}_type-of-claim-dropdown`}
          icon="clipboard"
          label="Type of claim"
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormDateField
          label={{
            icon: 'date',
            text: 'Date of claim',
          }}
          testId={`${testId}_date-of-accident-or-claim-input`}
          id={`${testId}_date-of-accident-or-claim-input`}
          name={`${name}.claimDate`}
          configuration={{
            day: {
              enabled: true,
              placeholder: 'DD',
            },
            month: {
              placeholder: 'MM',
            },
            year: {
              placeholder: 'YYYY',
            },
          }}
        />
      </FormFieldBox>
      <FormFieldBox>
        <Label
          className="mb-4"
          htmlFor={`${testId}_claim-fault-dropdown`}
          icon="user-group"
          name={additionalDriver ? 'Were they at fault?' : 'Were you at fault?'}
          testId={`${testId}_claim-fault-dropdown_label`}
        />
        <FormRadioSelect
          id={`${testId}_claim-fault-dropdown`}
          testId={`${testId}_claim-fault-dropdown`}
          name={fault.fieldName}
          options={getClaimFaultOptions(testId)}
        />
      </FormFieldBox>
      <FormFieldBox>
        <Label
          className="mb-4"
          htmlFor={`${testId}_claim-settled-dropdown`}
          icon="legal"
          name="Was the claim settled?"
          testId={`${testId}_claim-settled-dropdown_label`}
        />
        <FormRadioSelect
          id={`${testId}_claim-settled-dropdown`}
          testId={`${testId}_claim-settled-dropdown`}
          name={claimSettled.fieldName}
          options={getClaimSettledOptions(testId)}
        />
      </FormFieldBox>
      <FormFieldBox>
        <Label
          className="mb-4"
          htmlFor={`${testId}_claim-amount-radio`}
          icon="wallet"
          name="Do you know the claim amount?"
          testId={`${testId}_claim-amount-radio_label`}
        />
        <FormRadioSelect
          onChangeOptional={handleClaimAmountRadio}
          id={`${testId}_claim-amount-radio`}
          testId={`${testId}_claim-amount-radio`}
          name={knowClaimAmount.fieldName}
          options={getClaimAmountOptions(testId)}
        />
      </FormFieldBox>
      {displayClaimAmountInput && (
        <FormFieldBox>
          <FormNumField
            label={{ text: 'Claim amount', icon: 'wallet' }}
            testId={`${testId}_claim-amount-input`}
            id={`${testId}_claim-amount-input`}
            name={claimAmount.fieldName}
            type="number"
            placeholder="Enter value (£)"
            min={1}
            max={200000}
            onInput={digitOnlyMask}
          />
        </FormFieldBox>
      )}
      <div className="flex relative gap-2" ref={fieldRef}>
        <Button
          variant="bordered"
          id={`${testId}_add-button`}
          testId={`${testId}_add-button`}
          onClick={handleSave}
          text="Add this claim"
        />
        {shouldDisplayCancelButton && (
          <Button
            variant="bordered"
            id={`${testId}_cancel-button`}
            testId={`${testId}_cancel-button`}
            onClick={onCancel}
            text="Cancel"
          />
        )}
        {isSavedError && (
          <FieldError
            id={`${name}-error`}
            testId={`${testId}_error`}
            error={isSavedError}
            className="absolute bottom-[-1.25rem] left-0 mt-2"
          />
        )}
      </div>
    </div>
  );
};
