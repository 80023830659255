import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';
import {
  CreditAgreement,
  AnitMoneyLaundering,
  DirectDebitGuaranteeCard,
  FormTextField,
  MonthlyImportantInformation,
  PreContractCreditInformation,
  FormFieldBox,
} from '../../../../../components';
import {
  bankSortCodeMask,
  bankAccountNumberMask,
  nameInputMask,
  getNthNumber,
  isYes,
} from '../../../../../helpers';
import { QuoteJourneyFormData } from '../../../../../types';
import { AccountHolderRadio } from '../../AccountHolderRadio';

type DirectDebitDetailsFormProps = {
  testId: string;
};

export const DirectDebitDetailsForm = ({ testId }: DirectDebitDetailsFormProps) => {
  const {
    setFieldValue,
    setFieldTouched,
    values: {
      cover: { startDateTimeUtc },
      isAccountHolder,
      paymentDay,
    },
  } = useFormikContext<QuoteJourneyFormData>();
  const userIsAccountHolder = isYes(isAccountHolder?.id);
  const dayNumber = startDateTimeUtc?.day || null;
  const resetValidationOnRadioChange = () => {
    setFieldTouched('paymentDay', false);
    setFieldTouched('accountHolder', false);
    setFieldTouched('bankAccountNumber', false);
    setFieldTouched('sortCode', false);
  };
  useEffect(() => {
    if (dayNumber) {
      const formatedDayNumber = `${dayNumber}${getNthNumber(Number(dayNumber))}`;
      setFieldValue('paymentDay', { id: dayNumber, name: formatedDayNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div data-testid={testId}>
      <div className="flex relative flex-col">
        <AccountHolderRadio onChange={resetValidationOnRadioChange} testId={testId} />
        {userIsAccountHolder && (
          <>
            <FormFieldBox>
              <FormTextField
                label={{ text: 'Name of account holder', icon: 'user' }}
                testId={`${testId}_direct-debit_account-holder-input`}
                id={`${testId}_direct-debit_account-holder-input`}
                name="accountHolder"
                placeholder="e.g. John Smith"
                onInput={nameInputMask}
              />
            </FormFieldBox>

            <FormFieldBox>
              <FormTextField
                label={{ text: 'Bank account number', icon: 'bank' }}
                testId={`${testId}_direct-debit_bank-account-input`}
                id={`${testId}_direct-debit_bank-account-input`}
                name="bankAccountNumber"
                placeholder="Enter 8 digits"
                onInput={bankAccountNumberMask}
              />
            </FormFieldBox>

            <FormFieldBox className="grid grid-cols-1 md:grid-cols-2">
              <FormTextField
                label={{ text: 'Bank sort code', icon: 'bank' }}
                testId={`${testId}_direct-debit_sort-code-input`}
                id={`${testId}_direct-debit_sort-code-input`}
                name="sortCode"
                placeholder="Enter 6 digits"
                onInput={bankSortCodeMask}
              />
            </FormFieldBox>

            {paymentDay?.name && (
              <Card classNames="mb-6 md:mb-12">
                <Card.CardListItem
                  className="mt-0"
                  icon="date"
                  label="Payment day of the month"
                  testId={`${testId}_direct-debit_payment-day`}
                >
                  {paymentDay.name}
                </Card.CardListItem>
              </Card>
            )}
          </>
        )}
        {!userIsAccountHolder && !!isAccountHolder && (
          <Card
            classNames={clsx('flex items-center', 'mb-8')}
            paddingLevel="small"
            testId={`${testId}_RAC-phone`}
          >
            <Icon className="text-main-content-1" size="2rem" name="warning" />
            <div className="ml-6 text-main-content-1">
              Only the account holder can authorise the Direct Debit instruction.
            </div>
          </Card>
        )}
        <CreditAgreement className="mb-6" testId={testId} />
        <DirectDebitGuaranteeCard className="mb-6" testId={testId} />
        <MonthlyImportantInformation className="mb-6" testId={testId} />
        <AnitMoneyLaundering className="mb-12" testId={testId} />
        <PreContractCreditInformation testId={testId} />
      </div>
    </div>
  );
};
