import {
  Button,
  Card,
  ContentContainer,
  ErrorDialog,
  PageContainer,
  PasswordValidation,
  Toast,
} from '@dayinsure/components';
import { Formik, Form, FormikHelpers } from 'formik';
import {
  lowercaseRegex,
  numberRegex,
  symbolRegex,
  uppercaseRegex,
  useNavigateWithReferrer,
} from '@dayinsure/shared';
import { useState } from 'react';
import {
  EditPasswordFormData,
  editPasswordInitialData,
  EditPasswordVlidationSchema,
} from '../../../forms/editPassword';
import { FormPasswordField } from '../../../components';
import { usePerson } from '../../../hooks';
import { OpenAPI, Dayinsure } from '../../../api/v2';

const testId = 'password-edit';

const passwordValidationCopy = {
  minCharacters: 'Minimum eight characters',
  uppercase: 'One uppercase letter',
  lowercase: 'One lowercase letter',
  number: 'One number',
  symbol: 'One symbol',
  passwordMatch: 'Passwords match',
};

const passwordValidationRegExpsConfig = {
  uppercaseRegex,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
};

export const PasswordEdit = () => {
  const navigate = useNavigateWithReferrer();
  const { person } = usePerson();
  const [successToastOpen, setSuccessToastOpen] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

  const handleSubmit = async (
    values: EditPasswordFormData,
    { setSubmitting, setTouched, resetForm }: FormikHelpers<EditPasswordFormData>
  ) => {
    if (person && person.id) {
      const api = new Dayinsure(OpenAPI);
      try {
        const response = await api.person.putApiV2PersonChangePassword(person?.id, {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        });
        resetForm();
        if (!response.isSuccess) {
          setErrorDialogOpen(true);
        } else {
          setSuccessToastOpen(true);
        }
      } catch (e) {
        setErrorDialogOpen(true);
      } finally {
        setSubmitting(false);
        setTouched({});
      }
    }
  };

  const handleCancelChanges = () => navigate('/dashboard');

  const handleCloseToast = () => setSuccessToastOpen(false);

  const handleCloseErrorDialog = () => setErrorDialogOpen(false);

  return (
    <PageContainer>
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Password
      </h1>

      <ContentContainer>
        <Card classNames="mb-[3.5rem]" testId={`${testId}_info-card`}>
          Edit your password below.
        </Card>
        <Formik<EditPasswordFormData>
          initialValues={editPasswordInitialData}
          validationSchema={EditPasswordVlidationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleBlur, values, isValid, dirty }) => (
            <Form className="flex flex-col mx-auto mb-16 w-full md:max-w-tablet-container lg:mb-24 lg:max-w-desktop-container text-main-content-1">
              <FormPasswordField
                id={`${testId}_current_password-input`}
                testId={`${testId}_current_password-input`}
                name="currentPassword"
                ariaLabel="Current password"
                label={{ icon: 'lock', text: 'Current password' }}
                placeholder="Type password"
                spacingClassName="mb-8"
                onBlur={handleBlur}
              />
              <FormPasswordField
                id={`${testId}_new-password-input`}
                testId={`${testId}_new-password-input`}
                name="newPassword"
                ariaLabel="New password"
                label={{ icon: 'lock', text: 'New password' }}
                placeholder="Choose a password"
                spacingClassName="mb-8"
                onBlur={handleBlur}
              />
              <FormPasswordField
                id={`${testId}_confirm-password-input`}
                testId={`${testId}_confirm-password-input`}
                name="retypePassword"
                ariaLabel="Re-type new password"
                label={{ icon: 'lock', text: 'Re-type new password' }}
                placeholder="Re-type password"
                spacingClassName="mb-12"
                onBlur={handleBlur}
              />

              <PasswordValidation
                password={values.newPassword}
                confirmPassword={values.retypePassword}
                copy={passwordValidationCopy}
                regexpsConfig={passwordValidationRegExpsConfig}
              />

              <Button
                id={`${testId}_save-changes-button`}
                testId={`${testId}_save-changes-button`}
                text="Save changes"
                variant="primary"
                classNames="mb-6 mt-12 text-base px-6 h-14 lg:px-8 lg:h-16 w-full"
                disabled={!isValid || !dirty}
                submit
              />
              <Button
                id={`${testId}_cancel-changes-button`}
                testId={`${testId}_cancel-changes-button`}
                text="Cancel changes"
                variant="bordered"
                onClick={handleCancelChanges}
                classNames="text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full"
              />
            </Form>
          )}
        </Formik>

        <Toast
          id={`${testId}_toast`}
          message="Password changed"
          open={successToastOpen}
          onClose={handleCloseToast}
        />
        <ErrorDialog
          open={errorDialogOpen}
          onClose={handleCloseErrorDialog}
          title="Sorry, there was a problem"
          error="There was a problem changing your password"
          ctaId={`${testId}_close-error-dialog`}
          ctaMessage="Go back and try again"
        />
      </ContentContainer>
    </PageContainer>
  );
};
