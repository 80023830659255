import { useNavigateWithReferrer } from '@dayinsure/shared';
import { Button } from '@dayinsure/components';
import { QuoteJourneyRoutes } from '../../../../routes';

type PolicyInfoButtonsProps = {
  plateNo?: string;
  testId?: string;
  cantEdit?: boolean;
  pendingChanges: boolean;
  showModal: (flag: true) => void;
};

export const PolicyInfoButtons = ({
  testId = 'policy-info',
  plateNo,
  cantEdit,
  pendingChanges = false,
  showModal,
}: PolicyInfoButtonsProps) => {
  const navigate = useNavigateWithReferrer();

  /* const handleCancelPolicy = () => {
    navigate(`/cancel-policy/${policyId}`);
  }; */

  const editPolicy = () => {
    if (pendingChanges) {
      showModal(true);
      return;
    }
    navigate(`mta`);
  };

  const insureAgain = () => {
    navigate({
      pathname: `../../../quote/${QuoteJourneyRoutes.RegistrationSearch}`,
      search: plateNo ? `plate=${plateNo}` : undefined,
    });
  };

  return (
    <nav>
      {!cantEdit ? (
        <Button
          classNames="mb-16"
          id={`${testId}_edit-button`}
          testId={`${testId}_edit-button`}
          submit
          fullWidth
          text="Make changes to policy"
          onClick={editPolicy}
        />
      ) : (
        <Button
          classNames="mb-16"
          id={`${testId}_insure-again-button`}
          testId={`${testId}_insure-again-button`}
          submit
          fullWidth
          text="Insure again"
          onClick={insureAgain}
        />
      )}

      {/* <Button
        classNames="mb-24 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full"
        id={`${testId}_cancel-button`}
        testId={`${testId}_cancel-button`}
        submit
        fullWidth
        text="Cancel policy"
        onClick={() => handleCancelPolicy()}
        variant="bordered"
      /> */}
    </nav>
  );
};
