import { Form, useFormikContext } from 'formik';
import { ContentContainer, Card } from '@dayinsure/components';
import { useState } from 'react';
import { CarValuation } from '../../../Quote/YourCar/CarValuation';
import { CarModification } from '../../../Quote/YourCar/CarModification';
import { MtaChangeButtons, BackButton, MtaCoverStartDate } from '../../../../components';
import { RegisteredKeeperLegalOwner } from '../../../Quote/YourCar/RegisteredKeeperLegalOwner';
import { RegistrationPlate } from './RegistrationPlate';
import { CarDetailsCards } from './CarDetailsCards';
import { CarPurchaseDate } from '../../../Quote/YourCar/CarPurchaseDate';
import { PolicyMtaPaths } from '../../../../routes';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { registrationPlateValidationSchema } from '../../../../forms/mtaJourney';
import { vehicleDetailsInitialData } from '../../../../forms';
import { parseVehicleToFormikState } from './RegistrationPlate/parseVehicleToFormikState';
import { useRegistrationSearchQuery } from '../../../../hooks';

const testId = 'mta-new-car';

export const NewCar = () => {
  const registrationSearchQuery = useRegistrationSearchQuery();
  const [isLoadingCarDetails, setIsLoadingCarDetails] = useState<boolean>(false);
  const {
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    validateForm,
    submitForm,
  } = useFormikContext<MtaJourneyFormData>();

  const handleSearchCar = async () => {
    if (values.isSameRegPlate) {
      return;
    }

    try {
      registrationPlateValidationSchema.validateSync(values.vehicle.regPlate);
    } catch (error: unknown) {
      setFieldTouched('vehicle.regPlate', true);
      return;
    }

    try {
      setIsLoadingCarDetails(true);
      setFieldValue(
        'vehicle',
        { ...vehicleDetailsInitialData, ...values.vehicle },
        false
      );

      const carDetails = await registrationSearchQuery(values.vehicle.regPlate || '');

      if (carDetails.detail) {
        const vehicle = parseVehicleToFormikState(carDetails.detail);
        setFieldValue('vehicle', {
          ...values.vehicle,
          ...vehicle,
          registrationNumber: values.vehicle.regPlate,
        });
        setFieldTouched('vehicle', false, false);
      }
    } catch (error) {
      setFieldError('vehicle.regPlate', "Sorry we can't find this vehicle");
    } finally {
      setIsLoadingCarDetails(false);
    }
  };

  const handleContinue = async () => {
    const errors = await validateForm();
    if (Object.entries(errors).length > 0) {
      await submitForm();
      return;
    }
    setFieldTouched('vehicle.regPlate', true);

    if (!values.vehicle.abiCode?.id) {
      await handleSearchCar();
      return;
    }

    await submitForm();
  };

  return (
    <div className="font-raleway">
      <BackButton customBackLink={`../${PolicyMtaPaths.PolicyChange}`} />
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_form-title`}
      >
        Your new car
      </h1>

      <ContentContainer>
        <Card
          testId={`${testId}_check-info_card`}
          paddingLevel="small"
          classNames="mb-6 lg:mb-8"
        >
          <span>Please enter the new car details below.</span>
        </Card>

        <Form>
          <MtaCoverStartDate testId={testId} />
          <RegistrationPlate
            isLoading={isLoadingCarDetails}
            onSearchCar={handleSearchCar}
            testId={testId}
          />
          <CarDetailsCards testId={testId} />
          <CarValuation />
          <CarModification />
          <CarPurchaseDate />
          <RegisteredKeeperLegalOwner testId={testId} />
          <MtaChangeButtons
            onContinue={handleContinue}
            disabled={values.isSameRegPlate || isLoadingCarDetails}
          />
        </Form>
      </ContentContainer>
    </div>
  );
};
