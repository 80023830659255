import React, { useContext, useState } from 'react';
import { ContentContainer, PageContainer, Toast } from '@dayinsure/components';
import { Formik } from 'formik';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { QuoteLoader } from '../../../Quote/QuoteJourney/QuoteLoader';
import { AddCardFormData } from '../../../../types/addCardForm';
import { addCardInitialFormData } from '../../../../forms';
import { addCardValidation } from '../../../../forms/addCardForm/addCardValidation';
import { PayByDebitCard } from '../../../Quote';
import { AddCardNavButtons } from './AddCardNavButtons/AddCardNavButtons';
import { useAddPaymentMethodMutation } from '../../../../hooks';
import { AccountPaths } from '../../../../routes';
import { TopCards } from '../../../../components';

const testId = 'account-details';
export const AddCard = () => {
  const navigate = useNavigateWithReferrer();
  const [isLoading, setIsLoading] = useState(false);
  const [successToastState, setSuccessToastState] = useState(false);
  const savePaymentMethod = useAddPaymentMethodMutation();
  const { setGlobalError } = useContext(GlobalErrorContext);

  const onSuccess = () => {
    setSuccessToastState(true);
    setTimeout(
      () =>
        navigate(`/account/${AccountPaths.PaymentMethods}/${AccountPaths.SavedCards}`),
      500
    );
  };

  const closeSuccessToast = () => {
    setSuccessToastState(false);
    setIsLoading(false);
  };
  const onError = (error: unknown) => {
    setGlobalError(error, {
      ctaId: 'add_card_cta',
    });
    setIsLoading(false);
  };
  const submitForm = (values: AddCardFormData) => {
    setIsLoading(true);
    const payload = {
      paymentDetails: values,
      paymentMethod: 'scheme',
    };
    savePaymentMethod.mutate(payload, {
      onSuccess,
      onError,
    });
  };
  return (
    <>
      <QuoteLoader />
      <Formik<AddCardFormData>
        initialValues={addCardInitialFormData}
        onSubmit={submitForm}
        validationSchema={addCardValidation}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <PageContainer className="font-raleway">
              <h1
                className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
                data-testid={`${testId}_form-title`}
              >
                Add a new card
              </h1>

              <ContentContainer>
                <TopCards
                  testId={`${testId}_cards`}
                  infoCardText="Please enter your card details below."
                />
                <PayByDebitCard noTitle />
                <AddCardNavButtons
                  testId={testId}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
                <Toast
                  id="account-details-toast"
                  message="Card saved"
                  open={successToastState}
                  onClose={closeSuccessToast}
                />
              </ContentContainer>
            </PageContainer>
          </form>
        )}
      </Formik>
    </>
  );
};
