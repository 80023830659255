import {
  FieldError,
  IconNames,
  Label,
  Listbox,
  ListboxOption,
  ListboxProps,
} from '@dayinsure/components';
import clsx from 'clsx';
import { useField } from 'formik';
import { ReactNode, useRef } from 'react';
import { useFocusOnError } from '../../../hooks';

type OnChangeOptionalProp = { onChangeOptional?: (option: ListboxOption) => void };
type FormListBoxProps = {
  icon: IconNames;
  label: ReactNode;
  name: string;
  testId: string;
  className?: string;
  noOptionsMessage?: string;
  unselectedMessage?: string;
  disabled?: boolean;
} & Pick<ListboxProps, 'options' | 'onBlur' | 'id'> &
  OnChangeOptionalProp;

export const FormListBox = ({
  className,
  icon,
  testId,
  label,
  options,
  name,
  noOptionsMessage = 'No options',
  unselectedMessage = '',
  onBlur,
  id,
  onChangeOptional,
  disabled,
}: FormListBoxProps) => {
  const [field, { error, touched }] = useField({ name });
  const fieldRef = useRef<HTMLDivElement | null>(null);
  useFocusOnError({ fieldRef, name });
  const handleChange = (value: ListboxOption) => {
    if (onChangeOptional) {
      onChangeOptional(value);
    }
    field.onChange({ target: { value, name: field.name } });
  };

  const handleBlur = () => {
    field.onBlur({ target: { name: field.name } });
    onBlur?.();
  };
  return (
    <div className={clsx('relative', className)} ref={fieldRef}>
      <Label name={label} htmlFor={id} icon={icon} className="mb-4" />
      <Listbox
        {...field}
        id={id}
        options={options}
        onChange={handleChange}
        unselectedMessage={unselectedMessage}
        noOptionsMessage={noOptionsMessage}
        onBlur={handleBlur}
        testId={testId}
        error={touched && !!error}
        disabled={disabled}
        variant="annual"
      />
      {touched && error && (
        <FieldError
          className="absolute right-0 mt-2"
          error={error}
          id={`${id}_error`}
          testId={`${testId}_error`}
        />
      )}
    </div>
  );
};
