import { useMemo, useState } from 'react';
import LoadingSpinner from '../../../Feedback/LoadingSpinner/LoadingSpinner';
import Button from '../../../Inputs/Button/Button';
import Card from '../../../Layout/Card/Card';
import Pagination from '../../../Layout/Pagination/Pagination';
import { Policy } from '../Policies.types';
import { PolicyCarDetails } from '../PolicyCarDetails';

type ExpiredAndCancelledPoliciesCopy = {
  buttonLabel: string;
  noPolicies: string;
};

export type ExpiredAndCancelledPoliciesProps = {
  copy: ExpiredAndCancelledPoliciesCopy;
  isLoading: boolean;
  policies: Policy[] | undefined;
  testId: string;
  onButtonClick(policyId?: string | null): void;
};

export const ExpiredAndCancelledPolicies = ({
  copy,
  policies,
  isLoading,
  testId,
  onButtonClick,
}: ExpiredAndCancelledPoliciesProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const policiesData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return policies?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, policies]);

  const handleButtonClick = (policyId?: string | null) => () => onButtonClick(policyId);

  if (isLoading || !policies) {
    return (
      <Card paddingLevel="small" classNames="w-full bg-content-background-prominent">
        <LoadingSpinner
          size="small"
          centered
          testId={`${testId}_expired-and-concelled_loader`}
        />
      </Card>
    );
  }

  if (policies?.length === 0) {
    return (
      <Card paddingLevel="small" classNames="w-full bg-content-background-prominent">
        <span data-testid={`${testId}_expired-and-concelled_no-policies`}>
          {copy.noPolicies}
        </span>
      </Card>
    );
  }

  return (
    <>
      <div className="flex flex-col space-y-6 w-full sm:space-y-8">
        {policiesData?.map((policy, index) => (
          <Card
            key={policy.id}
            paddingLevel="small"
            classNames="bg-content-background-prominent"
            testId={`${testId}_expired-and-concelled-policy-${policy.id}`}
          >
            <PolicyCarDetails
              vehicleRegistration={policy.vehicleRegistration}
              vehicleDescription={policy.vehicleDescription}
              policyIndex={index}
              testId={`${testId}_expired-and-concelled`}
            />

            <span
              className="flex mt-2 text-sm leading-[120%] text-main-content-2"
              data-testid={`${testId}_expired-and-concelled_end-date-${index}`}
            >
              {`${policy.state === 'expired' ? 'Ended' : 'Cancelled'} ${new Date(
                policy.endDate || ''
              )
                .toLocaleString('en-UK', { timeZone: 'Europe/London' })
                .replaceAll(',', '')
                .slice(0, -3)}`}
            </span>
            <span className="pl-1">{policy.id ? ` (ID ${policy.id})` : ''}</span>
            <Button
              classNames="mt-6"
              variant="secondary"
              id={`${testId}_expired-and-concelled_cta-button-${index}`}
              testId={`${testId}_expired-and-concelled_cta-button-${index}`}
              text={copy.buttonLabel}
              onClick={handleButtonClick(policy.id)}
            />
          </Card>
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={policies.length}
        pageSize={pageSize}
        onPageChange={(page: number) => setCurrentPage(page)}
        siblingCount={0}
      />
    </>
  );
};
