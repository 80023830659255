import { Card, Icon } from '@dayinsure/components';

type ImportantInformationMtaProps = {
  className?: string;
  testId: string;
};

export const ImportantInformationMta = ({
  className,
  testId,
}: ImportantInformationMtaProps) => {
  return (
    <section className={className}>
      <Card classNames="mb-8 md:mb-12" testId={`${testId}_information-card`}>
        <h3 className="mb-8 font-raleway font-normal text-main-content-1">
          Important information
        </h3>

        <div className="font-lato text-sm font-normal leading-5 text-main-content-1">
          <h4 className="my-6 font-bold">
            How we use your data to protect against fraud
          </h4>
          <p className="mb-6">
            The personal information we have collected from you will be shared with fraud
            prevention agencies who will use it to prevent fraud and money-laundering and
            to verify your identity. If fraud is detected, you could be refused certain
            services, finance or employment. Further details of how your information will
            be used by us and these fraud prevention agencies, and your data protection
            rights can be found in the Privacy Policy:
          </p>
          <div className="flex items-center">
            <Icon name="download" className="mr-2 text-link" />
            <a
              className="block text-base font-semibold underline text-link"
              href="https://www.dayinsure.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>

        <div className="font-lato text-sm font-normal leading-5 text-main-content-1">
          <h4 className="my-6 font-bold">Please read and confirm</h4>

          <ul className="pl-4 mt-6 list-disc">
            <li>
              The details you provided are accurate to the best of your knowledge and you
              will update them if they change.
            </li>
            <li>
              You have read the Policy handbook, Insurance Product Information Document
              and the respective add-on documents.
            </li>
            <div className="flex relative right-4 flex-col mb-6">
              <div className="flex items-center mt-6">
                <Icon name="download" className="mr-2 text-link" />
                <a
                  className="text-base font-semibold underline text-link"
                  href="https://www.dayinsure.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Policy handbook
                </a>
              </div>

              <div className="flex items-center mt-6">
                <Icon name="download" className="mr-2 text-link" />
                <a
                  className="text-base font-semibold underline text-link"
                  href="https://www.dayinsure.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Insurance Product Information Document
                </a>
              </div>
            </div>
            <li>
              You have read and you accept the Privacy Policy and our Terms of Business.
            </li>
            <div className="flex relative right-4 flex-col mb-6">
              <div className="flex items-center mt-6">
                <Icon name="download" className="mr-2 text-link" />
                <a
                  className="text-base font-semibold underline text-link"
                  href="https://www.dayinsure.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </div>

              <div className="flex items-center mt-6">
                <Icon name="download" className="mr-2 text-link" />
                <a
                  className="text-base font-semibold underline text-link"
                  href="https://www.dayinsure.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Business
                </a>
              </div>
            </div>

            <li>
              This product is suitable for customers that need insurance for a private
              motor vehicle, who want to manage their motor insurance online via a website
              or mobile app, coupled with telephone support when making and managing a
              claim.
            </li>
            <li>
              This product isn&apos;t suitable for customers that need short-term or
              temporary motor insurance.
            </li>
          </ul>
        </div>
      </Card>
    </section>
  );
};
