import { Button, Card, Icon } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { useContext, useRef } from 'react';
import {
  PaymentMobileFooter,
  QuoteJourneyLayout,
  ImportantInformation,
  FormCheckbox,
} from '../../../components';
import { usePaymentInfo } from '../../../hooks';
import { PaymentType } from '../YourQuote/PaymentType';
import { PaymentCard } from '../../../components/PaymentCard';
import { PayByDebitCard } from './PayByDebitCard';
import { QuoteJourneyFormData } from '../../../types';
import { QuoteJourneyStatusContext } from '../../../contexts';
import { PaymentContext } from '../../../contexts/PaymentContext';
import {
  isAnnualPaymentPlan,
  isMonthlyPaymentPlan,
  isYes,
  isLessThanTimeToStart,
} from '../../../helpers';
import { DirectDebitDetails } from './DirectDebitDetails';
import { Subtitle } from './Subtitle';

const testId = 'payment';
const TWO_HOURS = 2 * 60 * 60 * 1000;

export const Payment = () => {
  const cardFormRef = useRef<HTMLDivElement | null>(null);
  const monthlyFormRef = useRef<HTMLDivElement | null>(null);
  const { cardFormVisible } = useContext(PaymentContext);
  const { isLoadingState } = useContext(QuoteJourneyStatusContext);
  const {
    data,
    quoteId,
    handleChangePaymentType: onChangePaymentType,
    isLoading,
  } = usePaymentInfo();

  const {
    values: {
      cover,
      usualPaymentFrequency,
      isDirectDebitConfirmed,
      isAccountHolder,
      isMonthlyImportantInformationConfirmed,
      isAnnualImportantInformationConfirmed,
    },
    setFieldValue,
    setFieldTouched,
    validateForm,
    submitForm,
  } = useFormikContext<QuoteJourneyFormData>();
  const isPlanAnnual = isAnnualPaymentPlan(usualPaymentFrequency.code?.id);
  const isPlanMonthly = isMonthlyPaymentPlan(usualPaymentFrequency.code?.id);
  const userIsAccountHolder = isYes(isAccountHolder?.id);
  if (!data?.paymentPlans || !quoteId) {
    return null;
  }
  const isSubbmitTypeButton = isPlanAnnual || (isPlanMonthly && isDirectDebitConfirmed);
  const buttonLabel = isSubbmitTypeButton ? 'Pay with card' : 'Continue';
  const displayDebitCard = !isPlanMonthly || isDirectDebitConfirmed;
  const disabledByCheckboxState = displayDebitCard
    ? isAnnualImportantInformationConfirmed
    : isMonthlyImportantInformationConfirmed;
  const handleChangePaymentType = (option: { id: string; name: string }) => {
    setFieldValue('encryptedCardNumber', '');
    setFieldValue('cardholderName', '');
    setFieldValue('encryptedExpiryMonth', '');
    setFieldValue('encryptedExpiryYear', '');
    setFieldValue('encryptedSecurityCode', '');
    setFieldTouched('isAnnualImportantInformationConfirmed', false);
    setFieldTouched('isMonthlyImportantInformationConfirmed', false);
    setFieldTouched('encryptedCardNumber', false);
    setFieldTouched('cardholderName', false);
    setFieldTouched('encryptedExpiryMonth', false);
    setFieldTouched('encryptedExpiryYear', false);
    setFieldTouched('encryptedSecurityCode', false);
    onChangePaymentType(option);
  };

  const handleButtonClick = async () => {
    const errors = await validateForm();
    if (isDirectDebitConfirmed && Object.keys(errors).length === 0) {
      return submitForm();
    }
    if (Object.keys(errors).length === 0) {
      setFieldValue('isDirectDebitConfirmed', true);
    }

    const paymentFormContainer = monthlyFormRef.current || cardFormRef.current;

    if (paymentFormContainer) {
      setTimeout(
        () =>
          paymentFormContainer?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        0
      );
    }
    return null;
  };

  const paymentCardTitle = isPlanAnnual ? 'Payment' : '';

  return (
    <>
      <QuoteJourneyLayout>
        <h1
          className="mb-8 text-xl text-center md:text-left"
          data-testid={`${testId}_title`}
        >
          Payment
        </h1>
        <Card
          testId={`${testId}_cards_info-card`}
          paddingLevel="small"
          classNames="mb-6 lg:mb-8"
        >
          <span>Please enter your details below.</span>
        </Card>

        {isLessThanTimeToStart(cover.startDateTimeUtc?.parsedDate, TWO_HOURS) && (
          <Card
            testId={`${testId}_cards_date-info-card`}
            paddingLevel="small"
            classNames="!bg-popup-background flex items-center gap-4 md:gap-6 text-left"
          >
            <Icon name="warning" size="2rem" className="text-popup-content-1" />
            <span>
              The cover start date you selected is approaching. If payment is not
              processed in time, your cover dates may change, to start after the payment
              date.
            </span>
          </Card>
        )}

        <Form className="mb-16 sm:mb-24">
          <PaymentType
            disabled={isLoading}
            paymentPlans={data.paymentPlans}
            insurencePrice={data.insurancePrice}
            totalPriceIncAddOns={data.totalPriceIncAddOns}
            testId={testId}
            onChangeOptional={handleChangePaymentType}
            title="Payment type"
          />
          {isPlanMonthly && (
            <>
              <Subtitle title="Direct Debit" testId={`${testId}_direct-debit-subtitle`} />
              {!isDirectDebitConfirmed && (
                <>
                  <PaymentCard
                    title=""
                    addOns={data.addOns}
                    insurancePrice={data.insurancePrice}
                    paymentPlans={data.paymentPlans}
                    cover={data.cover}
                    totalPriceIncAddOns={data.totalPriceIncAddOns}
                    excesses={data.excesses}
                    disabled={isLoading}
                    testId={testId}
                    usualPaymentFrequency={usualPaymentFrequency}
                  />
                </>
              )}
            </>
          )}
          {isPlanMonthly && (
            <div ref={monthlyFormRef} id="monthly">
              <DirectDebitDetails testId={testId} />
            </div>
          )}
          {isPlanMonthly && isDirectDebitConfirmed && (
            <>
              <Subtitle title="Deposit" testId={`${testId}_initial-payment-subtitle`} />
              <PaymentCard
                title={paymentCardTitle}
                addOns={data.addOns}
                insurancePrice={data.insurancePrice}
                paymentPlans={data.paymentPlans}
                cover={data.cover}
                totalPriceIncAddOns={data.totalPriceIncAddOns}
                excesses={data.excesses}
                disabled={isLoading}
                testId={testId}
                isDirectDebit
                customSubtitle="Price to pay now"
                usualPaymentFrequency={usualPaymentFrequency}
              />
            </>
          )}
          {isPlanAnnual && (
            <PaymentCard
              title={paymentCardTitle}
              addOns={data.addOns}
              insurancePrice={data.insurancePrice}
              paymentPlans={data.paymentPlans}
              cover={data.cover}
              totalPriceIncAddOns={data.totalPriceIncAddOns}
              excesses={data.excesses}
              disabled={isLoading}
              testId={testId}
              usualPaymentFrequency={usualPaymentFrequency}
            />
          )}
          <div ref={cardFormRef}>{displayDebitCard && <PayByDebitCard />}</div>
          {displayDebitCard && (
            <ImportantInformation className="mb-8 md:mb-12" testId={testId} />
          )}

          <div className="mb-8 md:mb-12">
            {displayDebitCard ? (
              <FormCheckbox
                displayText="I confirm I have read and accept all the information"
                name="isAnnualImportantInformationConfirmed"
                id={`${testId}_confirm-information_input`}
                testId={`${testId}_confirm-information_input`}
              />
            ) : (
              <FormCheckbox
                displayText="I confirm I have read and accept all the information"
                name="isMonthlyImportantInformationConfirmed"
                id={`${testId}_confirm-information_input`}
                testId={`${testId}_confirm-information_input`}
              />
            )}
          </div>
          {cardFormVisible && (
            <Button
              id={`${testId}_continue_button`}
              testId={`${testId}_continue_button`}
              submit={isSubbmitTypeButton}
              fullWidth
              loading={isLoadingState}
              icon={{ name: 'arrow-right', position: 'right' }}
              text={buttonLabel}
              disabled={
                isLoadingState ||
                (isPlanMonthly && !userIsAccountHolder) ||
                !disabledByCheckboxState
              }
              onClick={handleButtonClick}
            />
          )}
        </Form>
        <PaymentMobileFooter />
      </QuoteJourneyLayout>
    </>
  );
};
