import clsx from 'clsx';
import { PasswordValidationRow } from './PasswordValidationRow';

type PasswordValidationCopy = {
  minCharacters: string;
  uppercase: string;
  lowercase: string;
  number: string;
  symbol: string;
  passwordMatch: string;
};

type RegexpsConfig = {
  uppercaseRegex: RegExp;
  lowercaseRegex: RegExp;
  numberRegex: RegExp;
  symbolRegex: RegExp;
};

type PasswordValidationComponentProps = {
  password: string;
  confirmPassword: string;
  variant?: 'default' | 'dialog';
  copy: PasswordValidationCopy;
  regexpsConfig: RegexpsConfig;
};

export const PasswordValidation = ({
  password,
  confirmPassword,
  variant = 'default',
  copy,
  regexpsConfig,
}: PasswordValidationComponentProps) => {
  const textColour = clsx({
    'text-main-content-1': variant === 'default',
    'text-popup-content-1': variant === 'dialog',
  });

  return (
    <div className={clsx('flex flex-col space-y-1 font-lato text-sm', textColour)}>
      <PasswordValidationRow
        isValid={password.length >= 8}
        copy={copy.minCharacters}
        variant={variant}
      />
      <PasswordValidationRow
        isValid={regexpsConfig.uppercaseRegex.test(password)}
        copy={copy.uppercase}
        variant={variant}
      />
      <PasswordValidationRow
        isValid={regexpsConfig.lowercaseRegex.test(password)}
        copy={copy.lowercase}
        variant={variant}
      />
      <PasswordValidationRow
        isValid={regexpsConfig.numberRegex.test(password)}
        copy={copy.number}
        variant={variant}
      />
      <PasswordValidationRow
        isValid={regexpsConfig.symbolRegex.test(password)}
        copy={copy.symbol}
        variant={variant}
      />
      <PasswordValidationRow
        isValid={!!(password && password === confirmPassword)}
        copy={copy.passwordMatch}
        variant={variant}
      />
    </div>
  );
};
