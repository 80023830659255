import { FormRadioSelect } from '../../../../components';
import { getOnOffOptions } from '../../../../helpers/forms/getOnOffOptions/getOnOffOptions';

type AutoRenewAllPoliciesRadioProps = {
  testId: string;
};
export const AutoRenewAllPoliciesRadio = ({ testId }: AutoRenewAllPoliciesRadioProps) => {
  return (
    <div className="my-6 md:my-16">
      <h2
        data-testid={`${testId}_renew-all-policies-label`}
        className="flex relative w-full font-raleway font-normal form-subtitle-compact"
      >
        Auto-renew all policies
      </h2>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          id={`${testId}_renew-all-policies-radio`}
          testId={`${testId}_renew-all-policies-radio`}
          name="renew"
          options={getOnOffOptions(`${testId}_renew-all-policies`)}
        />
      </div>
    </div>
  );
};
