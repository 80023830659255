import {
  ActiveAndUpcomingPoliciesProps,
  ExpiredAndCancelledPoliciesProps,
} from '@dayinsure/components';

export const activeAndUpcomingPoliciesCopy: ActiveAndUpcomingPoliciesProps['copy'] = {
  noPolicies: 'No active or upcoming policies.',
  startsIn: 'Starts in',
  endsIn: 'Ends in',
  buttonLabel: 'Manage policy',
};

export const expiredAndCancelledCopy: ExpiredAndCancelledPoliciesProps['copy'] = {
  buttonLabel: 'View policy',
  noPolicies: 'No previous or cancelled policies.',
};
