import { useMemo } from 'react';
import { FormListBox, FormRadioSelect, Label } from '../../../../../components';
import { testId, isImportOptions, seatsNumberOptions } from './CarSpecsForm.const';
import { useVehicleOptionsQuery } from '../../../../../hooks';
import { CarSpecsInputsName } from './CarSpecsForm.types';
import { baseOptionDtoToViewOptions } from '../../../../../helpers';
import { TrackingDevice } from '../../TrackingDevice';

export const CarSpecsForm = () => {
  const { data } = useVehicleOptionsQuery();
  const steerlingWheelOptions = useMemo(
    () =>
      data?.steeringWheelSides
        // remove option center
        ?.filter(option => option.code?.toLowerCase() !== 'c')
        .map(baseOptionDtoToViewOptions(`${testId}_steerling-wheel-side`)) || [],
    [data]
  );

  const alarmImmobiliserOptions = useMemo(
    () =>
      data?.alarmImmobiliserTypes?.map(
        baseOptionDtoToViewOptions(`${testId}_alarm-immobiliser`)
      ) || [],
    [data]
  );

  return (
    <div data-testid={testId}>
      <h3
        className="mb-8 font-raleway text-lg font-normal"
        data-testid={`${testId}_title`}
      >
        Car specs
      </h3>
      <div className="flex flex-col mb-4 md:mb-8">
        <Label
          text="Steering wheel side"
          icon="steering-wheel"
          testId={`${testId}_steerling-wheel-side_label`}
        />
        <FormRadioSelect
          name={`vehicle.${CarSpecsInputsName.STEERLING_WHEEL}`}
          options={steerlingWheelOptions}
          id={`${testId}_steerling-wheel-side_input`}
          testId={`${testId}_steerling-wheel-side_input`}
        />
      </div>

      <div className="flex flex-col mb-4 md:mb-8">
        <FormListBox
          icon="seat"
          label="Number of seats"
          name={`vehicle.${CarSpecsInputsName.SEATS}`}
          options={seatsNumberOptions}
          id={`${testId}_seats_input`}
          testId={`${testId}_seats_input`}
        />
      </div>
      <TrackingDevice testId={testId} />
      <div className="flex flex-col mb-4 md:mb-8">
        <FormListBox
          icon="alarm"
          label="Alarm / immobiliser"
          name={`vehicle.${CarSpecsInputsName.ALARM_IMMOBILISER}`}
          options={alarmImmobiliserOptions}
          id={`${testId}_alarm-immobiliser_input`}
          testId={`${testId}_alarm-immobiliser_input`}
        />
      </div>

      <div className="flex flex-col mb-4 md:mb-8">
        <Label
          text="Imported vehicle"
          icon="container"
          testId={`${testId}_imported-vehicle_label`}
        />
        <FormRadioSelect
          name={`vehicle.${CarSpecsInputsName.IS_IMPORTED}`}
          options={isImportOptions}
          id={`${testId}_imported-vehicle_input`}
          testId={`${testId}_imported-vehicle_input`}
        />
      </div>
    </div>
  );
};
