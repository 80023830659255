import { Card } from '@dayinsure/components';
import { CardsTable } from '../../../../../../components';
import ncbPopUpMock from '../../../../../../constants/ncbPopUpDataMock.json';
// TODO exchange mock for api data in task #9214
type AddonNCBDialogDescriptionProps = {
  testId: string;
};
export const ProtectedNCB = ({ testId }: AddonNCBDialogDescriptionProps) => {
  const { NoClaimsBonusText } = ncbPopUpMock;
  return (
    <div data-testid={testId}>
      <Card
        paddingLevel="small"
        classNames="!bg-popup-background text-left whitespace-pre-line font-lato leading-[18px] text-sm"
        testId={`${testId}_description`}
      >
        <div>{NoClaimsBonusText}</div>
      </Card>
      <CardsTable testId={testId} data={ncbPopUpMock.NoClaimsBonusRules} />
    </div>
  );
};
