import { Card } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { DateEntry } from '../../../../types';
import { EditCardLink } from '../../EditCardLink';
import { QuoteJourneyRoutes } from '../../../../routes';

type CoverStartDateItemProps = {
  startDate: DateEntry;
};

export const CoverStartDateItem = ({ startDate }: CoverStartDateItemProps) => {
  const formattedDate = formatInUKLocalTime('dd/MM/yyyy', startDate.parsedDate);
  return (
    <Card.CardListItem
      testId="quote-summary-card_edit-cover-start-date-item"
      label="Cover start date"
      asideItem={
        <EditCardLink
          to={QuoteJourneyRoutes.Cover}
          testId="quote-summary-card_edit-cover-start-date-link"
          className="text-white"
        >
          Edit
        </EditCardLink>
      }
      icon="date"
    >
      <span className="block">{formattedDate}</span>
    </Card.CardListItem>
  );
};
