import { PolicyMtaPaths } from '../../../../routes';
import { PolicyChangeFormData } from '../../../../types/mtaJourneyForm';

export const getNewCostBackLink = (
  hasEndorsments202or208: boolean,
  { changeCar, changeReg, changeDrivers }: PolicyChangeFormData['policyChange']
): string | undefined => {
  if (hasEndorsments202or208) {
    return `../${PolicyMtaPaths.CarSecurityPolicyConditions}`;
  }

  if (changeDrivers) {
    return `../../${PolicyMtaPaths.Drivers}`;
  }

  if (changeCar) {
    return `../../${PolicyMtaPaths.NewCarUsage}`;
  }

  if (changeReg) {
    return `../../${PolicyMtaPaths.NewReg}`;
  }

  return undefined;
};
