/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetListIAPIResult } from '../models/AssetListIAPIResult';
import type { ConfirmEmailChangeRequest } from '../models/ConfirmEmailChangeRequest';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { ConfirmPhoneNumberChangeRequest } from '../models/ConfirmPhoneNumberChangeRequest';
import type { ConvertToCustomerRequest } from '../models/ConvertToCustomerRequest';
import type { CreatePersonRequest } from '../models/CreatePersonRequest';
import type { EmailChangeRequest } from '../models/EmailChangeRequest';
import type { IAPIResult } from '../models/IAPIResult';
import type { IdCheckDataResponseListIAPIResult } from '../models/IdCheckDataResponseListIAPIResult';
import type { IdCheckStatus } from '../models/IdCheckStatus';
import type { IdCheckType } from '../models/IdCheckType';
import type { IdentityCheckReponseIAPIResult } from '../models/IdentityCheckReponseIAPIResult';
import type { IdentityCheckRequest } from '../models/IdentityCheckRequest';
import type { LicenceIAPIResult } from '../models/LicenceIAPIResult';
import type { MarketingPreferenceIEnumerableIAPIResult } from '../models/MarketingPreferenceIEnumerableIAPIResult';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PersonIAPIResult } from '../models/PersonIAPIResult';
import type { PhoneNumberChangeRequest } from '../models/PhoneNumberChangeRequest';
import type { PolicySummaryListIAPIResult } from '../models/PolicySummaryListIAPIResult';
import type { QuoteSummaryListIAPIResult } from '../models/QuoteSummaryListIAPIResult';
import type { SaveMarketingPreferencesRequest } from '../models/SaveMarketingPreferencesRequest';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdatePersonRequest } from '../models/UpdatePersonRequest';
import type { VerifyEmailAddressRequest } from '../models/VerifyEmailAddressRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PersonService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets a person given an ID
   * @param id An ID representing a person
   * @returns PersonIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Person(
    id: string,
  ): CancelablePromise<PersonIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates an existing person given the id.  Properties given will be added or updated
   * @param id An ID representing a person
   * @param requestBody Request containing a persons details.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1Person(
    id: string,
    requestBody?: UpdatePersonRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Deletes an existing person given the id.
   * @param id An ID representing a person
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public deleteApiV1Person(
    id: string,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get policy summaries by the user id.
   * @param personId An ID representing a person Id
   * @returns PolicySummaryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonPolicySummary(
    personId: string,
  ): CancelablePromise<PolicySummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/policy-summary',
      path: {
        'personId': personId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get quote summaries by the user id.
   * @param personId An ID representing a person Id
   * @param take An int value representing the number of quote summaries the user would like to see
   * @returns QuoteSummaryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonQuoteSummary(
    personId: string,
    take?: number,
  ): CancelablePromise<QuoteSummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/quote-summary',
      path: {
        'personId': personId,
      },
      query: {
        'take': take,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Creates a new person object given properties of that person
   * @param requestBody Request containing a persons details.
   * @returns PersonIAPIResult Success
   * @throws ApiError
   */
  public postApiV1Person(
    requestBody?: CreatePersonRequest,
  ): CancelablePromise<PersonIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Sends a new email verification email to the given email address if an unverified person with the email address exists
   * @param email The email address to send the verification to
   * @param requestBody Model containing the return Url
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonSendEmailVerification(
    email: string,
    requestBody?: SendVerificationEmailRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{email}/send-email-verification',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Verifies the email address of the user by security id.
   * @param personId The security id representing the person identification
   * @param requestBody Model containing the email details to be verified
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonVerifyEmailAddress(
    personId: string,
    requestBody?: VerifyEmailAddressRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/verify-email-address',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Checks email details and dispatches a verification email.
   * @param personId The security id representing the person identification
   * @param requestBody Model containing the new email details requested
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonRequestEmailChange(
    personId: string,
    requestBody?: EmailChangeRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/request-email-change',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Uses the email token to confirm the email change to an account and update username
   * @param personId The security id representing the person identification
   * @param requestBody Model containing the new email details to be confirmed and updated
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonConfirmEmailChange(
    personId: string,
    requestBody?: ConfirmEmailChangeRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/confirm-email-change',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Sends a password reset email.
   * @param email The email address to send the password reset email to.
   * @param requestBody Model containing the return Url.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonSendPasswordReset(
    email: string,
    requestBody?: SendPasswordResetRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{email}/send-password-reset',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Uses the password token to confirm request and updates the email
   * @param personId The security id representing the person identification
   * @param requestBody Model containing password and password token
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonConfirmPasswordReset(
    personId: string,
    requestBody?: ConfirmPasswordResetRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/confirm-password-reset',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates the current password with the new password provided
   * @param personId The security id representing the person identification
   * @param requestBody Model containing the current and new password
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonChangePassword(
    personId: string,
    requestBody?: PasswordChangeRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{personId}/change-password',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Checks phone number details and dispatches a verification code.
   * @param personId The security id representing the person identification
   * @param requestBody Model containing the new phone number details requested
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonRequestPhoneNumberChange(
    personId: string,
    requestBody?: PhoneNumberChangeRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/request-phone-number-change',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Uses the phone token to confirm the phone number change to an account
   * @param personId The security id representing the person identification
   * @param requestBody Model containing the new phone number details to be confirmed and updated
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonConfirmPhoneNumberChange(
    personId: string,
    requestBody?: ConfirmPhoneNumberChangeRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/confirm-phone-number-change',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets all assets belonging to a person
   * @param personId The security id representing the person identification
   * @returns AssetListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonAsset(
    personId: string,
  ): CancelablePromise<AssetListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/asset',
      path: {
        'personId': personId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Save marketing preferences for a person
   * @param personId The security id representing the person identification
   * @param requestBody Request containg the marketing data to be saved for the user
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonMarketingPreferences(
    personId: string,
    requestBody?: SaveMarketingPreferencesRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{personId}/marketing-preferences',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Gets all marketing preferences belonging to a person
   * @param personId The security id representing the person identification
   * @returns MarketingPreferenceIEnumerableIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonMarketingPreferences(
    personId: string,
  ): CancelablePromise<MarketingPreferenceIEnumerableIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/marketing-preferences',
      path: {
        'personId': personId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a licence belonging to a person.
   * @param id The security id representing the person identification
   * @returns LicenceIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonLicence(
    id: string,
  ): CancelablePromise<LicenceIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/licence',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Create/Update an account for end user
   * @param id An ID representing a security id
   * @param requestBody Model containing request details
   * @returns IdentityCheckReponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonIdCheck(
    id: string,
    requestBody?: IdentityCheckRequest,
  ): CancelablePromise<IdentityCheckReponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{id}/id-check',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get the ID Checks for a person.
   * @param id An ID representing a security id
   * @param type The Type of ID Check
   * @param drivingLicenceNumber The Driving Licence Number of the person
   * @param groupId
   * @param status
   * @returns IdCheckDataResponseListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonIdCheck(
    id: string,
    type?: IdCheckType,
    drivingLicenceNumber?: string,
    groupId?: number,
    status?: IdCheckStatus,
  ): CancelablePromise<IdCheckDataResponseListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/id-check',
      path: {
        'id': id,
      },
      query: {
        'type': type,
        'drivingLicenceNumber': drivingLicenceNumber,
        'groupId': groupId,
        'status': status,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * convert guest into DI account holder
   * @param id The security id representing the person identification
   * @param requestBody Model containing the current and new password
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonConvertToCustomer(
    id: string,
    requestBody?: ConvertToCustomerRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}/convert-to-customer',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

}