const calculateNextCycle = (interval: number) => {
  const timeStampCurrentOrOldDate = Date.now();
  const timeStampStartOfDay = new Date().setHours(0, 0, 0, 0);
  const timeDiff = timeStampCurrentOrOldDate - timeStampStartOfDay;
  const mod = Math.ceil(timeDiff / interval);
  return new Date(timeStampStartOfDay + mod * interval);
};

const fiveMinutesInterval = 5 * 60 * 1000;

export const getNextSignificantTodayStartTime = () =>
  calculateNextCycle(fiveMinutesInterval);
