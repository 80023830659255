import { FormRadioSelect } from '../../../../components';
import { YesNoAnswer } from '../../../../types/YesNoAnswer';
import { YesNoDataEntry } from '../../../../types/quoteJourneyForm';

const getIsHomeOwnerOptions = (testIdPrefix: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testIdPrefix}_homeowner-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testIdPrefix}_homeowner-no`,
  },
];

type IsHomeOwnerProps = {
  testId: string;
};

export const IsHomeOwner = ({ testId }: IsHomeOwnerProps) => (
  <div className="my-6 md:my-16">
    <h2
      data-testid={`${testId}_is-homeowner-label`}
      className="flex relative w-full font-raleway font-normal form-subtitle-compact"
    >
      Do you own your own home?
    </h2>
    <div className="mb-4 md:mb-8">
      <FormRadioSelect
        id={`${testId}_is-homeowner-radio`}
        testId={`${testId}_is-homeowner-radio`}
        name="proposer.isHomeOwner"
        options={getIsHomeOwnerOptions(testId)}
      />
    </div>
  </div>
);
