export type BreakdownPackage = {
  isIncluded: boolean;
  description: string;
};

export const breakdownPackages: BreakdownPackage[] = [
  {
    isIncluded: false,
    description: 'Assistance at the side of the road',
  },
  {
    isIncluded: false,
    description: 'Recovery to any UK destination',
  },
  {
    isIncluded: false,
    description: 'Assistance at your home',
  },
  {
    isIncluded: false,
    description: 'Hire car',
  },
  {
    isIncluded: false,
    description: 'Overnight accommodation',
  },
  {
    isIncluded: false,
    description: 'Breakdown assistance in Europe',
  },
];
