import { Card, Icon } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { QuoteJourneyFormData } from '../../../types/quoteJourneyForm';
import { isAnnualPaymentPlan } from '../../../helpers/utils/getters';

type BenefitsProps = {
  testId?: string;
};

export const Benefits = ({ testId = 'payments-benefits' }: BenefitsProps) => {
  const { values } = useFormikContext<QuoteJourneyFormData>();

  const getPaymentName = () =>
    isAnnualPaymentPlan(values.usualPaymentFrequency.code?.id) ? 'annually' : 'monthly';

  if (!values.usualPaymentFrequency.code?.id) {
    return null;
  }

  return (
    <section className="mb-12">
      <h2
        data-testid={`${testId}_benefits-${getPaymentName()}-label`}
        className="flex relative w-full font-raleway font-normal form-subtitle-compact"
      >
        Benefits of paying {getPaymentName()}
      </h2>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <Card
          testId={`${testId}_benefits_cards_info-card`}
          paddingLevel="small"
          classNames="!h-full flex-1 bg-content-background-prominent mb-6 flex flex-col justify-center items-center gap-4 text-center"
        >
          {isAnnualPaymentPlan(values.usualPaymentFrequency.code?.id) ? (
            <Icon name="paying-monthly" size="2rem" />
          ) : (
            <Icon name="direct-debit" size="2rem" />
          )}
          <span>
            {isAnnualPaymentPlan(values.usualPaymentFrequency.code?.id)
              ? 'Cheaper than paying monthly'
              : 'Pay by monthly Direct Debit'}
          </span>
        </Card>
        <Card
          testId={`${testId}_benefits_cards_info-card`}
          paddingLevel="small"
          classNames="!h-full flex-1 bg-content-background-prominent mb-6 flex flex-col justify-center items-center gap-4 text-center"
        >
          {isAnnualPaymentPlan(values.usualPaymentFrequency.code?.id) ? (
            <Icon name="stars" size="2rem" />
          ) : (
            <Icon name="year-calendar" size="2rem" />
          )}

          <span>
            {isAnnualPaymentPlan(values.usualPaymentFrequency.code?.id)
              ? 'Simple, pay up-front plan'
              : 'Spread the cost across the year'}
          </span>
        </Card>
      </div>
    </section>
  );
};
