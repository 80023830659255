import clsx from 'clsx';
import Icon, { IconNames } from '../../DataDisplay/Icon/Icon';
import { onEnter } from '../../../helpers/keyboard';
import FieldError from '../../Feedback/FieldError/FieldError';

type PaymentDateFieldProps = {
  iframeIdMonth: string;
  iframeIdYear: string;
  label: {
    icon: IconNames;
    text: string;
  };
  securedFields: { setFocusOn: (field: string) => void } | null;
  focused?: boolean;
  disabled?: boolean;
  error?: string;
};

export const PaymentDateField = ({
  iframeIdMonth,
  label,
  focused,
  disabled,
  error,
  iframeIdYear,
  securedFields,
}: PaymentDateFieldProps) => {
  const focusMonthField = () => {
    securedFields?.setFocusOn(iframeIdMonth);
  };
  const focusYearField = () => {
    securedFields?.setFocusOn(iframeIdYear);
  };

  return (
    <div className="relative form-field-spacing">
      <div className="flex space-x-2">
        <label htmlFor={iframeIdMonth} className="w-full">
          <span
            className={clsx(
              'inline-flex gap-2 items-center mb-4 leading-5 md:gap-3 text-main-content-1'
            )}
          >
            <Icon name={label.icon} />
            {label.text}
          </span>

          <div
            className={clsx(
              'bg-transparent rounded-lg border-2 transition-colors focus:border-main-content-1 field-base',
              { 'opacity-40': disabled },
              { 'border-main-content-3': !error && !focused },
              { 'border-error-border': error },
              { 'border-main-content-1': focused }
            )}
          >
            <span
              id={iframeIdMonth}
              role="button"
              aria-label="focus"
              tabIndex={0}
              onKeyDown={onEnter(focusMonthField)}
              onClick={focusMonthField}
              data-cse={iframeIdMonth}
              className="flex justify-evenly w-full h-6 text-center bg-transparent border-r focus:outline-none lg:h-8 payment-date text-main-content-1 placeholder:text-main-content-2 border-main-content-4"
            />
            <span
              role="button"
              aria-label="focus"
              tabIndex={0}
              onKeyDown={onEnter(focusYearField)}
              onClick={focusYearField}
              data-cse={iframeIdYear}
              className="flex justify-evenly w-full h-6 text-center bg-transparent border-l focus:outline-none lg:h-8 payment-date text-main-content-1 placeholder:text-main-content-2 border-main-content-4"
            />
          </div>
        </label>
      </div>
      {error && (
        <FieldError
          id={`${iframeIdMonth}-error`}
          error={error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
