import Icon from '@dayinsure/components/src/components/DataDisplay/Icon/Icon';
import { LinkWithReferrer } from '@dayinsure/shared';
import clsx from 'clsx';
import { LinkProps } from 'react-router-dom';

export type EditCardLinkProps = { testId?: string } & LinkProps;

export const EditCardLink = ({ testId, className, ...restProps }: EditCardLinkProps) => {
  return (
    <LinkWithReferrer
      data-testid={testId}
      className={clsx(
        'flex items-center text-sm font-semibold leading-[14px]',
        className
      )}
      {...restProps}
    >
      <Icon name="edit" className="mr-2" size="12px" />
      Edit
    </LinkWithReferrer>
  );
};
