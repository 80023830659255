export const addCommaToNumber = (arr: string[]) =>
  arr.reduce((res: string[], el: string, i: number) => {
    if ((i + 1) % 3 === 0 && i !== arr.length - 1) {
      return [...res, el, ','];
    }
    return [...res, el];
  }, []);

export const transformForCommaAndNoCommaValue = (val: string) => {
  const valueArr = Array.from(val);
  const comaFilterArr = valueArr.filter((letter: string) => letter !== ',');
  const reversedFilteredArr = [...comaFilterArr].reverse();
  const addComma = addCommaToNumber(reversedFilteredArr);
  const valueWithComa = addComma.reverse().join('');
  const formValue = comaFilterArr.join('');
  return { formValue: Number(formValue), valueWithComa };
};
