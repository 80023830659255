import { useCallback } from 'react';
import { useVehicleOptionsQuery } from '../../../hooks';
import { FormListBox } from '../../FormsFields';

type CarLocationProps = {
  testId: string;
};

export const CarLocation = ({ testId }: CarLocationProps) => {
  const { data } = useVehicleOptionsQuery();

  const getCarUsageOptions = useCallback(
    (testIdPrefix: string) =>
      data?.vehicleKeptLocations?.map(option => ({
        id: option?.code || '',
        name: option?.description || '',
        testId: `${testIdPrefix}_option_${option.code}`,
      })) || [],
    [data]
  );

  return (
    <div data-testid={`${testId}_car-kept`} className="my-8 md:my-16">
      <h3
        className="mb-6 font-raleway text-lg font-normal md:mb-12"
        data-testid={`${testId}_car-kept_title`}
      >
        Where will the car be parked?
      </h3>
      <div className="flex relative flex-col mb-4 md:mb-8">
        <FormListBox
          unselectedMessage="Please select"
          name="vehicle.location.daytimeLocation"
          options={getCarUsageOptions(`${testId}_car-kept_day`)}
          id={`${testId}_car-kept_day-select`}
          testId={`${testId}_car-kept_day-select`}
          icon="sun"
          label="During the day"
        />
      </div>
      <div className="flex relative flex-col mb-4 md:mb-8">
        <FormListBox
          unselectedMessage="Please select"
          name="vehicle.location.overnightLocation"
          options={getCarUsageOptions(`${testId}_car-kept_overnight`)}
          id={`${testId}_car-kept_overnight-select`}
          testId={`${testId}_car-kept_overnight-select`}
          icon="moon"
          label="At night"
        />
      </div>
    </div>
  );
};
