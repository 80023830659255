import { Card } from '@dayinsure/components';
import { DriverCardsProps } from '../DriverCards.types';
import { getDriverAddress } from '../../../../../../helpers';

export const DriverHomeCard = ({ driver, testId }: DriverCardsProps) => (
  <Card testId={`${testId}_drivers-home-card`} classNames="w-full mb-6">
    <h3 className="font-raleway text-lg font-normal">Policyholder’s home</h3>
    <Card.CardListItem
      label="Address"
      icon="location"
      testId={`${testId}_drivers-home-card_list-item_address`}
    >
      {getDriverAddress(driver.address?.formattedAddress)}
    </Card.CardListItem>
  </Card>
);
