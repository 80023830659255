import { Formik, FormikHelpers } from 'formik';
import { ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalErrorContext } from '@dayinsure/shared';
import { PageContainer } from '@dayinsure/components/src/components/Layout';
import { PolicyMtaPaths } from '../../../routes';
import { getCurrentMtaStepFromRoute } from '../../../helpers';
import { useNextStepMtaSubmit } from '../../../hooks/submitHandlers/useNextStepMtaSubmit';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import {
  getValidationMtaSchemaFromRoute,
  mtaJourneyInitialFormData,
} from '../../../forms/mtaJourney';
import { usePolicyChangeSubmit } from '../../../hooks/submitHandlers/usePolicyChangeSubmit';
import { useNewCarUsageSubmit } from '../../../hooks/submitHandlers/useNewCarUsageSubmit';
import {
  useMtaAdditionalDriverDetailsSubmit,
  useMtaAdditionalDriverLicenceAndHistorySubmit,
  usePaymentSubmit,
} from '../../../hooks';
import { QuoteLoader } from '../../Quote/QuoteJourney/QuoteLoader';
import { withQuoteJourneyStatusContextWrapper } from '../../../contexts';
import { useMtaNestedStepSubmit } from '../../../hooks/submitHandlers/useMtaNestedStepSubmit';
import { useCreateMtaQuoteSubmit } from '../../../hooks/submitHandlers/useCreateMtaQuoteSubmit';
import { useCarSecuritySubmit } from '../../../hooks/submitHandlers/useCarSecuritySubmit';
import { CancelMTAChangesContextProvider } from '../../../contexts/CancelMTAChangesContext';
import { CancelMTAChangesDialog } from './CancelMTAChangesDialog';

type MTAJourneyProps = {
  children: ReactNode;
};

export const MTAJourney = ({ children }: MTAJourneyProps) => {
  const { pathname } = useLocation();
  const { setGlobalError } = useContext(GlobalErrorContext);
  const currentMtaStep = getCurrentMtaStepFromRoute(pathname);
  const policyChangeSubmit = usePolicyChangeSubmit();
  const newCarUsageSubmit = useNewCarUsageSubmit();
  const nextStepSubmitHandler = useNextStepMtaSubmit(currentMtaStep);
  const paymentSubmitHandler = usePaymentSubmit(true);
  const nestedQouteIdSubmit = useMtaNestedStepSubmit(currentMtaStep);
  const additionalDriverDetailsSubmit =
    useMtaAdditionalDriverDetailsSubmit(currentMtaStep);
  const additionalDriverLicenceAndHistorySubmit =
    useMtaAdditionalDriverLicenceAndHistorySubmit(currentMtaStep);

  const createMtaQuoteSubmit = useCreateMtaQuoteSubmit();
  const carSecuritySubmitHandler = useCarSecuritySubmit(true);
  const handleSubmit = (
    values: MtaJourneyFormData,
    formikHelpers: FormikHelpers<MtaJourneyFormData>
  ) => {
    try {
      switch (currentMtaStep) {
        case PolicyMtaPaths.PolicyChange:
          policyChangeSubmit(values, formikHelpers);
          break;
        case PolicyMtaPaths.NewCarUsage:
        case PolicyMtaPaths.NewReg:
          newCarUsageSubmit(values, formikHelpers);
          break;
        case PolicyMtaPaths.NewCost:
          nestedQouteIdSubmit(formikHelpers);
          break;
        case PolicyMtaPaths.Drivers:
          createMtaQuoteSubmit(values, formikHelpers);
          break;
        case PolicyMtaPaths.CarSecurity:
          carSecuritySubmitHandler(
            values.carSecurity,
            formikHelpers,
            nestedQouteIdSubmit
          );
          break;
        case PolicyMtaPaths.CarSecurityPolicyConditions:
          nestedQouteIdSubmit(formikHelpers);
          break;
        case PolicyMtaPaths.PolicyConditions:
          nestedQouteIdSubmit(formikHelpers);
          break;
        case PolicyMtaPaths.DriverDetails:
          additionalDriverDetailsSubmit(formikHelpers);
          break;
        case PolicyMtaPaths.DriverLicenceAndHistory:
          additionalDriverLicenceAndHistorySubmit(values, formikHelpers);
          break;
        case PolicyMtaPaths.Review:
          nestedQouteIdSubmit(formikHelpers);
          break;
        case PolicyMtaPaths.Payment:
          paymentSubmitHandler(values);
          break;
        default:
          nextStepSubmitHandler(formikHelpers);
      }
    } catch (error) {
      setGlobalError(error);
    }
  };

  return (
    <PageContainer>
      <QuoteLoader />
      <Formik<MtaJourneyFormData>
        initialValues={mtaJourneyInitialFormData}
        onSubmit={handleSubmit}
        validationSchema={getValidationMtaSchemaFromRoute(pathname)}
      >
        <CancelMTAChangesContextProvider>
          <>
            {children}
            <CancelMTAChangesDialog />
          </>
        </CancelMTAChangesContextProvider>
      </Formik>
    </PageContainer>
  );
};

export const MTAJourneyWrapper =
  withQuoteJourneyStatusContextWrapper<MTAJourneyProps>(MTAJourney);
