import { FormTooltip } from '../../../../../components';

type DrivingConvictionsTooltipProps = {
  testId: string;
};

export const DrivingConvictionsTooltip = ({ testId }: DrivingConvictionsTooltipProps) => {
  return (
    <FormTooltip testId={`${testId}_convictions`}>
      <h4 className="mb-2 font-bold">Driving convictions</h4>
      <p className="mb-2">
        Details of driving convictions and driving licence endorsements can be found on
        your driving licence. You must declare all convictions, driving licence
        endorsements and fixed penalties received in the last five years regardless of
        whether you received them on your car, motorbike, van or any other motor vehicle.
      </p>
      <p>
        <b>Attention!</b> If you have a TT99 conviction code (a disqualification under a
        &apos;totting up&apos; procedure) you’ll need to disclose all of the convictions
        that led to the disqualification. If you don’t know this information, you can
        obtain it from the convicting court.
      </p>
    </FormTooltip>
  );
};
