import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared/src/contexts/GlobalErrorContext';
import { useQuery } from 'react-query';
import {
  PaymentMethodResponseIAPIResult,
  ApiError,
  OpenAPI,
  Dayinsure as DayInsureAPI,
} from '../../../api/v1';
import { query } from '../queryKeys';

export const usePaymentMethodQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getPaymentMethods = () => {
    const api = new DayInsureAPI(OpenAPI);

    return api.paymentMethod.getApiV1PaymentMethod(
      undefined,
      undefined,
      undefined,
      undefined,
      'annual'
    );
  };

  return useQuery<PaymentMethodResponseIAPIResult, ApiError>(
    query.paymentMethods,
    getPaymentMethods,
    {
      onError: setGlobalError,
    }
  );
};
