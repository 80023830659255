import { Card } from '@dayinsure/components';
import { transformForCommaAndNoCommaValue } from '../../../../../helpers';

type CarValueCardProps = {
  testId: string;
  value: string | number;
};

export const CarValueCard = ({ testId, value }: CarValueCardProps) => {
  const valueWithComma = transformForCommaAndNoCommaValue(String(value));
  return (
    <Card classNames="w-full mb-6" testId={`${testId}_car-value-card`}>
      <h3 className="font-raleway text-lg font-normal">Car value</h3>
      <Card.CardListItem
        label="Estimated value"
        icon="chart"
        testId="car-details-card_vehicle-estimated-value"
      >
        {`£${valueWithComma.valueWithComa}`}
      </Card.CardListItem>
    </Card>
  );
};
