import { useContext, useState } from 'react';
import { Button, ContentContainer, PageContainer, Toast } from '@dayinsure/components';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { CreditCard } from '../CreditCard';
import { usePaymentMethodQuery } from '../../../../hooks/queries/usePaymentMethodQuery';
import { useRemovePaymentMethodMutation } from '../../../../hooks/mutations/useRemovePaymentMethodMutation/useRemovePaymentMutation';
import { AccountPaths } from '../../../../routes';
import { StoredPaymentMethod } from '../../../../api/v1';
import { QuoteLoader } from '../../../Quote/QuoteJourney/QuoteLoader';

const testId = 'account-payment-cards';
export const SavedCards = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const [successToastState, setSuccessToastState] = useState(false);
  const {
    data: paymentMethods,
    isLoading: paymentsLoading,
    error: paymentsError,
  } = usePaymentMethodQuery();
  const navigate = useNavigateWithReferrer();

  const removePaymentMethodMutation = useRemovePaymentMethodMutation();

  if (paymentsError) {
    setGlobalError('Payment method error occurred!');
    return null;
  }

  const openSuccessToast = () => {
    setSuccessToastState(true);
  };

  const closeSuccessToast = () => {
    setSuccessToastState(false);
  };

  const navigateAddCard = () => {
    navigate(`../${AccountPaths.PaymentsAddCard}`);
  };

  const removePayment = (paymentId: string | undefined | null) => {
    if (paymentId && paymentId !== null) {
      removePaymentMethodMutation
        .mutateAsync({ paymentMethodId: paymentId })
        .then(() => {
          openSuccessToast();
        })
        .catch(() => {
          setGlobalError('Removing payment method error occurred!');
        });
    }
  };

  return (
    <PageContainer className="font-raleway">
      <ContentContainer>
        <h1
          className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
          data-testid={`${testId}_form-title`}
        >
          Saved cards
        </h1>

        {paymentsLoading && <QuoteLoader />}

        {paymentMethods?.detail?.storedPaymentMethods &&
          paymentMethods?.detail?.storedPaymentMethods?.map(
            (card: StoredPaymentMethod, index: number) => (
              <div key={card.id}>
                <CreditCard
                  testId={`${testId}_card-${index}`}
                  card={card}
                  count={paymentMethods?.detail?.storedPaymentMethods?.length || 0}
                  index={index}
                  onRemove={removePayment}
                />
              </div>
            )
          )}

        <nav>
          <Button
            classNames="mb-16 mt-4"
            id={`${testId}_add-card-button`}
            testId={`${testId}_add-card-button`}
            fullWidth
            text="Add a new card"
            onClick={navigateAddCard}
          />
        </nav>
        <Toast
          id="card-list-toast"
          message="Card removed"
          open={successToastState}
          onClose={closeSuccessToast}
        />
      </ContentContainer>
    </PageContainer>
  );
};
