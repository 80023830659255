import { Card, onEnter } from '@dayinsure/components';
import clsx from 'clsx';
import { useMemo, useState } from 'react';

// TODO remove types and use when model will be returned from api
type BonusClaimsDetails = {
  OrderIndex: number;
  ClaimCountTitle: string;
  BonusAtRenewal: string;
};

type NoClaimsBonusRules = {
  Title: string;
  OrderIndex: number;
  ClaimsDetails: BonusClaimsDetails[];
};

type CardsTableProps = {
  testId: string;
  data: NoClaimsBonusRules[];
};
export const CardsTable = ({ testId, data }: CardsTableProps) => {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const renderCardsButtons = useMemo(() => {
    const sortedNoClaimsBonusRules = data?.sort(
      (a: NoClaimsBonusRules, b: NoClaimsBonusRules) => a.OrderIndex - b.OrderIndex
    );
    return sortedNoClaimsBonusRules.map((rule: NoClaimsBonusRules, i: number) => (
      <span
        data-testid={`${testId}_select_years_${i}`}
        role="button"
        tabIndex={i}
        key={rule.OrderIndex}
        className={clsx(
          {
            'underline underline-offset-8 text-link': activeCardIndex === i,
          },
          'pt-4 mr-6 cursor-pointer'
        )}
        onClick={() => setActiveCardIndex(i)}
        onKeyDown={onEnter(() => setActiveCardIndex(i))}
      >
        {rule.Title}
      </span>
    ));
  }, [data, testId, activeCardIndex]);

  const renderCardBody = useMemo(() => {
    const currentRule = data[activeCardIndex];
    const sortedClaimDetails = currentRule.ClaimsDetails.sort(
      (a: BonusClaimsDetails, b: BonusClaimsDetails) => a.OrderIndex - b.OrderIndex
    );
    return sortedClaimDetails.map((claim: BonusClaimsDetails) => (
      <div
        key={claim?.OrderIndex}
        className="flex px-4 border-b-2 md:px-6 lg:px-8 border-content-background"
      >
        <div className="py-4 w-full border-r-2 border-content-background">
          {claim?.ClaimCountTitle}
        </div>
        <div className="p-4 w-full md:px-6 lg:px-8">{claim?.BonusAtRenewal}</div>
      </div>
    ));
  }, [data, activeCardIndex]);
  return (
    <Card
      paddingLevel="none"
      classNames="!bg-popup-background text-left whitespace-pre-line font-lato leading-[18px] text-sm mt-6"
      testId={`${testId}_description`}
    >
      <div className="p-4 font-bold md:px-6 lg:px-8 bg-content-background-prominent">
        No Claims Bonus years at the start of the period of insurance
      </div>
      <div className="px-4 pt-4 md:px-6 lg:px-8">Select years</div>
      <div className="flex flex-wrap px-4 pb-8 font-bold md:px-6 lg:px-8">
        {renderCardsButtons}
      </div>
      <div className="flex  px-4 font-bold md:px-6 lg:px-8 bg-content-background-prominent">
        <div className="py-4 w-full border-r-2 border-content-background">Claims</div>
        <div className="p-4 w-full md:px-6 lg:px-8">No Claims Bonus at next renewal</div>
      </div>
      {renderCardBody}
    </Card>
  );
};
