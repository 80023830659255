import { WheelEvent } from 'react';

export const numberInputOnWheelPreventChange = (e: WheelEvent<HTMLInputElement>) => {
  const target = e.target as HTMLInputElement;
  // Prevent the input value change
  target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the currentfunction is done)
  setTimeout(() => {
    target.focus();
  }, 0);
};
