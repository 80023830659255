import { useField, useFormikContext } from 'formik';
import { AccidentOrClaimType } from '../../../../../../../types';

export const useAccidentOrClamiFields = (name: string) => {
  const { validateField, setFieldTouched, setFieldValue, setFieldError } =
    useFormikContext();
  const [accidentOrClaimField, accidentOrClaimMeta] = useField<AccidentOrClaimType>({
    name,
  });
  const [selfDeclaredClaimTypeField, selfDeclaredClaimTypeMeta] = useField<
    AccidentOrClaimType['selfDeclaredClaimType']
  >({
    name: `${name}.selfDeclaredClaimType`,
  });
  const [claimDateField, claimDateMeta] = useField<AccidentOrClaimType['claimDate']>({
    name: `${name}.claimDate`,
  });
  const [faultField, faultMeta] = useField<AccidentOrClaimType['fault']>({
    name: `${name}.fault`,
  });
  const [claimSettledField, claimSettledMeta] = useField<
    AccidentOrClaimType['claimSettled']
  >({
    name: `${name}.claimSettled`,
  });
  const [claimAmountField, claimAmountMeta] = useField<
    AccidentOrClaimType['claimAmount']
  >({
    name: `${name}.claimAmount`,
  });
  const [isSavedField, isSavedMeta] = useField<AccidentOrClaimType['isSaved']>({
    name: `${name}.isSaved`,
  });
  const [knowClaimAmountField, knowClaimAmountMeta] = useField<
    AccidentOrClaimType['knowClaimAmount']
  >({
    name: `${name}.knowClaimAmount`,
  });
  return {
    accidentOrClaim: {
      value: accidentOrClaimField.value,
      meta: accidentOrClaimMeta,
      fieldName: accidentOrClaimField.name,
    },
    selfDeclaredClaimType: {
      value: selfDeclaredClaimTypeField.value,
      meta: selfDeclaredClaimTypeMeta,
      fieldName: selfDeclaredClaimTypeField.name,
    },
    claimDate: {
      value: claimDateField.value,
      meta: claimDateMeta,
      fieldName: claimDateField.name,
    },
    fault: {
      value: faultField.value,
      meta: faultMeta,
      fieldName: faultField.name,
    },
    claimSettled: {
      value: claimSettledField.value,
      meta: claimSettledMeta,
      fieldName: claimSettledField.name,
    },
    claimAmount: {
      value: claimAmountField.value,
      meta: claimAmountMeta,
      fieldName: claimAmountField.name,
    },
    isSaved: {
      value: isSavedField.value,
      meta: isSavedMeta,
      fieldName: isSavedField.name,
    },
    knowClaimAmount: {
      value: knowClaimAmountField.value,
      meta: knowClaimAmountMeta,
      fieldName: knowClaimAmountField.name,
    },
    validateField,
    setFieldTouched,
    setFieldValue,
    setFieldError,
  };
};
