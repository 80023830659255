import { useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers, isEmptyArray } from 'formik';
import { QuoteJourneyRoutes, quoteJourneyMap } from '../../../routes';
import { getEmptyProposerDriver } from '../../../helpers';
import { DriverFormData, QuoteJourneyFormData } from '../../../types';

export const useCarUsageSubmit = (currentQuoteStep: QuoteJourneyRoutes | null) => {
  const navigate = useNavigateWithReferrer();
  const next = quoteJourneyMap.getNext(currentQuoteStep);
  return (
    drivers: DriverFormData[],
    { setSubmitting, setTouched, setFieldValue }: FormikHelpers<QuoteJourneyFormData>
  ) => {
    if (isEmptyArray(drivers)) {
      setFieldValue('drivers[0]', getEmptyProposerDriver());
    }
    if (next) {
      navigate(next);
    }
    setSubmitting(false);
    setTouched({}, false);
  };
};
