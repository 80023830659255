import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { FormNumField } from '../../FormsFields';
import {
  digitOnlyMask,
  isBusinessUsage,
  divideByWeeksInYearAnRoundTo,
} from '../../../helpers';
import { QuoteJourneyFormData } from '../../../types';

type CarUsageMilesProps = {
  testId: string;
};

export const CarUsageMiles = ({ testId }: CarUsageMilesProps) => {
  const {
    values: {
      vehicle: {
        usage: { type: usageType, annualMileage, businessMileage },
      },
    },
    setFieldTouched,
  } = useFormikContext<QuoteJourneyFormData>();
  const annualMilesPerWeek = divideByWeeksInYearAnRoundTo(annualMileage, 5);
  const businessMilesPerWeek = divideByWeeksInYearAnRoundTo(businessMileage, 5);
  const shouldShowBusinessAnnualMileage = isBusinessUsage(usageType?.id);

  useEffect(() => {
    if (shouldShowBusinessAnnualMileage) {
      setFieldTouched('vehicle.usage.businessMileage', false);
    }
  }, [setFieldTouched, shouldShowBusinessAnnualMileage]);

  return (
    <div data-testid={`${testId}_miles`} className="my-8 md:my-16">
      <h3
        className="mb-6 font-raleway text-lg font-normal md:mb-12"
        data-testid={`${testId}_miles_title`}
      >
        What will be the total annual mileage?
      </h3>
      <div className="flex relative flex-col mb-8 md:mb-12">
        <FormNumField
          placeholder="Enter an amount"
          name="vehicle.usage.annualMileage"
          id={`${testId}_miles_input`}
          testId={`${testId}_miles_personal-miles_input`}
          label={{ text: 'Personal miles', icon: 'user' }}
          tooltip="Enter your personal miles excluding your business miles."
          onInput={digitOnlyMask}
          milesPerWeek={annualMilesPerWeek || undefined}
        />
      </div>
      {shouldShowBusinessAnnualMileage && (
        <div className="flex relative flex-col mb-8 md:mb-12">
          <FormNumField
            placeholder="Enter an amount"
            name="vehicle.usage.businessMileage"
            id={`${testId}_miles_business-miles_input`}
            testId={`${testId}_miles_input`}
            label={{ text: 'Business miles', icon: 'briefcase' }}
            onInput={digitOnlyMask}
            milesPerWeek={businessMilesPerWeek || undefined}
          />
        </div>
      )}
    </div>
  );
};
