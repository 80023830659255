import { useEffect, useState } from 'react';

export enum ScriptStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
}

const useScript = (src: string, additionalAttributes?: { [key: string]: string }) => {
  const [status, setStatus] = useState<ScriptStatus>(
    src ? ScriptStatus.LOADING : ScriptStatus.IDLE
  );

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus(ScriptStatus.IDLE);
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(
        `script[src="${src}"]`
      ) as HTMLScriptElement | null;

      if (!script) {
        // Create script
        script = document.createElement('script') as HTMLScriptElement;
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', ScriptStatus.LOADING);

        if (additionalAttributes) {
          Object.entries(additionalAttributes).forEach(att => {
            script?.setAttribute(att[0], att[1]);
          });
        }

        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          (script as HTMLScriptElement).setAttribute(
            'data-status',
            event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR
          );
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        const currentScriptStatus = script.getAttribute('data-status');
        setStatus(
          currentScriptStatus ? (currentScriptStatus as ScriptStatus) : ScriptStatus.ERROR
        );
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR);
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      // eslint-disable-next-line consistent-return
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [additionalAttributes, src] // Only re-run effect if script src changes
  );
  return status;
};

export { useScript };
