/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptanceCriteriaGroupListIAPIResult } from '../models/AcceptanceCriteriaGroupListIAPIResult';
import type { CountryListIAPIResult } from '../models/CountryListIAPIResult';
import type { DocumentIAPIResult } from '../models/DocumentIAPIResult';
import type { DocumentListIAPIResult } from '../models/DocumentListIAPIResult';
import type { DurationListIAPIResult } from '../models/DurationListIAPIResult';
import type { ProductIAPIResult } from '../models/ProductIAPIResult';
import type { ProductListIAPIResult } from '../models/ProductListIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get list of all supported products
   * @param properties OPTIONAL - The properties used to filter products eg assetType
   * @returns ProductListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Product(
    properties?: Record<string, string>,
  ): CancelablePromise<ProductListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product',
      query: {
        'properties': properties,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get list of all supported products
   * @param productName An enum representing a product.
   * @param properties OPTIONAL - The properties used to filter products eg assetType
   * @returns ProductIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Product1(
    productName: string,
    properties?: Record<string, string>,
  ): CancelablePromise<ProductIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{productName}',
      path: {
        'productName': productName,
      },
      query: {
        'properties': properties,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets all supported durations for a given product
   * @param product REQUIRED - Name of the product
   * @param properties OPTIONAL - The properties used to filter product durations eg assetType
   * @returns DurationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductDuration(
    product: string,
    properties?: Record<string, string>,
  ): CancelablePromise<DurationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/duration',
      path: {
        'product': product,
      },
      query: {
        'properties': properties,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets all supported add-ons for a given product
   * @param product REQUIRED - Name of the product
   * @param properties OPTIONAL - The properties used to filter product add-ons eg assetType
   * @returns DurationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductAddOn(
    product: string,
    properties?: Record<string, string>,
  ): CancelablePromise<DurationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/add-on',
      path: {
        'product': product,
      },
      query: {
        'properties': properties,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets supported Passport Countries for the selected product. This is used for passport MRZ Row 2 validation
   * @param product REQUIRED - Name of the product
   * @returns CountryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductDrivingLicenceIssuingCountry(
    product: string,
  ): CancelablePromise<CountryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/driving-licence-issuing-country',
      path: {
        'product': product,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the Acceptance Criteria for the selected product
   * @param product REQUIRED - Name of the product
   * @returns AcceptanceCriteriaGroupListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductAcceptanceCriteria(
    product: string,
  ): CancelablePromise<AcceptanceCriteriaGroupListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/acceptance-criteria',
      path: {
        'product': product,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the insurance documents (Policy wording, IPID etc. for the selected product)
   * @param product REQUIRED - Name of the product
   * @param assetType REQUIRED - Type of asset being insured
   * @param underwriter OPTIONAL - Type of underwriter
   * @param brandName OPTIONAL - Name of the brand
   * @returns DocumentListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductDocument(
    product: string,
    assetType?: string,
    underwriter?: string,
    brandName?: string,
  ): CancelablePromise<DocumentListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/document',
      path: {
        'product': product,
      },
      query: {
        'assetType': assetType,
        'underwriter': underwriter,
        'brandName': brandName,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get an individual insurance document (Policy wording, IPID etc. for the selected product)
   * @param product REQUIRED - Name of the product
   * @param documentType REQUIRED - The document type of the document being downloaded
   * @param assetType REQUIRED - The asset type of the document being downloaded
   * @param underwriter
   * @param brandName
   * @returns DocumentIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductDocument1(
    product: string,
    documentType: string,
    assetType?: string,
    underwriter?: string,
    brandName?: string,
  ): CancelablePromise<DocumentIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/document/{documentType}',
      path: {
        'product': product,
        'documentType': documentType,
      },
      query: {
        'assetType': assetType,
        'underwriter': underwriter,
        'brandName': brandName,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}