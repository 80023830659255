import { Button } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared/src/hooks';

type BackButtonProps = {
  customBackLink?: string;
};
export const BackButton = ({ customBackLink }: BackButtonProps) => {
  const navigate = useNavigateWithReferrer();
  const handleGoBack = () => (customBackLink ? navigate(customBackLink) : navigate(-1));

  return (
    <Button
      testId="car-details_back-button"
      id="car-details_back-button"
      variant="ghost"
      onClick={handleGoBack}
      text="Back"
      classNames="mt-12 mb-8"
      icon={{
        position: 'left',
        name: 'arrow-left',
        size: '.75rem',
      }}
    />
  );
};
