import {
  createContext,
  useState,
  useMemo,
  ReactNode,
  useContext,
  useCallback,
} from 'react';

type CancelMTAChangesContextType = {
  isOpen: boolean;
  openCancelDialog(): void;
  closeCancelDialog(): void;
};

const CancelMTAChangesContext = createContext<CancelMTAChangesContextType>({
  isOpen: false,
  openCancelDialog: () => null,
  closeCancelDialog: () => null,
});

type CancelMTAChangesProviderProps = {
  children: ReactNode;
};

export const CancelMTAChangesContextProvider = ({
  children,
}: CancelMTAChangesProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openCancelDialog = useCallback(() => setIsOpen(true), [setIsOpen]);

  const closeCancelDialog = useCallback(() => setIsOpen(false), [setIsOpen]);

  const contextValue = useMemo(
    () => ({ isOpen, openCancelDialog, closeCancelDialog }),
    [isOpen, openCancelDialog, closeCancelDialog]
  );

  return (
    <CancelMTAChangesContext.Provider value={contextValue}>
      {children}
    </CancelMTAChangesContext.Provider>
  );
};

export const useCancelMTAChangesContext = () => {
  const context = useContext(CancelMTAChangesContext);

  if (!context) {
    throw new Error('useCancelMTAChangesContext outside of the provider');
  }

  return context;
};
