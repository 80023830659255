import { lazyWithPreload } from '@dayinsure/shared';

const [AccountRoutes, preloadAccountRoutes] = lazyWithPreload(
  () => import('./AccountRoutes')
);

const [AuthRoutes, preloadAuthRoutes] = lazyWithPreload(() => import('./AuthRoutes'));

const [DashboardRoutes, preloadDashboardRoutes] = lazyWithPreload(
  () => import('./DashboardRoutes')
);

const [PoliciesRoutes, preloadPoliciesRoutes] = lazyWithPreload(
  () => import('./PoliciesRoutes')
);

const preloadContextValue = {
  preloadAuthRoutes,
  preloadDashboardRoutes,
  preloadPoliciesRoutes,
  preloadAccountRoutes,
} as const;

export {
  preloadContextValue,
  AccountRoutes,
  AuthRoutes,
  DashboardRoutes,
  PoliciesRoutes,
};
