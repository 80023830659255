import { Button, Card, Dialog } from '@dayinsure/components';
import React from 'react';
import { ExecessesValues } from '../../../types';

type ExcessCardDialogProps = {
  open: boolean;
  close: () => void;
  excessesValues: ExecessesValues;
  testId?: string;
  dontDisplayVoluntary?: boolean;
  dontDisplayCompulsory?: boolean;
};
export const ExcessCardDialog = ({
  open,
  close,
  testId,
  excessesValues,
  dontDisplayVoluntary,
  dontDisplayCompulsory,
}: ExcessCardDialogProps) => {
  const {
    voluntary,
    compulsory,
    windscreenRepair,
    windscreenReplacement,
    nonAapprovedRepair,
    youngDriverKeys,
    theftOfKeys,
  } = excessesValues;
  const youngDriverExcess =
    excessesValues?.youngDriverKeys &&
    parseInt(excessesValues?.youngDriverKeys.replace('£', ''), 10);
  return (
    <Dialog title="Excesses" open={open} onClose={close}>
      <Card
        classNames="!bg-popup-background flex flex-col items-center mb-8 p-8"
        paddingLevel="small"
      >
        <div className="w-full font-lato text-sm">
          {!dontDisplayVoluntary && (
            <div className="flex justify-between leading-5">
              <span>Voluntary excess</span>
              <span className="font-bold">{voluntary}</span>
            </div>
          )}
          {!dontDisplayCompulsory && (
            <div className="flex justify-between leading-5">
              <span>Compulsory excess</span>
              <span className="font-bold">{compulsory}</span>
            </div>
          )}
          {windscreenRepair && (
            <div className="flex justify-between leading-5">
              <span>Windscreen repair excess</span>
              <span className="font-bold">{windscreenRepair}</span>
            </div>
          )}
          {windscreenReplacement && (
            <div className="flex justify-between leading-5">
              <span>Windscreen replacement excess</span>
              <span className="font-bold">{windscreenReplacement}</span>
            </div>
          )}
          {nonAapprovedRepair && (
            <div className="flex justify-between leading-5">
              <span>Non-approved repair excess</span>
              <span className="font-bold">{nonAapprovedRepair}</span>
            </div>
          )}
          {theftOfKeys && (
            <div className="flex justify-between leading-5">
              <span>Theft of keys excess</span>
              <span className="font-bold">{theftOfKeys}</span>
            </div>
          )}
          {youngDriverKeys &&
            !Number.isNaN(youngDriverExcess) &&
            youngDriverExcess !== 0 && (
              <div className="flex justify-between leading-5">
                <span>Young driver excess</span>
                <span className="font-bold">{youngDriverKeys}</span>
              </div>
            )}
        </div>
      </Card>
      <Button
        id={`${testId}_close-btn`}
        data-testid={`${testId}_close-btn`}
        fullWidth
        onClick={close}
        text="Close"
      />
    </Dialog>
  );
};
