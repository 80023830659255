import { Label, Listbox, ListboxOption } from '@dayinsure/components';

type DriversSelectorProps = {
  testId: string;
  options: ListboxOption[];
  onChange: (option: ListboxOption) => void;
  value: ListboxOption;
};

export const DriversSelector = ({
  testId,
  options,
  value,
  onChange,
}: DriversSelectorProps) => (
  <div className="mb-8 w-full sm:mb-12" data-testid={`${testId}_driver-select`}>
    <Label
      name="Select a driver"
      htmlFor={`${testId}_driver-select_label`}
      icon="user"
      className="mb-4"
    />
    <Listbox
      id={`${testId}_driver-select`}
      name={`${testId}_driver-select_listbox`}
      options={options}
      value={value}
      onChange={onChange}
      unselectedMessage="Select a driver"
      noOptionsMessage="No options"
      variant="annual"
    />
  </div>
);
