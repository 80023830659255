import { Tooltip } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { FormRadioSelect, FormFieldBox } from '../../../../components';
import { QuoteJourneyFormData } from '../../../../types';
import { getYesNoOptions } from '../../../../helpers';

const AntiCloneImmobiliserOptions = getYesNoOptions('anit-clone-immobiliser');

type AntiCloneImmobiliserProps = {
  testId: string;
};

export const AntiCloneImmobiliser = ({ testId }: AntiCloneImmobiliserProps) => {
  const { setFieldTouched, setFieldValue } = useFormikContext<QuoteJourneyFormData>();

  const handleAntiCloneImmobiliserChange = () => {
    setFieldTouched('carSecurity.immobiliser', false);
    setFieldValue('carSecurity.immobiliser', null);
    setFieldTouched('carSecurity.installationProof', false);
    setFieldValue('carSecurity.installationProof', null);
  };

  return (
    <>
      <h3
        className="relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_anti-clone-immobiliser_title`}
      >
        Does your vehicle have an anti-clone immobiliser which requires a PIN or ADR tag?
        <div className="inline-block ml-2 sm:!relative">
          <Tooltip
            ariaLabel="hint"
            className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
            position="relative"
            testId={`${testId}_anti-clone-immobiliser_tooltip`}
            variant="annual"
          >
            <h4 className="font-bold">Immobiliser and tracker information</h4>
            <b>What’s an anti-clone immobiliser?</b>
            It’s an anti-clone vehicle immobiliser system, which prevents your vehicle
            from being stolen. It uses the buttons in your vehicle, such as those on the
            steering wheel, door panels, or centre console. These allow you to make a
            unique, changeable PIN code sequence that must first be entered before you can
            drive your car.
            <b>What’s ADR?</b>
            Automatic driver recognition (ADR) is a feature on premium tracking products
            that identifies approved drivers. These identifiers are usually small tags the
            driver keeps with them. If your vehicle is moved without a driver
            Identification tag present, an alert will be sent to you directly.
            <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
              Got it
            </Tooltip.Close>
          </Tooltip>
        </div>
      </h3>
      <FormFieldBox>
        <FormRadioSelect
          onChangeOptional={handleAntiCloneImmobiliserChange}
          name="carSecurity.antiCloneImmobiliser"
          options={AntiCloneImmobiliserOptions}
          id={`${testId}_approved-tracker_input`}
          testId={`${testId}_approved-tracker_input`}
        />
      </FormFieldBox>
    </>
  );
};
