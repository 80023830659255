import get from 'lodash.get';

const matchMultipleWhiteSpacesRegexp = / {2,}/g;

const trimSpaces = <T extends string>(s: T) =>
  s.replace(matchMultipleWhiteSpacesRegexp, ' ').trim();

type SetFieldValue = (field: string, value: unknown, shouldValidate?: boolean) => void;

export const trimWhiteSpaces =
  <T extends Record<string, any>>(values: T, setFieldValue: SetFieldValue) =>
  (inputName: string) =>
  () => {
    setFieldValue(inputName, trimSpaces(get(values, inputName)));
  };
