import { useNavigateWithReferrer } from '@dayinsure/shared';
import { useLocation, useParams } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { mtaJourneyMap } from '../../../routes/MtaRoutesMap';
import { PolicyMtaPaths } from '../../../routes';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import { getMtaNextStepRoute } from '../../../helpers/forms/getMtaNextStepRoute';
import { getQuoteId } from '../../../helpers';
import { QuoteJourneyFormData } from '../../../types';

export const useMtaNestedStepSubmit = (currentMtaStep: PolicyMtaPaths | null) => {
  const { qouteId, ...params } = useParams();
  const navigate = useNavigateWithReferrer();
  const next = mtaJourneyMap.getNext(currentMtaStep);
  const { pathname } = useLocation();

  return ({
    setSubmitting,
    setTouched,
  }: FormikHelpers<MtaJourneyFormData> | FormikHelpers<QuoteJourneyFormData>) => {
    const route = getMtaNextStepRoute(currentMtaStep, next, pathname);
    const quoteId = qouteId || getQuoteId(params);
    if (route && quoteId) {
      navigate(`${quoteId}/${next}`);
    }
    setSubmitting(false);
    setTouched({}, false);
  };
};
