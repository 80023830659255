import { Card, Button, PageContainer } from '@dayinsure/components';
import { useSignIn } from '@dayinsure/shared';
import { usePerson, useReferrer } from '../../../../hooks';
import { QuoteJourneyFormData } from '../../../../types';

const testId = 'password-reset-success';

export const PasswordResetSuccess = () => {
  const referrer = useReferrer();
  const { isGuest } = usePerson();
  const { signIn } = useSignIn<QuoteJourneyFormData>(referrer.name, isGuest);
  const handleButtonClick = () => signIn();

  return (
    <PageContainer>
      <div className="flex flex-col mx-auto mb-16 w-full md:max-w-tablet-container lg:mb-24 lg:max-w-desktop-container text-main-content-1">
        <h1
          className="my-8 text-xl text-center sm:my-12 text-main-content-1"
          data-testid={`${testId}_title`}
        >
          Password reset
        </h1>
        <Card paddingLevel="large" classNames="bg-content-background-prominent">
          <Card classNames="mb-8 md:mb-12">
            <p data-testid={`${testId}_message`}>
              Your password has been reset. You can now log back in to your account.
            </p>
          </Card>

          <Button
            id={`${testId}_log-in-btn`}
            testId={`${testId}_log-in-btn`}
            submit
            fullWidth
            text="Log in"
            onClick={handleButtonClick}
          />
        </Card>
      </div>
    </PageContainer>
  );
};
