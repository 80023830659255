import { Card } from '@dayinsure/components';
import { usePerson, useReferrer } from '../../../hooks';
import { LoginCTA } from '../LoginCTA';

type TopCardsProps = {
  testId: string;
  infoCardText: string;
};

export const TopCards = ({ testId, infoCardText }: TopCardsProps) => {
  const { customerAccessEnabled } = useReferrer();
  const { isFullAccount } = usePerson();

  return (
    <>
      <Card testId={`${testId}_info-card`} paddingLevel="small" classNames="mb-6 lg:mb-8">
        <span>{infoCardText}</span>
      </Card>
      {customerAccessEnabled && !isFullAccount && (
        <LoginCTA testId={`${testId}_login-cta`} className="mb-8" />
      )}
    </>
  );
};
