/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderDto } from '../models/OrderDto';
import type { PlaceOrderRequestDto } from '../models/PlaceOrderRequestDto';
import type { PlaceOrderResponseDto } from '../models/PlaceOrderResponseDto';
import type { ResumeOrderRequestDto } from '../models/ResumeOrderRequestDto';
import type { ResumeOrderResponseDto } from '../models/ResumeOrderResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Place an order for a given motor quote.
   * @param requestBody REQUIRED
   * @returns PlaceOrderResponseDto Success
   * @throws ApiError
   */
  public postApiV1Order(
    requestBody?: PlaceOrderRequestDto,
  ): CancelablePromise<PlaceOrderResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/order',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Resume the payment for an order.
   * @param id REQUIRED
   * @param requestBody
   * @returns ResumeOrderResponseDto Success
   * @throws ApiError
   */
  public postApiV1OrderResume(
    id: string,
    requestBody?: ResumeOrderRequestDto,
  ): CancelablePromise<ResumeOrderResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/order/{id}/resume',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Get an order by ID
   * @param id REQUIRED
   * @returns OrderDto Success
   * @throws ApiError
   */
  public getApiV1Order(
    id: string,
  ): CancelablePromise<OrderDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/order/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}