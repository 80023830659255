import { useNavigateWithReferrer } from '@dayinsure/shared';
import { Button, Card } from '@dayinsure/components';
import { PoliciesPaths } from '../../../../routes';
import { transformForCommaAndNoCommaValue } from '../../../../helpers';

type PolicyInfoCarProps = {
  vehicleRegistration?: string | null;
  vehicleDescription?: string | null;
  vehicleEstimatedValue?: number | null;
  testId?: string;
  noMoreButton?: boolean;
};

export const PolicyInfoCar = ({
  testId,
  vehicleDescription,
  vehicleRegistration,
  vehicleEstimatedValue,
  noMoreButton,
}: PolicyInfoCarProps) => {
  const navigate = useNavigateWithReferrer();
  const estimateValueComma = vehicleEstimatedValue
    ? transformForCommaAndNoCommaValue(String(vehicleEstimatedValue))
    : null;
  const handleClick = () => {
    navigate(`car/${PoliciesPaths.CarInfo}`);
  };

  return (
    <section>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_active-and-upcoming_policy`}
      >
        <h2 className="mb-4 font-raleway text-lg">Car</h2>
        {vehicleRegistration && (
          <div data-testid="car-details-card_vehicle-details-item" className="mt-4 mb-2">
            <span
              className="inline-flex py-1 px-2 text-sm rounded bg-content-background"
              data-testid="car-details-card_vehicle-details-item_title"
            >
              {vehicleRegistration}
            </span>
          </div>
        )}

        {vehicleDescription && (
          <div>
            <span
              className="text-base"
              data-testid="car-details-card_vehicle-details-item_description"
            >
              {vehicleDescription}
            </span>
          </div>
        )}
        {vehicleEstimatedValue && (
          <Card.CardListItem
            label="Estimated value"
            icon="chart"
            testId="car-details-card_vehicle-estimated-value"
          >
            {`£${estimateValueComma?.valueWithComa}`}
          </Card.CardListItem>
        )}
        {!noMoreButton && (
          <Button
            id={`${testId}_car_moreinfo-button`}
            testId={`${testId}_car_moreinfo-button`}
            text="More info"
            variant="bordered"
            onClick={() => handleClick()}
            classNames="mt-6 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full sm:w-fit "
          />
        )}
      </Card>
    </section>
  );
};
