import { Button } from '@dayinsure/components';
import { useCancelMTAChangesContext } from '../../../../../contexts/CancelMTAChangesContext';

type ReviewButtonsProps = {
  testId?: string;
};

export const ReviewButtons = ({ testId = 'mta_buttons' }: ReviewButtonsProps) => {
  const { openCancelDialog } = useCancelMTAChangesContext();
  return (
    <nav>
      <Button
        classNames="mb-8 mt-4"
        id={`${testId}_continue-button`}
        testId={`${testId}_continue-button`}
        submit
        fullWidth
        text="Continue"
        icon={{ name: 'arrow-right', position: 'right' }}
      />

      <Button
        classNames="mb-24 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full"
        id={`${testId}_cancel-button`}
        testId={`${testId}_cancel-button`}
        fullWidth
        text="Cancel changes"
        onClick={openCancelDialog}
        variant="bordered"
      />
    </nav>
  );
};
