import { SelectRadioOption } from '@dayinsure/components';
import { getFormFieldAccessPath, getYesNoOptions } from '../../../../helpers';
import { FormRadioSelect } from '../../../../components';

type AccountHolderRadioProps = {
  testId: string;
  onChange: (option: SelectRadioOption) => void;
};
export const AccountHolderRadio = ({ testId, onChange }: AccountHolderRadioProps) => {
  const provideAccountHolderPath = getFormFieldAccessPath(['isAccountHolder']);
  return (
    <div className="my-6 md:my-16">
      <h2
        data-testid={`${testId}_account_holder-label`}
        className="flex relative w-full font-raleway font-normal form-subtitle-compact"
      >
        Are you the account holder and can you authorise a Direct Debit instruction to
        this account?
      </h2>
      <div>
        <FormRadioSelect
          onChangeOptional={onChange}
          id={`${testId}__account_holder-radio`}
          testId={`${testId}__account_holder-radio`}
          name={provideAccountHolderPath}
          options={getYesNoOptions(`${testId}_account_holder`)}
        />
      </div>
    </div>
  );
};
