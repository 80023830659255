import {
  lowercaseRegex,
  numberRegex,
  symbolRegex,
  uppercaseRegex,
} from '@dayinsure/shared';
import * as Yup from 'yup';

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Too short')
    .matches(uppercaseRegex, {
      message: 'One uppercase letter',
      excludeEmptyString: true,
    })
    .matches(lowercaseRegex, {
      message: 'One lowercase letter',
      excludeEmptyString: true,
    })
    .matches(numberRegex, {
      message: 'One number',
      excludeEmptyString: true,
    })
    .matches(symbolRegex, {
      message: 'One symbol',
      excludeEmptyString: true,
    })
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords do not match')
    .required('Required'),
});
