import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';

type SameRegistrationPlateCardProps = {
  testId: string;
  className?: string;
};

export const SameRegistrationPlateCard = ({
  testId,
  className,
}: SameRegistrationPlateCardProps) => (
  <Card
    classNames={clsx(
      'flex gap-4 items-center mt-8 text-left md:gap-6 md:mt-12 !bg-popup-background',
      className
    )}
    paddingLevel="small"
    testId={`${testId}_same-registration-plate-card`}
  >
    <Icon name="warning" size="2rem" className="text-popup-content-1" />
    <span className="text-popup-content-1">
      Please check the car reg above. It looks like this is the same reg as your current
      car.
    </span>
  </Card>
);
