import { useReferrerFetcher } from '@dayinsure/shared';
import { ReactNode } from 'react';
import { AuthProvider as OIDCAuthProvider } from 'react-oidc-context';
import { referrers } from '../constants/referrers';
import { useAuthConfig } from '../hooks';

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const referrer = useReferrerFetcher(referrers, 'Everyday');
  const authConfig = useAuthConfig(referrer);

  return <OIDCAuthProvider {...authConfig}>{children}</OIDCAuthProvider>;
};
