import { v4 as uuidv4 } from 'uuid';
import { DriverFormData, YesNoAnswer } from '../../../types';

const emptyDriverPrototype: Omit<
  DriverFormData,
  'id' | 'isProposer' | 'relationshipToProposer' | 'isMainDriver'
> = {
  firstName: '',
  lastName: '',
  title: null,
  maritalStatus: null,
  dateOfBirth: {
    day: '',
    month: '',
    year: '',
    parsedDate: '',
  },
  homePostcode: null,
  homeAddress: null,
  address: null,
  drivingLicence: {
    provideLicence: null,
    type: null,
    number: '',
    yearsHeld: null,
    monthsHeld: null,
  },
  occupations: [
    {
      employmentStatus: null,
      type: null,
      industry: null,
      anotherJob: null,
      isPrimary: true,
    },
  ],
  ukResidencyDetails: {
    isUkResidentSinceBirth: null,
    ukResidentSince: null,
  },
  medicalCondition: {
    hasMedicalConditions: null,
    code: null,
  },
  advancedDrivingQualifications: {
    hasAdvancedDrivingQualifications: null,
    advancedDrivingQualifications: [],
  },
  otherVehicleUse: {
    otherVehicleUse: null,
    otherVehicleUseCode: null,
  },
  motorConvictions: {
    hasMotorConvictions: null,
    motorConvictions: [],
  },
  hasNonMotoringConvictions: null,
  accidentsOrClaims: {
    hasAccidentOrClaimInLast5Years: null,
    selfDeclaredPreviousMotorClaims: [],
  },
};

export const NEW_DRIVER_ID = 'new-driver';

export const getEmptyProposerDriver = (): DriverFormData => ({
  ...emptyDriverPrototype,
  id: uuidv4(),
  isProposer: {
    id: YesNoAnswer.YES,
    name: 'Yes',
  },
  relationshipToProposer: {
    id: 'PROP',
    name: 'Proposer',
  },
  isMainDriver: {
    id: YesNoAnswer.YES,
    name: 'Yes',
  },
});

export const getEmptyAdditionalDriver = (id?: string): DriverFormData => ({
  ...emptyDriverPrototype,
  id: id || uuidv4(),
  isProposer: {
    id: YesNoAnswer.NO,
    name: 'No',
  },
  relationshipToProposer: null,
  isMainDriver: {
    id: YesNoAnswer.NO,
    name: 'No',
  },
});
