import { useParams, useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { QuoteJourneyFormData } from '../../../types';
import { useMakeOrderMutation } from '../../mutations';
import { getOrderPayload, isMonthlyPaymentPlan } from '../../../helpers';
import { QuoteJourneyStatusContext } from '../../../contexts';
import { ApiError, PlaceOrderResponseDto } from '../../../api/v1';
import { PaymentContext } from '../../../contexts/PaymentContext';
import { useGlobalError } from '../../useGlobalError';
import { usePaymentProcessing } from '../../usePaymentProcessing';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';

enum PaymentResultType {
  AUTHORISED = 'authorised',
  PENDING = 'pending',
  CHALLENGE_SHOPPER = 'challenge_shopper',
  IDENTIFY_SHOPPER = 'identify_shopper',
  RECEIVED = 'received',
}

const threeDSConfiguration = {
  challengeWindowSize: '03',
};
export const usePaymentSubmit = (mta?: boolean) => {
  const { checkoutContext, setOrderId, setCardFormVisible } = useContext(PaymentContext);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const makeOrderMutation = useMakeOrderMutation();
  const { setIsLoadingState, setIsLoading, setCopy } = useContext(
    QuoteJourneyStatusContext
  );
  const { showGlobalError } = useGlobalError();
  const { processPayment } = usePaymentProcessing(mta);
  const handleSetError = (error: unknown) => {
    showGlobalError(error);
    setIsLoadingState(false);
    setOrderId(null);
  };

  const handleSuccessError = (data: PlaceOrderResponseDto) => {
    const apiError = new ApiError(
      {
        statusText: `${data.errorCode}`,
        status: 404,
        body: {
          error: data?.errorMessage || 'error',
        },
        url: '',
        ok: true,
      },
      `${data.errorCode}`
    );

    handleSetError(apiError);
    setIsLoading(false);
  };

  const handleSuccess = (resp: PlaceOrderResponseDto) => {
    if (resp.errorCode) {
      handleSuccessError(resp);
      return;
    }

    const { action = null, orderId = null } = resp;
    setCardFormVisible(false);
    setOrderId(orderId);
    const resultCode = resp?.resultCode || '';

    switch (resultCode) {
      case PaymentResultType.AUTHORISED:
      case PaymentResultType.RECEIVED:
        processPayment();
        break;
      case PaymentResultType.CHALLENGE_SHOPPER:
      case PaymentResultType.PENDING:
      case PaymentResultType.IDENTIFY_SHOPPER:
        if (action) {
          setIsLoading(false);
          const updatedAction = { ...action, subtype: action?.subType };
          document
            .getElementById('scroll-container')
            ?.scroll({ top: 900, behavior: 'smooth' });
          setTimeout(() => {
            checkoutContext
              ?.createFromAction(updatedAction, threeDSConfiguration)
              .mount('#my-container');
          }, 0);
        }
        break;
      default:
        handleSetError({
          url: '',
          status: '404',
          statusText: 'Error',
          body: 'Not found',
        });
        break;
    }
    setIsLoadingState(false);
  };
  return (formData: QuoteJourneyFormData | MtaJourneyFormData) => {
    let isRefund = false;

    if (isMonthlyPaymentPlan(formData?.usualPaymentFrequency?.code?.id)) {
      if (!mta && !formData.isDirectDebitConfirmed) {
        return;
      }
    }

    if ('isRefund' in formData) {
      isRefund = !!formData?.isRefund;
    }

    const payload = getOrderPayload('card', formData, params, searchParams, isRefund);
    setIsLoadingState(true);
    setCopy({
      title: 'Processing payment',
      subtitle: 'Please wait while we process your payment',
    });

    setIsLoading(true);
    makeOrderMutation.mutate(payload, {
      onSuccess: handleSuccess,
      onError: handleSetError,
    });
  };
};
