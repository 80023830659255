/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AmendmentQuoteIAPIResult } from '../models/AmendmentQuoteIAPIResult';
import type { CancellationQuoteIAPIResult } from '../models/CancellationQuoteIAPIResult';
import type { CancellationQuoteRequest } from '../models/CancellationQuoteRequest';
import type { CancelPolicyRequest } from '../models/CancelPolicyRequest';
import type { CancelPolicyResponseIAPIResult } from '../models/CancelPolicyResponseIAPIResult';
import type { CreateAmendQuoteRequest } from '../models/CreateAmendQuoteRequest';
import type { DocumentDetailsListIAPIResult } from '../models/DocumentDetailsListIAPIResult';
import type { ExtensionDurationListIAPIResult } from '../models/ExtensionDurationListIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { PaymentMethodResponseIAPIResult } from '../models/PaymentMethodResponseIAPIResult';
import type { PolicyDocumentIAPIResult } from '../models/PolicyDocumentIAPIResult';
import type { PolicyPollingResponseIAPIResult } from '../models/PolicyPollingResponseIAPIResult';
import type { PurchaseAmendmentQuoteRequest } from '../models/PurchaseAmendmentQuoteRequest';
import type { PurchaseQuoteResponseIAPIResult } from '../models/PurchaseQuoteResponseIAPIResult';
import type { ResumePurchaseAmendmentQuoteRequest } from '../models/ResumePurchaseAmendmentQuoteRequest';
import type { StringIServiceResult } from '../models/StringIServiceResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PolicyService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a policy by the id.
   * @param policyId An ID representing a policy.
   * @param policyVersion OPTIONAL - the version of the policy you wish to read.
   * @returns PolicyPollingResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV2Policy(
    policyId: string,
    policyVersion?: number,
  ): CancelablePromise<PolicyPollingResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/policy/{policyId}',
      path: {
        'policyId': policyId,
      },
      query: {
        'policyVersion': policyVersion,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get all documents for the current quote.
   * @param policyId An ID representing a policy
   * @returns DocumentDetailsListIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PolicyDocument(
    policyId: string,
  ): CancelablePromise<DocumentDetailsListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/policy/{policyId}/document',
      path: {
        'policyId': policyId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get the chosen document for the current quote.
   * @param policyId An ID representing a policy
   * @param documentType The documentType you want to return
   * @returns PolicyDocumentIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PolicyDocument1(
    policyId: string,
    documentType: string,
  ): CancelablePromise<PolicyDocumentIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/policy/{policyId}/document/{documentType}',
      path: {
        'policyId': policyId,
        'documentType': documentType,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Marks the chosen document to be sent for post.
   * @param policyId An ID representing a policy
   * @param documentType The documentType you want to return
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PolicyDocumentSendPaperCopy(
    policyId: string,
    documentType: string,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/policy/{policyId}/document/{documentType}/send-paper-copy',
      path: {
        'policyId': policyId,
        'documentType': documentType,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the extendable durations for a polucy
   * @param policyId An ID representing a policy
   * @returns ExtensionDurationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PolicyExtensionDuration(
    policyId: string,
  ): CancelablePromise<ExtensionDurationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/policy/{policyId}/extension-duration',
      path: {
        'policyId': policyId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the extendable durations for a polucy
   * @param policyId An ID representing a policy
   * @param requestBody An Object representing the changes you wish to make to a policy
   * @returns AmendmentQuoteIAPIResult Success
   * @throws ApiError
   */
  public postApiV2PolicyAmendmentQuote(
    policyId: string,
    requestBody?: CreateAmendQuoteRequest,
  ): CancelablePromise<AmendmentQuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/policy/{policyId}/amendment-quote',
      path: {
        'policyId': policyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the supported payment methods for a given quote
   * @param policyId
   * @param amendmentQuoteId
   * @param channel
   * @param countryCode
   * @returns PaymentMethodResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PolicyAmendmentQuotePaymentMethod(
    policyId: string,
    amendmentQuoteId: string,
    channel?: string,
    countryCode?: string,
  ): CancelablePromise<PaymentMethodResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/policy/{policyId}/amendment-quote/{amendmentQuoteId}/payment-method',
      path: {
        'policyId': policyId,
        'amendmentQuoteId': amendmentQuoteId,
      },
      query: {
        'channel': channel,
        'countryCode': countryCode,
      },
    });
  }

  /**
   * Process payment for a quote. Completing this will result in a new policy. Failing payment will allow the payment to be retried.
   * @param policyId
   * @param amendmentQuoteId
   * @param requestBody
   * @returns PurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV2PolicyAmendmentQuotePurchase(
    policyId: string,
    amendmentQuoteId: string,
    requestBody?: PurchaseAmendmentQuoteRequest,
  ): CancelablePromise<PurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/policy/{policyId}/amendment-quote/{amendmentQuoteId}/purchase',
      path: {
        'policyId': policyId,
        'amendmentQuoteId': amendmentQuoteId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        503: `Server Error`,
      },
    });
  }

  /**
   * Get an amendment quote.
   * @param policyId
   * @param amendmentQuoteId
   * @returns PurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PolicyAmendmentQuote(
    policyId: string,
    amendmentQuoteId: string,
  ): CancelablePromise<PurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/policy/{policyId}/amendment-quote/{amendmentQuoteId}',
      path: {
        'policyId': policyId,
        'amendmentQuoteId': amendmentQuoteId,
      },
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Process payment for a amendment quote. Completing this will result in a new policy. Failing payment will allow the payment to be retried.
   * @param policyId
   * @param amendmentQuoteId REQUIRED
   * @param requestBody REQUIRED - The payment method, required fields for the payment method in Adyen encrypted format are all required
   * @returns PurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV2PolicyAmendmentQuoteResumePurchase(
    policyId: string,
    amendmentQuoteId: string,
    requestBody?: ResumePurchaseAmendmentQuoteRequest,
  ): CancelablePromise<PurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/policy/{policyId}/amendment-quote/{amendmentQuoteId}/resume-purchase',
      path: {
        'policyId': policyId,
        'amendmentQuoteId': amendmentQuoteId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        503: `Server Error`,
      },
    });
  }

  /**
   * Returns a cancellation quote for the given policy.
   * @param policyId An ID representing a policy.
   * @param requestBody A request containing a choosen cancellation datetime.
   * @returns CancellationQuoteIAPIResult Success
   * @throws ApiError
   */
  public postApiV2PolicyCancellationQuote(
    policyId: string,
    requestBody?: CancellationQuoteRequest,
  ): CancelablePromise<CancellationQuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/policy/{policyId}/cancellation-quote',
      path: {
        'policyId': policyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Cancels a policy using the stored cancellation quote if valid.
   * @param policyId An ID representing a policy.
   * @param cancellationQuoteId The id of the stored cancellation quote.
   * @param requestBody
   * @returns CancelPolicyResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV2PolicyCancellationQuoteCancelPolicy(
    policyId: string,
    cancellationQuoteId: string,
    requestBody?: CancelPolicyRequest,
  ): CancelablePromise<CancelPolicyResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/policy/{policyId}/cancellation-quote/{cancellationQuoteId}/cancel-policy',
      path: {
        'policyId': policyId,
        'cancellationQuoteId': cancellationQuoteId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
        410: `Client Error`,
      },
    });
  }

  /**
   * save policy id in cache to later retrive it and link to an existing confirm account.
   * @param policyId
   * @returns StringIServiceResult Success
   * @throws ApiError
   */
  public postApiV2PolicyCreateSession(
    policyId: string,
  ): CancelablePromise<StringIServiceResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/policy/{policyId}/create-session',
      path: {
        'policyId': policyId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

}