import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { isToday } from 'date-fns';
import { CoverStartDate } from '../../CoverStartDate';
import { FormTimeSelect } from '../../Common/FormTimeSelect';
import { usePolicyFromList } from '../../../hooks/usePolicyFromList';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';

const isTodayStartDate = (parsedDate?: string): boolean => {
  if (!parsedDate) {
    return false;
  }

  return isToday(new Date(parsedDate));
};

type MtaCoverStartDateProps = {
  testId: string;
};

export const MtaCoverStartDate = ({ testId }: MtaCoverStartDateProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    mtaQuoteStartEndDate: { start, end },
  } = usePolicyFromList(id);
  const { values } = useFormikContext<MtaJourneyFormData>();

  return (
    <section className="mb-8 md:mb-12">
      <CoverStartDate
        testId={testId}
        title="When do you need the changes to start?"
        startDate={start || ''}
        maxDate={end || ''}
      />
      <FormTimeSelect
        label={{ icon: 'time', text: 'Start time (local UK time) ' }}
        hourId={`${testId}_cover-start-date_hour-input`}
        hourName="cover.exactStartHour"
        minuteId={`${testId}_cover-start-date_minute-input`}
        minuteName="cover.exactStartMinute"
        isToday={isTodayStartDate(values.cover.startDateTimeUtc?.parsedDate)}
      />
    </section>
  );
};
