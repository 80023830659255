import { PaymentPlanDto } from '../../../api/v1';
import { UsualPaymentFrequency } from '../../../types';
import { isAnnualPaymentPlan, isMonthlyPaymentPlan } from '../../utils';
import { toBaseDataEntry } from '../../quoteSubmission';
import { PaymentTypesEnum } from '../../../constants/payments';

export const transformPlanForFormikValue = (
  plan?: PaymentPlanDto
): UsualPaymentFrequency => {
  if (isAnnualPaymentPlan(plan?.type?.code)) {
    return {
      code: toBaseDataEntry(PaymentTypesEnum.ANNUAL) || null,
    };
  }

  if (isMonthlyPaymentPlan(plan?.type?.code)) {
    return {
      code: toBaseDataEntry(PaymentTypesEnum.MONTHLY) || null,
    };
  }

  return {
    code: null,
  };
};
