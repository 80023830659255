import { NavigateWithReferrer } from '@dayinsure/shared';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { QuoteJourneyRoutes } from '../../../routes';
import { QuoteJourneyFormData } from '../../../types';

export const RootPage = () => {
  const { resetForm } = useFormikContext<QuoteJourneyFormData>();

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <NavigateWithReferrer
      to={{ pathname: QuoteJourneyRoutes.RegistrationSearch }}
      replace
    />
  );
};
