import { utcToZonedTime } from 'date-fns-tz';
import { GBTimeZone } from '../time.const';

export const getUKLocalTime = (date?: Date | string | number) => {
  if (!date) {
    return utcToZonedTime(new Date(), GBTimeZone);
  }

  return utcToZonedTime(date, GBTimeZone);
};
