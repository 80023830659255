import { PolicyMtaPaths, QuoteJourneyRoutes } from './routes';

export type MtaJourneyPath = PolicyMtaPaths | null;

type MtaJourneyMapEntry = {
  previous: MtaJourneyPath;
  next: MtaJourneyPath;
};

class MtaRoutesMap {
  private initialStep = PolicyMtaPaths.PolicyChange;

  private mtaMap: Record<PolicyMtaPaths, MtaJourneyMapEntry> = {
    [PolicyMtaPaths.Mta]: {
      previous: null,
      next: null,
    },
    [PolicyMtaPaths.PolicyChange]: {
      previous: null,
      next: null,
    },
    [PolicyMtaPaths.NewCar]: {
      previous: PolicyMtaPaths.PolicyChange,
      next: PolicyMtaPaths.NewCarUsage,
    },
    [PolicyMtaPaths.NewCarUsage]: {
      previous: PolicyMtaPaths.NewCar,
      next: PolicyMtaPaths.Drivers,
    },
    [PolicyMtaPaths.NewReg]: {
      previous: PolicyMtaPaths.PolicyChange,
      next: PolicyMtaPaths.NewCost,
    },
    [PolicyMtaPaths.DriversGroup]: {
      previous: PolicyMtaPaths.PolicyChange,
      next: PolicyMtaPaths.Drivers,
    },
    [PolicyMtaPaths.Drivers]: {
      previous: PolicyMtaPaths.PolicyChange,
      next: PolicyMtaPaths.CarSecurity,
    },
    [PolicyMtaPaths.DriverId]: {
      previous: PolicyMtaPaths.Drivers,
      next: null,
    },
    [PolicyMtaPaths.DriverDetails]: {
      previous: PolicyMtaPaths.Drivers,
      next: PolicyMtaPaths.DriverLicenceAndHistory,
    },
    [PolicyMtaPaths.DriverLicenceAndHistory]: {
      previous: PolicyMtaPaths.DriverDetails,
      next: PolicyMtaPaths.Drivers,
    },
    [PolicyMtaPaths.CarSecurity]: {
      previous: PolicyMtaPaths.Drivers,
      next: PolicyMtaPaths.CarSecurityPolicyConditions,
    },
    [PolicyMtaPaths.CarSecurityPolicyConditions]: {
      previous: PolicyMtaPaths.CarSecurity,
      next: PolicyMtaPaths.NewCost,
    },
    [PolicyMtaPaths.NewCost]: {
      previous: PolicyMtaPaths.CarSecurityPolicyConditions,
      next: PolicyMtaPaths.PolicyConditions,
    },
    [PolicyMtaPaths.PolicyConditions]: {
      previous: PolicyMtaPaths.NewCost,
      next: PolicyMtaPaths.Review,
    },
    [QuoteJourneyRoutes.QuoteId]: {
      previous: null,
      next: null,
    },
    [PolicyMtaPaths.Review]: {
      previous: PolicyMtaPaths.NewCost,
      next: PolicyMtaPaths.Payment,
    },
    // TODO add links when done
    [PolicyMtaPaths.Payment]: {
      previous: PolicyMtaPaths.Review,
      next: PolicyMtaPaths.ChangeComplete,
    },
    [PolicyMtaPaths.ChangeComplete]: {
      previous: null,
      next: null,
    },
    [PolicyMtaPaths.Empty]: {
      previous: null,
      next: null,
    },
  };

  getPrevious = (quoteStep: PolicyMtaPaths | null): MtaJourneyPath => {
    if (!quoteStep) {
      return this.initialStep;
    }

    return this.mtaMap[quoteStep].previous;
  };

  getNext = (quoteStep: PolicyMtaPaths | null): MtaJourneyPath => {
    if (!quoteStep) {
      return null;
    }

    return this.mtaMap[quoteStep].next;
  };
}

export const mtaJourneyMap = new MtaRoutesMap();
