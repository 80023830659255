export const displayJobTitleBaseOnEmploymentStatus = (
  employmentStatusId?: string | null
): boolean => {
  if (!employmentStatusId) {
    return false;
  }
  const employmentStatusCodes = ['E', 'F', 'S', 'V'];

  return employmentStatusCodes.includes(employmentStatusId);
};
