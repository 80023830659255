import phone from 'phone';
import { isValidPhoneNumber as validatePhoneNumber } from 'libphonenumber-js';

const isValidPhoneNumber = (contactNumber: string): boolean =>
  phone(contactNumber, { country: 'GB' }).isValid;

const isValidLandLineNumber = (contactNumber: string): boolean =>
  validatePhoneNumber(contactNumber, 'GB');

const isValidContactNumber = (contactNumber: string): boolean =>
  isValidPhoneNumber(contactNumber) || isValidLandLineNumber(contactNumber);

export { isValidPhoneNumber, isValidLandLineNumber, isValidContactNumber };
