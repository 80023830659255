import { TestContext } from 'yup';
import { AnyObject } from 'yup/es/types';
import { DriverFormData, FromObj, TestContextExtended } from '../../../../types';
import { isYes } from '../../../utils';

type DriverFormDataFromObj = {
  value: DriverFormData;
};

type DriversFromObj = {
  value: {
    drivers: DriverFormData[];
  };
};

export const isTestContextExtended = (
  ctx: TestContext<AnyObject>
): ctx is TestContextExtended => 'from' in ctx;

const isDriverFormData = (
  formData: Record<string, unknown> | null
): formData is DriverFormData => {
  const formKeys = formData ? Object.keys(formData) : [];
  return (
    formKeys.includes('firstName') &&
    formKeys.includes('lastName') &&
    formKeys.includes('dateOfBirth')
  );
};

const isDriverFormDataFromObj = (fromObj: FromObj): fromObj is DriverFormDataFromObj =>
  isDriverFormData(fromObj.value);

export const getDriverFormDataFromFormCtx = (
  ctx: TestContext<AnyObject>
): undefined | DriverFormData =>
  isTestContextExtended(ctx) ? ctx.from.find(isDriverFormDataFromObj)?.value : undefined;

const isCurrentDriverFormDataFromArray = (ctx: TestContext<AnyObject>, index: number) =>
  isTestContextExtended(ctx)
    ? ctx.from.find((fromObj: FromObj): fromObj is DriversFromObj => {
        const drivers = fromObj?.value?.drivers;
        return (drivers as DriverFormData[])?.length === index + 1;
      })?.value
    : undefined;
export const getDriverFormDataFromFormCtxDriversArray = (
  ctx: TestContext<AnyObject>,
  index: number
): undefined | DriverFormData => {
  const fromObject = isCurrentDriverFormDataFromArray(ctx, index);
  const drivers = fromObject?.drivers;
  return drivers && drivers[index];
};

export const getDriverFormData = (
  ctx: TestContext<AnyObject>,
  index: number | undefined
) => {
  if (!index) {
    return getDriverFormDataFromFormCtx(ctx);
  }
  return getDriverFormDataFromFormCtxDriversArray(ctx, index);
};

export const getMainDriver = (ctx: TestContext<AnyObject>) => {
  const latestValue = isTestContextExtended(ctx)
    ? ctx?.from?.find((from: FromObj) => from?.value?.drivers)
    : undefined;
  const drivers = latestValue?.value?.drivers || [];
  return (drivers as DriverFormData[])?.find((driver: DriverFormData) =>
    isYes(driver?.isMainDriver?.id)
  );
};
