import { Button, Card, Dialog, Icon } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { useCancelMTAChangesContext } from '../../../../contexts/CancelMTAChangesContext';

const testId = 'cancel-mta-changes-dialog';

export const CancelMTAChangesDialog = () => {
  const navigate = useNavigateWithReferrer();
  const { id } = useParams<{ id: string }>();
  const { isOpen, closeCancelDialog } = useCancelMTAChangesContext();
  const { resetForm } = useFormikContext<MtaJourneyFormData>();

  const handleClose = () => closeCancelDialog();

  const handleConfirm = () => {
    resetForm();
    navigate({
      pathname: `/policies/${id}/mta`,
    });
    closeCancelDialog();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} title="Cancel changes">
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
      >
        <Icon name="warning" size="2rem" className="text-popup-content-1" />
        <p>
          Are you sure you want to cancel changes? All progress will be lost. To make
          changes another time, you will need to get a new quote, where the premium may be
          different.
        </p>
      </Card>

      <Button
        id={`${testId}_back-button`}
        testId={`${testId}_back-button`}
        fullWidth
        onClick={handleClose}
        text="No, go back"
        classNames="mt-6 md:mt-8"
      />

      <Button
        id={`${testId}_continue-button`}
        testId={`${testId}_continue-button`}
        fullWidth
        onClick={handleConfirm}
        text="Yes, cancel changes"
        classNames="mt-6 md:mt-8"
        variant="dialogSecondary"
      />
    </Dialog>
  );
};
