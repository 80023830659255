import { trimWhiteSpaces } from '@dayinsure/shared';
import { useFormikContext } from 'formik';
import { FocusEvent, useMemo } from 'react';
import {
  baseOptionDtoToViewOptions,
  getFormFieldAccessPath,
  nameInputMask,
} from '../../../helpers';
import { useDriverOptionsQuery } from '../../../hooks';
import { DriverFormData, QuoteJourneyFormData } from '../../../types';
import { FormDateField, FormListBox, FormTextField } from '../../index';
import { FormFieldBox } from '../../FormsFields/FormFieldBox';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';

type DriverInfoProps = {
  formNameSpaceKey?: string;
  testId: string;
  relationShipField?: boolean;
};

export const DriverBasicInfo = <
  T extends QuoteJourneyFormData | DriverFormData | MtaJourneyFormData
>({
  testId,
  formNameSpaceKey,
  relationShipField,
}: DriverInfoProps) => {
  const { data } = useDriverOptionsQuery();
  const { handleBlur, values, setFieldValue } = useFormikContext<T>();
  const trimSpaces = trimWhiteSpaces(values, setFieldValue);

  const userTitleOptions = useMemo(
    () => data?.titles?.map(baseOptionDtoToViewOptions(`${testId}_title`)) ?? [],
    [data?.titles, testId]
  );

  const maritalStatusOptions = useMemo(
    () =>
      data?.maritalStatuses?.map(
        baseOptionDtoToViewOptions(`${testId}_marital-status`)
      ) ?? [],
    [data?.maritalStatuses, testId]
  );

  const relationshipOptions = useMemo(() => {
    const relationships = data?.relationships ?? [];
    const filteredRelationships = relationships.filter(
      ({ description }) => description !== 'Proposer'
    );
    return filteredRelationships.map(
      baseOptionDtoToViewOptions(`${testId}_relationship`)
    );
  }, [data?.relationships, testId]);

  const handleTextFieldBlurEvent =
    (inputName: string) => (e: FocusEvent<HTMLInputElement>) => {
      trimSpaces(inputName)();
      handleBlur(e);
    };

  const relationshipToProposerPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'relationshipToProposer',
  ]);

  const titlePath = getFormFieldAccessPath([formNameSpaceKey, 'title']);
  const firstNamePath = getFormFieldAccessPath([formNameSpaceKey, 'firstName']);
  const lastNamePath = getFormFieldAccessPath([formNameSpaceKey, 'lastName']);
  const dateOfBirthPath = getFormFieldAccessPath([formNameSpaceKey, 'dateOfBirth']);
  const maritalStatusPath = getFormFieldAccessPath([formNameSpaceKey, 'maritalStatus']);

  return (
    <div data-testid={testId}>
      {relationShipField && (
        <FormFieldBox>
          <FormListBox
            unselectedMessage="Please select"
            name={relationshipToProposerPath}
            options={relationshipOptions}
            id={`${testId}_relationship-dropdown`}
            testId={`${testId}_relationship-dropdown`}
            icon="chain"
            label="Relationship to you"
          />
        </FormFieldBox>
      )}
      <FormFieldBox>
        <FormListBox
          unselectedMessage="Please select"
          name={titlePath}
          options={userTitleOptions}
          id={`${testId}_title-dropdown`}
          testId={`${testId}_title-dropdown`}
          icon="user-card"
          label="Title"
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormTextField
          placeholder="e.g. John"
          name={firstNamePath}
          id={`${testId}_first-name-input`}
          testId={`${testId}_first-name-input`}
          label={{ text: 'First name', icon: 'user' }}
          onInput={nameInputMask}
          onBlur={handleTextFieldBlurEvent(firstNamePath)}
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormTextField
          placeholder="e.g. Smith"
          name={lastNamePath}
          id={`${testId}_last-name-input`}
          testId={`${testId}_last-name-input`}
          label={{ text: 'Surname', icon: 'user' }}
          onInput={nameInputMask}
          onBlur={handleTextFieldBlurEvent(lastNamePath)}
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormDateField
          label={{
            icon: 'date',
            text: 'Date of birth',
          }}
          testId={`${testId}_date-of-birth-input`}
          id={`${testId}_date-of-birth-input`}
          name={dateOfBirthPath}
          configuration={{
            day: {
              enabled: true,
              placeholder: 'DD',
            },
            month: {
              placeholder: 'MM',
            },
            year: {
              placeholder: 'YYYY',
            },
          }}
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormListBox
          unselectedMessage="Please select"
          name={maritalStatusPath}
          options={maritalStatusOptions}
          id={`${testId}_marital-status-dropdown`}
          testId={`${testId}_marital-status-dropdown`}
          icon="heart"
          label="Marital status"
        />
      </FormFieldBox>
    </div>
  );
};
