import { Tooltip } from '@dayinsure/components';

type ProtectYourClaimsDiscountTooltipProps = {
  testId: string;
};

export const ProtectYourClaimsDiscountTooltip = ({
  testId,
}: ProtectYourClaimsDiscountTooltipProps) => (
  <div className="inline-block ml-2 sm:!relative">
    <Tooltip
      ariaLabel="hint"
      className="inline-flex top-[72px] flex-col sm:top-8 text-popup-content-1"
      position="relative"
      testId={`${testId}_protect-no-claim-discount`}
      variant="annual"
    >
      <h4 className="mb-2 font-bold">Protected no claims bonus</h4>
      <p className="mb-2">
        Depending on how long you have been driving claim-free for, we will give you the
        option to protect your no claims bonus at an additional cost.
      </p>
      <p>
        No Claims Bonus Protection does not protect the overall price of your insurance
        policy. The price of your insurance policy may increase following an accident even
        if you were not at fault.
      </p>
      <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
        Got it
      </Tooltip.Close>
    </Tooltip>
  </div>
);
