import clsx from 'clsx';
import { ReactNode } from 'react';

type LinkProps = {
  children: ReactNode;
  className?: string;
  href?: string;
  testId?: string;
};

export const Link = ({ children, className, href = '/#', testId }: LinkProps) => (
  <a
    className={clsx('font-bold underline text-link', className)}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    data-testid={testId}
  >
    {children}
  </a>
);
