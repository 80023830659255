import {
  onEnter,
  PaymentMobileFooter as PaymentMobileFooterView,
  PaymentMobileFooterProps as PaymentMobileFooterViewProps,
} from '@dayinsure/components';
import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { QuoteJourneyFormData } from '../../../types';
import { usePaymentInfo } from '../../../hooks';
import { PriceBreakdownDialog } from '../../PaymentCard/PriceBreakdownDialog';
import {
  getCurrentPaymentPlan,
  getDisplayPrice,
  isAnnualPaymentPlan,
} from '../../../helpers';

const testId = 'payment-mobile-footer';

const paymentMobileFooterCopy: PaymentMobileFooterViewProps['copy'] = {
  title: 'Total price',
  info: 'Includes 12% IPT and an admin fee payable to Everydayinsure.',
  button: 'View price breakdown',
};

export const PaymentMobileFooter = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    values: { usualPaymentFrequency },
  } = useFormikContext<QuoteJourneyFormData>();
  const isPlanAnnual = isAnnualPaymentPlan(usualPaymentFrequency.code?.id);
  const { data, isLoading } = usePaymentInfo();

  useEffect(() => {
    const footer = document.getElementById('footer');

    if (footer && !isLoading) {
      footer.classList.add('mb-28');
      footer.classList.add('md:mb-0');
    }

    return () => {
      footer?.classList.remove('mb-28');
      footer?.classList.remove('md:mb-0');
    };
  });

  if (isLoading || !data) {
    return null;
  }

  const handleDialogVisibility = () => setDialogOpen((status: boolean) => !status);

  const currentPlan = getCurrentPaymentPlan(usualPaymentFrequency, data?.paymentPlans);
  const displayPrice = getDisplayPrice(
    isPlanAnnual,
    currentPlan,
    data.totalPriceIncAddOns
  );
  const priceSufix = isPlanAnnual ? ' /year' : ' /month';

  return (
    <>
      <PaymentMobileFooterView
        copy={paymentMobileFooterCopy}
        price={displayPrice}
        priceSufix={priceSufix}
        testId={testId}
        onButtonClick={handleDialogVisibility}
        onButtonKeyDown={onEnter(handleDialogVisibility)}
      />
      <PriceBreakdownDialog
        testId={`${testId}_price-breakdown-dialog`}
        close={handleDialogVisibility}
        open={dialogOpen}
        addOns={data.addOns}
        insurancePrice={data.insurancePrice}
        cover={data.cover}
        currentPlan={currentPlan}
        totalPriceIncAddOns={data.totalPriceIncAddOns}
      />
    </>
  );
};
