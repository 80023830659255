import { Button } from '@dayinsure/components';
import { useCancelMTAChangesContext } from '../../../contexts/CancelMTAChangesContext';

type NewCarButtonsProps = {
  disabled?: boolean;
  testId?: string;
  isOneOffPayment?: boolean;
  isPaymentPage?: boolean;
  onContinue?: () => void;
  onCancel?: () => void;
};

export const MtaChangeButtons = ({
  testId = 'mta_buttons',
  disabled = false,
  isOneOffPayment = false,
  onContinue,
  onCancel,
  isPaymentPage = false,
}: NewCarButtonsProps) => {
  const { openCancelDialog } = useCancelMTAChangesContext();

  const getButtonText = () => {
    if (isOneOffPayment) {
      return 'Pay with card';
    }
    if (isPaymentPage) {
      return 'Confirm changes';
    }
    return 'Continue';
  };

  const buttonText = onContinue ? 'Continue' : getButtonText();
  const buttonProps = onContinue ? { onClick: onContinue } : { submit: true };

  return (
    <nav>
      <Button
        classNames="mb-8 mt-4"
        id={`${testId}_continue-button`}
        testId={`${testId}_continue-button`}
        {...buttonProps}
        fullWidth
        text={buttonText}
        icon={{ name: 'arrow-right', position: 'right' }}
        disabled={disabled}
      />

      <Button
        classNames="mb-24 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full"
        id={`${testId}_cancel-button`}
        testId={`${testId}_cancel-button`}
        fullWidth
        text="Cancel changes"
        onClick={onCancel || openCancelDialog}
        variant="bordered"
      />
    </nav>
  );
};
