import { Transition, LoadingSpinner } from '@dayinsure/components';
import clsx from 'clsx';
import { Fragment, useContext } from 'react';
import { QuoteJourneyStatusContext } from '../../../../contexts';

const testId = 'quote-loader';

export const QuoteLoader = () => {
  const { showLoader, copy } = useContext(QuoteJourneyStatusContext);

  return (
    <Transition
      show={showLoader}
      enter="transition-opacity duration-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as={Fragment}
    >
      <div
        className={clsx(
          'flex fixed top-0 left-0 z-30 flex-col items-center px-6 pt-6 w-full h-full text-center md:pt-12',
          'bg-gradient-to-br from-page-background-from to-page-background-to'
        )}
        data-testid={`${testId}_overlay`}
      >
        <span
          className="mt-6 text-xl text-main-content-1"
          data-testid={`${testId}_primary-text`}
        >
          {copy.title}
        </span>
        <LoadingSpinner className="my-16" />
        <span
          className="text-lg text-main-content-1"
          data-testid={`${testId}_secondary-text`}
        >
          {copy.subtitle}
        </span>
      </div>
    </Transition>
  );
};
