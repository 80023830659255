import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SelectRadioOption } from '@dayinsure/components';
import { FormListBox, FormRadioSelect, Label } from '../../../../components';
import { QuoteJourneyFormData, YesNoAnswer } from '../../../../types';
import {
  baseOptionDtoToViewOptions,
  getFormFieldAccessPath,
  isYes,
} from '../../../../helpers';
import { useVehicleOptionsQuery } from '../../../../hooks';

export const trackingDeviceOptions = (testId: string) => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_tracking-device_option_yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_tracking-device_option_no`,
  },
];

type TrackingDeviceProps = {
  testId: string;
};
export const TrackingDevice = ({ testId }: TrackingDeviceProps) => {
  const {
    values: {
      vehicle: { trackingDevice },
    },
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const { data } = useVehicleOptionsQuery();
  const provideTrackingDeviceRadioPath = getFormFieldAccessPath([
    'vehicle',
    'trackingDevice',
    'hasTrackingDevice',
  ]);
  const provideTrackingDeviceOptionPath = getFormFieldAccessPath([
    'vehicle',
    'trackingDevice',
    'code',
  ]);

  const radioIsYes = useMemo(
    () => isYes(trackingDevice?.hasTrackingDevice?.id),
    [trackingDevice?.hasTrackingDevice?.id]
  );
  const trackingDevicesOptions = useMemo(() => {
    return (
      data?.trackingDevices?.map(
        baseOptionDtoToViewOptions(`${testId}_tracking-device-options`)
      ) || []
    );
  }, [data?.trackingDevices, testId]);

  const handleRadioChange = useCallback(
    (option: SelectRadioOption) => {
      const { id } = option;
      if (id === YesNoAnswer.NO) {
        setFieldValue(provideTrackingDeviceOptionPath, null);
      }
      setFieldTouched(provideTrackingDeviceOptionPath, false);
    },
    [provideTrackingDeviceOptionPath, setFieldTouched, setFieldValue]
  );

  return (
    <div className="my-6 md:my-8">
      <div className="flex flex-col mb-4 md:mb-8">
        <Label
          text="Tracking device installed"
          icon="location"
          testId={`${testId}_tracking-device_label`}
        />
        <FormRadioSelect
          onChangeOptional={handleRadioChange}
          name={provideTrackingDeviceRadioPath}
          options={trackingDeviceOptions(testId)}
          id={`${testId}_tracking-device_input`}
          testId={`${testId}_tracking-device_input`}
        />
      </div>
      {radioIsYes && (
        <div className="flex flex-col mb-4 md:mb-8">
          <FormListBox
            unselectedMessage="Please select"
            icon="location"
            label="Tracking device"
            name={provideTrackingDeviceOptionPath}
            options={trackingDevicesOptions}
            id={`${testId}_tracking-device-list`}
            testId={`${testId}_tracking-device-list`}
          />
        </div>
      )}
    </div>
  );
};
