import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
  OptionCodeDto,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useOtherVehicleUseQuery = (enabled: boolean) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getOtherVehicleUse = () => {
    const api = new DayinsureAPIv2(OpenAPI);

    return api.product.getApiV2ProductOtherVehicleUsages('motor', 'latest');
  };

  return useQuery<OptionCodeDto[], ApiError>(
    query.vehicleOtherUsage,
    getOtherVehicleUse,
    {
      enabled,
      keepPreviousData: true,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
