/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AddressFamily {
  UNSPECIFIED = 'Unspecified',
  UNIX = 'Unix',
  INTER_NETWORK = 'InterNetwork',
  IMP_LINK = 'ImpLink',
  PUP = 'Pup',
  CHAOS = 'Chaos',
  NS = 'NS',
  IPX = 'Ipx',
  OSI = 'Osi',
  ISO = 'Iso',
  ECMA = 'Ecma',
  DATA_KIT = 'DataKit',
  CCITT = 'Ccitt',
  SNA = 'Sna',
  DEC_NET = 'DecNet',
  DATA_LINK = 'DataLink',
  LAT = 'Lat',
  HYPER_CHANNEL = 'HyperChannel',
  APPLE_TALK = 'AppleTalk',
  NET_BIOS = 'NetBios',
  VOICE_VIEW = 'VoiceView',
  FIRE_FOX = 'FireFox',
  BANYAN = 'Banyan',
  ATM = 'Atm',
  INTER_NETWORK_V6 = 'InterNetworkV6',
  CLUSTER = 'Cluster',
  IEEE12844 = 'Ieee12844',
  IRDA = 'Irda',
  NETWORK_DESIGNERS = 'NetworkDesigners',
  MAX = 'Max',
  PACKET = 'Packet',
  CONTROLLER_AREA_NETWORK = 'ControllerAreaNetwork',
  UNKNOWN = 'Unknown',
}