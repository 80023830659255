import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import {
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
  ApiError,
  Occupation,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useOccupationQuery = (occupationQuery: string) => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const getOccupations = () => {
    const api = new DayinsureAPIv2(OpenAPI);
    const queryLength = occupationQuery.length;
    const take = queryLength > 2 ? undefined : 5;
    return api.product.getApiV2ProductOccupations(
      'motor',
      'latest',
      occupationQuery,
      take
    );
  };

  return useQuery<Occupation[], ApiError>(
    query.occupations(occupationQuery),
    getOccupations,
    {
      keepPreviousData: true,
      cacheTime: 900000, // 15 mins
      retry: false,
      refetchOnWindowFocus: false,
      onError: err => {
        if (err.status === 422) {
          setGlobalError(err, {
            message: 'Something doesn’t look quite right, please go back and try again.',
          });
          return;
        }

        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
