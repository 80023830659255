import { addCurrency } from '../../utils';

export const numberToCurrencyOptions =
  (testId: string) =>
  (option: number): { id: string; name: string; testId: string } => {
    const value = addCurrency(option);
    return {
      id: String(option) || '',
      name: value,
      testId: `${testId}_option_${option}`,
    };
  };
