import { useContext, useEffect } from 'react';
import { PersonContext } from '../../contexts';
import { usePersonV2Query } from '../queries';

export const usePerson = () => {
  const personContext = useContext(PersonContext);
  const { setPerson } = personContext;
  const { data: personData } = usePersonV2Query(personContext.personId);

  useEffect(() => {
    if (personData && setPerson) {
      setPerson(personData);
    }
  }, [personData, setPerson]);

  return personContext;
};
