import clsx from 'clsx';
import Icon, { IconNames } from '../../DataDisplay/Icon/Icon';
import FieldError from '../../Feedback/FieldError/FieldError';

type PaymentFieldProps = {
  iframeId: string;
  label: {
    icon: IconNames;
    text: string;
  };
  focused?: boolean;
  disabled?: boolean;
  error?: string;
};

export const PaymentField = ({
  iframeId,
  label,
  focused,
  disabled,
  error,
}: PaymentFieldProps) => {
  return (
    <div className="relative form-field-spacing">
      <div className="flex space-x-2">
        <label htmlFor={iframeId} className="w-full">
          <span
            className={clsx(
              'inline-flex gap-2 items-center mb-4 leading-5 md:gap-3 text-main-content-1'
            )}
          >
            <Icon name={label.icon} />
            {label.text}
          </span>

          <div
            className={clsx(
              'bg-transparent rounded-lg border-2 transition-colors focus:border-main-content-1 field-base',
              { 'opacity-40': disabled },
              { 'border-main-content-3': !error && !focused },
              { 'border-error-border': error },
              { 'border-main-content-1': focused }
            )}
          >
            <span id={iframeId} data-cse={iframeId} className="w-full h-full" />
          </div>
        </label>
      </div>
      {error && (
        <FieldError
          id={`${iframeId}-error`}
          error={error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
