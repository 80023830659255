import { TestContext } from 'yup';
import { AnyObject } from 'yup/es/types';
import { FromObj, Vehicle } from '../../../types';
import { isTestContextExtended } from '../validation';

export const getCarManufactureYearDate = (ctx: TestContext<AnyObject>): Date | null => {
  if (!isTestContextExtended(ctx)) {
    return null;
  }

  const vehicleCtx = ctx?.from?.find((from: FromObj) => from?.value?.manufactureYear) as
    | { value: Vehicle }
    | undefined;

  if (!vehicleCtx) {
    return null;
  }

  const manufactureYear = parseInt(vehicleCtx.value.manufactureYear?.id || '', 10);
  if (Number.isNaN(manufactureYear)) {
    return null;
  }

  return new Date(manufactureYear, 0, 1);
};
