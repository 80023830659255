import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { QuoteJourneyLayout } from '../../../components';
import { useQuoteQuery } from '../../../hooks';
import {
  getEndorsement202,
  getEndorsement208,
  displayInstallationProof,
} from './CarSecurity.utils';
import { ApprovedTracker } from './ApprovedTracker';
import { AntiCloneImmobiliser } from './AntiCloneImmobiliser';
import { InstallationProof } from './InstallationProof';
import { Immobilisers } from './Immobilisers';
import { QuoteJourneyFormData } from '../../../types';
import {
  getEndorsement208BaseOnEndorsement202,
  isMtaQuoteJourneyData,
  isYes,
} from '../../../helpers';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import { PolicyMtaPaths } from '../../../routes';

const testId = 'car-security';

export const CarSecurity = () => {
  const { values, setFieldValue } = useFormikContext<
    QuoteJourneyFormData | MtaJourneyFormData
  >();
  const {
    carSecurity: { approvedTracker, antiCloneImmobiliser, immobiliser },
  } = values;
  const { quoteId } = useParams<{ quoteId: string; id: string }>();
  const quote = useQuoteQuery(quoteId);
  const isEndorsement202 = useMemo(
    () => !!getEndorsement202(quote.data?.endorsements),
    [quote.data?.endorsements]
  );
  const isEndorsement208 = useMemo(
    () => !!getEndorsement208(quote.data?.endorsements),
    [quote.data?.endorsements]
  );

  useEffect(() => {
    setFieldValue('carSecurity.isEndorsement202', isEndorsement202);
    setFieldValue('carSecurity.isEndorsement208', isEndorsement208);
  }, [isEndorsement202, isEndorsement208, setFieldValue]);

  const getBackLink = (): string | undefined => {
    if (isMtaQuoteJourneyData(values) && values.policyChange.changeCar) {
      return `../../${PolicyMtaPaths.NewCarUsage}`;
    }
    return undefined;
  };

  return (
    <QuoteJourneyLayout showBackButton customeBackLink={getBackLink()}>
      <h1 className="mt-12 mb-8 text-xl text-center" data-testid={`${testId}_title`}>
        Your car’s security
      </h1>
      <Card
        testId={`${testId}_info-card`}
        paddingLevel="small"
        classNames="mb-8 lg:mb-12"
      >
        <span>
          We just need to check a few vehicle security details to finalise your quote.
        </span>
      </Card>

      <div className="mb-16 sm:mb-24">
        <Form>
          {isEndorsement202 && <ApprovedTracker testId={testId} />}

          {isEndorsement208 &&
            getEndorsement208BaseOnEndorsement202(
              isEndorsement202,
              approvedTracker?.id
            ) && (
              <>
                <AntiCloneImmobiliser testId={testId} />
                {isYes(antiCloneImmobiliser?.id) && <Immobilisers testId={testId} />}
                {displayInstallationProof(immobiliser) && (
                  <InstallationProof testId={testId} />
                )}
              </>
            )}

          <Button
            id={`${testId}_continue_button`}
            testId={`${testId}_continue_button`}
            submit
            fullWidth
            icon={{ name: 'arrow-right', position: 'right' }}
            text="Continue"
          />
        </Form>
      </div>
    </QuoteJourneyLayout>
  );
};
