/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicyPollingResponseIAPIResult } from '../models/PolicyPollingResponseIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QuoteService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get a policy by the quote id provided.
   * @param id REQUIRED
   * @returns PolicyPollingResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV2QuotePolicy(
    id: string,
  ): CancelablePromise<PolicyPollingResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/quote/{id}/policy',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}