import { IconNames } from '@dayinsure/components';
import { ReactNode } from 'react';
import { FormListBox } from '../../../../components';

const getNumericOptionsForListBox = (testIdPrefix: string) =>
  Array.from({ length: 16 }, (_, index) => ({
    id: index.toString(),
    name: index.toString(),
    testId: `${testIdPrefix}_option_${index}`,
  }));

type NumericValueListboxProps = {
  testId: string;
  header: string;
  name: string;
  icon: IconNames;
  label: ReactNode;
};

export const NumericValueListbox = ({
  testId,
  label,
  icon,
  header,
  name,
}: NumericValueListboxProps) => (
  <div className="my-6 md:my-16">
    <h2
      data-testid={`${testId}_label`}
      className="flex relative w-full font-raleway font-normal md:mb-12 form-subtitle-compact"
    >
      {header}
    </h2>
    <div className="mb-4 md:mb-8">
      <FormListBox
        id={`${testId}_listbox`}
        testId={`${testId}_listbox`}
        name={name}
        unselectedMessage="Please select"
        options={getNumericOptionsForListBox(`${testId}_listbox`)}
        icon={icon}
        label={label}
      />
    </div>
  </div>
);
