import { Tooltip } from '@dayinsure/components';
import { ReactNode } from 'react';

type FormTooltipProps = {
  children: ReactNode;
  testId: string;
};

export const FormTooltip = ({ children, testId }: FormTooltipProps) => {
  return (
    <div className="inline-block ml-1 sm:!relative">
      <Tooltip
        ariaLabel="hint"
        className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
        position="relative"
        testId={testId}
        variant="annual"
      >
        {children}
        <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
          Got it
        </Tooltip.Close>
      </Tooltip>
    </div>
  );
};
