import { useMemo, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Card } from '@dayinsure/components';
import { AddOnModelDto } from '../../../../api/v1';
import { AddOn, QuoteJourneyFormData } from '../../../../types';
import { AddOnCheckboxSelect } from './AddOnCheckboxSelect';
import {
  addBreakdownOptionalAddonOption,
  addOnToOption,
  BREAKDOWN_NOT_NEEDED_ID,
  findAddOnByType,
  findBreakdownOptionalAddOnValue,
  isBreakdownAddOn,
  isOptionalAddOn,
} from './AddOns.utils';
import { BreakdownAddonRadio } from './BreakdownAddonRadio';
import { isYes } from '../../../../helpers';
import { AddOnType } from './AddOns.types';

type AddOnsProps = {
  addOns: AddOnModelDto[];
  disabled: boolean;
  testId: string;
  onAddOnsUpdate: (addOns: AddOn[]) => void;
};

export const AddOns = ({ addOns, disabled, testId, onAddOnsUpdate }: AddOnsProps) => {
  const [selectedAddOn, setSelectedAddOn] = useState<AddOn | null>(null);
  const { setFieldValue, values, setFieldTouched } =
    useFormikContext<QuoteJourneyFormData>();

  const addOnsOptions = useMemo(() => {
    const breakdownOneAddon = findAddOnByType(addOns, AddOnType.BREAKDOWN_1);
    return addBreakdownOptionalAddonOption(
      breakdownOneAddon,
      values.usualPaymentFrequency.code?.id
    )(addOns.map(addOnToOption(values.usualPaymentFrequency.code?.id)));
  }, [addOns, values.usualPaymentFrequency.code?.id]);

  useEffect(() => {
    setFieldValue('cover.addOns', addOnsOptions);
  }, [setFieldValue, addOnsOptions]);

  const optionalAddons = useMemo(
    () => addOnsOptions.filter(isOptionalAddOn),
    [addOnsOptions]
  );

  const breakdownAddonsOptions = useMemo(
    () => addOnsOptions.filter(isBreakdownAddOn),
    [addOnsOptions]
  );

  const handleCloseConfirmationDialog = (isConfirm: boolean, currentAddon?: AddOn) => {
    const currentAddOn = currentAddon || selectedAddOn;
    if (isConfirm) {
      const updatedAddOns = values.cover.addOns.map(addOn => {
        if (isBreakdownAddOn(currentAddOn) && isBreakdownAddOn(addOn)) {
          const breakdownAddOn = {
            ...addOn,
            checked: addOn.id === currentAddOn?.id,
          };

          if (breakdownAddOn.checked) {
            setFieldValue('cover.breakdownAddon', breakdownAddOn);
          }

          return breakdownAddOn;
        }
        if (addOn.id === currentAddOn?.id) {
          return { ...currentAddOn, checked: true };
        }
        return addOn;
      });

      setFieldValue('cover.addOns', updatedAddOns);
      onAddOnsUpdate(updatedAddOns);
    }
    setSelectedAddOn(null);
  };

  const resetBreakdownAddOns = () =>
    values.cover.addOns.map(addOn => {
      if (isBreakdownAddOn(addOn)) {
        return {
          ...addOn,
          checked: false,
        };
      }
      return addOn;
    });

  const handleAddOnClick = (currentAddOn: AddOn) => {
    const updatedAddOns = values.cover.addOns.map(addOn => {
      if (addOn.id === currentAddOn?.id) {
        return currentAddOn;
      }
      return addOn;
    });

    if (currentAddOn.id === BREAKDOWN_NOT_NEEDED_ID) {
      setSelectedAddOn(currentAddOn);
      onAddOnsUpdate(resetBreakdownAddOns());
      setFieldValue('cover.breakdownAddon', null);
      setFieldTouched('cover.breakdownAddon', false);
      return;
    }

    if (!currentAddOn.checked) {
      setFieldValue('cover.addOns', updatedAddOns);
      onAddOnsUpdate(updatedAddOns);
      if (currentAddOn?.id === AddOnType.PROTECTED_NCB) {
        setFieldValue('protectNoClaimsDiscount', {
          id: 'NO',
          name: 'No',
          testId: 'cover_protect-your-claims-bonus_option-no',
        });
      }
    } else {
      setSelectedAddOn(currentAddOn);
      handleCloseConfirmationDialog(true, currentAddOn);
    }
  };

  const handleBreakdownAddonClick = (option: AddOn) => {
    const updatedAddOns = values.cover.addOns.map(addOn => {
      if (isBreakdownAddOn(option) && isBreakdownAddOn(addOn)) {
        const breakdownAddOn = {
          ...addOn,
          checked: addOn.id === option?.id,
        };

        if (breakdownAddOn.checked) {
          setFieldValue('cover.breakdownAddon', breakdownAddOn);
        }

        return breakdownAddOn;
      }
      return addOn;
    });

    setFieldValue('cover.addOns', updatedAddOns);
    onAddOnsUpdate(updatedAddOns);
  };

  const breakdownOptionalAddonOptionValue = findBreakdownOptionalAddOnValue(
    values.cover?.optionalAddOnsValidation
  );
  const displayBreakdownAddons = isYes(breakdownOptionalAddonOptionValue?.id);

  return (
    <div className="my-6 md:my-12" data-testid={`${testId}_addons`}>
      <h3
        className="relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_optional-add-ons_title`}
      >
        Optional add-ons
      </h3>

      <AddOnCheckboxSelect
        testId={`${testId}_optional_add-ons_checkbox-select`}
        value={optionalAddons}
        onClick={handleAddOnClick}
        cols={{ mobile: 1, tablet: 1, desktop: 1 }}
        disabled={disabled}
      />

      {displayBreakdownAddons && (
        <section>
          <Card
            testId={`${testId}_breakdown-addons_info-card`}
            classNames="mb-8 font-railway"
          >
            Select the level of RAC Breakdown cover you need.
          </Card>

          <div className="mb-12 lg:mb-16">
            <BreakdownAddonRadio
              id={`${testId}_add-ons-checkbox-select`}
              testId={`${testId}_add-ons-checkbox-select`}
              name="cover.breakdownAddon"
              options={breakdownAddonsOptions}
              onChangeOptional={handleBreakdownAddonClick}
              disabled={disabled}
            />
          </div>
        </section>
      )}
    </div>
  );
};
