import { useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { QuoteJourneyRoutes } from '../../../routes';
import { QuoteJourneyFormData } from '../../../types';
import { getQuoteId } from '../../../helpers';

export const useYourQuoteSubmit = () => {
  const params = useParams();
  const navigate = useNavigateWithReferrer();

  return (
    { setSubmitting, setTouched }: FormikHelpers<QuoteJourneyFormData>,
    values: QuoteJourneyFormData
  ) => {
    const quoteId = getQuoteId(params);
    const reviewRoute = QuoteJourneyRoutes.Review;
    const policyConditionsRoute = QuoteJourneyRoutes.PolicyConditions;
    const route =
      values?.endorsements && values?.endorsements?.length > 0
        ? policyConditionsRoute
        : reviewRoute;
    navigate(`${quoteId}/${route}`);
    setSubmitting(false);
    setTouched({}, false);
  };
};
