import { ErrorDialog as GenericErrorDialog } from '@dayinsure/components';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';

const somethingWentWrong = "Something's wrong";
const genericErrorMessage = 'An unknown error occurred';
const ctaTryAgain = 'Go back and try again';
const GenericErrorDialogId = 'generic-error-dialog-cta';

export const ErrorDialog = () => {
  const { error, title, message, cta, ctaId, onClose, clearGlobalError } =
    useContext(GlobalErrorContext);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    clearGlobalError();
  };

  return (
    <GenericErrorDialog
      open={error}
      onClose={handleClose}
      title={title || somethingWentWrong}
      error={message || genericErrorMessage}
      ctaMessage={cta || ctaTryAgain}
      ctaId={ctaId || GenericErrorDialogId}
    />
  );
};
