import { v4 as uuid } from 'uuid';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { mtaJourneyMap } from '../../../routes/MtaRoutesMap';
import { PolicyMtaPaths } from '../../../routes';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import { NEW_DRIVER_ID } from '../../../helpers';

export const useMtaAdditionalDriverLicenceAndHistorySubmit = (
  currentMtaStep: PolicyMtaPaths | null
) => {
  const navigate = useNavigateWithReferrer();
  const next = mtaJourneyMap.getNext(currentMtaStep);

  return (
    values: MtaJourneyFormData,
    { setSubmitting, setTouched, setFieldValue }: FormikHelpers<MtaJourneyFormData>
  ) => {
    const newDriver = values.drivers.driversToAdd.find(
      driver => driver.id === NEW_DRIVER_ID
    );

    if (newDriver) {
      const updatedDriver = {
        ...newDriver,
        id: uuid(),
      };
      const updatedDriversToAdd = values.drivers.driversToAdd.map(driver =>
        driver.id === NEW_DRIVER_ID ? updatedDriver : driver
      );
      setFieldValue('drivers.driversToAdd', updatedDriversToAdd);
      setFieldValue('drivers.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled', null);
    }

    if (next) {
      navigate(`../mta/${next}`);
    }

    setSubmitting(false);
    setTouched({}, false);
  };
};
