import { noClaimsOptions } from './NoClaimsDiscount.const';
import { FormListBox, FormFieldBox } from '../../../../components';
import { NoClaimsDiscountTooltip } from './NoClaimsDiscountTooltip';

type NoClaimsDiscountProps = {
  testId: string;
};

export const NoClaimsDiscount = ({ testId }: NoClaimsDiscountProps) => (
  <FormFieldBox>
    <h3
      className="relative mb-6 font-raleway text-lg font-normal md:mb-12"
      data-testid={`${testId}_no-claim-discount-label`}
    >
      How many years No Claims Bonus do you{' '}
      <span className="inline-block">
        have?
        <NoClaimsDiscountTooltip testId={testId} />
      </span>
    </h3>
    <div className="flex relative flex-col mb-4 md:mb-8">
      <FormListBox
        unselectedMessage="Please select"
        name="cover.noClaimsHistory.yearsNoClaimsBonus"
        options={noClaimsOptions}
        id={`${testId}_no-claim-discount-select`}
        testId={`${testId}_no-claim-discount-select`}
        icon="date"
        label="Number of years"
      />
    </div>
  </FormFieldBox>
);
