import { GlobalErrorContext } from '@dayinsure/shared';
import { Formik, FormikHelpers } from 'formik';
import { ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getValidationSchemaFromRoute,
  quoteJourneyInitialFormData,
} from '../../../forms';
import { getCurrentStepFromRoute, quoteJourneyStorage } from '../../../helpers';
import {
  useCarUsageSubmit,
  useCreateQuoteSubmit,
  useDriversSubmit,
  useNextStepSubmit,
  usePaymentSubmit,
  useRegistrationSearchQuery,
  useRegistrationSearchSubmit,
  useReviewSubmit,
  useYourDetailSubmit,
  useYourQuoteSubmit,
} from '../../../hooks';
import { QuoteJourneyRoutes } from '../../../routes';
import { QuoteJourneyFormData } from '../../../types';
import { QuoteLoader } from './QuoteLoader';
import { withQuoteJourneyStatusContextWrapper } from '../../../contexts';
import { useCarSecuritySubmit } from '../../../hooks/submitHandlers/useCarSecuritySubmit';
import { QuoteJourneySideEffects } from './QuoteJourneySideEffects';

type QuoteJourneyWrapperProps = {
  children: ReactNode;
};

const QuoteJourneyWrapperInner = ({ children }: QuoteJourneyWrapperProps) => {
  const { pathname } = useLocation();
  const { setGlobalError } = useContext(GlobalErrorContext);
  const currentQuoteStep = getCurrentStepFromRoute(pathname);
  const registrationSearchQuery = useRegistrationSearchQuery();
  const registrationSearchSubmitHandler = useRegistrationSearchSubmit();
  const carUsageSubmitHandler = useCarUsageSubmit(currentQuoteStep);
  const yourDetailsSubmitHandler = useYourDetailSubmit();
  const nextStepSubmitHandler = useNextStepSubmit(currentQuoteStep);
  const driversSubmitHandler = useDriversSubmit(currentQuoteStep);
  const reviewSubmitHandler = useReviewSubmit();
  const createQuoteSubmitHandler = useCreateQuoteSubmit();
  const paymentSubmitHandler = usePaymentSubmit();
  const yourQuoteSubmitHandler = useYourQuoteSubmit();
  const carSecuritySubmitHandler = useCarSecuritySubmit();
  const { values: savedValues, touched: savedTouched } =
    quoteJourneyStorage.loadQuoteJourneyProgress();

  const handleSubmit = (
    values: QuoteJourneyFormData,
    formikHelpers: FormikHelpers<QuoteJourneyFormData>
  ) => {
    try {
      switch (currentQuoteStep) {
        case QuoteJourneyRoutes.RegistrationSearch:
          registrationSearchSubmitHandler(
            formikHelpers,
            registrationSearchQuery(values.vehicle.registrationNumber)
          );
          break;
        case QuoteJourneyRoutes.CarUsage:
          carUsageSubmitHandler(values.drivers, formikHelpers);
          break;
        case QuoteJourneyRoutes.YourDetails:
          yourDetailsSubmitHandler(values, formikHelpers, nextStepSubmitHandler);
          break;
        case QuoteJourneyRoutes.DriverDetails:
          driversSubmitHandler(formikHelpers);
          break;
        case QuoteJourneyRoutes.Cover:
          createQuoteSubmitHandler(values, formikHelpers);
          break;
        case QuoteJourneyRoutes.CarSecurity:
          carSecuritySubmitHandler(
            values.carSecurity,
            formikHelpers,
            nextStepSubmitHandler
          );
          break;
        case QuoteJourneyRoutes.Review:
          reviewSubmitHandler(values, formikHelpers);
          break;
        case QuoteJourneyRoutes.Payment:
          paymentSubmitHandler(values);
          break;
        case QuoteJourneyRoutes.YourQuote:
          yourQuoteSubmitHandler(formikHelpers, values);
          break;
        default:
          nextStepSubmitHandler(formikHelpers);
      }
    } catch (error) {
      setGlobalError(error);
    }
  };

  return (
    <>
      <QuoteLoader />
      <Formik<QuoteJourneyFormData>
        initialValues={savedValues || quoteJourneyInitialFormData}
        initialTouched={savedTouched || {}}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchemaFromRoute(pathname)}
      >
        <QuoteJourneySideEffects>{children}</QuoteJourneySideEffects>
      </Formik>
    </>
  );
};

export const QuoteJourneyWrapper =
  withQuoteJourneyStatusContextWrapper<QuoteJourneyWrapperProps>(
    QuoteJourneyWrapperInner
  );
