import { Card } from '@dayinsure/components';
import { useMemo } from 'react';
import { EditCardLink } from '../../EditCardLink';
import { DriverFormData } from '../../../../types';
import { QuoteJourneyRoutes } from '../../../../routes';

const editDriverButtonAllowedRoutes: QuoteJourneyRoutes[] = [
  QuoteJourneyRoutes.Cover,
  QuoteJourneyRoutes.YourQuote,
  QuoteJourneyRoutes.Review,
  QuoteJourneyRoutes.Payment,
  QuoteJourneyRoutes.YourHome,
  QuoteJourneyRoutes.Drivers,
  QuoteJourneyRoutes.YourLicenceAndHistory,
];

type DriversItemProps = {
  drivers: DriverFormData[];
  currentStep: QuoteJourneyRoutes;
};

export const DriversItem = ({ drivers, currentStep }: DriversItemProps) => {
  const getDriverDisplayName = ({ firstName, lastName }: DriverFormData) =>
    `${firstName} ${lastName}`;

  const editRoute = useMemo(() => {
    if (drivers.length > 1) {
      return QuoteJourneyRoutes.Drivers;
    }
    return QuoteJourneyRoutes.YourDetails;
  }, [drivers.length]);

  const shouldDisplayEditDriverButton = useMemo(
    () =>
      editDriverButtonAllowedRoutes.includes(currentStep) &&
      currentStep !== QuoteJourneyRoutes.Drivers,
    [currentStep]
  );

  return (
    <Card.CardListItem
      testId="quote-summary-card_drivers-item"
      label="Drivers"
      asideItem={
        shouldDisplayEditDriverButton && (
          <EditCardLink
            to={editRoute}
            testId="quote-summary-card_edit-drivers-link"
            className="text-white"
          >
            Edit
          </EditCardLink>
        )
      }
      icon="user"
    >
      {drivers.map(driver => (
        <span className="block" key={driver.id}>
          {getDriverDisplayName(driver)}
        </span>
      ))}
    </Card.CardListItem>
  );
};
