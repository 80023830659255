import { InsurancePriceDto, PaymentPlanDto } from '../../../api/v1';

export const getTotalPrice = (
  isPlanAnnual: boolean,
  currentPlan?: PaymentPlanDto,
  insurancePrice?: InsurancePriceDto
) =>
  isPlanAnnual
    ? insurancePrice?.total?.amount
    : currentPlan?.totalCost?.amount ||
      // TODO: remove when we get total price for monthly payment from backend, for demo purpose only
      currentPlan?.installmentDetails?.financeAmount?.amount;
