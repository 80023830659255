import { useField } from 'formik';
import { MotorConviction } from '../../../../../../types/quoteJourneyForm';
import { ConvictionCard } from './ConvictionCard';
import { ConvictionForm } from './ConvictionForm';

type ConvictionListItemProps = {
  name: string;
  testId: string;
  shouldDisplayCancelButton: boolean;
  onRemove: (conviction: MotorConviction) => void;
};

export const ConvictionListItem = ({
  name,
  testId,
  onRemove,
  shouldDisplayCancelButton,
}: ConvictionListItemProps) => {
  const [field] = useField<MotorConviction>({ name });
  const handleRemove = () => onRemove(field.value);
  const isAdding = field.value.isSaved === false;

  return isAdding ? (
    <ConvictionForm
      shouldDisplayCancelButton={shouldDisplayCancelButton}
      name={name}
      testId={testId}
      onCancel={handleRemove}
    />
  ) : (
    <ConvictionCard conviction={field.value} testId={testId} onRemove={handleRemove} />
  );
};
