import {
  Combobox,
  ComboboxProps,
  FieldError,
  IconNames,
  Label,
  Tooltip,
} from '@dayinsure/components';
import { useField } from 'formik';
import { useRef } from 'react';
import { useFocusOnError } from '../../../hooks';

type FormCompoboxProps = {
  label: {
    icon: IconNames;
    text: string;
  };
  name: string;
  closeTooltip?: string;
  hint?: string;
  tooltip?: string;
} & Omit<ComboboxProps, 'name' | 'value' | 'onChange' | 'onBlur' | 'error'>;

export const FormCombobox = ({
  label,
  tooltip,
  closeTooltip = 'Close',
  hint = 'hint',
  ...comboboxProps
}: FormCompoboxProps) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [field, meta] = useField(comboboxProps);
  useFocusOnError({ fieldRef, name: comboboxProps.name });

  const isRequiredErrorAndHasValue = meta.error === 'Required' && meta.value;

  return (
    <div className="relative form-field-spacing">
      <div className="flex space-x-2">
        <Label
          name={label.text}
          icon={label.icon}
          htmlFor={comboboxProps.id}
          disabled={comboboxProps.disabled}
          className="mb-4"
        />
        {tooltip && (
          <Tooltip
            ariaLabel={hint}
            position="relative"
            className="flex flex-col text-popup-content-1"
            variant="annual"
          >
            {tooltip}
            <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
              {closeTooltip}
            </Tooltip.Close>
          </Tooltip>
        )}
      </div>
      <Combobox
        {...comboboxProps}
        name={field.name}
        value={field.value}
        onChange={value => {
          field.onChange({ target: { value, name: field.name } });
        }}
        onBlur={() => {
          field.onBlur({ target: { name: field.name } });
        }}
        error={meta.touched && meta.error !== undefined}
        ref={fieldRef}
      />
      {meta.touched && meta.error && !isRequiredErrorAndHasValue && (
        <FieldError
          id={`${comboboxProps.id}-error`}
          error={meta.error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
