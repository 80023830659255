import { ListboxOption } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { FormListBox, FormFieldBox } from '../../../../../../components';
import { MtaJourneyFormData } from '../../../../../../types/mtaJourneyForm';
import { useMainDriver } from '../../../../../../hooks/useMainDriver';

type MainDriverSelectProps = {
  testId: string;
};

export const MainDriverSelect = ({ testId }: MainDriverSelectProps) => {
  const { setFieldValue } = useFormikContext<MtaJourneyFormData>();
  const { mainDriverOptions } = useMainDriver();
  const handleChangeMainDriver = (option: ListboxOption) => {
    setFieldValue('drivers.mainDriver', option);
  };

  return (
    <FormFieldBox className="mb-12">
      <h3
        className="mb-6 font-raleway text-lg font-normal md:mb-12"
        data-testid={`${testId}_main-driver-label`}
      >
        Who will be the main driver?
      </h3>
      <div className="flex relative flex-col mb-4 md:mb-8">
        <FormListBox
          id={`${testId}_main-driver-select`}
          testId={`${testId}_main-driver-select`}
          icon="user"
          label="Driver"
          name="drivers.mainDriver"
          options={mainDriverOptions}
          unselectedMessage="Please select"
          onChangeOptional={handleChangeMainDriver}
        />
      </div>
    </FormFieldBox>
  );
};
