import { FormikTouched } from 'formik';
import { QuoteJourneyFormData } from '../../types';

type QuoteJourneyStorageConfig = {
  inMemory: boolean;
};

const config: QuoteJourneyStorageConfig = {
  inMemory: true,
};

const VALUES_KEY = 'QUOTE_JOURNEY_PROGRESS_VALUES';
const TOUCHED_KEY = 'QUOTE_JOURNEY_PROGRESS_TOUCHED';

let inMemoryValues: QuoteJourneyFormData | null = null;
let inMemoryTouched: FormikTouched<QuoteJourneyFormData> | null = null;

const saveQuoteJourneyProgress =
  ({ inMemory }: QuoteJourneyStorageConfig) =>
  (values: QuoteJourneyFormData, touched: FormikTouched<QuoteJourneyFormData>) => {
    if (inMemory) {
      inMemoryValues = values;
      inMemoryTouched = touched;
    } else {
      sessionStorage.setItem(VALUES_KEY, JSON.stringify(values));
      sessionStorage.setItem(TOUCHED_KEY, JSON.stringify(touched));
    }
  };

type QuoteJourneyProgress = {
  values: QuoteJourneyFormData | null;
  touched: FormikTouched<QuoteJourneyFormData> | null;
};

const loadQuoteJourneyProgress =
  ({ inMemory }: QuoteJourneyStorageConfig) =>
  (): QuoteJourneyProgress => {
    if (inMemory) {
      return {
        values: inMemoryValues,
        touched: inMemoryTouched,
      };
    }

    try {
      const values = sessionStorage.getItem(VALUES_KEY);
      const touched = sessionStorage.getItem(TOUCHED_KEY);

      if (values && touched) {
        return {
          values: JSON.parse(values),
          touched: JSON.parse(touched),
        };
      }

      return {
        values: null,
        touched: null,
      };
    } catch (e) {
      return {
        values: null,
        touched: null,
      };
    }
  };

const clearQuoteJourneyProgress =
  ({ inMemory }: QuoteJourneyStorageConfig) =>
  (): void => {
    if (inMemory) {
      inMemoryValues = null;
      inMemoryTouched = null;
    } else {
      sessionStorage.removeItem(VALUES_KEY);
      sessionStorage.removeItem(TOUCHED_KEY);
    }
  };

export const quoteJourneyStorage = {
  saveQuoteJourneyProgress: saveQuoteJourneyProgress(config),
  loadQuoteJourneyProgress: loadQuoteJourneyProgress(config),
  clearQuoteJourneyProgress: clearQuoteJourneyProgress(config),
};
