import get from 'lodash.get';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAddressDetailQuery } from '../../../../hooks';
import { AddressSearch, AddressSearchProps } from '../AddressSearch';
import { BaseDataEntry } from '../../../../types';

type WithAddressDetailsProps = {
  addressDetailsInputName: string;
} & AddressSearchProps;

const withAddressDetails =
  (WrappedComponent: typeof AddressSearch) =>
  ({ addressDetailsInputName, ...componentProps }: WithAddressDetailsProps) => {
    const { values, setFieldValue } = useFormikContext();
    const { id: postKey }: BaseDataEntry = get(
      values,
      componentProps.addressInputName
    ) || { id: '' };
    const {
      data: addressDetail,
      isFetching: addressDetailFetching,
      error: addressDetailError,
    } = useAddressDetailQuery(postKey || undefined);

    useEffect(() => {
      if (!addressDetailError && addressDetail) {
        setFieldValue(addressDetailsInputName, addressDetail);
      }
    }, [addressDetail, addressDetailError, addressDetailsInputName, setFieldValue]);

    return <WrappedComponent {...componentProps} isFetching={addressDetailFetching} />;
  };

export const SearchAddressDetails = withAddressDetails(AddressSearch);
