import { v4 as uuidv4 } from 'uuid';
import { MotorConviction } from '../../../types';

export const getEmptyConviction = (): MotorConviction => ({
  id: uuidv4(),
  code: null,
  date: {
    day: '',
    month: '',
    year: '',
    parsedDate: '',
  },
  banReceived: null,
  disqualifiedLengthInMonths: null,
  isSaved: false,
  penaltyPoints: null,
  fineAmount: undefined,
});
