type BreakdownOneProps = {
  testId: string;
};

export const BreakdownOne = ({ testId }: BreakdownOneProps) => (
  <div className="font-lato text-sm leading-5 text-main-content-1" data-testid={testId}>
    <ul className="pl-4 list-disc">
      <li>Assistance at the side of the road</li>
      <li>Recovery to any UK destination</li>
    </ul>
  </div>
);
