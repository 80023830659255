import { useField } from 'formik';
import {
  FieldError,
  CheckboxSelector,
  CheckboxSelectorProps,
} from '@dayinsure/components';
import { useRef } from 'react';
import { useFocusOnError } from '../../../hooks';

type FormCheckboxProps = Pick<
  CheckboxSelectorProps,
  | 'additionalIcon'
  | 'name'
  | 'displayText'
  | 'tooltip'
  | 'id'
  | 'testId'
  | 'noBorders'
  | 'enlarged'
>;

export const FormCheckbox = ({ name, ...props }: FormCheckboxProps) => {
  const [field, meta] = useField({ name });
  const fieldRef = useRef<HTMLDivElement | null>(null);
  useFocusOnError({ fieldRef, name });
  const handleChange = ({ checked }: { checked: boolean }) => {
    field.onChange({ target: { name, value: checked } });
  };

  return (
    <div className="relative form-field-spacing" ref={fieldRef}>
      <CheckboxSelector
        {...field}
        {...props}
        error={meta.touched && !!meta.error}
        isChecked={!!field.value}
        onChange={handleChange}
      />
      {meta.touched && meta.error && (
        <FieldError
          className="absolute right-0 mt-2"
          error={meta.error}
          id={`${props.testId}_error`}
          testId={`${props.testId}_error`}
        />
      )}
    </div>
  );
};
