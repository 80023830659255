import { Button, ContentContainer } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { isNull } from '@dayinsure/core';
import { useFormikContext } from 'formik';
import { quoteJourneyMap, QuoteJourneyRoutes } from '../../../routes';
import { getCurrentStepFromRoute, hasEndorsement202Or208 } from '../../../helpers';
import { QuoteJourneyFormData } from '../../../types';

type QuoteJourneyLayoutProps = {
  children: ReactNode;
  showBackButton?: boolean;
  customeBackLink?: string;
};

export const QuoteJourneyLayout = ({
  children,
  showBackButton = true,
  customeBackLink,
}: QuoteJourneyLayoutProps) => {
  const {
    values: { endorsements, usualPaymentFrequency },
    setFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const doUserGotEndorsements = endorsements && endorsements?.length > 0;
  const { pathname } = useLocation();
  const navigate = useNavigateWithReferrer();
  const currentQuoteStep = getCurrentStepFromRoute(pathname);
  const previous = quoteJourneyMap.getPrevious(currentQuoteStep);

  const handleGoBack = () => {
    if (customeBackLink) {
      return navigate(customeBackLink);
    }

    if (pathname.includes('mta')) {
      return navigate(-1);
    }

    if (!isNull(previous)) {
      switch (currentQuoteStep) {
        case QuoteJourneyRoutes.Review:
          if (doUserGotEndorsements) {
            navigate(`../${QuoteJourneyRoutes.PolicyConditions}`);
            break;
          }
          navigate(`../${QuoteJourneyRoutes.YourQuote}`);
          break;
        case QuoteJourneyRoutes.YourQuote:
          if (endorsements && hasEndorsement202Or208(endorsements)) {
            navigate(`../${QuoteJourneyRoutes.CarSecurity}`);
            break;
          }
          navigate(`../${previous}`);
          break;
        case QuoteJourneyRoutes.Payment:
          if (usualPaymentFrequency?.code?.id === 'MON') {
            setFieldValue('isMonthlyImportantInformationConfirmed', false);
            setFieldValue('isAccountHolder', false);
            setFieldValue('isDirectDebitConfirmed', false);
            setFieldValue('accountHolder', null);
            setFieldValue('bankAccountNumber', null);
            setFieldValue('sortCode', null);
            setFieldValue('encryptedCardNumber', null);
            setFieldValue('cardholderName', null);
            setFieldValue('encryptedExpiryMonth', null);
            setFieldValue('encryptedExpiryYear', null);
            setFieldValue('encryptedSecurityCode', null);
          }
          navigate(`../${previous}`);
          break;
        default:
          navigate(`../${previous}`);
      }
    }
    return null;
  };

  const displayButton = showBackButton && !isNull(previous);

  return (
    <div className="w-full">
      {displayButton && (
        <Button
          testId="car-details_back-button"
          id="car-details_back-button"
          variant="ghost"
          onClick={handleGoBack}
          text="Back"
          classNames="mt-12 mb-8 text-cta-secondary-text"
          icon={{
            position: 'left',
            name: 'arrow-left',
            size: '.75rem',
          }}
        />
      )}
      <ContentContainer>{children}</ContentContainer>
    </div>
  );
};
