import { useFormikContext } from 'formik';
import { FormRadioSelect } from '../../../../components';
import { QuoteJourneyFormData, YesNoDataEntry, YesNoAnswer } from '../../../../types';
import { InsuranceDeclinedTooltip } from './InsuranceDeclinedTooltip';

const getInsuranceDeclinedOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_insurance-declined-option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_insurance-declined-option-no`,
  },
];

type InsuranceDeclinedProps = {
  testId: string;
};

export const InsuranceDeclined = ({ testId }: InsuranceDeclinedProps) => {
  const {
    values: { drivers },
  } = useFormikContext<QuoteJourneyFormData>();

  return (
    <div className="my-6 md:my-16">
      <h2
        data-testid={`${testId}_insurance-declined-label`}
        className="relative w-full font-raleway font-normal form-subtitle-compact"
      >
        {drivers?.length > 1
          ? 'Has any driver ever had insurance declined or '
          : 'Have you ever had insurance declined or '}
        <span className="inline-block">
          cancelled?
          <InsuranceDeclinedTooltip testId={testId} />
        </span>
      </h2>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          id={`${testId}_insurance-declined-radio`}
          testId={`${testId}_insurance-declined-radio`}
          name="hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled"
          options={getInsuranceDeclinedOptions(testId)}
        />
      </div>
    </div>
  );
};
