/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptanceCriteriaDto } from '../models/AcceptanceCriteriaDto';
import type { AddOnDto } from '../models/AddOnDto';
import type { CoverDto } from '../models/CoverDto';
import type { CoverOptionsDto } from '../models/CoverOptionsDto';
import type { DocumentDto } from '../models/DocumentDto';
import type { DriverOptionsDto } from '../models/DriverOptionsDto';
import type { EndorsementsDto } from '../models/EndorsementsDto';
import type { LicenceDurationsDto } from '../models/LicenceDurationsDto';
import type { OptionCodeDto } from '../models/OptionCodeDto';
import type { VehicleOptionsDto } from '../models/VehicleOptionsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets a list of acceptance criteria for a product and scheme
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param schemeName REQUIRED - name of the scheme
   * @returns AcceptanceCriteriaDto Success
   * @throws ApiError
   */
  public getApiV2ProductSchemeAcceptanceCriteria(
    productName: string,
    productVersion: string,
    schemeName: string,
  ): CancelablePromise<Array<AcceptanceCriteriaDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/acceptance-criteria',
      path: {
        'productName': productName,
        'productVersion': productVersion,
        'schemeName': schemeName,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets A List of AddOns
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param schemeName REQUIRED - name of the scheme
   * @returns AddOnDto Success
   * @throws ApiError
   */
  public getApiV2ProductSchemeAddons(
    productName: string,
    productVersion: string,
    schemeName: string,
  ): CancelablePromise<Array<AddOnDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/addons',
      path: {
        'productName': productName,
        'productVersion': productVersion,
        'schemeName': schemeName,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets An AddOn
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param schemeName REQUIRED - name of the scheme
   * @param addOn REQUIRED - name of the addOn
   * @returns AddOnDto Success
   * @throws ApiError
   */
  public getApiV2ProductSchemeAddons1(
    productName: string,
    productVersion: string,
    schemeName: string,
    addOn: string,
  ): CancelablePromise<AddOnDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/addons/{addOn}',
      path: {
        'productName': productName,
        'productVersion': productVersion,
        'schemeName': schemeName,
        'addOn': addOn,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of canbus immobilisers for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductCanbusImmobilisers(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/canbus-immobilisers',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of claim types for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductClaimTypes(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/claim-types',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of documents for a product and scheme
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param schemeName REQUIRED - name of the scheme
   * @returns DocumentDto Success
   * @throws ApiError
   */
  public getApiV2ProductSchemeDocuments(
    productName: string,
    productVersion: string,
    schemeName: string,
  ): CancelablePromise<Array<DocumentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/documents',
      path: {
        'productName': productName,
        'productVersion': productVersion,
        'schemeName': schemeName,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of conviction types for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductConvictionTypes(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/conviction-types',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a Cover
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param schemeName REQUIRED - name of the scheme
   * @param coverType
   * @returns CoverDto Success
   * @throws ApiError
   */
  public getApiV2ProductSchemeCover(
    productName: string,
    productVersion: string,
    schemeName: string,
    coverType: string,
  ): CancelablePromise<CoverDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/cover/{coverType}',
      path: {
        'productName': productName,
        'productVersion': productVersion,
        'schemeName': schemeName,
        'coverType': coverType,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the cover options
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param schemeName
   * @returns CoverOptionsDto Success
   * @throws ApiError
   */
  public getApiV2ProductSchemeCoverOptions(
    productName: string,
    productVersion: string,
    schemeName: string,
  ): CancelablePromise<CoverOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/cover-options',
      path: {
        'productName': productName,
        'productVersion': productVersion,
        'schemeName': schemeName,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets the driver options
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns DriverOptionsDto Success
   * @throws ApiError
   */
  public getApiV2ProductDriverOptions(
    productName: string,
    productVersion: string,
  ): CancelablePromise<DriverOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/driver-options',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of driving qualifications for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductDrivingQualifications(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/driving-qualifications',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of employment industries for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param match OPTIONAL - a wildcard match string for the description of the modification
   * @param take OPTIONAL - the number of employment industries to return
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductEmploymentIndustries(
    productName: string,
    productVersion: string,
    match?: string,
    take?: number,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/employment-industries',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      query: {
        'match': match,
        'take': take,
      },
      errors: {
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Gets a list of employment statuses for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductEmploymentStatuses(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/employment-statuses',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of endorsements for a product and scheme
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param schemeName REQUIRED - name of the scheme
   * @returns EndorsementsDto Success
   * @throws ApiError
   */
  public getApiV2ProductSchemeEndorsements(
    productName: string,
    productVersion: string,
    schemeName: string,
  ): CancelablePromise<Array<EndorsementsDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/scheme/{schemeName}/endorsements',
      path: {
        'productName': productName,
        'productVersion': productVersion,
        'schemeName': schemeName,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of licence durations for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns LicenceDurationsDto Success
   * @throws ApiError
   */
  public getApiV2ProductLicenceDurations(
    productName: string,
    productVersion: string,
  ): CancelablePromise<LicenceDurationsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/licence-durations',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of medical conditions for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductMedicalConditions(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/medical-conditions',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list on no claim discount years
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductNoClaimsDiscountYears(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/no-claims-discount-years',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of occupations for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param match OPTIONAL - a wildcard match string for the description of the modification
   * @param take OPTIONAL - the number of occupations to return
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductOccupations(
    productName: string,
    productVersion: string,
    match?: string,
    take?: number,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/occupations',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      query: {
        'match': match,
        'take': take,
      },
      errors: {
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Gets a list of other vehicle usages for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductOtherVehicleUsages(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/other-vehicle-usages',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of student occupations usages for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductStudentOccupations(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/student-occupations',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of vehicle modifications for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @param match OPTIONAL - a wildcard match string for the description of the modification
   * @param take OPTIONAL - the number of vehicle modifications to return
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductVehicleModifications(
    productName: string,
    productVersion: string,
    match?: string,
    take?: number,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-modifications',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      query: {
        'match': match,
        'take': take,
      },
      errors: {
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Gets a list of vehicle options for a product
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns VehicleOptionsDto Success
   * @throws ApiError
   */
  public getApiV2ProductVehicleOptions(
    productName: string,
    productVersion: string,
  ): CancelablePromise<VehicleOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-options',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of vehicle owners
   * @param productName REQUIRED - name of the product
   * @param productVersion REQUIRED - version of the product or 'latest'
   * @returns OptionCodeDto Success
   * @throws ApiError
   */
  public getApiV2ProductVehicleOwner(
    productName: string,
    productVersion: string,
  ): CancelablePromise<Array<OptionCodeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/product/{productName}/{productVersion}/vehicle-owner',
      path: {
        'productName': productName,
        'productVersion': productVersion,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

}