/* eslint-disable react/no-danger */
import { Icon } from '@dayinsure/components';
import clsx from 'clsx';
import { useState } from 'react';
import { AccordionItemType } from '../../../types/Accordion';

export const AccordionItem = ({ title, description }: AccordionItemType) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="py-1 border-b-2 border-main-content-5">
      <button
        type="button"
        className="py-4 w-full text-left focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex justify-between items-center">
          <span className="pr-2" dangerouslySetInnerHTML={{ __html: title }} />

          <Icon
            name="chevron-down"
            className={clsx('transition-transform text-link', { 'rotate-180': isOpen })}
          />
        </div>
      </button>
      {isOpen && (
        <div className="pb-3" dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </div>
  );
};
