import { Card, Icon, NegatePageContainer } from '@dayinsure/components';
import { LinkWithReferrer } from '@dayinsure/shared';
import { briefcase, coffee } from '../../../assets';

type LinkCardsProps = {
  testId: string;
};

export const LinkCards = ({ testId }: LinkCardsProps) => (
  <NegatePageContainer className="grid grid-cols-[repeat(2,minmax(240px,_1fr))] gap-6 mb-8">
    <LinkWithReferrer data-testid={`${testId}-link-card_manage-policies`} to="/policies">
      <Card paddingLevel="small" classNames="h-48 w-full !p-8">
        <img src={briefcase} alt="trophy" className="mx-auto mb-5 h-[88px]" />
        <div className="flex justify-center items-center font-semibold">
          Manage policies
          <Icon name="arrow-right" size="0.75rem" className="ml-3" />
        </div>
      </Card>
    </LinkWithReferrer>
    <LinkWithReferrer
      data-testid={`${testId}-link-card_manage-account`}
      to="/account/details"
    >
      <Card paddingLevel="small" classNames="h-48 w-full !p-8">
        <img src={coffee} alt="trophy" className="mx-auto mb-5 h-[88px]" />
        <div className="flex justify-center items-center font-semibold">
          Manage account
          <Icon name="arrow-right" size="0.75rem" className="ml-3" />
        </div>
      </Card>
    </LinkWithReferrer>
  </NegatePageContainer>
);
