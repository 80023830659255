import { KeyboardEvent } from 'react';
import Icon from '../Icon/Icon';
import FormattedPrice from '../FormattedPrice/FormattedPrice';

type PaymentMobileFooterCopy = {
  title: string;
  info: string;
  button: string;
};

export type PaymentMobileFooterProps = {
  copy: PaymentMobileFooterCopy;
  testId: string;
  price?: number;
  priceSufix?: string;
  onButtonClick(): void;
  onButtonKeyDown(e: KeyboardEvent<HTMLInputElement>): void;
};

export const PaymentMobileFooter = ({
  copy,
  price,
  priceSufix,
  onButtonClick,
  onButtonKeyDown,
  testId,
}: PaymentMobileFooterProps) => {
  return (
    <div className="flex fixed inset-x-0 bottom-0 z-30 flex-col items-center py-4 mt-auto w-full text-center text-white rounded-t-lg md:hidden bg-summary-1">
      <div
        className="flex gap-2 items-center mb-2 font-lato text-sm leading-3 text-white/60"
        data-testid={`${testId}_title`}
      >
        <Icon name="policy" size="0.75rem" /> {copy.title}
      </div>

      <div data-testid={`${testId}_price`}>
        <FormattedPrice price={price} lineThrough sufix={priceSufix} />
      </div>

      <span className="flex gap-2 items-center mb-2 font-lato text-sm leading-4">
        {copy.info}
      </span>
      <span
        role="button"
        tabIndex={0}
        className="flex gap-2 items-center mb-2 text-sm font-bold leading-3"
        onClick={onButtonClick}
        onKeyDown={onButtonKeyDown}
        data-testid={`${testId}_price-breakdown-dialog_open-button`}
      >
        <Icon name="info" size="0.75rem" />
        {copy.button}
      </span>
    </div>
  );
};
