import { PageContainer, Card, Button } from '@dayinsure/components';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalErrorContext, useSignIn } from '@dayinsure/shared';
import { useSendPasswordResetMutation, usePerson, useReferrer } from '../../../../hooks';

type State = {
  email: string;
};

const testId = 'forgot-password-secure-link';

export const ForgotPasswordSecureLink = () => {
  const location = useLocation();
  const state = location.state as State;
  const referrer = useReferrer();
  const { isGuest } = usePerson();
  const { signIn } = useSignIn(referrer.name, isGuest);
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);
  const { mutateAsync, isLoading } = useSendPasswordResetMutation();

  const [isLinkResent, setIsLinkResent] = useState(false);

  const handleResetPasswordError = (error: unknown) => {
    setGlobalError(error, {
      title: 'Sorry, there was a problem',
      message: 'There was a problem sending your password reset email',
      cta: 'Go back and try again',
      ctaId: `${testId}_error-dialog_cta-button`,
      onClose: clearGlobalError,
    });
  };

  const resendLink = () => {
    const requestBody = {
      resetPasswordUrl: `${window.origin}/auth/forgotten-password/reset`,
      returnUrl: `${window.location.href}`,
      referrer: referrer.name,
      tenant: referrer.identityProviderName,
    };

    mutateAsync({ email: state.email, requestBody })
      .then(() => setIsLinkResent(true))
      .catch(handleResetPasswordError);
  };

  return (
    <PageContainer className="pt-6" testId={`${testId}_page`}>
      <div className="flex flex-col mx-auto mb-16 w-full md:max-w-tablet-container lg:mb-24 lg:max-w-desktop-container text-main-content-1">
        <h1
          className="self-center mt-2 mb-8 text-xl leading-10 text-center sm:mt-6 sm:mb-12"
          data-testid={`${testId}_page-title`}
        >
          Secure link
        </h1>
        <Card
          paddingLevel="large"
          classNames="bg-content-background-prominent"
          testId={`${testId}_card`}
        >
          <Card paddingLevel="small" classNames="bg-content-background-prominent">
            <p className="text-left" data-testid={`${testId}_card_text`}>
              So you can reset your password securely, check your emails and click on the
              link we’ve sent to
            </p>
            <p
              className="mt-6 text-left break-words"
              data-testid={`${testId}_card_email`}
            >
              {state.email}
            </p>
          </Card>
          <Button
            classNames="mt-8 md:mt-12"
            id={`${testId}_login-button`}
            testId={`${testId}_login-button`}
            text="I’ve clicked the link, log in"
            onClick={() => {
              signIn();
            }}
            fullWidth
          />
          <Button
            classNames="mt-6 sm:mt-8"
            id={`${testId}_resend-link-button`}
            testId={`${testId}_resend-link-button`}
            text="Resend link"
            onClick={resendLink}
            variant="secondary"
            fullWidth
            loading={isLoading}
            disabled={isLinkResent}
          />
        </Card>
      </div>
    </PageContainer>
  );
};
