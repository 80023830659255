import { Tooltip } from '@dayinsure/components';
import { FormRadioSelect } from '../../../../../components';
import { YesNoAnswer, YesNoDataEntry } from '../../../../../types';

const getInsuranceDeclinedOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_insurance-declined-option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_insurance-declined-option-no`,
  },
];

type InsuranceDeclinedProps = {
  testId: string;
};

export const InsuranceDeclined = ({ testId }: InsuranceDeclinedProps) => {
  return (
    <div className="my-6 md:my-16">
      <h2
        data-testid={`${testId}_insurance-declined-label`}
        className="relative w-full font-raleway font-normal form-subtitle-compact"
      >
        Has any driver ever had insurance declined or{' '}
        <span className="inline-block">
          cancelled?
          <div className="inline-block ml-2 sm:!relative">
            <Tooltip
              ariaLabel="hint"
              className="inline-flex top-[72px] flex-col sm:top-8 text-popup-content-1"
              position="relative"
              testId={`${testId}_insurance-declined`}
              variant="annual"
            >
              <h4 className="mb-2 font-bold">Cancelled, declined or voided policies</h4>
              <p>
                We may decline, cancel or void your insurance for a variety of reasons.
                Things like not being honest when you buy insurance or getting a criminal
                conviction during a policy term.
              </p>
              <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
                Got it
              </Tooltip.Close>
            </Tooltip>
          </div>
        </span>
      </h2>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          id={`${testId}_insurance-declined-radio`}
          testId={`${testId}_insurance-declined-radio`}
          name="drivers.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled"
          options={getInsuranceDeclinedOptions(testId)}
        />
      </div>
    </div>
  );
};
