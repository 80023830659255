import { Card } from '@dayinsure/components';
import { CostDto } from '../../../../api/v1';
// import { useNavigateWithReferrer } from '@dayinsure/shared';
// import { PoliciesPaths } from '../../../../routes';

type PolicyInfoPaymentsProps = {
  testId?: string;
  payments: {
    type: string;
    amount: string;
    lastPayment: string;
  };
  totalPrice: CostDto;
};

export const PolicyInfoPayments = ({
  testId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  payments,
  totalPrice,
}: PolicyInfoPaymentsProps) => {
  // const navigate = useNavigateWithReferrer();
  // const handleClick = () => {
  //   navigate(PoliciesPaths.Payments);
  // };

  return (
    <Card
      paddingLevel="small"
      classNames="bg-content-background-prominent mb-6"
      testId={`${testId}_payments`}
    >
      <h2 className="mb-4 font-raleway text-lg">Payments</h2>

      {/* <div className="flex items-center mb-2 text-sm text-main-content-2"> */}
      {/*  <Icon name="previous-claims" /> */}
      {/*  <span className="ml-2">Payment type</span> */}
      {/* </div> */}

      {/* {payments?.type && <div className="mb-4">{payments.type}</div>} */}
      <Card.CardListItem
        testId={`${testId}_payments_payment-amount_list-item`}
        label="Payment amount"
        icon="previous-claims"
      >
        {`£${totalPrice.amount?.toFixed(2)} /year`}
      </Card.CardListItem>

      {/* <div className="flex items-center mb-2 text-sm text-main-content-2"> */}
      {/*  <Icon name="previous-claims" /> */}
      {/*  <span className="ml-2">Last payment</span> */}
      {/* </div> */}

      {/* {payments?.lastPayment && <div className="mb-4">{payments.lastPayment}</div>} */}

      {/* <Button */}
      {/*  id={`${testId}_payment_moreinfo_button`} */}
      {/*  testId={`${testId}_payment_moreinfo_button`} */}
      {/*  text="More info" */}
      {/*  variant="bordered" */}
      {/*  onClick={() => handleClick()} */}
      {/*  classNames="mt-6 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full sm:w-fit " */}
      {/* /> */}
    </Card>
  );
};
