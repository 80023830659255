export type SignupFormData = {
  signUp: {
    emailAddress: string;
    password: string;
    retypePassword: string;
    reaptcha: string;
  };
};

export const signUpFormInitialData: SignupFormData = {
  signUp: {
    emailAddress: '',
    password: '',
    retypePassword: '',
    reaptcha: '',
  },
};
