import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  Provider,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Referrer, Referrers } from '../../types';
import { getReferrer, getReferrerNameFromOidcState } from '../../helpers';

const createReferrerContext = <T extends Referrer<string>>(defaultReferrer: T) =>
  createContext<T>(defaultReferrer);

type ReferrerWrapperProps<T extends string> = {
  referrers: Referrers<T>;
  ContextProvider: Provider<Referrer<T>>;
  defaultReferrerName: string;
  children: ReactNode;
};

const ReferrerWrapper = <T extends string>({
  referrers,
  defaultReferrerName,
  ContextProvider,
  children,
}: ReferrerWrapperProps<T>) => {
  const location = useLocation();
  const [searchParams] = useSearchParams({ referrer: '' });

  const determineReferrer = useCallback(() => {
    let referrerName = defaultReferrerName;
    const referrerParam = searchParams.get('referrer');
    const oidcStateParam = searchParams.get('state');

    if (referrerParam) {
      referrerName = referrerParam;
    } else if (oidcStateParam) {
      referrerName = getReferrerNameFromOidcState(oidcStateParam, defaultReferrerName);
    }

    return getReferrer(referrers, referrerName) || referrers[defaultReferrerName];
  }, [defaultReferrerName, referrers, searchParams]);

  const [referrer, setReferrer] = useState<Referrer<T>>(determineReferrer());

  useEffect(() => {
    setReferrer(determineReferrer());
  }, [determineReferrer, location]);

  return <ContextProvider value={referrer}>{children}</ContextProvider>;
};

export { createReferrerContext, ReferrerWrapper };
