/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AddressService } from './services/AddressService';
import { PersonService } from './services/PersonService';
import { PolicyService } from './services/PolicyService';
import { PolicySessionService } from './services/PolicySessionService';
import { ProductService } from './services/ProductService';
import { QuoteService } from './services/QuoteService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class Dayinsure {

  public readonly address: AddressService;
  public readonly person: PersonService;
  public readonly policy: PolicyService;
  public readonly policySession: PolicySessionService;
  public readonly product: ProductService;
  public readonly quote: QuoteService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.address = new AddressService(this.request);
    this.person = new PersonService(this.request);
    this.policy = new PolicyService(this.request);
    this.policySession = new PolicySessionService(this.request);
    this.product = new ProductService(this.request);
    this.quote = new QuoteService(this.request);
  }
}
