import { NavigateWithReferrer } from '@dayinsure/shared';
import { useFormikContext } from 'formik';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QuoteJourneyFormData } from '../../../types';
import { quoteJourneyStorage } from '../../../helpers';
import { QuoteJourneyRoutes } from '../../../routes';

export const QuoteJourneyGuard = ({
  children,
  redirect,
}: {
  children: ReactNode;
  redirect: string;
}) => {
  const { pathname } = useLocation();
  const { values, touched } = useFormikContext<QuoteJourneyFormData>();

  useEffect(() => {
    return () => {
      quoteJourneyStorage.saveQuoteJourneyProgress(values, touched);
    };
  }, [values, touched]);

  if (
    !values.journeyStartPosition &&
    !pathname.includes(QuoteJourneyRoutes.PurchaseComplete)
  ) {
    return <NavigateWithReferrer to={redirect} replace />;
  }

  return <>{children}</>;
};
