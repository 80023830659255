import { Tooltip } from '@dayinsure/components';
import { ReactNode } from 'react';

export type LabelTooltipProps = {
  testId: string;
  children: ReactNode;
  buttonText?: string;
  label: string;
};

export const LabelTooltip = ({
  testId,
  children,
  buttonText = 'Got it',
  label,
}: LabelTooltipProps) => (
  <>
    {label}
    <div className="w-4 h-4 sm:!relative">
      <Tooltip
        ariaLabel="hint"
        className="inline-flex top-8 flex-col text-popup-content-1"
        position="relative"
        testId={testId}
        variant="annual"
      >
        {children}
        <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
          {buttonText}
        </Tooltip.Close>
      </Tooltip>
    </div>
  </>
);
