import { useRef, useEffect } from 'react';

/** Listens for the before unload event and fires a callback */
export const useSiteUnloadEffect = (callback: () => void) => {
  const cb = useRef(callback); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = callback;
  }, [callback]);

  useEffect(() => {
    const onUnload = () => cb.current?.();

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);
};
