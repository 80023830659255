import { Button, Card } from '@dayinsure/components';
import { Form } from 'formik';
import { ReactNode } from 'react';

type ReviewPageWrapperProps = {
  children?: ReactNode;
  testId: string;
};

export const ReviewPageWrapper = ({ children, testId }: ReviewPageWrapperProps) => (
  <>
    <h1 className="mb-8 text-xl text-center md:text-left" data-testid={`${testId}_title`}>
      Review
    </h1>
    <Card
      testId={`${testId}_cards_info-card`}
      paddingLevel="small"
      classNames="mb-8 lg:mb-12"
    >
      <span>Please check all the details below are correct before purchasing.</span>
    </Card>
    <div className="mb-16 sm:mb-24">
      <Form>
        {children}
        <Button
          classNames="mt-12 md:mt-16"
          id={`${testId}_continue_button`}
          testId={`${testId}_continue_button`}
          submit
          fullWidth
          icon={{ name: 'arrow-right', position: 'right' }}
          text="Continue"
        />
      </Form>
    </div>
  </>
);
