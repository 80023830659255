import { Card, ContentContainer, PageContainer } from '@dayinsure/components';
import { useFormikContext, Form } from 'formik';
import { BackButton, MtaChangeButtons } from '../../../../../components';
import { NEW_DRIVER_ID } from '../../../../../helpers';
import { MtaJourneyFormData } from '../../../../../types/mtaJourneyForm';
import { DriverBasicInfo } from '../../../../../components/Quote';
import { LiveInUK } from '../../../../Quote/YourDetails/LiveInUK';
import { OccupationFields } from '../../../../../components/OccupationFields';
import { PolicyMtaPaths } from '../../../../../routes';

const testId = 'additional-drivers-details';

export const DriverDetails = () => {
  const { values } = useFormikContext<MtaJourneyFormData>();
  const driverToAddIndex = values.drivers.driversToAdd.findIndex(
    driver => driver.id === NEW_DRIVER_ID
  );

  const formNameSpaceKey = `drivers.driversToAdd[${driverToAddIndex}]`;

  return (
    <PageContainer className="font-raleway">
      <BackButton customBackLink={`../../../${PolicyMtaPaths.Drivers}`} />
      <h1
        className="my-6 text-xl text-center md:my-8 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Additional driver’s details
      </h1>

      <ContentContainer>
        <Card
          testId={`${testId}_info_card`}
          paddingLevel="small"
          classNames="mb-6 lg:mb-8"
        >
          <span>We need a few details about the additional driver.</span>
        </Card>

        <div className="mb-16 sm:mb-24">
          <Form>
            <DriverBasicInfo
              formNameSpaceKey={formNameSpaceKey}
              testId={testId}
              relationShipField
            />
            <LiveInUK
              formNameSpaceKey={formNameSpaceKey}
              additionalDriver
              testId={`${testId}_additional-driver_live-in-UK`}
            />
            <OccupationFields
              formNameSpaceKey={formNameSpaceKey}
              additionalDriver
              testId={`${testId}_additional-driver_occupations`}
            />
            <MtaChangeButtons />
          </Form>
        </div>
      </ContentContainer>
    </PageContainer>
  );
};
