import { DateEntry } from '../../../../types';
import { DateAndTimeCard } from '../../../../components/Common/DateAndTimeCard';

type PolicyInfoCarProps = {
  testId?: string;
  startDate: string;
  endDate: string;
  customStartTitle?: string;
};

export const PolicyInfoCover = ({ testId, startDate, endDate, customStartTitle }: PolicyInfoCarProps) => {
  const getCoverDate = (date: string): DateEntry | null => {
    if (!date) {
      return null;
    }
    const parsedDate = new Date(date);
    if (Number.isNaN(parsedDate.getTime())) {
      return null;
    }

    const startDateParts = date.split('-');

    return {
      day: startDateParts[2],
      month: startDateParts[1],
      year: startDateParts[0],
      parsedDate: date,
    };
  };
  const startTitle = customStartTitle || 'Cover start';
  return (
    <section>
      <div className="flex flex-col md:flex-row md:gap-6">
        {startDate && (
          <DateAndTimeCard
            title={startTitle}
            date={getCoverDate(startDate)}
            testId={`${testId}_cover-start`}
          />
        )}

        {endDate && (
          <DateAndTimeCard
            title="Cover end"
            date={getCoverDate(endDate)}
            testId={`${testId}_cover-end`}
          />
        )}
      </div>
    </section>
  );
};
