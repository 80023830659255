import { Button } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';

type AccountNavButtonsProps = {
  testId: string;
};
export const AccountNavButtons = ({ testId }: AccountNavButtonsProps) => {
  const navigate = useNavigateWithReferrer();
  const cancelChanges = () => {
    navigate('/dashboard');
  };
  return (
    <nav>
      <Button
        classNames="mb-8 mt-4"
        id={`${testId}_save-changes-button`}
        testId={`${testId}_save-changes-button`}
        submit
        fullWidth
        text="Save changes"
      />
      <Button
        classNames="mb-24 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full"
        id={`${testId}_cancel-button`}
        testId={`${testId}_cancel-button`}
        fullWidth
        text="Cancel changes"
        onClick={cancelChanges}
        variant="bordered"
      />
    </nav>
  );
};
