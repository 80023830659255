import { FormTooltip } from '../../../../../components';

type NonMotoringConvictionsTootltipProps = {
  testId: string;
};

export const NonMotoringConvictionsTootltip = ({
  testId,
}: NonMotoringConvictionsTootltipProps) => (
  <FormTooltip testId={`${testId}_non-motoring-convictions`}>
    <h4 className="mb-2 font-bold">Non-motoring criminal convictions</h4>
    <p>
      Convictions considered to be spent under the Rehabilitation of Offenders Act 1974 DO
      NOT need to be disclosed as they can effectively be ignored after a specified period
      of time. However, if you&apos;ve received a prison sentence of more than four years,
      your conviction will never become spent. If you&apos;re based in England or Wales,
      you can use{' '}
      <a
        href="https://unlock.org.uk/disclosure-calculator/"
        target="_blank"
        rel="noreferrer"
        className="font-semibold underline text-link"
      >
        Unlock&apos;s criminal record disclosure calculator
      </a>{' '}
      (www.disclosurecalculator.org.uk) to work out whether your convictions are spent.
    </p>
  </FormTooltip>
);
