type NegatePageContainerProps = {
  id?: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
};

export const NegatePageContainer = ({
  id,
  children,
  className,
}: NegatePageContainerProps) => {
  return (
    <div
      id={id}
      className={`overflow-x-auto overflow-y-hidden w-screen -mx-6 px-6 md:-mx-[calc((100vw-theme(maxWidth.tablet-page))/2)] md:px-[calc((100vw-theme(maxWidth.tablet-page))/2)] lg:mx-0 lg:px-0 lg:w-auto ${className}`}
    >
      {children}
    </div>
  );
};
