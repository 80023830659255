import clsx from 'clsx';
import { CoverTypeOption, CoverOption } from './CoverTypeOption';

export type CoverTypeOptionsProps = {
  /** The options available in the radiogroup */
  options: CoverOption[];
  /** The current value. Must match one of the radio options. Can be undefined */
  value: CoverOption | undefined;
  /** The event which will trigger when an radio option is selected */
  onChange: (option: CoverOption) => void;
  /** Error state */
  error?: boolean;
  /** Disabled state */
  disabled?: boolean;
  /**  Whether to use the primary or secondary colour scheme */
  variant?: 'primary' | 'secondary';
  /** The number of columns to split into. Will be based on breakpoint if undefined */
  cols?: {
    mobile: 1 | 2;
    tablet: 1 | 2;
    desktop: 1 | 2;
  };
  /** id used for testing */
  testId?: string;
};

export const CoverTypeOptions = ({
  options,
  value,
  onChange,
  error,
  disabled,
  testId,
  variant = 'primary',
  cols,
}: CoverTypeOptionsProps) => {
  return (
    <div className="w-full" data-testid={testId}>
      <div
        className={clsx(
          'grid relative gap-6',
          { 'grid-cols-1 md:grid-cols-2': !cols },
          { 'grid-cols-1': cols?.mobile === 1 },
          { 'grid-cols-2': cols?.mobile === 2 },
          { 'sm:grid-cols-1': cols?.tablet === 1 },
          { 'sm:grid-cols-2': cols?.tablet === 2 },
          { 'lg:grid-cols-1': cols?.desktop === 1 },
          { 'lg:grid-cols-2': cols?.desktop === 2 }
        )}
      >
        {options.map((option, index) => (
          <CoverTypeOption
            key={option.name}
            disabled={disabled}
            error={error}
            index={index}
            option={option}
            variant={variant}
            checked={value?.id === option.id}
            onClick={onChange}
          />
        ))}
      </div>
    </div>
  );
};
