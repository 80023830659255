import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv2,
  DriverOptionsDto,
  OpenAPI,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useDriverOptionsQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getDriverOptions = () => {
    const api = new DayinsureAPIv2(OpenAPI);

    return api.product.getApiV2ProductDriverOptions('motor', 'latest');
  };

  return useQuery<DriverOptionsDto, ApiError>(query.driverOptions, getDriverOptions, {
    keepPreviousData: true,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
