import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { NavigateWithReferrer, useSignIn } from '@dayinsure/shared';
import { LoadingSpinner } from '@dayinsure/components';
import { usePerson, useReferrer } from '../../../hooks';

type ProtectedRouteProps = {
  redirect?: string;
  children: ReactNode;
  isAvaliableForGuset?: boolean;
};

export const ProtectedRoute = ({
  redirect = '/',
  children,
  isAvaliableForGuset,
}: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading, signinSilent, user, removeUser } = useAuth();
  const { person, isGuest, isSignedOff } = usePerson();
  const referrer = useReferrer();
  const { signIn } = useSignIn(referrer.name, isGuest);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [refreshFailed, setRefreshFailed] = useState(false);

  const shouldRefresh = !refreshFailed && !!user?.refresh_token;
  const cannotRefresh = refreshFailed || !user?.refresh_token;

  useEffect(() => {
    if (!isLoading && !isAuthenticated && user?.refresh_token && !refreshFailed) {
      setIsRefreshing(true);
      signinSilent()
        .catch(() => {
          setRefreshFailed(true);
          removeUser();
        })
        .finally(() => {
          setIsRefreshing(false);
        });
    }
  }, [isLoading, isAuthenticated, user, signinSilent, refreshFailed, removeUser]);

  useEffect(() => {
    if (!isAuthenticated && cannotRefresh && isSignedOff) {
      signIn();
    }
  }, [isAuthenticated, cannotRefresh, signIn, isSignedOff]);

  const showLoader =
    (!isAuthenticated && (shouldRefresh || cannotRefresh)) ||
    isRefreshing ||
    isLoading ||
    !person;

  if (showLoader) {
    return (
      <LoadingSpinner
        testId="protected-route_loading-spinner"
        centered
        className="mt-8"
      />
    );
  }

  if (!isAvaliableForGuset && isGuest) {
    return <NavigateWithReferrer to={{ pathname: redirect }} replace />;
  }
  return <>{children}</>;
};
