import { useMemo, useState } from 'react';
import throttle from 'lodash.throttle';
import { FormCombobox, FormFieldBox } from '../../FormsFields';
import { Occupation, OptionCodeDto } from '../../../api/v2';
import { useEmploymentIndustriesQuery, useOccupationQuery } from '../../../hooks';
import { getFormFieldAccessPath } from '../../../helpers';

type IndustryJobFieldsProps = {
  formNameSpaceKey?: string;
  testId: string;
  index: number;
};

const cutOptionsArray = <T,>(arr: T[], limit: number) => {
  const arrLength = arr.length;
  return arrLength > limit ? arr.splice(0, limit) : arr;
};
const mapOptions = <T extends { [key: string]: any }>(
  data: T[],
  key: string,
  testId: string
): { id: string; name: string }[] => {
  if (data) {
    const options = cutOptionsArray<T>(data, 40);
    return options?.map((option: T) => ({
      id: String(option[key]) || '',
      name: option.description || '',
      testId,
    }));
  }
  return [];
};
export const IndustryJobFields = ({
  formNameSpaceKey,
  testId,
  index,
}: IndustryJobFieldsProps) => {
  const [occupationQuery, setOccupationQuery] = useState('');
  const [employeeIndustriesQuery, setEmployeeIndustriesQuery] = useState('');
  const { data: occupationsData } = useOccupationQuery(occupationQuery);
  const { data: employeeIndustriesData } = useEmploymentIndustriesQuery(
    employeeIndustriesQuery
  );
  const userOccupationOptions = useMemo(
    () =>
      occupationsData &&
      mapOptions<Occupation>(occupationsData, 'code', `${testId}_type-options`),
    [occupationsData, testId]
  );

  const occupationTypePath = getFormFieldAccessPath([
    formNameSpaceKey,
    `occupations[${index}]`,
    'type',
  ]);
  const industryPath = getFormFieldAccessPath([
    formNameSpaceKey,
    `occupations[${index}]`,
    'industry',
  ]);

  const userIndustriesOptions = useMemo(
    () =>
      employeeIndustriesData &&
      mapOptions<OptionCodeDto>(
        employeeIndustriesData,
        'code',
        `${testId}_industries-options`
      ),
    [employeeIndustriesData, testId]
  );

  const throttledSetOccupationQuery = useMemo(
    () => throttle(setOccupationQuery, 1000),
    []
  );

  const throttledSetIndustryQuery = useMemo(
    () => throttle(setEmployeeIndustriesQuery, 1000),
    []
  );
  return (
    <>
      <FormFieldBox>
        <FormCombobox
          label={{
            icon: 'clipboard-with-pen',
            text: 'Job title',
          }}
          id={`${testId}_type-combobox`}
          name={occupationTypePath}
          placeholder="e.g. Teacher"
          noOptionsMessage="No options"
          filteredOptions={userOccupationOptions || []}
          onQueryChange={throttledSetOccupationQuery}
        />
      </FormFieldBox>
      <FormFieldBox>
        <FormCombobox
          label={{
            icon: 'industry',
            text: 'Job industry',
          }}
          id={`${testId}_industry-combobox`}
          name={industryPath}
          placeholder="e.g. Education"
          noOptionsMessage="No options"
          filteredOptions={userIndustriesOptions || []}
          onQueryChange={throttledSetIndustryQuery}
        />
      </FormFieldBox>
    </>
  );
};
