import { ObjectShape, TypeOfShape } from 'yup/es/object';
import { TestContext } from 'yup';
import { AnyObject } from 'yup/es/types';
import {
  anotherJobRadiosStatuses,
  employedAdditionalFieldsStatuses,
  employmentStatusesRequireIndustries,
} from '../../../components/OccupationFields';

export const isValidOcupationType = (
  value: TypeOfShape<ObjectShape> | null,
  ctx: TestContext<AnyObject>
) => {
  if (ctx.parent.employmentStatus?.name) {
    return (
      !!value ||
      !employedAdditionalFieldsStatuses.includes(ctx.parent.employmentStatus.name)
    );
  }
  return !!value;
};

export const isValidOccupationsJobAndIndustry = (
  value: TypeOfShape<ObjectShape> | null,
  ctx: TestContext<AnyObject>
) => {
  if (ctx.parent.employmentStatus?.name) {
    return (
      !!value ||
      !employmentStatusesRequireIndustries.includes(ctx.parent.employmentStatus.name)
    );
  }
  return !!value;
};

export const isValidOccupationsAnotherJobRadio = (
  value: TypeOfShape<ObjectShape> | null,
  ctx: TestContext<AnyObject>
) => {
  if (ctx.parent.employmentStatus?.name && !ctx.parent.anotherJob) {
    return !anotherJobRadiosStatuses.includes(ctx.parent.employmentStatus.name);
  }
  return true;
};
