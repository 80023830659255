import { v4 as uuidv4 } from 'uuid';
import { AccidentOrClaimType } from '../../../types/quoteJourneyForm';

export const getEmptyAccidentOrClaim = (): AccidentOrClaimType => ({
  id: uuidv4(),
  selfDeclaredClaimType: null,
  claimDate: {
    day: '',
    month: '',
    year: '',
    parsedDate: '',
  },
  fault: null,
  claimSettled: null,
  isSaved: false,
  claimAmount: null,
  knowClaimAmount: null,
});
