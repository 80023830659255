import { EndorsementsDto } from '../../../api/v2';
import { EndorsementDto } from '../../../api/v1';

export const getQuoteEndorsements = (
  endorsements: EndorsementsDto[],
  quoteEndorsements: EndorsementDto[]
) =>
  quoteEndorsements.reduce((res: EndorsementsDto[], quoteEndorsement: EndorsementDto) => {
    const fullEndorsement = endorsements.find(
      endorsement => endorsement.code === quoteEndorsement.code
    );
    if (fullEndorsement) {
      return [...res, fullEndorsement];
    }
    return res;
  }, []);
