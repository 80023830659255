const compareByKey = <T extends Record<string, string>>(a: T, b: T, key: keyof T) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};
export const sortByKey = <T extends Record<string, string>>(key: keyof T, arr: T[]) => {
  return arr.sort((a: T, b: T) => compareByKey<T>(a, b, key));
};
