import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv1,
  MotorPolicyDocumentDto,
  OpenAPI,
} from '../../../api/v1';
import { query } from '../queryKeys';

export const usePolicyDocumentsQuery = (id: string | undefined) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getDocumentsLinks = () => {
    if (!id) {
      throw new Error('No policy ID');
    }
    const api = new DayinsureAPIv1(OpenAPI);
    return api.motorPolicy.getApiV1MotorPolicyDocuments(id);
  };

  return useQuery<Array<MotorPolicyDocumentDto>, ApiError>(
    query.policyDocuments(id || ''),
    getDocumentsLinks,
    {
      keepPreviousData: true,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
