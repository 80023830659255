import { Card } from '@dayinsure/components';
import DirectDebitLogo from './DirectDebitLogo.svg';

type DirectDebitInfoCardProps = {
  className?: string;
  testId: string;
};

export const DirectDebitGuaranteeCard = ({
  className,
  testId,
}: DirectDebitInfoCardProps) => {
  return (
    <Card classNames={className} data-testid={`${testId}_direct-debit_guarantee-card`}>
      <h3 className="mb-6 font-raleway text-main-content-1">
        The Direct Debit Guarantee
      </h3>

      <ul className="pl-4 mb-6 font-lato text-sm leading-normal list-disc text-main-content-1">
        <li className="mb-4">
          The Guarantee is offered by all banks and building societies that accept
          instructions to pay Direct Debits.
        </li>
        <li className="mb-4">
          If there are any changes to the amount, date or frequency of your Direct Debit,
          we will notify you (normally 10 working days) in advance of your account being
          debited or as otherwise agreed. If you request us to collect a payment,
          confirmation of the amount and date will be given to you at the time of the
          request.
        </li>
        <li className="mb-4">
          If an error is made in the payment of your Direct Debit, by us or your bank or
          building society, you are entitled to a full and immediate refund of the amount
          paid from your bank or building society.
        </li>
        <li className="mb-4">
          If you receive a refund you are not entitled to, you must pay it back when we
          ask you to.
        </li>
        <li>
          You can cancel a Direct Debit at any time by simply contacting your bank or
          building society. Written confirmation may be required. Please also notify us.
        </li>
      </ul>
      <img src={DirectDebitLogo} width={100} height={32} alt="direct debit logo" />
    </Card>
  );
};
