import { useState, useMemo, useCallback, useRef } from 'react';
import { Button, ListItem, SelectRadioOption } from '@dayinsure/components';
import { useField, useFormikContext } from 'formik';
import throttle from 'lodash.throttle';
import { FormRadioSelect } from '../../../../components';
import { useFocusOnError, useModificationsQuery } from '../../../../hooks';
import { isYes } from '../../../../helpers';
import {
  BaseDataEntry,
  CarModifications,
  QuoteJourneyFormData,
  YesNoDataEntry,
  YesNoAnswer,
} from '../../../../types';
import { ModificationSearchField } from './ModificationSearchField';
import { OptionCodeDto } from '../../../../api/v2';
import { ModificationTooltip } from './ModificationTooltip';

const testId = 'car-details_modification';

const isCarModifiedOptions: YesNoDataEntry[] = [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_modified_option_yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_modified_option_no`,
  },
];

const mapModifications =
  (modifications: CarModifications) => (result: CarModifications, mod: OptionCodeDto) => {
    if (modifications.find(modification => modification.id === mod?.code)) {
      return result;
    }

    const modification = {
      id: mod?.code || '',
      name: mod?.description || '',
    };

    return [...result, modification];
  };

export const CarModification = () => {
  const isAddingModificationRef = useRef<HTMLInputElement>(null);
  useFocusOnError({
    fieldRef: isAddingModificationRef,
    name: 'vehicle.isAddingModification',
  });
  const { values, setFieldValue, setFieldTouched, setFieldError } =
    useFormikContext<QuoteJourneyFormData>();
  const {
    vehicle: { isCarModified, modifications, isAddingModification },
  } = values;

  const [
    _modificationField,
    { error: modificationsError, touched: modificationsTouched },
  ] = useField<QuoteJourneyFormData>('vehicle.modifications');
  const [
    _isAddingModificationField,
    { error: isAddingModificationError, touched: isAddingModificationsTouched },
  ] = useField<QuoteJourneyFormData>('vehicle.isAddingModification');
  const error = modificationsError || isAddingModificationError;
  const touched = modificationsTouched || isAddingModificationsTouched;

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState<BaseDataEntry | undefined>();

  const { data = [] } = useModificationsQuery(searchTerm);
  const throttledModificationQuery = useMemo(() => throttle(setSearchTerm, 1000), []);

  const searchedModifications = useMemo(
    () => data?.reduce<CarModifications>(mapModifications(modifications), []),
    [modifications, data]
  );

  const handleHideModificationsInput = useCallback(() => {
    setFieldValue('vehicle.isAddingModification', false);
    setFieldTouched('vehicle.isAddingModification', false);
    setSelectedValue(undefined);
  }, [setFieldTouched, setFieldValue]);

  const handleShowModificationsInput = useCallback(() => {
    setFieldValue('vehicle.isAddingModification', true);
    setFieldTouched('vehicle.isAddingModification', false);
  }, [setFieldValue, setFieldTouched]);

  const handleIsCarModifiedChange = useCallback(
    (option: SelectRadioOption) => {
      const { id } = option;
      setFieldError('vehicle.modifications', undefined);
      setFieldTouched('vehicle.modifications', false);
      if (id === YesNoAnswer.NO) {
        setFieldValue('vehicle.modifications', []);
        handleHideModificationsInput();
      } else {
        handleShowModificationsInput();
      }
    },
    [
      handleHideModificationsInput,
      handleShowModificationsInput,
      setFieldError,
      setFieldTouched,
      setFieldValue,
    ]
  );

  const handleModificationsInputBlur = () => {
    setFieldTouched('vehicle.isAddingModification', true);
  };

  const handleSaveModification = (modification: BaseDataEntry) => {
    setFieldValue('vehicle.modifications', [...modifications, modification]);
    setSelectedValue(undefined);
    handleHideModificationsInput();
  };

  const handleRemoveModification = (modification: BaseDataEntry) => () => {
    const newModifications = modifications.filter(mod => mod.id !== modification.id);
    if (newModifications.length === 0) {
      handleShowModificationsInput();
    }
    setFieldValue('vehicle.modifications', newModifications);
  };

  const isModified = isYes(isCarModified?.id);

  return (
    <div className="my-6 md:my-12" data-testid={testId}>
      <h3
        className="relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_title`}
      >
        Has the car been modified?
        <ModificationTooltip testId={testId} />
      </h3>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          onChangeOptional={handleIsCarModifiedChange}
          name="vehicle.isCarModified"
          options={isCarModifiedOptions}
          id={`${testId}_is-modified`}
          testId={`${testId}_is-modified`}
        />
      </div>
      <div className="mb-4 md:mb-8">
        {modifications?.map(modification => (
          <ListItem
            key={modification.id}
            icon="spanner"
            title={modification.name}
            subTitle="Modification"
            onRemove={handleRemoveModification(modification)}
          />
        ))}
      </div>
      {isModified && (
        <>
          {isAddingModification && (
            <div className="my-6 md:my-12" ref={isAddingModificationRef}>
              <ModificationSearchField
                label={{ icon: 'spanner', text: 'Modification' }}
                id={`${testId}_modification_input`}
                filteredOptions={searchedModifications}
                onQueryChange={throttledModificationQuery}
                onChange={handleSaveModification}
                onBlur={handleModificationsInputBlur}
                placeholder="e.g. Tinted windows"
                noOptionsMessage="No options"
                value={selectedValue}
                error={error}
                touched={touched}
                name="carModification"
              />
            </div>
          )}
          {!isAddingModification && (
            <Button
              variant="bordered"
              id={`${testId}_add-another-modification_button`}
              testId={`${testId}_add-another-modification_button`}
              onClick={handleShowModificationsInput}
              disabled={isAddingModification}
              text="Add an additional modification"
            />
          )}
          {isAddingModification && modifications.length > 0 && (
            <Button
              variant="bordered"
              id={`${testId}_cancel-add-modification`}
              testId={`${testId}_cancel-add-modification`}
              onClick={handleHideModificationsInput}
              text="Cancel"
            />
          )}
        </>
      )}
    </div>
  );
};
