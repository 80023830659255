import { Button, Card } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { QuoteJourneyLayout, PaymentMobileFooter } from '../../../components';
import { PaymentType } from './PaymentType';
import { AddOns } from './Addons';
import { PaymentCard } from '../../../components/PaymentCard';
import { usePaymentInfo, useQuoteQuery } from '../../../hooks';
import { Benefits } from './Benefits';
import { StandardIncluded } from './StandardIncluded/StandardIncluded';
import { QuoteJourneyFormData } from '../../../types';

const testId = 'your-quote';
export const YourQuote = () => {
  const { quoteId, handleAddOnsUpdate, handleChangePaymentType, isLoading } =
    usePaymentInfo();
  const { data, isRefetching } = useQuoteQuery(quoteId);
  const {
    values: { usualPaymentFrequency },
  } = useFormikContext<QuoteJourneyFormData>();

  if (!data?.paymentPlans || !data?.addOns || !quoteId) {
    return null;
  }

  const isDisabled = isRefetching || isLoading;

  return (
    <QuoteJourneyLayout>
      <h1
        className="mb-8 text-xl text-center md:text-left"
        data-testid={`${testId}_title`}
      >
        Your quote
      </h1>
      <Card
        testId={`${testId}_cards_info-card`}
        paddingLevel="small"
        classNames="mb-6 lg:mb-8"
      >
        <span>Refine your quote by selecting the cover that best meets your needs.</span>
      </Card>

      <div className="mb-16 sm:mb-24">
        <Form>
          <PaymentType
            disabled={isDisabled}
            paymentPlans={data.paymentPlans}
            insurencePrice={data.insurancePrice}
            totalPriceIncAddOns={data.totalPriceIncAddOns}
            testId={testId}
            onChangeOptional={handleChangePaymentType}
          />
          <Benefits testId={testId} />
          <StandardIncluded testId={testId} />
          <AddOns
            addOns={data.addOns}
            disabled={isDisabled}
            testId={testId}
            onAddOnsUpdate={handleAddOnsUpdate}
          />
          <PaymentCard
            addOns={data.addOns}
            insurancePrice={data.insurancePrice}
            paymentPlans={data.paymentPlans}
            cover={data.cover}
            totalPriceIncAddOns={data.totalPriceIncAddOns}
            excesses={data.excesses}
            disabled={isDisabled}
            testId={testId}
            usualPaymentFrequency={usualPaymentFrequency}
          />
          <Button
            id={`${testId}_continue_button`}
            testId={`${testId}_continue_button`}
            submit
            fullWidth
            icon={{ name: 'arrow-right', position: 'right' }}
            text="Continue"
            disabled={isDisabled}
          />
        </Form>
      </div>
      <PaymentMobileFooter />
    </QuoteJourneyLayout>
  );
};
