import { Card, Icon } from '@dayinsure/components';
import { useParams } from 'react-router-dom';
import { useDocumentsQuery } from '../../hooks';
import { DocumentDto } from '../../api/v2';

type DocumentsProps = {
  testId: string;
};
export const Documents = ({ testId }: DocumentsProps) => {
  const { quoteId } = useParams<{ quoteId: string }>();
  const { data: documents } = useDocumentsQuery(quoteId || '');

  return (
    <Card paddingLevel="small" classNames="mt-6 lg:mt-8 bg-content-background-prominent">
      <h2 className="text-lg">Documents</h2>
      {documents?.map(
        ({ description, url }: DocumentDto) =>
          url &&
          description && (
            <a
              data-testid={`${testId}_link`}
              id={`${testId}_link`}
              key={description}
              href={url}
              target="_blank"
              rel="noreferrer"
              className="flex gap-2 items-center mt-4 mb-2 text-base font-semibold leading-[19px] cursor-pointer text-link"
            >
              <Icon name="download" className="text-link" />
              {description}
            </a>
          )
      )}
    </Card>
  );
};
