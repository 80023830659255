import { useField, useFormikContext } from 'formik';
import { Button } from '@dayinsure/components';
import { MotorConviction, MotorConvictions } from '../../../../../types/quoteJourneyForm';
import { ConvictionListItem } from './ConvictionListItem';
import { getEmptyConviction } from '../../../../../helpers';

type DrivingConvictionsFieldSetProps = {
  name: string;
  testId: string;
};

export const DrivingConvictionsFieldSet = ({
  name,
  testId,
}: DrivingConvictionsFieldSetProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, _meta] = useField<MotorConvictions>({ name });
  const isAdding =
    field.value.length && field.value.some(({ isSaved }) => isSaved === false);

  const handleRemoveConviction = (removedConviction: MotorConviction) => {
    const newConvictions = field.value.filter(
      conviction => conviction.id !== removedConviction.id
    );

    if (newConvictions.length === 0) {
      setFieldValue(field.name, [getEmptyConviction()]);
    } else {
      setFieldValue(field.name, newConvictions);
    }
    setFieldTouched(field.name, false);
  };

  const handleAddConviction = () => {
    const newConvictions = [...field.value, getEmptyConviction()];
    setFieldValue(field.name, newConvictions);
  };

  return (
    <div>
      {field.value.map((conviction, index) => (
        <ConvictionListItem
          name={`${name}[${index}]`}
          key={conviction.id}
          testId={`${testId}_conviction-${index}`}
          onRemove={handleRemoveConviction}
          shouldDisplayCancelButton={index !== 0}
        />
      ))}
      {!isAdding && (
        <Button
          variant="bordered"
          id={`${testId}_add-button`}
          testId={`${testId}_add-button`}
          onClick={handleAddConviction}
          text="Add another conviction"
        />
      )}
    </div>
  );
};
