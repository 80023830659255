import { Button, Card, PageContainer } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';

const testId = 'secure-link-expired';

export const ForgotPasswordSecureLinkExpired = () => {
  const navigate = useNavigateWithReferrer();

  const handleButtonClick = () => navigate('../');

  return (
    <PageContainer>
      <div className="flex flex-col mx-auto mb-16 w-full md:max-w-tablet-container lg:mb-24 lg:max-w-desktop-container text-main-content-1">
        <h1
          className="my-8 text-xl text-center sm:my-12 text-main-content-1"
          data-testid={`${testId}_title`}
        >
          Secure link expired
        </h1>
        <Card paddingLevel="large" classNames="bg-content-background-prominent">
          <Card classNames="mb-8 md:mb-12">
            <p data-testid={`${testId}_message`}>
              The secure link we sent you has expired. Click to resend a new link to your
              email.
            </p>
          </Card>

          <Button
            id={`${testId}_resend-link-btn`}
            testId={`${testId}_resend-link-btn`}
            submit
            fullWidth
            text="Resend link"
            onClick={handleButtonClick}
          />
        </Card>
      </div>
    </PageContainer>
  );
};
