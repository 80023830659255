import { Card } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { DriverCardsProps } from '../DriverCards.types';

const getLicenceHeldDescription = (years?: number, months?: number): string => {
  if (years === 0 && (months || months === 0)) {
    return months !== 1 ? `${months} months` : `${months} month`;
  }
  return (years || years === 0) && years > 0 && years !== 1
    ? `${years} years`
    : `${years} year`;
};

export const DriverLicenceCard = ({ driver, testId }: DriverCardsProps) => {
  const title = driver?.isPolicyholder
    ? 'Policyholder’s licence and history'
    : 'Additional driver’s licence and history';

  return (
    <Card testId={`${testId}_drivers-licence-card`} classNames="w-full mb-6">
      <h3 className="font-raleway text-lg font-normal">{title}</h3>
      <Card.CardListItem
        label="Licence type"
        icon="card"
        testId={`${testId}_drivers-details-card_list-item_licence-type`}
      >
        {driver.drivingLicence?.type?.description || driver.drivingLicence?.type?.code}
      </Card.CardListItem>
      <Card.CardListItem
        label="Period licence held"
        icon="date"
        testId={`${testId}_drivers-details-card_list-item_licence-held`}
      >
        {getLicenceHeldDescription(
          driver.drivingLicence?.yearsHeld,
          driver.drivingLicence?.monthsHeld
        )}
      </Card.CardListItem>

      <Card.CardListItem
        label="Additional driving qualifications"
        icon="cert"
        testId={`${testId}_drivers-details-card_list-item_driving-qualifications`}
      >
        {driver.advancedDrivingQualifications &&
        driver.advancedDrivingQualifications?.length > 0 ? (
          <ul className="pl-4 list-disc">
            {driver.advancedDrivingQualifications.map(qualification => (
              <li key={qualification.type?.code}>
                {qualification.type?.description || qualification.type?.code}
              </li>
            ))}
          </ul>
        ) : (
          'No'
        )}
      </Card.CardListItem>

      <Card.CardListItem
        label="Notifiable medical conditions"
        icon="medical"
        testId={`${testId}_drivers-details-card_list-item_medical-conditions`}
      >
        {driver.medicalCondition?.code ? (
          <ul className="pl-4 list-disc">
            <li>
              {driver.medicalCondition?.description || driver.medicalCondition?.code}
            </li>
          </ul>
        ) : (
          'No'
        )}
      </Card.CardListItem>

      <Card.CardListItem
        label="Claims"
        icon="clipboard"
        testId={`${testId}_drivers-details-card_list-item_claims`}
      >
        {driver.previousMotorClaims && driver.previousMotorClaims.length > 0 ? (
          <ul className="pl-4 list-disc">
            {driver.previousMotorClaims.map(claim => (
              <li key={claim.type?.code}>
                {claim.type?.description || claim.type?.code} (on{' '}
                {formatInUKLocalTime('dd/MM/yyyy', claim.date)})
              </li>
            ))}
          </ul>
        ) : (
          'No'
        )}
      </Card.CardListItem>

      <Card.CardListItem
        label="Driving convictions"
        icon="clipboard"
        testId={`${testId}_drivers-details-card_list-item_convictions`}
      >
        {driver.motorConvictions && driver.motorConvictions?.length !== 0 ? (
          <ul className="pl-4 list-disc">
            {driver.motorConvictions.map(({ type, date }) => (
              <li key={type?.code}>
                {`Code ${type?.code}`} (on {`${formatInUKLocalTime('dd/MM/yyyy', date)}`})
              </li>
            ))}
          </ul>
        ) : (
          'No'
        )}
      </Card.CardListItem>
    </Card>
  );
};
