import { Card, Icon } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { DateEntry } from '../../../types';

type DateAndTimeCardProps = {
  title: string;
  date: DateEntry | null;
  testId?: string;
};

export const DateAndTimeCard = ({ title, date, testId = '' }: DateAndTimeCardProps) => {
  if (!date) {
    return null;
  }
  const formattedDate = formatInUKLocalTime('dd/MM/yyyy', date.parsedDate);
  const formattedTime = formatInUKLocalTime('HH:mm', date.parsedDate);
  return (
    <Card
      testId={`${testId}_datetime-item`}
      paddingLevel="small"
      classNames="bg-content-background-prominent mb-6 flex-1"
    >
      <h2 className="mb-4 font-raleway text-lg">{title}</h2>

      <div className="flex items-center text-sm text-main-content-2">
        <Icon name="date" />
        <span className="ml-2">Date</span>
      </div>
      <div className="block mt-1 mb-2">{formattedDate}</div>

      <div className="flex items-center text-sm  text-main-content-2">
        <Icon name="time" />
        <span className="ml-2">Time (local UK time)</span>
      </div>
      <div className="block mt-1 mb-2">{formattedTime}</div>
    </Card>
  );
};
