import { matchPath } from 'react-router-dom';
import { PolicyMtaPaths } from '../../routes';

export const isMtaJourneyPath = (value: string): value is PolicyMtaPaths =>
  Object.values(PolicyMtaPaths).includes(value as PolicyMtaPaths);

const getDynamicMtaJourneyRouteFromPath = (lastPath: string, previousPath: string) =>
  Object.values(PolicyMtaPaths).find(path =>
    matchPath(`/${path}`, `/${previousPath}/${lastPath}`)
  );

export const getCurrentMtaStepFromRoute = (route: string): PolicyMtaPaths | null => {
  const [lastPath, previousPath] = route.split('/').reverse();

  if (isMtaJourneyPath(lastPath)) {
    return lastPath;
  }

  const dynamicRoute = getDynamicMtaJourneyRouteFromPath(lastPath, previousPath);
  if (dynamicRoute) {
    return dynamicRoute;
  }

  return null;
};


