const FIVE_MINUTES = 5 * 60 * 1000;

export const isLessThanTimeToStart = (
  startTime?: string | number,
  timeToStart: number = FIVE_MINUTES
): boolean => {
  if (!startTime) {
    return false;
  }

  const startDate = new Date(startTime);
  const nowDate = new Date();
  const timeToStartFromNow = new Date(nowDate.getTime() + timeToStart);

  return startDate <= timeToStartFromNow;
};
