import React from 'react';
import { Link as ReactRouterLink, LinkProps, Path, To } from 'react-router-dom';
import { useSearchParamsToBuildPermittedQueryParams } from '../../../helpers/url';

const isPath = (to: To): to is Partial<Path> => {
  return typeof to !== 'string';
};

// This component wraps the react-router Link component, but preserves the referrer paramater.
// This is important so that we don't lose the theme between navigations
const LinkWithReferrer = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, to, ...props }: LinkProps, ref) => {
    const permittedQueryParams = useSearchParamsToBuildPermittedQueryParams();

    if (permittedQueryParams === '') {
      return (
        <ReactRouterLink to={to} {...props} ref={ref}>
          {children}
        </ReactRouterLink>
      );
    }

    if (isPath(to)) {
      return (
        <ReactRouterLink
          to={{
            pathname: to.pathname,
            search: to.search
              ? `${to.search}&${permittedQueryParams}`
              : `${permittedQueryParams}`,
            hash: to.hash,
          }}
          {...props}
          ref={ref}
        >
          {children}
        </ReactRouterLink>
      );
    }

    return (
      <ReactRouterLink
        to={
          to.includes('?')
            ? `${to}&${permittedQueryParams}`
            : `${to}?${permittedQueryParams}`
        }
        {...props}
        ref={ref}
      >
        {children}
      </ReactRouterLink>
    );
  }
);

export { LinkWithReferrer };
