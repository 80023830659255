import { ReactNode } from 'react';
import { Button, Dialog } from '../../../index';

type InformationDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  testId: string;
};
export const InformationDialog = ({
  open,
  title,
  children,
  testId,
  onClose,
}: InformationDialogProps) => {
  return (
    <Dialog title={title} open={open} onClose={onClose}>
      {children}
      <Button
        id={`${testId}_close-btn`}
        data-testid={`${testId}_close-btn`}
        fullWidth
        onClick={onClose}
        text="Close"
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};
