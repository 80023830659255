import { useFormikContext } from 'formik';
import { useCallback, useMemo, useEffect } from 'react';
import { SelectRadioOption } from '@dayinsure/components';
import get from 'lodash.get';
import { useMedicalConditionsOptionsQuery } from '../../../../hooks';
import {
  FormListBox,
  FormRadioSelect,
  FormTooltip,
  FormFieldBox,
} from '../../../../components';
import {
  baseOptionDtoToViewOptions,
  getFormFieldAccessPath,
  getSelectedOption,
  isYes,
} from '../../../../helpers';
import { YesNoDataEntry, QuoteJourneyFormData, YesNoAnswer } from '../../../../types';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';

const getMedicalConditionsRadioOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_medical-conditions-option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_medical-conditions-option-no`,
  },
];

type NotifableMedicalConditionsProps = {
  testId: string;
  formNameSpaceKey?: string;
  additionalDriver?: boolean;
};
export const NotifableMedicalConditions = <
  T extends QuoteJourneyFormData | MtaJourneyFormData
>({
  testId,
  formNameSpaceKey,
  additionalDriver,
}: NotifableMedicalConditionsProps) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<T>();
  const currentDriver = formNameSpaceKey ? get(values, formNameSpaceKey) : values;
  const {
    medicalCondition: { hasMedicalConditions },
  } = currentDriver || { medicalCondition: {} };
  const radioIsYes = useMemo(
    () => isYes(hasMedicalConditions?.id),
    [hasMedicalConditions?.id]
  );
  const { data: conditions } = useMedicalConditionsOptionsQuery(radioIsYes);
  const medicalConditionOptions = useMemo(() => {
    return (
      conditions?.map(
        baseOptionDtoToViewOptions(`${testId}_medical-conditions-options`)
      ) || []
    );
  }, [conditions, testId]);

  const provideMedicalConditionsPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'medicalCondition',
    'code',
  ]);

  const provideHasMedicalConditionsPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'medicalCondition',
    'hasMedicalConditions',
  ]);

  useEffect(() => {
    const medicalconditionCode = currentDriver?.medicalCondition?.code;
    if (medicalconditionCode && medicalconditionCode?.id === medicalconditionCode?.name) {
      setFieldValue(
        provideMedicalConditionsPath,
        getSelectedOption(medicalConditionOptions, medicalconditionCode?.id),
        false
      );
    }
  }, [
    currentDriver?.medicalCondition,
    medicalConditionOptions,
    provideMedicalConditionsPath,
    setFieldValue,
  ]);

  const handleRadioChange = useCallback(
    (option: SelectRadioOption) => {
      const { id } = option;
      if (id === YesNoAnswer.NO) {
        setFieldValue(provideMedicalConditionsPath, null);
      }
      setFieldTouched(provideMedicalConditionsPath, false);
    },
    [provideMedicalConditionsPath, setFieldTouched, setFieldValue]
  );
  return (
    <div className="my-6 md:my-12">
      <h3
        className="relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_medical-conditions-label`}
      >
        {additionalDriver
          ? 'Do they have any notifiable medical conditions?'
          : 'Do you have any notifiable medical conditions?'}
        <FormTooltip testId={`${testId}_medical-conditions`}>
          <h4 className="mb-2 font-bold">Medical conditions</h4>
          <p>
            These are conditions the DVLA says you must inform it about. They may affect
            your ability to drive and as such may also affect the conditions under which
            you&apos;re granted your driving licence.
          </p>
        </FormTooltip>
      </h3>
      <div className="mb-4 md:mb-8">
        <FormFieldBox>
          <FormRadioSelect
            onChangeOptional={handleRadioChange}
            id={`${testId}_provide-medical-conditions-radio`}
            testId={`${testId}_provide-medical-conditions-radio`}
            name={provideHasMedicalConditionsPath}
            options={getMedicalConditionsRadioOptions(testId)}
          />
        </FormFieldBox>
        {radioIsYes && (
          <FormFieldBox>
            <FormListBox
              id={`${testId}_medical-conditions`}
              testId={`${testId}_medical-conditions`}
              unselectedMessage="Please select"
              name={provideMedicalConditionsPath}
              options={medicalConditionOptions}
              icon="medical"
              label="Condition"
            />
          </FormFieldBox>
        )}
      </div>
    </div>
  );
};
