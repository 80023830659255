import { useContext } from 'react';
import { LazyPreloadContext } from '../contexts';

const useLazyPreload = () => {
  const preloadContext = useContext(LazyPreloadContext);

  if (!preloadContext) {
    throw new Error('useLazyPreload used outside of provider');
  }

  return preloadContext;
};

export { useLazyPreload };
