import { useMutation } from 'react-query';
import {
  Dayinsure as DayInsureAPIv2,
  UpdatePersonRequestV2,
  OpenAPI,
} from '../../../api/v2';

export const useV2PutPersonMutation = () => {
  const updatePerson = ({
    id,
    personData,
  }: {
    id: string;
    personData: UpdatePersonRequestV2;
  }) => {
    const api = new DayInsureAPIv2(OpenAPI);

    return api.person.putApiV2Person(id, personData);
  };

  return useMutation({
    mutationFn: updatePerson,
  });
};
