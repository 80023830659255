import { useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { PolicyMtaPaths } from '../../../routes';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import { useCreateMtaQuoteSubmit } from '../useCreateMtaQuoteSubmit';

export const useNewCarUsageSubmit = () => {
  const navigate = useNavigateWithReferrer();
  const createMtaQuoteSubmit = useCreateMtaQuoteSubmit();
  return (
    values: MtaJourneyFormData,
    formikHelpers: FormikHelpers<MtaJourneyFormData>
  ) => {
    if (values.policyChange.changeDrivers) {
      navigate(PolicyMtaPaths.Drivers);
    } else {
      createMtaQuoteSubmit(values, formikHelpers);
    }

    formikHelpers.setSubmitting(false);
    return formikHelpers.setTouched({}, false);
  };
};
