/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetIAPIResult } from '../models/AssetIAPIResult';
import type { CreateAssetRequest } from '../models/CreateAssetRequest';
import type { UpdateAssetRequest } from '../models/UpdateAssetRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AssetService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets an asset given it's string identifier
   * @param id A string identifier representing an asset
   * @returns AssetIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Asset(
    id: string,
  ): CancelablePromise<AssetIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/asset/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Update an existing asset given properties of that asset
   * @param id An ID representing an asset
   * @param requestBody An Object representing an asset you want to Update
   * @returns AssetIAPIResult Success
   * @throws ApiError
   */
  public putApiV1Asset(
    id: string,
    requestBody?: UpdateAssetRequest,
  ): CancelablePromise<AssetIAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/asset/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete an asset with the given ID
   * @param id An ID representing an asset
   * @returns AssetIAPIResult Success
   * @throws ApiError
   */
  public deleteApiV1Asset(
    id: string,
  ): CancelablePromise<AssetIAPIResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/asset/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Creates a new asset given a type and properties of that asset
   * @param requestBody An object representing an asset to be added.
   * @returns AssetIAPIResult Success
   * @throws ApiError
   */
  public postApiV1Asset(
    requestBody?: CreateAssetRequest,
  ): CancelablePromise<AssetIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/asset',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }

}