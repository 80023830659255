import { ReactNode } from 'react';
import { useResetPolicyConditionConfirmation } from '../../../../hooks';

type QuoteJourneySideEffectsProps = {
  children: ReactNode;
};

export const QuoteJourneySideEffects = ({ children }: QuoteJourneySideEffectsProps) => {
  useResetPolicyConditionConfirmation();
  return <>{children}</>;
};
