import { Button, Card } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { QuoteJourneyLayout, CoverStartDate } from '../../../components';
import { VoluntaryExcess } from './VoluntaryExcess';
import { CoverType } from './CoverType';
import { InsuranceDeclined } from './InsuranceDeclined';
import { HowDoYouWantToPayRadio } from './HowDoYouWantToPayRadio';
import { NoClaimsDiscount } from './NoClaimsDiscount';
import { TermsAndConditions } from './TermsAndConditions';
import { QuoteJourneyFormData } from '../../../types';
import { ProtectYourClaimsDiscount } from './ProtectYourClaimsDiscount';
import { shouldIDisplayVoluntaryExcess } from '../../../helpers/quote/cover';

const testId = 'cover';

export const Cover = () => {
  const { values } = useFormikContext<QuoteJourneyFormData>();
  const coverType = values?.cover?.type?.id || '';
  const displayExcess = shouldIDisplayVoluntaryExcess(coverType);
  useEffect(() => {
    sessionStorage.setItem(
      'userPrefferedPaymentType',
      values?.usualPaymentFrequency?.code?.id || ''
    );
  }, [values.usualPaymentFrequency]);

  return (
    <QuoteJourneyLayout>
      <h1
        className="mb-8 text-xl text-center md:text-left"
        data-testid={`${testId}_title`}
      >
        Cover
      </h1>
      <Card
        testId={`${testId}_cards_info-card`}
        paddingLevel="small"
        classNames="mb-6 lg:mb-8"
      >
        <span>We need a few details about the cover you need.</span>
      </Card>
      <div className="mb-16 sm:mb-24">
        <Form>
          <CoverStartDate testId={testId} />
          <CoverType testId={testId} />
          {displayExcess && coverType && <VoluntaryExcess testId={testId} />}
          <HowDoYouWantToPayRadio testId={testId} />
          <NoClaimsDiscount testId={testId} />
          <ProtectYourClaimsDiscount testId={testId} />
          <InsuranceDeclined testId={testId} />
          <TermsAndConditions testId={testId} />
          <Button
            id={`${testId}_continue_button`}
            testId={`${testId}_continue_button`}
            submit
            fullWidth
            icon={{ name: 'arrow-right', position: 'right' }}
            text="Continue"
          />
        </Form>
      </div>
    </QuoteJourneyLayout>
  );
};
