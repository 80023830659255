import React, { Provider, ReactNode } from 'react';
import { LazyFactory } from '../../helpers';

type LazyPreloadContextValue<T extends string> = Record<T, LazyFactory<unknown>>;

const createLazyPreloadContext = <T extends string>(ctx?: LazyPreloadContextValue<T>) =>
  React.createContext(ctx);

type LazyPreloadContextWrapperProps<T extends string> = {
  children: ReactNode;
  context: LazyPreloadContextValue<T>;
  ContextProvider: Provider<LazyPreloadContextValue<T> | undefined>;
};

const LazyPreloadContextWrapper = <T extends string>({
  children,
  context,
  ContextProvider,
}: LazyPreloadContextWrapperProps<T>) => {
  return <ContextProvider value={context}>{children}</ContextProvider>;
};

export { createLazyPreloadContext, LazyPreloadContextWrapper };
