import { Footer as FooterUI } from '@dayinsure/components';
import { DEFAULT_TOBA_URL } from '../../../constants/apps';

/* 
  TODO: Add documents support as in
  src/components/Footer/Footer.tsx
  after API is ready
*/

const footerWording =
  'Everydayinsure™ is a registered trademark © 2024 Everydayinsure.com Ltd. Authorised and regulated by the Financial Conduct Authority No: XXXXXX. Company Registration No: 15202873';

const Footer = (): JSX.Element => {
  return (
    <FooterUI footerLegalWording={footerWording}>
      <FooterUI.Link id="footer-terms-of-business-link" href={DEFAULT_TOBA_URL}>
        Terms of business
      </FooterUI.Link>
      <FooterUI.Link
        id="footer-privacy-policy-link"
        href="https://www.dayinsure.com/dayinsure-privacy-policy/"
      >
        Privacy Policy
      </FooterUI.Link>
      <FooterUI.Link
        id="footer-cookie-policy-link"
        href="https://www.dayinsure.com/cookie-policy/"
      >
        Cookie Policy
      </FooterUI.Link>
    </FooterUI>
  );
};

export { Footer };
