import { useEffect, useMemo } from 'react';
import { addDays, format, isAfter } from 'date-fns';
import { useFormikContext } from 'formik';
import { useMotorPoliciesSummaryQuery } from './queries';
import { MtaJourneyFormData } from '../types/mtaJourneyForm';

export const usePolicyFromList = (id: string | undefined) => {
  const { setFieldValue } = useFormikContext<MtaJourneyFormData>();
  const { data: motorQuote } = useMotorPoliciesSummaryQuery();
  const policySummary = useMemo(
    () => motorQuote?.find(mq => mq.id === id),
    [id, motorQuote]
  );
  const mtaQuoteStartEndDate = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const thirtyDaysFromToday = addDays(today, 29);
    if (policySummary?.startDate && policySummary?.endDate) {
      const policyStartDate = new Date(policySummary?.startDate);
      const isStartDateAfterToday = isAfter(policyStartDate, today);
      const start = isStartDateAfterToday ? policyStartDate : today;
      start.setHours(0, 0, 0, 0);

      const policyEndDate = new Date(policySummary?.endDate);
      const isEndDateAfterThirtyDaysFromToday = isAfter(
        policyEndDate,
        thirtyDaysFromToday
      );
      const end = isEndDateAfterThirtyDaysFromToday ? thirtyDaysFromToday : policyEndDate;
      return {
        start: format(start, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSxxx"),
        end: format(end, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSxxx"),
      };
    }
    return {
      start: format(today, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSxxx"),
      end: format(thirtyDaysFromToday, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSxxx"),
    };
  }, [policySummary?.endDate, policySummary?.startDate]);

  useEffect(() => {
    const { start, end } = mtaQuoteStartEndDate;
    setFieldValue('minStartDate', start);
    setFieldValue('maxStartDate', end);
  }, [mtaQuoteStartEndDate, setFieldValue]);

  return { policySummary, policyList: motorQuote, mtaQuoteStartEndDate };
};
