/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IAPIResult } from '../models/IAPIResult';
import type { PaymentMethodResponseIAPIResult } from '../models/PaymentMethodResponseIAPIResult';
import type { SavePaymentMethodRequest } from '../models/SavePaymentMethodRequest';
import type { SavePaymentMethodResponseIAPIResult } from '../models/SavePaymentMethodResponseIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentMethodService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns SavePaymentMethodResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PaymentMethod(
    requestBody?: SavePaymentMethodRequest,
  ): CancelablePromise<SavePaymentMethodResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payment-method',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Gets the supported payment methods
   * @param channel
   * @param country
   * @param currency
   * @param amount
   * @param product
   * @returns PaymentMethodResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PaymentMethod(
    channel?: string,
    country?: string,
    currency?: string,
    amount?: string,
    product?: string,
  ): CancelablePromise<PaymentMethodResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payment-method',
      query: {
        'channel': channel,
        'country': country,
        'currency': currency,
        'amount': amount,
        'product': product,
      },
    });
  }

  /**
   * @param paymentMethodId
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public deleteApiV1PaymentMethod(
    paymentMethodId: string,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/payment-method/{paymentMethodId}',
      path: {
        'paymentMethodId': paymentMethodId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}