import { useRef } from 'react';
import { AddOnType } from '../AddOns.types';
import { LegalExpenses } from './LegalExpenses';
import { CourtesyCarEnhanced } from './CourtesyCarEnhanced';
import { Keys } from './Keys';
import { BreakdownOne } from './BreakdownOne';
import { BreakdownTwo } from './BreakdownTwo';
import { BreakdownThree } from './BreakdownThree';
import { BreakdownFour } from './BreakdownFour';
import { ProtectedNCB } from './ProtectedNCB';

type AddOnDescriptionProps = {
  addOnType?: string;
  testId: string;
};

export const AddOnDescription = ({ addOnType, testId }: AddOnDescriptionProps) => {
  const type = useRef(addOnType);
  switch (type.current) {
    case AddOnType.PROTECTED_NCB:
      return <ProtectedNCB testId={`${testId}_add-on_protected-ncb`} />;
    case AddOnType.LEGAL_EXPENSES:
      return <LegalExpenses testId={`${testId}_add-on_legal-expenses`} />;
    case AddOnType.COURTESY_CAR_ENHANCED:
      return <CourtesyCarEnhanced testId={`${testId}_add-on_courtesy-car-enhanced`} />;
    case AddOnType.KEYS:
      return <Keys testId={`${testId}_add-on_keys-and-locks`} />;
    case AddOnType.BREAKDOWN_1:
      return <BreakdownOne testId={`${testId}_add-on_breakdown-one`} />;
    case AddOnType.BREAKDOWN_2:
      return <BreakdownTwo testId={`${testId}_add-on_breakdown-two`} />;
    case AddOnType.BREAKDOWN_3:
      return <BreakdownThree testId={`${testId}_add-on_breakdown-three`} />;
    case AddOnType.BREAKDOWN_4:
      return <BreakdownFour testId={`${testId}_add-on_breakdown-four`} />;
    default:
      return (
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu augue dolor.
          Morbi pellentesque volutpat congue. Ut a vulputate purus, ac commodo massa.
          Fusce id molestie nisi. Morbi et justo et libero rhoncus volutpat. Nunc
          porttitor, lorem in varius tincidunt, massa ex commodo enim, et elementum dolor
          velit id elit. Etiam ac sodales quam. Aliquam turpis velit, bibendum ac felis
          at, accumsan sollicitudin leo. Quisque a nisl nec quam tristique ultrices
          vulputate ac nunc. Cras nisl tortor, rhoncus ac consectetur sit amet, finibus id
          tellus.
        </div>
      );
  }
};
