import { Form } from 'formik';
import { Button } from '@dayinsure/components';
import { QuoteJourneyLayout, TopCards } from '../../../components';
import { Licence } from './Licence';
import { NonMotoringConvictions } from './NonMotoringConvictions';
import { DriveAnotherCar } from './DriveAnotherCar';
import { Qualifications } from './Qualifications';
import { NotifableMedicalConditions } from './NotifableMedicalConditions';
import { DrivingConvictions } from './DrivingConvictions';
import { AccidentOrClaim } from './AccidentOrClaim';

const testId = 'your-licence-and-history';

export const YourLicenseAndHistory = () => {
  return (
    <QuoteJourneyLayout>
      <h1
        className="mb-8 text-xl text-center md:text-left"
        data-testid={`${testId}_title`}
      >
        Your licence and history
      </h1>
      <TopCards
        testId={`${testId}_cards`}
        infoCardText="We need some details about your driving licence and history."
      />
      <div className="mb-16 sm:mb-24">
        <Form>
          <Licence formNameSpaceKey="drivers[0]" testId={testId} />
          <DriveAnotherCar formNameSpaceKey="drivers[0]" testId={testId} />
          <Qualifications formNameSpaceKey="drivers[0]" testId={testId} />
          <NotifableMedicalConditions formNameSpaceKey="drivers[0]" testId={testId} />
          <AccidentOrClaim formNameSpaceKey="drivers[0]" testId={testId} />
          <DrivingConvictions formNameSpaceKey="drivers[0]" testId={testId} />
          <NonMotoringConvictions formNameSpaceKey="drivers[0]" testId={testId} />
          <Button
            id={`${testId}_continue_button`}
            testId={`${testId}_continue_button`}
            submit
            fullWidth
            icon={{ name: 'arrow-right', position: 'right' }}
            text="Continue"
          />
        </Form>
      </div>
    </QuoteJourneyLayout>
  );
};
