import { LabelTooltip } from '../../../Common';

type CarUsageTooltipProps = {
  testId: string;
};

export const CarUsageTooltip = ({ testId }: CarUsageTooltipProps) => {
  return (
    <LabelTooltip buttonText="Got it" label="Usage" testId={testId}>
      <h4 className="mb-2 font-bold">Using the car</h4>
      <ul className="pl-4 list-disc">
        <li>
          <b>Social only</b> - covers normal day-to-day driving (such as visiting friends
          and family, shopping, etc) but not commuting.
        </li>
        <li>
          <b>Social including commuting</b> - covers social use plus commuting to a
          single, permanent place of work.
        </li>
        <li>
          <b>Business use</b> - includes social use and also covers the designated drivers
          in connection with their jobs (eg driving to different sites or offices and
          prearranged meetings away from the normal place of work).
        </li>
        <li>
          <b>Commercial travelling</b> - Covers social use and also using the vehicle for
          commercial travel, for example delivering goods or door-to-door sales. As there
          are different definitions of commercial travelling, you should check with the
          insurance company before you buy.
        </li>
      </ul>
    </LabelTooltip>
  );
};
