import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const useResetFormState = () => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    resetForm();
  }, [resetForm]);
};
