import clsx from 'clsx';
import { ReactNode } from 'react';
import { AsideCardItem } from './AsideCardItem';

export type AsideCardProps = {
  children?: ReactNode;
  className?: string;
  summaryContent?: ReactNode;
  title: string;
  testId?: string;
};

const AsideCard = ({
  children,
  className,
  summaryContent,
  testId,
  title,
}: AsideCardProps) => {
  return (
    <>
      <div
        data-testid={testId}
        className={clsx(
          `mb-4 w-full max-w-[14rem] text-white lg:min-w-[19.5rem] lg:max-w-[19.5rem]`,
          className
        )}
      >
        <div
          className={clsx('p-6 bg-summary-2', {
            'rounded-t-lg': !!summaryContent,
            'rounded-lg': !summaryContent,
          })}
          data-testid={`${testId}_content`}
        >
          <span className="block mb-4 text-lg" data-testid={`${testId}_title`}>
            {title}
          </span>
          {children}
        </div>
        {summaryContent && (
          <div
            className="relative p-6 rounded-b-lg bg-summary-1"
            data-testid={`${testId}_summary-content`}
          >
            {summaryContent}
          </div>
        )}
      </div>
    </>
  );
};

AsideCard.Item = AsideCardItem;

export { AsideCard };
