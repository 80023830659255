import { Avatar, Drawer, Icon } from '@dayinsure/components';
import {
  LinkWithReferrer,
  useSearchParamsToBuildPermittedQueryParams,
} from '@dayinsure/shared';
import { useAuth } from 'react-oidc-context';
import { useReferrer } from '../../../../../hooks';
import { AccountPaths } from '../../../../../routes';

const AccountDrawer = () => {
  const referrer = useReferrer();
  const { signoutRedirect, user } = useAuth();

  const completeQueryParamsFromJourney = useSearchParamsToBuildPermittedQueryParams();

  const handleSignOut = () =>
    signoutRedirect({
      id_token_hint: user?.id_token,
      post_logout_redirect_uri: `${window.origin}/auth/logout`,
      state: {
        referrerName: referrer.name,
        completeQueryParamsFromJourney,
      },
    });

  return (
    <Drawer
      trigger={
        <button id="account-user-avatar-btn-drawer" type="button" aria-label="Open">
          <Avatar />
        </button>
      }
    >
      <div className="w-full md:w-80 text-menu-content-1">
        <div className="flex justify-between items-center py-4 px-6">
          <span className="text-lg leading-7">Menu</span>
          <Drawer.Close id="mobile-menu-close-btn" aria-label="Close menu">
            <Icon name="cross" />
          </Drawer.Close>
        </div>
        <hr className="text-menu-content-2" />
        <div className="py-8 px-6 space-y-8">
          <Drawer.Close
            as={LinkWithReferrer}
            id="account-menu-account-details-btn-drawer"
            to={`/account/${AccountPaths.Details}`}
            className="link-with-icon"
          >
            <Icon name="user" />
            Account details
          </Drawer.Close>
          <Drawer.Close
            as={LinkWithReferrer}
            id="account-menu-payment-methods-btn-drawer"
            to={`/account/${AccountPaths.PaymentMethods}`}
            className="link-with-icon"
          >
            <Icon name="card" />
            Saved Cards
          </Drawer.Close>
          <Drawer.Close
            as={LinkWithReferrer}
            id="account-menu-password-btn-drawer"
            to={`/account/${AccountPaths.PasswordEdit}`}
            className="link-with-icon"
          >
            <Icon name="lock" />
            Password
          </Drawer.Close>
          <Drawer.Close
            type="button"
            id="account-menu-logout-btn-drawer"
            className="link-with-icon"
            onClick={handleSignOut}
          >
            <Icon name="logout" />
            Log out
          </Drawer.Close>
        </div>
      </div>
    </Drawer>
  );
};

export { AccountDrawer };
