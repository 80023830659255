import { Card } from '@dayinsure/components';
import { FormCheckboxSelect } from '../../../../components';

type MarketingPreferencesProps = {
  testId: string;
};

export const MarketingPreferences = ({ testId }: MarketingPreferencesProps) => (
  <div data-testid={testId} className="my-8 md:my-16">
    <h3
      className="mb-6 font-raleway text-lg font-normal md:mb-8"
      data-testid={`${testId}_title`}
    >
      Keeping you updated
    </h3>
    <Card
      testId={`${testId}_description`}
      classNames="mb-6 md:mb-8 pre-wrap"
      paddingLevel="small"
    >
      Fancy receiving discounts, rewards, interesting news or special deals? Select your
      preferred contact method(s) and we’ll send you occasional information that we hope
      you’ll love. We’ll also include the best offers from our friendly partners and the
      wider insurance group we belong to.
    </Card>
    <FormCheckboxSelect
      id={`${testId}_checkbox-select`}
      testId={`${testId}_checkbox-select`}
      name="proposer.marketingPreferences"
      cols={{ mobile: 1, tablet: 2, desktop: 2 }}
    />
  </div>
);
