import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import { QuoteJourneyFormData } from '../../types';
import { QuoteJourneyRoutes } from '../../routes';

const routesToResetOn = [
  QuoteJourneyRoutes.RegistrationSearch,
  QuoteJourneyRoutes.CarDetails,
  QuoteJourneyRoutes.CarUsage,
  QuoteJourneyRoutes.YourDetails,
  QuoteJourneyRoutes.YourHome,
  QuoteJourneyRoutes.YourLicenceAndHistory,
  QuoteJourneyRoutes.Drivers,
  QuoteJourneyRoutes.DriverDetails,
  QuoteJourneyRoutes.DriverLicenceAndHistory,
  QuoteJourneyRoutes.Cover,
] as const;

const resetOnRoute = (pathname: string) =>
  routesToResetOn.some(route => pathname.includes(route));

export const useResetPolicyConditionConfirmation = () => {
  const { pathname } = useLocation();
  const { values, setFieldValue } = useFormikContext<QuoteJourneyFormData>();

  useEffect(() => {
    if (values.policyConditions && resetOnRoute(pathname)) {
      setFieldValue('policyConditions', false);
    }
  }, [pathname, values, setFieldValue]);
};
