import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv1,
  MotorPolicyDto,
  OpenAPI,
} from '../../../api/v1';
import { query } from '../queryKeys';

export const usePolicyQuery = (id: string | undefined | null) => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const getPolicy = () => {
    if (!id) {
      throw new Error('No policy ID');
    }
    const api = new DayinsureAPIv1(OpenAPI);
    return api.motorPolicy.getApiV1MotorPolicy(id);
  };

  return useQuery<MotorPolicyDto, ApiError>(query.policy(id || ''), getPolicy, {
    keepPreviousData: true,
    enabled: id !== undefined,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
