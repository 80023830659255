const getNumericOptions = () =>
  [...Array(19).keys()].map(key => {
    const optionNumber = (key + 1).toString();

    return { id: optionNumber, name: optionNumber };
  });

const baseOptions = [...getNumericOptions(), { id: '20', name: '20+' }];

export const noClaimsOptions = [{ id: '0', name: '0' }, ...baseOptions];
