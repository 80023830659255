import { SelectRadioOption } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { getFormFieldAccessPath } from '../../../helpers';
import {
  DriverFormData,
  Occupation,
  QuoteJourneyFormData,
  YesNoAnswer,
} from '../../../types';
import { FormRadioSelect, FormFieldBox } from '../../FormsFields';
import { getAnotherJobRadioOptions } from './AnotherJobRadio.const';

type AnotherJobRadioProps = {
  formNameSpaceKey?: string;
  testId: string;
  defaultValue: Occupation;
  additionalDriver?: boolean;
};
export const AnotherJobRadio = <T extends QuoteJourneyFormData | DriverFormData>({
  formNameSpaceKey,
  testId,
  defaultValue,
  additionalDriver,
}: AnotherJobRadioProps) => {
  const { setFieldValue } = useFormikContext<T>();

  const anotherJobOptions = useMemo(() => getAnotherJobRadioOptions(testId), [testId]);

  const occupationsPath = getFormFieldAccessPath([formNameSpaceKey, 'occupations']);
  const anotherJobOccupationPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'occupations[0]',
    'anotherJob',
  ]);
  const secondaryOccupationPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'occupations[1]',
  ]);

  const otherJobOnChange = useCallback(
    (option: SelectRadioOption) => {
      const { id } = option;
      if (id === YesNoAnswer.NO) {
        return setFieldValue(occupationsPath, [defaultValue]);
      }
      return setFieldValue(secondaryOccupationPath, {
        type: null,
        industry: null,
        isPrimary: false,
      });
    },
    [defaultValue, occupationsPath, secondaryOccupationPath, setFieldValue]
  );
  return (
    <div>
      <h3 className="flex relative w-full font-raleway font-normal form-subtitle-compact">
        {additionalDriver ? 'Do they have any other job?' : 'Do you have any other job?'}
      </h3>
      <FormFieldBox>
        <FormRadioSelect
          onChangeOptional={otherJobOnChange}
          name={anotherJobOccupationPath}
          options={anotherJobOptions}
          id={`${testId}_another-job-radio`}
          testId={`${testId}_another-job-radio`}
        />
      </FormFieldBox>
    </div>
  );
};
