import { Fragment } from 'react';
import {
  BreakdownItem,
  Button,
  Card,
  Dialog,
  FormattedPrice,
} from '@dayinsure/components';
import {
  AddOnModelDto,
  CostDto, CoverDto,
  CoverResponseDto,
  FeeDto,
  InsurancePriceDto,
  PaymentPlanDto,
} from '../../../api/v1';
import { useCoverOptionsQuery } from '../../../hooks';
import { CoverTypesDto } from '../../../api/v2';
import { getDisplayPriceInBreakdown, isAnnualPaymentPlan } from '../../../helpers';

const getInsurancePremiumPrice = (net = 0, tax = 0) => net + tax;

type PriceBreakdownDialogProps = {
  open: boolean;
  close: () => void;
  testId: string;
  addOns?: AddOnModelDto[] | null;
  insurancePrice?: InsurancePriceDto;
  cover?: CoverResponseDto | CoverDto;
  currentPlan?: PaymentPlanDto;
  totalPriceIncAddOns?: CostDto;
  mta?: boolean;
};
export const PriceBreakdownDialog = ({
  open,
  close,
  testId,
  addOns,
  insurancePrice,
  cover,
  currentPlan,
  totalPriceIncAddOns,
  mta,
}: PriceBreakdownDialogProps) => {
  const { data: coverData } = useCoverOptionsQuery();
  const coverType = coverData?.coverTypes?.find(
    (type: CoverTypesDto) => type.code === cover?.type?.code
  );
  const adminFee = insurancePrice?.fees?.find(
    (fee: FeeDto) => fee.type === 'AdministrationFee'
  );
  const installmentDetails = currentPlan?.installmentDetails;
  const isAnnualPlan = isAnnualPaymentPlan(currentPlan?.type?.code);
  const totalPrice = getDisplayPriceInBreakdown(
    isAnnualPlan,
    currentPlan,
    totalPriceIncAddOns
  );
  const activeAddons = addOns?.filter(addOn => addOn.selected) ?? [];

  return (
    <Dialog title="Price breakdown" open={open} onClose={close} scrollToTop>
      <Card
        classNames="!bg-popup-background flex flex-col items-center mb-6 !py-4 !px-0"
        paddingLevel="small"
      >
        <>
          {coverType?.description && (
            <BreakdownItem
              title="Cover type"
              value={coverType?.description}
              testId={`${testId}_cover-type`}
              addDivider
            />
          )}
          {insurancePrice?.insurancePremiumTax && (
            <BreakdownItem
              title="Insurance premium"
              value={
                <FormattedPrice
                  price={getInsurancePremiumPrice(
                    insurancePrice?.insurancePremiumTax?.amount,
                    insurancePrice?.netPremium?.amount
                  )}
                  size="BASE"
                />
              }
              testId={`${testId}_insurance-premium`}
              addDivider
            />
          )}
          {activeAddons.map(addOn => (
            <Fragment key={addOn.type?.code}>
              <BreakdownItem
                title={addOn.type?.description || ''}
                value={<FormattedPrice price={addOn.price?.total?.amount} size="BASE" />}
                testId={`${testId}_add-on_${addOn.type?.code}`}
              />
              <hr className="my-4 -mx-4 w-full border-y border-popup-background" />
            </Fragment>
          ))}
          <span className="px-4 font-lato text-sm text-popup-content-2">
            The prices above are inclusive of 12% insurance premium tax
          </span>
          <hr className="my-4 -mx-4 w-full border-y border-popup-background" />
          {adminFee?.total && (
            <BreakdownItem
              title="Admin fee (payable to Everydayinsure, under a separate contract)"
              value={<FormattedPrice price={adminFee.total.amount} size="BASE" />}
              testId={`${testId}_admin-fee`}
            />
          )}
        </>
      </Card>
      <Card
        classNames="!bg-popup-background flex flex-col items-center !py-4 !px-0"
        paddingLevel="small"
      >
        {installmentDetails?.firstInstallment?.amount && !mta && (
          <BreakdownItem
            title="Deposit"
            value={
              <FormattedPrice price={installmentDetails?.deposit?.amount} size="BASE" />
            }
            testId={`${testId}_initial-payment`}
            addDivider
          />
        )}
        {installmentDetails?.financeAmount && (
          <BreakdownItem
            title="Amount to be financed"
            value={
              <FormattedPrice
                price={installmentDetails?.financeAmount.amount}
                size="BASE"
              />
            }
            testId={`${testId}_amount-to-be-financed`}
            addDivider
          />
        )}
        {installmentDetails?.aprFromProvider && (
          <>
            <BreakdownItem
              title="Interest rate"
              value={`${installmentDetails?.interestRate}%`}
              testId={`${testId}_interest-rate`}
              addDivider
            />
            <BreakdownItem
              title="Representative APR"
              value={`${installmentDetails?.aprFromProvider}%`}
              testId={`${testId}_representative-apr`}
              addDivider
            />
          </>
        )}
        {installmentDetails?.monthlyCost && (
          <BreakdownItem
            title={`${installmentDetails?.numberOfInstallments} monthly payments`}
            value={
              <FormattedPrice
                price={installmentDetails?.monthlyCost.amount}
                size="BASE"
              />
            }
            testId={`${testId}_monthly-payments`}
            addDivider
          />
        )}
        <BreakdownItem
          title="Total amount payable"
          value={<FormattedPrice price={totalPrice} size="LG" />}
          testId="price-breakdown-total-price"
          addDivider
        />
        {coverType && (
          <div className="flex flex-col font-lato text-sm text-popup-content-2">
            <p>{coverType.description} cover underwritten by Mulsanne</p>
          </div>
        )}
      </Card>
      <Button
        id={`${testId}_close-btn`}
        data-testid={`${testId}_close-btn`}
        fullWidth
        onClick={close}
        text="Close"
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};
