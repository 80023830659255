import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { ListboxOption } from '@dayinsure/components';
import { FormListBox } from '../../FormsFields';
import { useVehicleOptionsQuery } from '../../../hooks';
import { baseOptionDtoToViewOptions, isBusinessUsage } from '../../../helpers';
import { QuoteJourneyFormData } from '../../../types';
import { CarUsageTooltip } from './CarUsageTooltip';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';

type CarUsageTypeProps = {
  testId: string;
};

export const CarUsageType = ({ testId }: CarUsageTypeProps) => {
  const { data } = useVehicleOptionsQuery();
  const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext<
    QuoteJourneyFormData | MtaJourneyFormData
  >();

  const carUsageOptions = useMemo(
    () => data?.vehicleUsages?.map(baseOptionDtoToViewOptions(`${testId}_type`)) || [],
    [data, testId]
  );

  const onVehicleUseChange = (option: ListboxOption) => {
    if (option.id && !isBusinessUsage(option.id)) {
      setFieldValue('vehicle.usage.businessMileage', null);
      setFieldTouched('vehicle.usage.businessMileage', false);
      setFieldError('vehicle.usage.businessMileage', undefined);
    }
  };

  return (
    <div data-testid={`${testId}_type`} className="my-8 md:my-16">
      <h3
        className="mb-6 font-raleway text-lg font-normal md:mb-12"
        data-testid={`${testId}_type_title`}
      >
        What will the car be used for?
      </h3>
      <div className="flex relative flex-col mb-4 md:mb-8">
        <FormListBox
          onChangeOptional={onVehicleUseChange}
          unselectedMessage="Please select"
          name="vehicle.usage.type"
          options={carUsageOptions}
          id={`${testId}_type_select`}
          testId={`${testId}_type_select`}
          icon="cog"
          label={<CarUsageTooltip testId={`${testId}_type`} />}
        />
      </div>
    </div>
  );
};
