import { matchPath } from 'react-router-dom';
import { QuoteJourneyRoutes } from '../../routes';

export const stepProgressValues: Record<QuoteJourneyRoutes, [number, number] | null> = {
  [QuoteJourneyRoutes.RegistrationSearch]: [0, 0.25],
  [QuoteJourneyRoutes.CarDetails]: [0, 0.5],
  [QuoteJourneyRoutes.CarUsage]: [0, 0.75],
  [QuoteJourneyRoutes.YourDetails]: [1, 0.15],
  [QuoteJourneyRoutes.YourHome]: [1, 0.3],
  [QuoteJourneyRoutes.YourLicenceAndHistory]: [1, 0.45],
  [QuoteJourneyRoutes.Drivers]: [1, 0.6],
  [QuoteJourneyRoutes.AdditionalDriverId]: [1, 0.75],
  [QuoteJourneyRoutes.DriverDetails]: [1, 0.75],
  [QuoteJourneyRoutes.DriverLicenceAndHistory]: [1, 0.75],
  [QuoteJourneyRoutes.Cover]: [2, 0.25],
  [QuoteJourneyRoutes.QuoteId]: null,
  [QuoteJourneyRoutes.CarSecurity]: null,
  [QuoteJourneyRoutes.CarSecurityPolicyConditions]: null,
  [QuoteJourneyRoutes.YourQuote]: [2, 0.5],
  [QuoteJourneyRoutes.Review]: [3, 0.75],
  [QuoteJourneyRoutes.Payment]: [4, 0.5],
  [QuoteJourneyRoutes.PurchaseComplete]: null,
  [QuoteJourneyRoutes.PolicyConditions]: null,
  [QuoteJourneyRoutes.Empty]: null,
};

export const isQuoteJourneyPath = (value: string): value is QuoteJourneyRoutes =>
  Object.values(QuoteJourneyRoutes).includes(value as QuoteJourneyRoutes);

const getDynamicQuoteJourneyRouteFromPath = (lastPath: string, previousPath: string) =>
  Object.values(QuoteJourneyRoutes).find(path =>
    matchPath(`/${path}`, `/${previousPath}/${lastPath}`)
  );

export const getCurrentStepFromRoute = (route: string): QuoteJourneyRoutes | null => {
  const [lastPath, previousPath] = route.split('/').reverse();

  if (isQuoteJourneyPath(lastPath)) {
    return lastPath;
  }

  const dynamicRoute = getDynamicQuoteJourneyRouteFromPath(lastPath, previousPath);
  if (dynamicRoute) {
    return dynamicRoute;
  }

  return null;
};

export const getStepperProgressFromRoute = (route: string): [number, number] | null => {
  const currentStepFromRoute = getCurrentStepFromRoute(route);

  return currentStepFromRoute ? stepProgressValues[currentStepFromRoute] : null;
};

export const matchQuoteDriversPath = (route: string, driversPath: string) =>
  matchPath(
    {
      path: `/quote/${QuoteJourneyRoutes.Drivers}/${QuoteJourneyRoutes.AdditionalDriverId}/${driversPath}`,
    },
    route
  );
