import { FormCheckbox } from '../../../../../components';

type TheSameCarProps = {
  testId: string;
};

export const TheSameCar = ({ testId }: TheSameCarProps) => {
  return (
    <div className="mb-16">
      <h3
        className="mt-8 mb-6 font-raleway text-lg font-normal"
        data-testid={`${testId}_the-same-car_title`}
      >
        Is the new reg for the same car?
      </h3>
      <FormCheckbox
        displayText="I confirm I am not changing my car"
        name="newReg.notChanging"
        id={`${testId}_the-same-car_input`}
        testId={`${testId}_the-same-car_input`}
      />
    </div>
  );
};
