import { Button, ContentContainer, PageContainer } from '@dayinsure/components';
import { useParams } from 'react-router-dom';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { useMotorPoliciesSummaryQuery, usePolicyQuery } from '../../../hooks';
import { PolicyInfoCar } from '../PolicyInfo/PolicyInfoCar';
import { PolicyInfoCover } from '../PolicyInfo/PolicyInfoCover';
import { PolicyInfoDrivers } from '../PolicyInfo/PolicyInfoDrivers';
import { PolicyInfoCovers } from '../PolicyInfo/PolicyInfoCovers';
import { PolicyInfoPayments } from '../PolicyInfo/PolicyInfoPayments';
import { ConditionsCard } from '../../../components/Quote';
import { PolicyDocuments } from '../../../components/PolicyDocuments';
import { FeesAndCharges } from '../../Quote/Review/FeesAndCharges';
import { ExcessesCard } from '../../../components';
import { NewMonthlyPayments } from '../../Quote';
import { PaymentTypesEnum } from '../../../constants/payments';
import { PaymentCard } from '../../../components/PaymentCard';
import { transformPlanForFormikValue } from '../../../helpers';

const policyMock = {
  payments: {
    type: 'Yearly',
    amount: '£948.99 /year',
    lastPayment: '£948.99 on 01/01/2023',
  },
};

const testId = 'upcoming-policy-changes';
export const UpcomingPolicyChanges = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigateWithReferrer();
  const { data: policy } = usePolicyQuery(id);
  const vehicleDescription = `${policy?.vehicle?.make} ${policy?.vehicle?.model}`;
  const { data: motorQuote } = useMotorPoliciesSummaryQuery();
  const policyDetails = motorQuote?.find(mq => mq.id === id);
  const usualPaymentFrequency = transformPlanForFormikValue(policy?.paymentPlan);
  const isPlanAnnual = usualPaymentFrequency?.code?.id === PaymentTypesEnum.ANNUAL;
  const handleBackToPolicy = () => {
    navigate(`/policies/${id}`);
  };
  return (
    <PageContainer className="font-raleway">
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Upcoming policy changes
      </h1>
      <ContentContainer>
        {id && (
          <>
            {policy?.vehicle?.registrationNumber && (
              <PolicyInfoCar
                testId={testId}
                vehicleDescription={vehicleDescription}
                vehicleRegistration={policy?.vehicle?.registrationNumber}
                vehicleEstimatedValue={policy?.vehicle?.selfDeclaredVehicleValue?.amount}
                noMoreButton
              />
            )}
            {policy?.pendingChanges?.effectiveDateTimeUtc && policyDetails?.endDate && (
              <PolicyInfoCover
                testId={testId}
                startDate={policy.pendingChanges.effectiveDateTimeUtc}
                endDate={policyDetails.endDate}
                customStartTitle="Changes start"
              />
            )}
            {policy?.drivers?.length && policy?.policyholder && (
              <PolicyInfoDrivers
                testId={testId}
                drivers={policy.drivers}
                policyHolder={policy.policyholder}
                noMoreButton
              />
            )}
            {policy?.cover && (
              <PolicyInfoCovers
                testId={testId}
                cover={policy.cover}
                addons={policy.addOns}
              />
            )}
            {policy?.totalPrice && (
              <PolicyInfoPayments
                testId={testId}
                payments={policyMock.payments}
                totalPrice={policy.totalPrice}
              />
            )}
            {!isPlanAnnual && policy?.paymentPlan && (
              <NewMonthlyPayments
                usualPaymentFrequency={usualPaymentFrequency}
                testId={testId}
                paymentPlans={[policy?.paymentPlan]}
                insurancePrice={policy?.price}
                classNames="bg-content-background-prominent"
              />
            )}
            <ConditionsCard testId={testId} />
            <PolicyDocuments testId={testId} policyId={id} noDownloadInfo />
            <FeesAndCharges
              isPlannAnnual
              testId={testId}
              feesForDisplay={policy?.price?.feesForDisplay}
            />
            <div className="mb-12">
              <ExcessesCard testId={testId} noEdit />
            </div>
          </>
        )}
        <div className="mb-16">
          {policy?.paymentPlan && (
            <PaymentCard
              addOns={policy?.addOns}
              insurancePrice={policy?.price}
              paymentPlans={[policy?.paymentPlan]}
              cover={policy?.cover}
              totalPriceIncAddOns={policy.totalPrice}
              excesses={policy.excesses}
              testId={testId}
              mta
              usualPaymentFrequency={usualPaymentFrequency}
              classNames="bg-content-background-prominent"
            />
          )}
        </div>
        <Button
          variant="primary"
          classNames="w-full mb-8"
          id={`${testId}_back-to-policy-button`}
          testId={`${testId}_back-to-policy-button`}
          text="Back to policy"
          onClick={handleBackToPolicy}
        />
        <Button
          variant="secondary"
          classNames="w-full mb-16"
          id={`${testId}_back-to-policy-button`}
          testId={`${testId}_back-to-policy-button`}
          text="Cancel policy changes"
          onClick={handleBackToPolicy}
        />
      </ContentContainer>
    </PageContainer>
  );
};
