import { Card } from '@dayinsure/components';
import CloseBrothersLogo from './CloseBrothersLogo.svg';

type CreditAgreementProps = {
  className?: string;
  testId: string;
};

export const CreditAgreement = ({ className, testId }: CreditAgreementProps) => {
  return (
    <Card classNames={className} testId={`${testId}_credit-agreement_card`}>
      <h3 className="mb-8 font-raleway text-main-content-1">
        Credit agreement information
      </h3>

      <div className="font-lato text-sm text-main-content-1">
        <p className="mb-4">
          By paying for your policy in monthly instalments, you will enter into a credit
          agreement. Your credit provider will be <b>Close Brothers Limited.</b>
        </p>

        <p className="mb-4 font-bold">
          CloseBrothers-Everydayinsure will show on your bank statement as Direct Debit
          payments are made. You can contact them directly for all matters relating to
          your finance plan on 0333 3218 566.
        </p>

        <p className="mb-4">
          Everydayinsure will be the credit broker, not the lender. Through arranging
          finance for you, we will receive a commission from the lender. Our arrangement
          with Close Brothers Premium Finance is exclusive and the commission arrangement
          may affect our impartiality in recommending a finance provider.
        </p>

        <p className="mb-6">
          A record of the credit search and details of your Credit Agreement will appear
          on your credit report and will be visible to other lenders.
        </p>
      </div>
      <img src={CloseBrothersLogo} width={154} height={30} alt="Close Brothers logo" />
    </Card>
  );
};
