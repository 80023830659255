import { Card, FormattedPrice, Icon, onEnter } from '@dayinsure/components';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AddOnCheckboxCardRadio } from './AddOnCheckboxCardRadio';
import { PaymentTypesEnum } from '../../../../../../constants/payments';
import { AddOnCheckboxOption } from '../types';
import { AddOnDto } from '../../../../../../api/v2';
import { YesNoAnswer } from '../../../../../../types';

type AddOnCheckboxCardProps = {
  name: string;
  addOnValue: AddOnCheckboxOption;
  addOnSchema: AddOnDto | undefined;
  onChange: (option: AddOnCheckboxOption) => void;
  clickFormMoreInfo?: () => void;
  disabled?: boolean;
  testId?: string;
};
export const AddOnCheckboxCard = ({
  testId = 'addon_checkbox_card',
  addOnValue,
  addOnSchema,
  onChange,
  name,
  clickFormMoreInfo,
  disabled,
}: AddOnCheckboxCardProps) => {
  const isPlanMonthly = addOnValue?.selectedPlan === PaymentTypesEnum.MONTHLY;
  const priceToPayValue = isPlanMonthly ? addOnValue.priceMonthly : addOnValue?.price;
  const onChangeHandler = (option: AddOnCheckboxOption) => {
    const checked = option.id === YesNoAnswer.YES;
    onChange({
      id: addOnValue.id,
      name: addOnValue.name,
      checked,
      price: addOnValue.price,
      priceSufix: addOnValue.priceSufix,
      pricePrefix: addOnValue.pricePrefix,
    });
  };

  const renderPerks = useMemo(() => {
    if (addOnSchema?.included) {
      return addOnSchema?.included.map((inc: string) => (
        <div key={inc} className="flex items-center font-lato">
          <Icon name="check" size="0.75rem" className="mr-2" />
          <p className="text-xs">{inc}</p>
        </div>
      ));
    }
    return null;
  }, [addOnSchema?.included]);
  return (
    <Card
      testId={`${testId}_addon-checkbox-card`}
      paddingLevel="small"
      classNames="!bg-popup-background mb-6 flex flex-col"
    >
      <h2
        data-testid={`${testId}_title`}
        className="flex relative mb-4 w-full font-raleway font-normal capitalize form-subtitle-compact"
      >
        {addOnSchema?.description}
      </h2>
      {addOnSchema?.included && (
        <div className="mb-6">
          <p className="mb-4 font-lato text-xs font-bold">{addOnSchema?.summary}</p>
          {renderPerks}
        </div>
      )}
      {clickFormMoreInfo && (
        <span
          role="button"
          tabIndex={0}
          className="flex gap-2 items-center my-2 mt-0 mb-6 text-sm font-bold leading-3"
          onClick={clickFormMoreInfo}
          onKeyDown={onEnter(clickFormMoreInfo)}
        >
          <Icon name="info" size="0.75rem" />
          View more information
        </span>
      )}
      <div className={clsx('font-raleway', { 'mb-6': !isPlanMonthly })}>
        {addOnValue.isOptionalBreakdownAddOn && <span className="text-sm">From</span>}{' '}
        <FormattedPrice price={priceToPayValue} size="LG" className="mb-2" />{' '}
        <span className="text-sm">{addOnValue?.priceSufix}</span>
      </div>
      {isPlanMonthly && (
        <div className="mb-4 font-raleway">
          <FormattedPrice price={addOnValue?.price} size="BASE" className="mb-2" />{' '}
          <span className="text-sm">/year</span>
        </div>
      )}
      <AddOnCheckboxCardRadio
        disabled={disabled}
        name={name}
        onChange={onChangeHandler}
        testId={testId}
      />
    </Card>
  );
};
