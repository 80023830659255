import { useMemo } from 'react';
import { FormFieldBox, FormListBox } from '../../FormsFields';
import { useStudentOccupationsQuery } from '../../../hooks';
import { baseOptionDtoToViewOptions, getFormFieldAccessPath } from '../../../helpers';

type StudentJobFieldProps = {
  formNameSpaceKey?: string;
  testId: string;
};

export const StudentJobField = ({ formNameSpaceKey, testId }: StudentJobFieldProps) => {
  const { data: studentOccupations } = useStudentOccupationsQuery();

  const studentOccupationOptions = useMemo(
    () =>
      studentOccupations?.map(
        baseOptionDtoToViewOptions(`${testId}_student-occupation`)
      ) || [],
    [studentOccupations, testId]
  );

  const studentOccupationTypePath = getFormFieldAccessPath([
    formNameSpaceKey,
    `occupations[0]`,
    'type',
  ]);

  return (
    <FormFieldBox>
      <FormListBox
        id={`${testId}_student-occupations-dropdown`}
        testId={`${testId}_student-occupations-dropdown`}
        unselectedMessage="Please select"
        name={studentOccupationTypePath}
        options={studentOccupationOptions}
        icon="student"
        label="Student type"
      />
    </FormFieldBox>
  );
};
