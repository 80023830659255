/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type ScrollToTopProps = {
  children?: JSX.Element | JSX.Element[];
};

export const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const location = useLocation();

  useEffect(() => {
    document.getElementById('scroll-container')?.scroll(0, 0);
  }, [location]);

  return <>{children}</>;
};
