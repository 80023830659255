import {
  emailRegex,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
  uppercaseRegex,
} from '@dayinsure/shared';
import * as Yup from 'yup';

export const SignUpValidationSchema = Yup.object().shape({
  signUp: Yup.object().shape({
    emailAddress: Yup.string()
      .matches(emailRegex, {
        message: 'Please check this',
        excludeEmptyString: true,
      })
      .required('Required'),
    password: Yup.string()
      .min(8, 'Too short')
      .matches(uppercaseRegex, {
        message: 'One uppercase letter',
        excludeEmptyString: true,
      })
      .matches(lowercaseRegex, {
        message: 'One lowercase letter',
        excludeEmptyString: true,
      })
      .matches(numberRegex, {
        message: 'One number',
        excludeEmptyString: true,
      })
      .matches(symbolRegex, {
        message: 'One symbol',
        excludeEmptyString: true,
      })
      .required('Required'),
    retypePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match')
      .required('Required'),
    reaptcha: Yup.string().required('Required'),
  }),
});
