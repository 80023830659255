import { ChangeEvent } from 'react';

const capitalizeFirstLetter = (str: string): string =>
  str
    .split(' ')
    .map(word => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    })
    .join(' ');

export const nameInputMask = (e: ChangeEvent<HTMLInputElement>) => {
  e.target.value = capitalizeFirstLetter(
    e.target.value.replace(/[^- '\p{L}]+/giu, '').trimStart()
  );
};
