import { FocusEvent, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SelectRadioOption } from '@dayinsure/components';
import { trimWhiteSpaces } from '@dayinsure/shared';
import { FormRadioSelect, FormTextField, FormFieldBox } from '../../FormsFields';
import { isNo, postcodeInputMask } from '../../../helpers';
import { YesNoDataEntry, QuoteJourneyFormData, YesNoAnswer } from '../../../types';

const getCarKeptHomeOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_kept-home_option_yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_kept-home_option_no`,
  },
];

type CarOvernightLocationProps = {
  testId: string;
};

export const CarOvernightLocation = ({ testId }: CarOvernightLocationProps) => {
  const { setFieldTouched, setFieldValue, values, handleBlur } =
    useFormikContext<QuoteJourneyFormData>();
  const {
    vehicle: {
      location: { keptHome },
    },
  } = values;
  const trimSpaces = trimWhiteSpaces(values, setFieldValue);

  const handleKeptHomeChange = useCallback(
    (option: SelectRadioOption) => {
      if (option.id === YesNoAnswer.YES) {
        setFieldValue('vehicle.location.overnightLocationPostcode', '');
      }
      setFieldTouched('vehicle.location.overnightLocationPostcode', false);
    },
    [setFieldTouched, setFieldValue]
  );

  const handleTextFieldBlurEvent =
    (inputName: string) => (e: FocusEvent<HTMLInputElement>) => {
      trimSpaces(inputName)();
      handleBlur(e);
    };

  const options = useMemo(
    () => getCarKeptHomeOptions(`${testId}_overnight-location`),
    [testId]
  );

  return (
    <div data-testid={`${testId}_overnight-location`}>
      <h3
        className="mt-12 mb-6 font-raleway text-lg font-normal"
        data-testid={`${testId}_overnight-location_title`}
      >
        Will the car be kept at home overnight?
      </h3>
      <div className="mb-6 md:mb-12">
        <FormRadioSelect
          onChangeOptional={handleKeptHomeChange}
          name="vehicle.location.keptHome"
          options={options}
          id={`${testId}_overnight-location_kept-home`}
          testId={`${testId}_overnight-location_kept-home`}
        />
      </div>

      {isNo(keptHome?.id) && (
        <FormFieldBox className="relative">
          <FormTextField
            placeholder="e.g. AB1 2CD"
            name="vehicle.location.overnightLocationPostcode"
            id={`${testId}_overnight-location_postcode-input`}
            testId={`${testId}_overnight-location_postcode_input`}
            label={{
              text: 'Postcode where the car will be kept overnight',
              icon: 'postcode',
            }}
            onInput={postcodeInputMask}
            onBlur={handleTextFieldBlurEvent(
              'vehicle.location.overnightLocationPostcode'
            )}
            uppercase
          />
        </FormFieldBox>
      )}
    </div>
  );
};
