export const APP_DISCOUNT = 5;

export const DEFAULT_PRIVACY_POLICY_URL =
  'https://www.dayinsure.com/document/dayinsure-privacy-policy/';
export const DEFAULT_TOBA_URL =
  'https://www.dayinsure.com/document/dayinsure-terms-of-business-2/';
export const DEFAULT_IPID_URL =
  'https://www.dayinsure.com/document/insurance-product-information-document/';
export const MULSANNE_PRIVACY_POLICY_URL =
  'https://www.dayinsure.com/document/mulsanne-privacy-policy/';
