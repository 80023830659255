import clsx from 'clsx';

type QuoteDetailsReviewListProps = { items: string[] };

export const QuoteDetailsReviewList = ({ items }: QuoteDetailsReviewListProps) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <ul className={clsx([items.length > 1 ? 'pl-4 list-disc md:pl-6' : 'list-none'])}>
      {items.map(item => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};
