import { FormNumField } from '../../../../components';
import { digitOnlyMask } from '../../../../helpers';

const testId = 'car-details_car-valuation';
export const CarValuation = () => {
  return (
    <div>
      <h3
        className="mt-12 mb-6 font-raleway text-lg font-normal"
        data-testid={`${testId}_title`}
      >
        How much is your car worth?
      </h3>
      <FormNumField
        label={{ text: 'Estimated value', icon: 'chart' }}
        testId={`${testId}_input`}
        id={`${testId}_input`}
        name="vehicle.selfDeclaredVehicleValue"
        placeholder="Enter value (£)"
        tooltip="Please enter your current estimated market value."
        tooltipCloseButton="Got it"
        tooltipHint="hint"
        onInput={digitOnlyMask}
      />
    </div>
  );
};
