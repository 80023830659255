import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import {
  AddOnDto,
  ApiError,
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useGetProductSchemeAddons = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const api = new DayinsureAPIv2(OpenAPI);
  const getAddonsScheme = () => {
    return api.product.getApiV2ProductSchemeAddons('motor', 'latest', 'everyday_scheme');
  };

  return useQuery<Array<AddOnDto>, ApiError>(query.addOnsSchema, getAddonsScheme, {
    keepPreviousData: true,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
