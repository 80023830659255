import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayInsureAPIv2,
  DocumentDto,
  OpenAPI,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useDocumentsQuery = (policyId: string) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getDocumentsLinks = () => {
    if (!policyId) {
      throw Error('No policy id');
    }
    const api = new DayInsureAPIv2(OpenAPI);

    return api.product.getApiV2ProductSchemeDocuments(
      'motor',
      'latest',
      'everyday_scheme'
    );
  };

  return useQuery<Array<DocumentDto>, ApiError>(query.documents, getDocumentsLinks, {
    keepPreviousData: true,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
