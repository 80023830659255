import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { SelectRadioOption } from '@dayinsure/components';
import { FormDateField, FormRadioSelect } from '../../../../components';
import { isYes } from '../../../../helpers';
import { QuoteJourneyFormData, YesNoDataEntry, YesNoAnswer } from '../../../../types';

const carPurchasedOptions: YesNoDataEntry[] = [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: 'car-details_purchased-yes',
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: 'car-details_purchased-no',
  },
];

export const CarPurchaseDate = () => {
  const {
    setFieldValue,
    setFieldTouched,
    values: {
      vehicle: {
        ownership: { isPurchased },
      },
    },
    isSubmitting,
  } = useFormikContext<QuoteJourneyFormData>();

  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched('vehicle.ownership.selfDeclaredDateOfPurchase', true);
    }
  }, [isSubmitting, setFieldTouched]);

  const handleIsPurchasedChange = useCallback(
    (option: SelectRadioOption) => {
      if (option.id === YesNoAnswer.NO) {
        setFieldValue('vehicle.ownership.selfDeclaredDateOfPurchase', {
          day: '',
          month: '',
          year: '',
          parsedDate: '',
        });
      }
      setFieldTouched('vehicle.ownership.selfDeclaredDateOfPurchase', false);
    },
    [setFieldTouched, setFieldValue]
  );

  return (
    <div className="my-6 md:my-12">
      <h2
        data-testid="car-details_is-purchased_label"
        className="flex relative w-full font-raleway font-normal form-subtitle-compact"
      >
        Have you bought the car yet?
      </h2>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          onChangeOptional={handleIsPurchasedChange}
          id="car-details_is-purchased_radio"
          testId="car-details_is-purchased_radio"
          name="vehicle.ownership.isPurchased"
          options={carPurchasedOptions}
        />
      </div>
      {isYes(isPurchased?.id) && (
        <div className="mb-4 md:mb-8">
          <FormDateField
            label={{
              icon: 'date',
              text: 'Purchase date',
            }}
            testId="car-details_is-purchased_date"
            id="car-details_is-purchased_date"
            name="vehicle.ownership.selfDeclaredDateOfPurchase"
            configuration={{
              month: {
                placeholder: 'MM',
              },
              year: {
                placeholder: 'YYYY',
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
