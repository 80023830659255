export const addCurrency = (price: number | string) => `£${price}`;
export const roundPrice = (price: number | string, fraction = 2) => {
  const priceValue = typeof price === 'string' ? parseInt(price, 2) : price;

  return priceValue.toFixed(fraction);
};
export const formatPrice = (price: number | string, fraction = 2) =>
  addCurrency(roundPrice(price, fraction));

export const toFixedString = (value?: number | null): string =>
  value ? value.toFixed(2).toString() : '';

export const formatNegativePrice = (price: number) =>
  price < 0
    ? `-£${toFixedString(Math.abs(price))}`
    : `£${toFixedString(Math.abs(price))}`;
