import { RadioGroup as HeadlessRadioSelect } from '@headlessui/react';
import clsx from 'clsx';
import FormattedPrice from '../../DataDisplay/FormattedPrice/FormattedPrice';
import { OptionDetail } from './OptionDetail';
import Icon from '../../DataDisplay/Icon/Icon';

const getPriceSufix = (isAnnual?: boolean, isMta?: boolean): string => {
  if (isMta) {
    return '';
  }
  return ` /${isAnnual ? 'year' : 'month'}`;
};

export type PaymentTypeRadioOption = {
  id: string;
  name: string;
  price: string;
  isAnnual?: boolean;
  isMta?: boolean;
  details?: {
    initialPayment: string;
    amountToBeFinanced: string;
    intrestRate: string;
    representativeAPR: string;
    numberOfInstallments: number;
    monthlyCost: string;
    total: string;
    deposit: string;
  };
  testId?: string;
};

export type PaymentTypeRadioProps = {
  id: string;
  name: string;
  options: PaymentTypeRadioOption[];
  value: PaymentTypeRadioOption | undefined;
  onChange: (option: PaymentTypeRadioOption) => void;
  onBlur?: () => void;
  error?: boolean;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
  cols?: {
    mobile: 1 | 2;
    tablet: 1 | 2;
    desktop: 1 | 2;
  };
  testId?: string;
  isMta?: boolean;
};

export const PaymentTypeRadio = ({
  id,
  name,
  options,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  testId,
  variant = 'primary',
  cols,
  isMta,
}: PaymentTypeRadioProps) => {
  return (
    <div className="w-full" data-testid={testId}>
      <HeadlessRadioSelect
        data-testid={id}
        name={name}
        value={options.find(o => o.id === value?.id)}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      >
        <div
          className={clsx(
            'grid relative gap-6',
            { 'grid-cols-1 md:grid-cols-2': !cols },
            { 'grid-cols-1': cols?.mobile === 1 },
            { 'grid-cols-2': cols?.mobile === 2 },
            { 'sm:grid-cols-1': cols?.tablet === 1 },
            { 'sm:grid-cols-2': cols?.tablet === 2 },
            { 'lg:grid-cols-1': cols?.desktop === 1 },
            { 'lg:grid-cols-2': cols?.desktop === 2 }
          )}
        >
          {options.map(option => (
            <HeadlessRadioSelect.Option
              data-testid={option.testId}
              key={option.id}
              value={option}
              className={({ checked }) =>
                clsx(
                  'flex-col gap-3 items-center py-[14px] px-4 w-full min-h-[3.5rem] rounded-lg border-2 transition-colors lg:px-8 lg:min-h-[4rem]',
                  {
                    'border-error-border text-main-content-1':
                      error && variant === 'primary',
                  },
                  {
                    'border-error-border text-popup-content-1':
                      error && variant === 'secondary',
                  },
                  {
                    'border-main-content-3 text-main-content-1':
                      !checked && !error && variant === 'primary',
                  },
                  {
                    'border-popup-selector-border text-popup text-popup-content-1':
                      !checked && !error && variant === 'secondary',
                  },
                  {
                    'bg-selector-active border-selector-border-1 text-selector-active-text':
                      checked && variant === 'primary',
                  },
                  {
                    'bg-popup-selector-active border-popup-selector-active-border text-popup-selector-active-text':
                      checked && variant === 'secondary',
                  },
                  { 'opacity-40': disabled },
                  { 'cursor-pointer': !disabled }
                )
              }
            >
              {({ checked }) => (
                <>
                  <div className="flex gap-3 justify-between items-center w-full">
                    <div
                      className={clsx(
                        'flex shrink-0 justify-center items-center w-6 h-6 rounded-full border-2 transition-colors',
                        {
                          'border-selector-border-1': variant === 'primary' && !checked,
                        },
                        {
                          'border-popup-selector-active':
                            variant === 'secondary' && !checked,
                        },
                        {
                          'bg-transparent': !checked,
                          'bg-white border-white': checked,
                        }
                      )}
                    >
                      <Icon
                        name="check"
                        className={clsx(
                          { 'text-selector-active': variant === 'primary' },
                          { 'text-popup-selector-active': variant === 'secondary' },
                          {
                            invisible: !checked,
                          }
                        )}
                      />
                    </div>
                    <HeadlessRadioSelect.Label as="div" className="w-full truncate">
                      <div className="flex justify-between items-center w-full">
                        <p className="font-raleway font-semibold">{option.name}</p>
                        <FormattedPrice
                          price={parseFloat(parseFloat(option.price).toFixed(2))}
                          size="LG"
                          sufix={getPriceSufix(option.isAnnual, option.isMta)}
                        />
                      </div>
                    </HeadlessRadioSelect.Label>
                  </div>
                  {option.details && (
                    <div className="mt-2">
                      {!isMta && (
                        <OptionDetail title="Deposit" value={option.details.deposit} />
                      )}
                      <OptionDetail
                        title="Amount to be financed"
                        value={option.details.amountToBeFinanced}
                      />
                      {option.details.intrestRate && (
                        <OptionDetail
                          title="Interest rate"
                          value={option.details.intrestRate}
                        />
                      )}
                      {option.details.representativeAPR && (
                        <OptionDetail
                          title="Representative APR"
                          value={option.details.representativeAPR}
                        />
                      )}
                      <OptionDetail
                        title={`${option.details.numberOfInstallments} monthly payment${
                          option.details.numberOfInstallments === 1 ? '' : 's'
                        }`}
                        value={option.details.monthlyCost}
                      />
                      <OptionDetail
                        title="Total amount payable"
                        value={option.details.total}
                      />
                    </div>
                  )}
                </>
              )}
            </HeadlessRadioSelect.Option>
          ))}
        </div>
      </HeadlessRadioSelect>
    </div>
  );
};
