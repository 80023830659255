import { ListboxOption, Tooltip } from '@dayinsure/components';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { FormListBox } from '../../../../components';
import { useImmobiliserQuery } from '../../../../hooks';
import { baseOptionDtoToViewOptions } from '../../../../helpers';
import { QuoteJourneyFormData } from '../../../../types';

type ImmobiliserListProps = {
  testId: string;
};

export const Immobilisers = ({ testId }: ImmobiliserListProps) => {
  const {
    setFieldTouched,
    setFieldValue,
    values: {
      carSecurity: { immobiliser },
    },
  } = useFormikContext<QuoteJourneyFormData>();
  const { data } = useImmobiliserQuery();

  const immobiliserOptions = useMemo(
    () => data?.map(baseOptionDtoToViewOptions(`${testId}_immobilisers`)) || [],
    [data, testId]
  );

  const handleImmobiliserChange = (option: ListboxOption) => {
    if ((!immobiliser && option.id !== '5') || option.id === '5') {
      setFieldTouched('carSecurity.installationProof', false);
      setFieldValue('carSecurity.installationProof', null);
    }
  };

  return (
    <>
      <h3
        data-testid={`${testId}_immobilisers_title`}
        className="relative w-full font-raleway font-normal form-subtitle-compact"
      >
        Which immobiliser do you have?
        <div className="inline-block ml-2 sm:!relative">
          <Tooltip
            ariaLabel="hint"
            className="inline-flex top-[72px] flex-col sm:top-8 text-popup-content-1"
            position="relative"
            testId={`${testId}_immobilisers_tooltip`}
            variant="annual"
          >
            <h4 className="font-bold">S5 and S5 Plus security systems</h4>
            <b>What’s the difference between a S5 and S5 Plus security system?</b>
            An S5 Plus system has all the benefits of a Thatcham S5 vehicle tracking
            system, with an additional immobilisation function. This can inhibit the
            engine and prevent it from being started.
            <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
              Got it
            </Tooltip.Close>
          </Tooltip>
        </div>
      </h3>
      <div className="flex relative flex-col mb-6 md:mb-12">
        <FormListBox
          onChangeOptional={handleImmobiliserChange}
          unselectedMessage="Please select"
          name="carSecurity.immobiliser"
          options={immobiliserOptions}
          id={`${testId}_immobilisers_select`}
          testId={`${testId}_immobilisers_select`}
          icon="alarm"
          label="Immobiliser"
        />
      </div>
    </>
  );
};
