import { Card } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';

type PurchaseDateCardProps = {
  testId: string;
  purchaseDate: string;
};

export const PurchaseDateCard = ({ testId, purchaseDate }: PurchaseDateCardProps) => (
  <Card testId={`${testId}_purchase-date-card`} classNames="w-full mb-6">
    <h3 className="font-raleway text-lg font-normal">Purchase date</h3>
    <Card.CardListItem
      label="Purchase date"
      icon="date"
      testId={`${testId}_purchase-date-card_list-item_purchase-date`}
    >
      {formatInUKLocalTime('MM/yyyy', purchaseDate)}
    </Card.CardListItem>
  </Card>
);
