import { Card } from '@dayinsure/components';
import { VehicleDto } from '../../../../../api/v1';

type NewRegCarDetailsCardsProps = {
  testId: string;
  vehicle: VehicleDto;
};

export const NewRegCarDetailsCards = ({
  vehicle: {
    model,
    manufactureYear,
    primaryFuelType,
    transmission,
    numberOfDoors,
    make,
    cubicCapicityInLitres,
  },
  testId,
}: NewRegCarDetailsCardsProps) => {
  const engineSize =
    cubicCapicityInLitres && cubicCapicityInLitres !== 0 ? cubicCapicityInLitres : '';

  return (
    <Card testId={`${testId}_car-details-card`} classNames="my-8 md:my-16">
      <div className="flex justify-between">
        <h3 data-testid={`${testId}_title`} className="font-raleway text-lg font-normal">
          Your current car
        </h3>
      </div>
      <Card.CardListItem
        testId={`${testId}_vehicle-make-and-model-item`}
        label="Car details"
        icon="car"
      >
        {`${make} ${model} ${engineSize}`}
      </Card.CardListItem>

      <Card.CardListItem
        testId={`${testId}_vehicle-year-of-manufacture-item`}
        label="Year of manufacture"
        icon="date"
      >
        {manufactureYear}
      </Card.CardListItem>
      <Card.CardListItem
        testId={`${testId}_vehicle-fuel-type-item`}
        label="Fuel type"
        icon="funnel"
      >
        {primaryFuelType?.description || primaryFuelType?.code}
      </Card.CardListItem>
      <Card.CardListItem
        testId={`${testId}_vehicle-transmission-item`}
        label="Transmission"
        icon="spanner"
      >
        {transmission?.description || transmission?.code}
      </Card.CardListItem>
      <Card.CardListItem
        testId={`${testId}_vehicle-doors-item`}
        label="Number of doors"
        icon="door"
      >
        {numberOfDoors}
      </Card.CardListItem>
    </Card>
  );
};
