import { Icon } from '@dayinsure/components';
import { LinkWithReferrer } from '@dayinsure/shared';

type NewQuoteButtonProps = {
  text: string;
};

const NewQuoteButton = ({ text }: NewQuoteButtonProps) => (
  <LinkWithReferrer
    id="header-get-new-quote-btn"
    className="box-border inline-flex gap-x-2 justify-center items-center px-4 h-8 font-raleway text-sm font-semibold bg-transparent rounded-lg border-2 text-header-content-1 border-header-content-1"
    to="/quote"
  >
    <Icon name="policies" size="0.75rem" />
    <span>{text}</span>
  </LinkWithReferrer>
);

export { NewQuoteButton };
