/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MethodImplAttributes {
  IL = 'IL',
  MANAGED = 'Managed',
  NATIVE = 'Native',
  OPTIL = 'OPTIL',
  RUNTIME = 'Runtime',
  CODE_TYPE_MASK = 'CodeTypeMask',
  UNMANAGED = 'Unmanaged',
  MANAGED_MASK = 'ManagedMask',
  NO_INLINING = 'NoInlining',
  FORWARD_REF = 'ForwardRef',
  SYNCHRONIZED = 'Synchronized',
  NO_OPTIMIZATION = 'NoOptimization',
  PRESERVE_SIG = 'PreserveSig',
  AGGRESSIVE_INLINING = 'AggressiveInlining',
  AGGRESSIVE_OPTIMIZATION = 'AggressiveOptimization',
  INTERNAL_CALL = 'InternalCall',
  MAX_METHOD_IMPL_VAL = 'MaxMethodImplVal',
}