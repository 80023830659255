import {
  Navigate as ReactRouterNavigate,
  NavigateProps,
  Path,
  To,
} from 'react-router-dom';
import { useSearchParamsToBuildPermittedQueryParams } from '../../../helpers';

const isPath = (to: To): to is Partial<Path> => {
  return typeof to !== 'string';
};

// This component wraps the react-router Navigate comnponent, but preserves the referrer query paramaters.
// This is important so that we don't lose the theme between navigations
export const NavigateWithReferrer = ({ to, ...props }: NavigateProps) => {
  const permittedQueryParams = useSearchParamsToBuildPermittedQueryParams();

  if (permittedQueryParams === '') {
    return <ReactRouterNavigate to={to} {...props} />;
  }
  if (isPath(to)) {
    return (
      <ReactRouterNavigate
        to={{
          pathname: to.pathname,
          search: to.search
            ? `${to.search}&${permittedQueryParams}`
            : `${permittedQueryParams}`,
          hash: to.hash,
        }}
        {...props}
      />
    );
  }

  return (
    <ReactRouterNavigate
      to={
        to.includes('?')
          ? `${to}&${permittedQueryParams}`
          : `${to}?${permittedQueryParams}`
      }
      {...props}
    />
  );
};
