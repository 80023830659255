import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Referrer, Referrers } from '../types';

const getReferrer = <T extends string>(referrers: Referrers<T>, referrerName: string) => {
  return Object.values(referrers).find(
    ref => ref.name.toLowerCase() === referrerName.toLowerCase()
  );
};

const getReferrerNameFromOidcState = (oidcStateCode: string) => {
  const oidcStateString = localStorage.getItem(`oidc.${oidcStateCode}`);
  const referrerName = oidcStateString
    ? JSON.parse(oidcStateString)?.data?.referrerName
    : undefined;
  return referrerName || 'dayinsure';
};

export const useReferrerFetcher = <T extends string>(
  referrers: Referrers<T>,
  defaultReferrerName = 'Dayinsure'
): Referrer<T> => {
  const location = useLocation();
  const [searchParams] = useSearchParams({ referrer: '' });

  const determineReferrer = useCallback(() => {
    let referrerName = defaultReferrerName;
    const referrerParam = searchParams.get('referrer');
    const oidcStateParam = searchParams.get('state');

    if (referrerParam) {
      referrerName = referrerParam;
    } else if (oidcStateParam) {
      referrerName = getReferrerNameFromOidcState(oidcStateParam);
    }

    return getReferrer(referrers, referrerName) || referrers.dayinsure;
  }, [referrers, defaultReferrerName, searchParams]);

  const [referrer, setReferrer] = useState(determineReferrer());

  useEffect(() => {
    setReferrer(determineReferrer());
  }, [determineReferrer, location]);

  return referrer;
};
