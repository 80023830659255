import { Driver } from '../../../../../types/mtaJourneyForm';
import { DriverFormData } from '../../../../../types';
import { isYes } from '../../../../../helpers';

export const isDriverType = (driver: Driver | DriverFormData): driver is Driver =>
  'isPolicyholder' in driver;

export const isDriverPolicyHolder = (driver: Driver | DriverFormData) =>
  isDriverType(driver) && driver.isPolicyholder;

export const isMainDriver = <T extends Driver | DriverFormData>(driver: T) => {
  const isDriver = isDriverType(driver);

  if (isDriver) {
    return isDriver && driver.isMainDriver;
  }

  return isYes(driver.isMainDriver?.id);
};
