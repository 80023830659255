import { Routes, Route, Outlet } from 'react-router-dom';
import { QuoteJourney, QuoteJourneyWrapper } from '../pages/Quote/QuoteJourney';
import { QuoteJourneyRoutes } from './routes';
import {
  RegistrationSearch,
  YourCar,
  CarUsage,
  YourDetails,
  YourHome,
  YourLicenseAndHistory,
  AdditionalDriverDetails,
  Drivers,
  AdditionalDriverWrapper,
  AdditionalDriverLicenceAndHistory,
  Cover,
  YourQuote,
  Review,
  Payment,
  PurchaseComplete,
  RootPage,
  PolicyConditions,
  CarSecurity,
} from '../pages';
import { QuoteJourneyGuard } from '../components';
import { Empty } from '../pages/Empty';

export const QuoteRoutes = () => (
  <QuoteJourneyWrapper>
    <Routes>
      <Route path="/" element={<QuoteJourney />}>
        <Route index element={<RootPage />} />
        <Route
          path={QuoteJourneyRoutes.RegistrationSearch}
          element={<RegistrationSearch />}
        />
        <Route
          path={QuoteJourneyRoutes.CarDetails}
          element={
            <QuoteJourneyGuard redirect="/quote">
              <YourCar />
            </QuoteJourneyGuard>
          }
        />
        <Route
          path={QuoteJourneyRoutes.CarUsage}
          element={
            <QuoteJourneyGuard redirect="/quote">
              <CarUsage />
            </QuoteJourneyGuard>
          }
        />
        <Route
          path={QuoteJourneyRoutes.YourDetails}
          element={
            <QuoteJourneyGuard redirect="/quote">
              <YourDetails />
            </QuoteJourneyGuard>
          }
        />
        <Route
          path={QuoteJourneyRoutes.YourHome}
          element={
            <QuoteJourneyGuard redirect="/quote">
              <YourHome />
            </QuoteJourneyGuard>
          }
        />
        <Route
          path={QuoteJourneyRoutes.YourLicenceAndHistory}
          element={
            <QuoteJourneyGuard redirect="/quote">
              <YourLicenseAndHistory />
            </QuoteJourneyGuard>
          }
        />
        <Route
          path={QuoteJourneyRoutes.Drivers}
          element={
            <QuoteJourneyGuard redirect="/quote">
              <Outlet />
            </QuoteJourneyGuard>
          }
        >
          <Route index element={<Drivers />} />
          <Route
            path={QuoteJourneyRoutes.AdditionalDriverId}
            element={<AdditionalDriverWrapper />}
          >
            <Route
              path={QuoteJourneyRoutes.DriverDetails}
              element={<AdditionalDriverDetails />}
            />
            <Route
              path={QuoteJourneyRoutes.DriverLicenceAndHistory}
              element={<AdditionalDriverLicenceAndHistory />}
            />
          </Route>
        </Route>
        <Route
          path={QuoteJourneyRoutes.Cover}
          element={
            <QuoteJourneyGuard redirect="/quote">
              <Cover />
            </QuoteJourneyGuard>
          }
        />
        <Route
          path={QuoteJourneyRoutes.QuoteId}
          element={
            <QuoteJourneyGuard redirect="/quote">
              <Outlet />
            </QuoteJourneyGuard>
          }
        >
          <Route path={QuoteJourneyRoutes.CarSecurity} element={<CarSecurity />} />
          <Route
            path={QuoteJourneyRoutes.CarSecurityPolicyConditions}
            element={
              <PolicyConditions
                conditionsCodesToDisplay={['202', '205', '208']}
                checkboxPath="endorsementsConfirmCheckbox"
                customBackLink={`../../${QuoteJourneyRoutes.Cover}`}
              />
            }
          />
          <Route path={QuoteJourneyRoutes.YourQuote} element={<YourQuote />} />
          <Route path={QuoteJourneyRoutes.Review} element={<Review />} />
          <Route path={QuoteJourneyRoutes.Payment} element={<Payment />} />
          <Route
            path={QuoteJourneyRoutes.PurchaseComplete}
            element={<PurchaseComplete />}
          />
          <Route
            path={QuoteJourneyRoutes.PolicyConditions}
            element={<PolicyConditions />}
          />
        </Route>
        <Route path={QuoteJourneyRoutes.Empty} element={<Empty />} />
      </Route>
    </Routes>
  </QuoteJourneyWrapper>
);
