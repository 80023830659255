import { Card, Icon } from '@dayinsure/components';

type TimeIsApproachingCardProps = {
  testId: string;
};

export const TimeIsApproachingCard = ({ testId }: TimeIsApproachingCardProps) => {
  return (
    <Card
      testId={`${testId}_time-is-approaching_card`}
      paddingLevel="small"
      classNames="!bg-popup-background flex items-center gap-4 md:gap-6 text-left"
    >
      <Icon name="warning" size="2rem" className="text-popup-content-1" />
      <span>
        The changes start date and time you selected is approaching. If payment is not
        processed in time, your cover date and time may change, to start after the payment
        date.
      </span>
    </Card>
  );
};
