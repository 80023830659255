import { ErrorDialog, LoadingSpinner, PageContainer } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OpenAPI, Dayinsure } from '../../../../api/v2';
import { useReferrer } from '../../../../hooks';

export const Verification = () => {
  const referrer = useReferrer();
  const [params] = useSearchParams();
  const navigate = useNavigateWithReferrer();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  useEffect(() => {
    const personId = params.get('personId');
    const emailConfirmationToken = params.get('emailVerificationToken');
    if (personId && emailConfirmationToken) {
      const api = new Dayinsure(OpenAPI);

      api.person
        .postApiV2PersonVerifyEmailAddress(personId, {
          emailConfirmationToken,
          tenant: referrer.identityProviderName,
          referrer: referrer.name,
        })
        .then(response => {
          if (!response.isSuccess) {
            navigate('failure', { state: { personId, emailConfirmationToken } });
          } else {
            navigate('success');
          }
        })
        .catch(() => {
          navigate('failure', { state: { personId, emailConfirmationToken } });
        });
    } else {
      setErrorDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    navigate('/sign-up');
  };

  return (
    <PageContainer className="pt-6">
      <LoadingSpinner centered className="mt-12" />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        title="Sorry, there was a problem"
        error="There was a problem sending your verification email"
        ctaId="close-account-details-error"
        ctaMessage="Go back and try again"
      />
    </PageContainer>
  );
};
