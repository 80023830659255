import { YesNoAnswer, YesNoDataEntry } from '../../../types';

export const getYesNoOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_option-no`,
  },
];
