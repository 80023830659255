import { Card, Icon } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { VehicleDetailItem } from '../../../../components';
import { VehicleDto } from '../../../../api/v1';
import { transformForCommaAndNoCommaValue } from '../../../../helpers';

type CarSummaryCardProps = {
  editLink?: string;
  vehicle?: VehicleDto;
  testId: string;
};

export const CarSummaryCard = ({ editLink, vehicle, testId }: CarSummaryCardProps) => {
  const navigate = useNavigateWithReferrer();
  const carValueComma = vehicle?.selfDeclaredVehicleValue?.amount
    ? transformForCommaAndNoCommaValue(String(vehicle?.selfDeclaredVehicleValue?.amount))
    : null;
  if (!vehicle) {
    return null;
  }

  const onEdit = () => editLink && navigate(editLink);
  const vehicleData = `${vehicle?.make} ${vehicle?.model}`;

  return (
    <Card testId={`${testId}_card`} classNames="w-full bg-content-background-prominent">
      <div className="flex justify-between">
        <h3 className="font-raleway text-lg font-normal">Car</h3>
        {editLink && (
          <button
            className="flex items-center text-sm font-semibold leading-[14px]"
            data-testid={`${testId}_edit-button`}
            onClick={onEdit}
            type="button"
          >
            <Icon name="edit" className="mr-2" size="12px" />
            Edit
          </button>
        )}
      </div>
      <VehicleDetailItem
        testId={`${testId}_vehicle-reg-number`}
        title={vehicle?.registrationNumber || ''}
        className="mt-4"
      >
        {vehicleData}
      </VehicleDetailItem>
      {vehicle.selfDeclaredVehicleValue?.amount && (
        <Card.CardListItem
          label="Estimated value"
          icon="chart"
          testId="car-details-card_vehicle-estimated-value"
        >
          {`£${carValueComma?.valueWithComa}`}
        </Card.CardListItem>
      )}
    </Card>
  );
};
