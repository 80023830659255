import { Button } from '@dayinsure/components';
import { useCancelMTAChangesContext } from '../../../contexts/CancelMTAChangesContext';

type MtaCancelButtonProps = {
  testId: string;
};

export const MtaCancelButton = ({ testId }: MtaCancelButtonProps) => {
  const { openCancelDialog } = useCancelMTAChangesContext();

  return (
    <Button
      onClick={openCancelDialog}
      id={`${testId}_go-back_button`}
      testId={`${testId}_go-back_button`}
      fullWidth
      text="Cancel changes"
      variant="secondary"
    />
  );
};
