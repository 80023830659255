import { Params } from 'react-router-dom';
import { PaymentFrequencyDataEntry, QuoteJourneyFormData } from '../../types';
import { PlaceOrderRequestDto } from '../../api/v1';
import { MtaJourneyFormData } from '../../types/mtaJourneyForm';
import { PaymentPlansEnum } from '../../constants/payments';
import { isMonthlyPaymentPlan } from '../utils';

export const translateSelectedPaymentPlan = (plan: PaymentFrequencyDataEntry | null) => {
  if (plan?.id) {
    return PaymentPlansEnum[plan.id];
  }
  return null;
};
export const getQuoteId = (params: Params) => {
  const url = params['*'];
  if (url) {
    return url.split('/')[0];
  }
  return null;
};

const collectBrowserInfo = () => {
  const { userAgent, language } = navigator;
  const { pixelDepth, height, width } = window.screen;
  const timeZoneOffset = new Date().getTimezoneOffset();
  return {
    acceptHeader: 'application/json',
    colorDepth: pixelDepth,
    javaEnabled: navigator.javaEnabled() || false,
    language: language || 'en',
    screenHeight: height,
    screenWidth: width,
    timeZoneOffset,
    userAgent,
  };
};
export const getOrderPayload = (
  method: string,
  formData: QuoteJourneyFormData | MtaJourneyFormData,
  params: Params,
  searchParams: URLSearchParams,
  isRefund: boolean
): PlaceOrderRequestDto => {
  const browserInfo = collectBrowserInfo();
  const {
    encryptedCardNumber,
    encryptedExpiryMonth,
    encryptedExpiryYear,
    encryptedSecurityCode,
    cardholderName,
    accountHolder,
    sortCode,
    bankAccountNumber,
    usualPaymentFrequency,
  } = formData;

  const { quoteId } = params;
  const motorQuoteId = quoteId || getQuoteId(params);
  return {
    ...(isMonthlyPaymentPlan(usualPaymentFrequency?.code?.id) && {
      bankDetails: {
        accountHolderName: accountHolder,
        sortCode,
        accountNumber: bankAccountNumber,
      },
    }),
    motorQuoteId,
    selectedPaymentPlan: translateSelectedPaymentPlan(usualPaymentFrequency.code),
    savePaymentDetails: false,

    ...(!isRefund && {
      paymentDetails: {
        paymentMethod: method,
        holderName: cardholderName,
        cardNumber: encryptedCardNumber,
        expiryMonth: encryptedExpiryMonth,
        expiryYear: encryptedExpiryYear,
        securityCode: encryptedSecurityCode,
      },
    }),
    browserInfo,
    returnUrl: `${window.location.href}${
      searchParams.get('referrer') ? `?referrer=${searchParams.get('referrer')}` : ''
    }`,
    origin: window.location.origin,
    channel: 'web',
  };
};
