import { ApiError as ApiErrorV1, DriverDto, FormattedAddressDto } from '../../api/v1';
import { QuoteJourneyRoutes } from '../../routes';
import { DriverFormData, YesNoAnswer } from '../../types';

export const isYes = (optionId?: YesNoAnswer | null): optionId is YesNoAnswer.YES =>
  optionId === YesNoAnswer.YES;

export const isNo = (optionId?: YesNoAnswer | null): optionId is YesNoAnswer.NO =>
  optionId === YesNoAnswer.NO;

export const isApiDriver = (driver: DriverDto | DriverFormData): driver is DriverDto =>
  typeof driver.isProposer === 'boolean';

export const isApiDrivers = (
  drivers?: Array<DriverFormData> | Array<DriverDto> | null
): drivers is Array<DriverDto> => !!drivers && drivers?.some(isApiDriver);

export const isNonNullable = <T>(entry: T | null | undefined): entry is NonNullable<T> =>
  !!entry;

export const isAddress = (
  address?: FormattedAddressDto
): address is FormattedAddressDto => !!address && Object.keys(address).length !== 0;

export const filterMapParser = <T, R>(
  entries: Array<T> | null | undefined,
  parser: (entry: T, index: number) => R | null | undefined
): Array<NonNullable<R>> | null => {
  if (!entries) {
    return null;
  }

  const parsedEntries = entries.reduce<Array<NonNullable<R>>>(
    (result, current, index) => {
      const parsedCurrent = parser(current, index);
      if (isNonNullable(parsedCurrent)) {
        return [...result, parsedCurrent];
      }
      return result;
    },
    []
  );

  return parsedEntries.length > 0 ? parsedEntries : null;
};

export const isJourneyRoute = <T extends string>(
  maybeRoute?: string | null
): maybeRoute is T => {
  if (!maybeRoute) {
    return false;
  }

  return Object.values<string>(QuoteJourneyRoutes).includes(maybeRoute);
};

export const isApiErrorV1 = (error: unknown): error is ApiErrorV1 => {
  const mappedError = error as ApiErrorV1;
  return (
    mappedError?.url !== undefined &&
    mappedError?.status !== undefined &&
    mappedError?.statusText !== undefined &&
    mappedError?.body !== undefined
  );
};

export const isEnumMember = <T extends Record<string, string>>(
  enumSchema: T,
  maybeMember?: string | null
): maybeMember is T[keyof T] =>
  !!maybeMember && Object.values(enumSchema).includes(maybeMember);
