import { Tooltip } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { FormRadioSelect, FormFieldBox } from '../../../../components';
import { getYesNoOptions } from '../../../../helpers';
import { QuoteJourneyFormData } from '../../../../types';

const approvedTrackerOptions = getYesNoOptions('approved-tracker');

type ApprovedTrackerProps = {
  testId: string;
};

export const ApprovedTracker = ({ testId }: ApprovedTrackerProps) => {
  const { setFieldTouched, setFieldValue } = useFormikContext<QuoteJourneyFormData>();

  const handleApprovedTrackerChange = () => {
    setFieldTouched('carSecurity.antiCloneImmobiliser', false);
    setFieldValue('carSecurity.antiCloneImmobiliser', null);
    setFieldTouched('carSecurity.immobiliser', false);
    setFieldValue('carSecurity.immobiliser', null);
    setFieldTouched('carSecurity.installationProof', false);
    setFieldValue('carSecurity.installationProof', null);
  };

  return (
    <>
      <h3
        className="relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_approved-tracker_title`}
      >
        Does the vehicle have a Thatcham approved S7 or S5 tracker and an active
        subscription?
        <div className="inline-block ml-2 sm:!relative">
          <Tooltip
            ariaLabel="hint"
            className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
            position="relative"
            testId={`${testId}_approved-tracker_tooltip`}
            variant="annual"
          >
            <h4 className="font-bold">Tracker information</h4>
            <b>
              What’s the difference between a Thatcham approved S7 and S5 vehicle tracker?
            </b>
            The Thatcham Security Certification is a programme to assess and recognise
            vehicle security products. The certificate checks the functionality, design,
            and performance of a security product through testing against a preset list of
            requirements.
            <b>S7 tracking systems</b>
            S7 tracking systems enable the vehicle to have its location tracked after a
            theft has been reported to the Police. This can result in the vehicle being
            tracked and recovered.
            <b>S5 tracking systems</b>
            S5 tracking systems have additional security features. So they’ll proactively
            alert a monitoring centre if the authorised driver recognition system is not
            in place when the vehicle is moved.
            <b>S5 Plus vehicle tracking systems</b>
            S5 rated vehicle tracking systems, which are advertised as ‘Plus’ trackers,
            are not part of Thatcham&apos;s certification programme. These systems will
            generally have an additional ‘remote immobilisation’ feature built in. This
            means the system has the ability to receive a remotely sent signal to activate
            the immobiliser. As a result, the engine will not start again until the system
            has been reset.
            <b>Tracker subscriptions</b>
            Both S7 and S5 trackers need a subscription account to be activated by the
            vehicle’s owner. This is done through a mobile application, online, or by
            calling the tracking provider. Subscriptions are usually a service that’s paid
            for on an ongoing basis, after a tracker device is installed or as an included
            part of the tracking system package. An ‘active’ subscription is necessary to
            ensure the vehicle can be monitored and tracked, should it be stolen.
            <b>Manufacturer installed tracking devices</b>
            Not all Manufacturer installed tracking devices are S5 or S7 Thatcham
            approved. Please check if you have a approved device, this can be checked on
            your certificate of installation.
            <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
              Got it
            </Tooltip.Close>
          </Tooltip>
        </div>
      </h3>
      <FormFieldBox>
        <FormRadioSelect
          onChangeOptional={handleApprovedTrackerChange}
          name="carSecurity.approvedTracker"
          options={approvedTrackerOptions}
          id={`${testId}_approved-tracker_input`}
          testId={`${testId}_approved-tracker_input`}
        />
      </FormFieldBox>
    </>
  );
};
