import { useFormikContext } from 'formik';
import { CarDetailsCard as CarDetailsCardView } from '../../../../components';
import { QuoteJourneyFormData } from '../../../../types';

type CarDetailsProps = {
  testId: string;
};

export const CarDetailsCard = ({ testId }: CarDetailsProps) => {
  const {
    values: { vehicle },
  } = useFormikContext<QuoteJourneyFormData>();

  return (
    <CarDetailsCardView
      vehicle={vehicle}
      testId={testId}
    />
  );
};
