import clsx from 'clsx';
import { ReactNode } from 'react';

export type VehicleDetailItemProps = {
  children?: string;
  className?: string;
  link?: ReactNode;
  testId?: string;
  title: string;
  isSummaryCard?: boolean;
};

export const VehicleDetailItem = ({
  children,
  className,
  link,
  testId,
  title,
  isSummaryCard = false,
}: VehicleDetailItemProps) => (
  <>
    <div data-testid={testId} className={clsx('flex justify-between mb-3', className)}>
      <span
        className={clsx('inline-flex py-1 px-2 text-sm rounded bg-content-background', {
          'bg-white/10': isSummaryCard,
        })}
        data-testid={`${testId}_title`}
      >
        {title}
      </span>
      {link}
    </div>
    {children && (
      <span data-testid={`${testId}_content`} className="block">
        {children}
      </span>
    )}
  </>
);
