import { useFormikContext } from 'formik';
import { MtaJourneyFormData } from '../../../../../types/mtaJourneyForm';
import { CarDetailsCard, DetailsCorrect } from '../../../../../components';
import { CarSpecs } from '../../../../Quote/YourCar/CarSpecs';

type CarDetailsCardsProps = {
  testId: string;
};

export const CarDetailsCards = ({ testId }: CarDetailsCardsProps) => {
  const { values } = useFormikContext<MtaJourneyFormData>();

  if (!values.vehicle?.abiCode) {
    return null;
  }

  return (
    <div className="my-8 md:my-16">
      <CarDetailsCard vehicle={values.vehicle} testId={testId} />
      <CarSpecs />
      <DetailsCorrect testId={testId} />
    </div>
  );
};
