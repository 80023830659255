import { FormikErrors, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { getFirstErrorPath } from '../helpers';
import { QuoteJourneyFormData } from '../types';

type UseFocusOnErrorArgs<E> = {
  fieldRef: React.RefObject<E>;
  name: string;
};

export const useFocusOnError = <E extends HTMLElement>({
  fieldRef,
  name,
}: UseFocusOnErrorArgs<E>) => {
  const { errors, submitCount, isValid } = useFormikContext<QuoteJourneyFormData>();
  const prevSubmitCountRef = useRef(submitCount);
  const firstErrorPath = getFirstErrorPath<FormikErrors<QuoteJourneyFormData>>(errors);

  useEffect(() => {
    if (submitCount > prevSubmitCountRef.current && !isValid) {
      if (fieldRef?.current && firstErrorPath === name) {
        fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        fieldRef.current.focus({ preventScroll: true });
      }
      prevSubmitCountRef.current = submitCount;
    }
  }, [submitCount, isValid, fieldRef, name, firstErrorPath]);
};
