import { Card, Carousel, CarouselItem, LoadingSpinner } from '@dayinsure/components';
import { useMemo } from 'react';
import { PolicyState } from '../../../api/v1';
import { PolicySummaryCard } from '../../../components';
import { getActivityDate } from '../../../helpers';
import { useMotorPoliciesSummaryQuery } from '../../../hooks';
import { getPolicyStateDescription } from './PoliciesCarousel.utils';

type PoliciesCarouselProps = {
  testId: string;
};

export const PoliciesCarousel = ({ testId }: PoliciesCarouselProps) => {
  const { data, isLoading } = useMotorPoliciesSummaryQuery();
  const policies = useMemo(
    () =>
      data?.filter(
        policy =>
          policy.state?.toLowerCase() === PolicyState.ACTIVE.toLowerCase() ||
          policy.state?.toLowerCase() === PolicyState.UPCOMING.toLowerCase()
      ) || [],
    [data]
  );

  const renderCarouselItems = () => {
    if (isLoading) {
      return (
        <Card classNames="grow" paddingLevel="small" withBackground={false}>
          <LoadingSpinner
            size="small"
            centered
            testId={`${testId}_policies-carousel_loader`}
          />
        </Card>
      );
    }

    if (policies?.length === 0) {
      return (
        <Card classNames="grow" paddingLevel="small" withBackground={false}>
          <span data-testid={`${testId}_policies-carousel_no-policies`}>
            No active or upcoming policies.
          </span>
        </Card>
      );
    }

    if (policies?.length === 1) {
      return (
        <PolicySummaryCard
          plateNo={policies[0].vehicleRegistration || ''}
          carDetails={policies[0].vehicleDescription || ''}
          progress={policies[0].policyProgress || 0}
          policyState={getPolicyStateDescription(policies[0]?.state)}
          buttonLink={`/policies/${policies[0].id}`}
          testId={`${testId}_policies-carousel_policy-${policies[0].id}`}
          activityDate={getActivityDate(policies[0])}
          withBackground={false}
          buttonLabel="Manage policy"
        />
      );
    }

    return policies.map(policy => (
      <CarouselItem key={policy.id} ariaLabel="policies-next-slide">
        <PolicySummaryCard
          plateNo={policy.vehicleRegistration || ''}
          carDetails={policy.vehicleDescription || ''}
          progress={policy.policyProgress || 0}
          policyState={getPolicyStateDescription(policy?.state)}
          buttonLink={`/policies/${policy.id}`}
          testId={`${testId}_policies-carousel_policy-${policy.id}`}
          activityDate={getActivityDate(policy)}
          withBackground={false}
          buttonLabel="Manage policy"
          policyId={policy.id || ''}
        />
      </CarouselItem>
    ));
  };

  return (
    <div>
      <h2 className="mb-4 font-raleway text-lg">Active and upcoming policies</h2>
      <Carousel
        className="grow"
        nextSlideAriaLabel="policies-next-slide"
        nextSlideButtonId="policies-next-slide"
      >
        {renderCarouselItems()}
      </Carousel>
    </div>
  );
};
