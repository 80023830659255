import {
  ActiveAndUpcomingPolicies,
  Card,
  ContentContainer,
  ExpiredAndCancelledPolicies,
  Icon,
  PageContainer,
} from '@dayinsure/components';

import { LinkWithReferrer, useNavigateWithReferrer } from '@dayinsure/shared';
import { trophy } from '../../../assets';
import { useMotorPolicySummaryQuery } from '../../../hooks';
import { activeAndUpcomingPoliciesCopy, expiredAndCancelledCopy } from './Policies.copy';
import { PolicyState } from '../../../api/v2';
import { getActivityDate } from '../../../helpers';

const testId = 'policies';

const Policies = () => {
  const { data: activeAndUpcoming, isLoading: isLoadingActiveAndUpcoming } =
    useMotorPolicySummaryQuery(undefined, [PolicyState.ACTIVE, PolicyState.UPCOMING]);
  const { data: expiredAndCancelled, isLoading: isLoadingExpiredAndCancelled } =
    useMotorPolicySummaryQuery(undefined, [PolicyState.CANCELLED, PolicyState.EXPIRED]);
  const navigate = useNavigateWithReferrer();

  const handleNavigate = (policyId?: string | null) => {
    if (!policyId) {
      return;
    }
    navigate(`${policyId}`);
  };

  return (
    <PageContainer testId={testId}>
      <ContentContainer>
        <div className="flex flex-col items-center mt-8 mb-16 w-full sm:mt-12 md:mb-24 text-main-content-1">
          <h1
            className="self-center mb-8 text-xl sm:mb-12"
            data-testid={`${testId}_title`}
          >
            Policies
          </h1>

          <LinkWithReferrer id="policies-get-quote-link" to="/quote/registration-search">
            <Card paddingLevel="small" classNames="h-48 w-full sm:w-80 !p-8">
              <img src={trophy} alt="trophy" className="mx-auto mb-5 h-[88px]" />
              <div className="flex justify-center items-center">
                Get a new quote
                <Icon name="arrow-right" size="0.75rem" className="ml-3" />
              </div>
            </Card>
          </LinkWithReferrer>

          <h2
            className="self-start mt-8 mb-6 text-lg sm:mb-8 lg:mt-12"
            data-testid={`${testId}_active-and-upcoming_title`}
          >
            Active and upcoming
          </h2>
          <ActiveAndUpcomingPolicies
            copy={activeAndUpcomingPoliciesCopy}
            isLoading={isLoadingActiveAndUpcoming}
            policies={activeAndUpcoming}
            testId={testId}
            onButtonClick={handleNavigate}
            getActivityDate={getActivityDate}
          />

          <h2
            className=" self-start mt-8 mb-6 text-lg sm:mb-8 lg:mt-12"
            data-testid={`${testId}_previous-and-cancelled_title`}
          >
            Previous and cancelled
          </h2>
          <ExpiredAndCancelledPolicies
            copy={expiredAndCancelledCopy}
            isLoading={isLoadingExpiredAndCancelled}
            policies={expiredAndCancelled}
            testId={testId}
            onButtonClick={handleNavigate}
          />
        </div>
      </ContentContainer>
    </PageContainer>
  );
};

export { Policies };
