import { useContext } from 'react';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { QuoteJourneyFormData } from '../../../types';
import { isNo } from '../../../helpers';
import { PoliciesPaths, QuoteJourneyRoutes } from '../../../routes';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';

export const useCarSecuritySubmit = (mta?: boolean) => {
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);
  const navigate = useNavigateWithReferrer();
  const { id } = useParams();

  return (
    {
      isEndorsement202,
      approvedTracker,
      isEndorsement208,
      antiCloneImmobiliser,
      immobiliser,
      installationProof,
    }: QuoteJourneyFormData['carSecurity'],
    formikHelpers:
      | FormikHelpers<MtaJourneyFormData>
      | FormikHelpers<QuoteJourneyFormData>,
    nextStepSubmitHandler: (
      formikHelpers:
        | FormikHelpers<MtaJourneyFormData>
        | FormikHelpers<QuoteJourneyFormData>
    ) => void
  ) => {
    const handleCloseGlobalError = () => {
      const navLink = mta
        ? `/${PoliciesPaths.Policies}/${id}`
        : `/quote/${QuoteJourneyRoutes.RegistrationSearch}`;
      formikHelpers.resetForm();
      formikHelpers.setSubmitting(false);
      clearGlobalError();
      navigate(navLink);
    };

    const errorConfig = {
      title: 'Please note',
      message: `We’ve looked at the details you’ve given us, and unfortunately, we can’t give you a quote at this time.`,
      cta: mta ? 'Go back to policy info' : 'Go back to vehicle search',
      ctaId: 'quote-decline_cta',
      onClose: handleCloseGlobalError,
    };

    if (isEndorsement202 && isNo(approvedTracker?.id)) {
      return setGlobalError(undefined, errorConfig);
    }

    if (isEndorsement208) {
      if (isNo(antiCloneImmobiliser?.id) || immobiliser?.id === '5') {
        return setGlobalError(undefined, errorConfig);
      }
      if (immobiliser?.id !== '5' && isNo(installationProof?.id)) {
        return setGlobalError(undefined, errorConfig);
      }
    }

    return nextStepSubmitHandler(formikHelpers);
  };
};
