import { useLocation } from 'react-router-dom';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { mtaJourneyMap } from '../../../routes/MtaRoutesMap';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import { PolicyMtaPaths } from '../../../routes';

export const useMtaAdditionalDriverDetailsSubmit = (
  currentMtaStep: PolicyMtaPaths | null
) => {
  const navigate = useNavigateWithReferrer();
  const next = mtaJourneyMap.getNext(currentMtaStep);
  const { pathname } = useLocation();

  return ({ setSubmitting, setTouched }: FormikHelpers<MtaJourneyFormData>) => {
    if (currentMtaStep && next) {
      const route = pathname.replace(currentMtaStep, next);
      navigate(route);
    }
    setSubmitting(false);
    setTouched({}, false);
  };
};
