import { Tooltip } from '@dayinsure/components';

type CoverStartDateTooltipProps = {
  testId: string;
};

export const CoverStartDateTooltip = ({ testId }: CoverStartDateTooltipProps) => (
  <div className="inline-block h-4 sm:!relative">
    <Tooltip
      ariaLabel="hint"
      className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
      position="relative"
      testId={testId}
      variant="annual"
    >
      <h4 className="mb-2 font-bold">Cover start date</h4>
      <p className="mb-2">
        Tell us the date you&apos;d like your policy to start. It&apos;s worth
        double-checking when your current policy expires as otherwise there may be a gap,
        leaving you uninsured.
      </p>
      <p>
        Please note: You can only select a cover start date within 30 days from today.
      </p>
      <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
        Got it
      </Tooltip.Close>
    </Tooltip>
  </div>
);
