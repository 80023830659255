import { useRef } from 'react';
import { useField } from 'formik';
import {
  FormTextField as FormTextFieldView,
  IconNames,
  TextFieldProps,
} from '@dayinsure/components';
import { numberInputOnWheelPreventChange } from '@dayinsure/shared';
import { useFocusOnError } from '../../../hooks';

export type FormTextFieldProps = {
  label: {
    icon: IconNames;
    text: string;
  };
  testId: string;
  tooltip?: string;
  tooltipHint?: string;
  tooltipCloseButton?: string;
  spacingClassName?: string;
  uppercase?: boolean;
} & Omit<TextFieldProps, 'label' | 'error' | 'onChange'>;

export const FormTextField = ({
  testId,
  label,
  name,
  placeholder,
  tooltipHint = 'hint',
  tooltipCloseButton = 'Close',
  onBlur,
  type,
  uppercase,
  ...props
}: FormTextFieldProps) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [field, meta] = useField({ name });
  useFocusOnError({ fieldRef, name });
  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    field.onBlur({ target: { name: field.name } });
    onBlur?.(event);
  };

  const handleWheelEvent =
    type === 'number' ? numberInputOnWheelPreventChange : undefined;

  return (
    <FormTextFieldView
      {...props}
      {...field}
      type={type}
      ref={fieldRef}
      error={meta.error}
      testId={testId}
      label={label}
      name={name}
      placeholder={placeholder}
      touched={meta.touched}
      tooltipHint={tooltipHint}
      tooltipCloseButton={tooltipCloseButton}
      value={field.value ?? ''}
      onBlur={handleBlur}
      onWheel={handleWheelEvent}
      uppercase={uppercase}
    />
  );
};
