import { useContext } from 'react';
import { useQuery } from 'react-query';
import { GlobalErrorContext } from '@dayinsure/shared';
import { query } from '../queryKeys';

import {
  Dayinsure as DayInsureAPIv2,
  OpenAPI,
  ApiError,
  PersonResponseV2,
} from '../../../api/v2';

export const usePersonV2Query = (id: string | undefined) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getPerson = () => {
    if (!id) {
      throw new Error('No person ID');
    }
    const api = new DayInsureAPIv2(OpenAPI);
    return api.person.getApiV2Person(id);
  };

  return useQuery<PersonResponseV2, ApiError>(query.person(id || ''), getPerson, {
    enabled: id !== undefined,
    onError: err => {
      // Don't show a global error on 401. We want to handle this manually.
      if (!(err?.status === 401)) {
        setGlobalError(err);
      }
    },
  });
};
