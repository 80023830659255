import { Button, Card } from '@dayinsure/components';
import { Form } from 'formik';
import {
  CarLocation,
  CarOvernightLocation,
  CarUsageMiles,
  CarUsageType,
  QuoteJourneyLayout,
} from '../../../components';

const testId = 'car-usage';

export const CarUsage = () => (
  <QuoteJourneyLayout>
    <h1 className="mb-8 text-xl text-center md:text-left">Car usage</h1>
    <Card
      testId={`${testId}_check-info_card`}
      paddingLevel="small"
      classNames="mb-6 lg:mb-8"
    >
      <span>Please tell us how your car will be used below.</span>
    </Card>
    <div className="mb-16 sm:mb-24">
      <Form>
        <CarUsageType testId={testId} />
        <CarUsageMiles testId={testId} />
        <CarOvernightLocation testId={testId} />
        <CarLocation testId={testId} />
        <Button
          id={`${testId}_continue_button`}
          testId={`${testId}_continue_button`}
          submit
          fullWidth
          icon={{ name: 'arrow-right', position: 'right' }}
          text="Continue"
        />
      </Form>
    </div>
  </QuoteJourneyLayout>
);
