import { ExecessesValues } from '../../../types';
import { ExcessAmountDto } from '../../../api/v1';
import { EXCESSES } from '../../../constants/payments';

export const getVoluntaryExcessesValue = (amount: ExcessAmountDto[]) => {
  const voluntaryAmounts = amount?.find(
    ({ type }: ExcessAmountDto) => type?.code === EXCESSES.policy
  );
  const excessName = voluntaryAmounts?.total;
  return excessName ? `£${excessName}` : `£0`;
};

export const getVoluntaryExcessesValueNumber = (amount: ExcessAmountDto[]) => {
  const voluntaryAmounts = amount?.find(
    ({ type }: ExcessAmountDto) => type?.code === EXCESSES.policy
  );
  return voluntaryAmounts?.total || 0;
};

const getExcessValueNumber = (
  excessAmounts: ExcessAmountDto[] | null | undefined,
  excessType: string
) => {
  const amount = excessAmounts?.find(
    ({ type }: ExcessAmountDto) => type?.code?.toLowerCase() === excessType.toLowerCase()
  );
  return amount?.total || 0;
};
export const getExcessValue = (
  excessAmounts: ExcessAmountDto[] | null | undefined,
  excessType: string
) => {
  const amount = excessAmounts?.find(
    ({ type }: ExcessAmountDto) => type?.code?.toLowerCase() === excessType.toLowerCase()
  );
  return `£${amount?.total || 0}`;
};

export const showExcess = (
  excessAmounts: ExcessAmountDto[] | null | undefined,
  excessType: string
) => {
  const amount = excessAmounts?.find(
    ({ type }: ExcessAmountDto) => type?.code?.toLowerCase() === excessType.toLowerCase()
  );
  return amount?.total && amount?.total > 0;
};

export const getExcessesValues = (
  excessAmounts: ExcessAmountDto[] | null | undefined,
  voluntaryAmounts: ExcessAmountDto[] | null | undefined
): ExecessesValues => {
  return {
    compulsory: getExcessValue(excessAmounts, EXCESSES.policy),
    voluntary: getVoluntaryExcessesValue(voluntaryAmounts || []),
    ...(showExcess(excessAmounts, EXCESSES.windscreenRepaier) && {
      windscreenRepair: getExcessValue(excessAmounts, EXCESSES.windscreenRepaier),
    }),
    ...(showExcess(excessAmounts, EXCESSES.windscreenReplace) && {
      windscreenReplacement: getExcessValue(excessAmounts, EXCESSES.windscreenReplace),
    }),
    ...(showExcess(excessAmounts, EXCESSES.nonApprovedRepair) && {
      nonAapprovedRepair: getExcessValue(excessAmounts, EXCESSES.nonApprovedRepair),
    }),
    ...(showExcess(excessAmounts, EXCESSES.youngDriverKeys) && {
      youngDriverKeys: getExcessValue(excessAmounts, EXCESSES.youngDriverKeys),
    }),
    ...(showExcess(excessAmounts, EXCESSES.theftOfKeys) && {
      theftOfKeys: getExcessValue(excessAmounts, EXCESSES.theftOfKeys),
    }),
  };
};

export const getCompulsoryExcessesValue = (
  amount: ExcessAmountDto[] | null | undefined
) => {
  const compulsoryAmounts = amount?.find(
    ({ type }: ExcessAmountDto) => type?.code === EXCESSES.policy
  );
  const totalAmounts = compulsoryAmounts?.total;
  return totalAmounts ? `£${totalAmounts}` : '';
};

export const getExcessesTotalValue = (
  excessAmounts: ExcessAmountDto[] | null | undefined,
  voluntaryAmounts: ExcessAmountDto[] | null | undefined
) => {
  const compulsoryExcesses = getExcessValueNumber(excessAmounts, EXCESSES.policy);
  const voluntaryExcesses = getVoluntaryExcessesValueNumber(voluntaryAmounts || []);
  return `£${compulsoryExcesses + voluntaryExcesses}`;
};
