import { FormTooltip } from '../../../../../components';

type AccidentOrClaimTooltipProps = {
  testId: string;
};

export const AccidentOrClaimTooltip = ({ testId }: AccidentOrClaimTooltipProps) => {
  return (
    <FormTooltip testId={`${testId}_accident-or-claim`}>
      <h4 className="mb-2 font-bold">
        Accidents, claims and damage in the last five years
      </h4>
      <p className="mb-2">
        You must declare any accidents, claims, losses or damage that occurred within the
        last five years, regardless of whether it involved your car, motorbike, van or any
        other motor vehicle, even where you were not at fault, were driving a different
        vehicle or the incident involved a named driver. <br />
        For example:
      </p>
      <ul className="pl-4 mb-2 list-disc">
        <li>Road traffic accidents</li>
        <li>Fire, flood or storm damage</li>
        <li>Theft of or from a vehicle</li>
        <li>Windscreen/glass claims</li>
      </ul>
      <p>
        Before you buy a policy, we may check your claims history with the Claims and
        Underwriting Exchange. This check helps them to prevent claims fraud.
      </p>
    </FormTooltip>
  );
};
