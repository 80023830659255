import { Button, Card } from '@dayinsure/components';
import { QuoteJourneyLayout } from '../../../components';
import { DriverBasicInfo } from '../../../components/Quote';
import { LiveInUK } from '../YourDetails/LiveInUK';
import { OccupationFields } from '../../../components/OccupationFields';
import { DriverFormData } from '../../../types';

const testId = 'additional-driver-details';

export const AdditionalDriverDetails = () => {
  return (
    <QuoteJourneyLayout>
      <h1
        className="mb-8 text-xl text-center md:text-left"
        data-testid={`${testId}_title`}
      >
        Additional driver’s details
      </h1>
      <Card testId={`${testId}_info_card`} paddingLevel="small" classNames="mb-6 lg:mb-8">
        <span>We need a few details about the additional driver.</span>
      </Card>
      <div className="mb-16 sm:mb-24">
        <DriverBasicInfo<DriverFormData>
          testId={`${testId}_additional-driver_basic-info`}
          relationShipField
        />
        <LiveInUK<DriverFormData>
          testId={`${testId}_additional-driver_live-in-UK`}
          additionalDriver
        />
        <OccupationFields<DriverFormData>
          testId={`${testId}_additional-driver_occupations`}
          additionalDriver
        />
        <Button
          id={`${testId}_continue_button`}
          testId={`${testId}_continue_button`}
          submit
          fullWidth
          icon={{ name: 'arrow-right', position: 'right' }}
          text="Continue"
        />
      </div>
    </QuoteJourneyLayout>
  );
};
