import { Card, Icon } from '@dayinsure/components';

type OpeningHoursCardProps = {
  testId: string;
};

export const OpeningHoursCard = ({ testId }: OpeningHoursCardProps) => {
  return (
    <Card classNames="flex items-center mb-8" paddingLevel="small" testId={testId}>
      <Icon className="text-main-content-1" size="2rem" name="clock" />
      <div className="ml-6 font-raleway text-main-content-1">
        Our opening hours are: Monday–Friday: 9am–8pm. Saturday: 9am–5pm. Sunday: Closed.
      </div>
    </Card>
  );
};
