import clsx from 'clsx';
import { Icon, IconNames } from '@dayinsure/components';

type LabelProps = {
  text: string;
  icon?: IconNames;
  className?: string;
  testId?: string;
};

export const Label = ({ icon, text, className, testId }: LabelProps) => {
  return (
    <h3
      className={clsx('flex items-center mb-2 font-raleway font-normal', className)}
      data-testid={testId}
    >
      {icon && <Icon className="mr-2" name={icon} />}
      {text}
    </h3>
  );
};
