import { SelectRadioOption, Tooltip } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import get from 'lodash.get';
import { useCallback } from 'react';
import { FormDateField, FormRadioSelect } from '../../../../components';
import { getFormFieldAccessPath, isNo } from '../../../../helpers';
import {
  DriverFormData,
  QuoteJourneyFormData,
  YesNoDataEntry,
  YesNoAnswer,
} from '../../../../types';

const getLiveInUKOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_option-no`,
  },
];

type LiveInUkProps = {
  formNameSpaceKey?: string;
  testId: string;
  additionalDriver?: boolean;
};

export const LiveInUK = <T extends QuoteJourneyFormData | DriverFormData>({
  formNameSpaceKey = '',
  testId,
  additionalDriver,
}: LiveInUkProps) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<T>();
  const currentDriver = formNameSpaceKey ? get(values, formNameSpaceKey) : values;

  const ukResidentSincePath = getFormFieldAccessPath([
    formNameSpaceKey,
    'ukResidencyDetails',
    'ukResidentSince',
  ]);

  const isUkResidentSinceBirthPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'ukResidencyDetails',
    'isUkResidentSinceBirth',
  ]);

  const handleLiveInUKChange = useCallback(
    (option: SelectRadioOption) => {
      if (option.id === YesNoAnswer.YES) {
        setFieldValue(ukResidentSincePath, {
          ukResidentSince: { day: '', month: '', year: '', parsedDate: '' },
        });
      }
      setFieldTouched(ukResidentSincePath, false);
    },
    [ukResidentSincePath, setFieldTouched, setFieldValue]
  );

  return (
    <div className="my-6 md:my-12" data-testid={testId}>
      <h3
        data-testid={`${testId}_title`}
        className="relative w-full font-raleway font-normal form-subtitle-compact"
      >
        {additionalDriver
          ? 'Have they lived in the UK continuously since birth?'
          : 'Have you lived in the UK continuously since birth?'}
        <div className="inline-block ml-2 sm:!relative">
          <Tooltip
            ariaLabel="hint"
            className="inline-flex top-[72px] flex-col sm:top-8 text-popup-content-1"
            position="relative"
            testId={testId}
            variant="annual"
          >
            <h4 className="mb-2 font-bold">Length of residency in the UK</h4>
            <p>
              Your length of residency in the UK can help us to understand how much
              experience you have of UK roads.
            </p>
            <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
              Got it
            </Tooltip.Close>
          </Tooltip>
        </div>
      </h3>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          onChangeOptional={handleLiveInUKChange}
          id={`${testId}_radio-input`}
          testId={`${testId}_radio-input`}
          name={isUkResidentSinceBirthPath}
          options={getLiveInUKOptions(testId)}
        />
      </div>
      {isNo(currentDriver?.ukResidencyDetails.isUkResidentSinceBirth?.id) && (
        <div className="mb-4 md:mb-8">
          <FormDateField
            label={{
              icon: 'date',
              text: 'Date moved to UK',
            }}
            testId={`${testId}_live-since-input`}
            id={`${testId}_live-since-input`}
            name={ukResidentSincePath}
            configuration={{
              month: {
                placeholder: 'MM',
              },
              year: {
                placeholder: 'YYYY',
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
