import { Card } from '@dayinsure/components';

type AnitMoneyLaunderingProps = {
  className?: string;
  testId: string;
};

export const AnitMoneyLaundering = ({ className, testId }: AnitMoneyLaunderingProps) => {
  return (
    <Card classNames={className} testId={`${testId}_anti-money-laundering_card`}>
      <h3 className="mb-8 font-raleway text-main-content-1">
        Anti-Money Laundering assessment
      </h3>

      <div className="font-lato text-sm text-main-content-1">
        <p className="mb-4">
          In assessing the customer application Close Brothers Premium Finance will
          undertake further checks.
        </p>

        <p className="mb-4">
          Close Brothers Ltd trading as Close Brothers Premium Finance (CBPF) will carry
          out an Anti-Money Laundering assessment, and may carry out a search with a
          credit reference agency who will keep a record of CBPF enquiry against your name
          and which may be linked to your representatives (“associated records”).
        </p>

        <p className="mb-4">
          For the purposes of any application for products or services from CBPF. you can
          find out more information about how CBPF uses Personal Data at{' '}
          <a
            className="font-semibold underline text-link"
            href="https://www.closebrotherspf.com/customers/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Close Brothers Privacy Policy.
          </a>
        </p>

        <p>
          The assessment will take place after CBPF has received your application. If it
          is unsuccessful CBPF will notify you in writing and you will then need to find
          an alternative way to pay for your insurance premium to avoid the possibility of
          your policy being cancelled.
        </p>
      </div>
    </Card>
  );
};
