import { useParams } from 'react-router-dom';
import { useIsMutating } from 'react-query';
import { useFormikContext } from 'formik';
import { PaymentCard } from '../PaymentCard';
import { useQuoteQuery } from '../../../hooks';
import { QuoteJourneyFormData } from '../../../types';

export const SummaryPaymentCard = () => {
  const { quoteId } = useParams<{ quoteId: string }>();
  const { data, isRefetching } = useQuoteQuery(quoteId);
  const updateQuoteMutationCount = useIsMutating(['update-quote']);
  const isLoading = !!updateQuoteMutationCount || isRefetching;
  const {
    values: { usualPaymentFrequency },
  } = useFormikContext<QuoteJourneyFormData>();

  return (
    <p className="text-sm" data-testid="quote-summary-card_summary">
      {quoteId && data ? (
        <PaymentCard
          addOns={data.addOns}
          insurancePrice={data.insurancePrice}
          paymentPlans={data.paymentPlans}
          cover={data.cover}
          totalPriceIncAddOns={data.totalPriceIncAddOns}
          excesses={data.excesses}
          testId="summmary"
          disabled={isLoading}
          isSummary
          usualPaymentFrequency={usualPaymentFrequency}
        />
      ) : (
        'A price will be shown here, as soon as we have all the info we need from you.'
      )}
    </p>
  );
};
