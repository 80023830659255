import { Tooltip } from '@dayinsure/components';

type InsuranceDeclinedTooltipProps = {
  testId: string;
};

export const InsuranceDeclinedTooltip = ({ testId }: InsuranceDeclinedTooltipProps) => (
  <div className="inline-block ml-2 sm:!relative">
    <Tooltip
      ariaLabel="hint"
      className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
      position="relative"
      testId={`${testId}_insurance-declined`}
      variant="annual"
    >
      <h4 className="mb-2 font-bold">Cancelled, declined or voided policies</h4>
      <p>
        We may decline, cancel or void your insurance for a variety of reasons. Things
        like not being honest when you buy insurance or getting a criminal conviction
        during a policy term.
      </p>
      <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
        Got it
      </Tooltip.Close>
    </Tooltip>
  </div>
);
