import { useSearchParams } from 'react-router-dom';

const permittedParams = [
  'referrer',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'referrerChannel',
];

const useSearchParamsToBuildPermittedQueryParams = () => {
  const [params] = useSearchParams();

  return permittedParams.reduce<string>((result, permittedParamName) => {
    const param = params.get(permittedParamName);
    if (param) {
      const delimeter = result === '' ? '' : '&';
      return result.concat(delimeter, permittedParamName, '=', param);
    }
    return result;
  }, '');
};

export { useSearchParamsToBuildPermittedQueryParams };
