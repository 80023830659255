import { IconNames, ListItem } from '@dayinsure/components';
import { LabelTooltip } from '../../../Common';
import { FormFieldBox } from '../../FormFieldBox';
import { FormListBox } from '../../FormListBox';
import { FieldOption } from '../types';

type FormValuesListFieldArrProps = {
  value: FieldOption[];
  name: string;
  icon: IconNames;
  title: string;
  options: FieldOption[];
  testId: string;
  description?: string;
  onRemove: (option: FieldOption) => void;
};

export const FormValuesListFieldArr = ({
  value,
  name,
  icon,
  title,
  description,
  options,
  testId,
  onRemove,
}: FormValuesListFieldArrProps) => (
  <div>
    {value?.map((val: FieldOption, index: number) => {
      const handleRemoveOption = () => onRemove(val);
      return (
        <FormFieldBox key={val?.id || 'key'}>
          {!val ? (
            <FormListBox
              id={`${testId}-select`}
              testId={`${testId}-select`}
              unselectedMessage="Please select"
              name={`${name}[${index}]`}
              options={options}
              icon={icon}
              label={
                description ? (
                  <LabelTooltip testId={`${testId}-tooltip`} label={title}>
                    {description}
                  </LabelTooltip>
                ) : (
                  title
                )
              }
            />
          ) : (
            <ListItem
              icon={icon}
              title={val?.name}
              subTitle={title}
              onRemove={handleRemoveOption}
            />
          )}
        </FormFieldBox>
      );
    })}
  </div>
);
