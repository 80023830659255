import { MtaJourneyPath } from '../../../routes/MtaRoutesMap';
import { isJourneyRoute } from '../../utils';
import { PolicyMtaPaths } from '../../../routes';

export const getMtaNextStepRoute = (
  currentMtaStep: PolicyMtaPaths | null,
  nextPath: MtaJourneyPath,
  pathname?: string
) => {
  if (!currentMtaStep || !nextPath) {
    return null;
  }
  if (isJourneyRoute<PolicyMtaPaths>(nextPath)) {
    return pathname?.replace(currentMtaStep, nextPath);
  }
  return nextPath;
};
