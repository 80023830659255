import { UsualPaymentFrequency } from '../../../types';
import { PaymentPlanDto } from '../../../api/v1';
import { PaymentPlansEnum, PaymentTypesEnum } from '../../../constants/payments';

export const getCurrentPaymentPlan = (
  usualPaymentFrequency: UsualPaymentFrequency,
  paymentPlans?: PaymentPlanDto[] | null
) => {
  const formPaymentType = usualPaymentFrequency?.code?.id || PaymentTypesEnum.ANNUAL;
  const currentPlan = paymentPlans?.find((plan: PaymentPlanDto) => {
    const code = plan.type?.code;
    const monthly = code?.includes(PaymentPlansEnum.MON);

    if (formPaymentType === PaymentTypesEnum.ANNUAL) {
      return !monthly ? plan : undefined;
    }
    if (formPaymentType === PaymentTypesEnum.MONTHLY) {
      return monthly ? plan : undefined;
    }
    return undefined;
  });
  return { ...currentPlan, shortType: formPaymentType };
};
