import { FormRadioSelect } from '../../../../components';
import { YesNoDataEntry, YesNoAnswer } from '../../../../types';
import { getFormFieldAccessPath } from '../../../../helpers';
import { NonMotoringConvictionsTootltip } from './NonMotoringConvictionsTootltip';

const getNonMotoringConvitionsOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_non-motoring-convictions-option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_non-motoring-convictions-option-no`,
  },
];

type NonMotoringConvictionsProps = {
  testId: string;
  formNameSpaceKey?: string;
  additionalDriver?: boolean;
};

export const NonMotoringConvictions = ({
  testId,
  formNameSpaceKey,
  additionalDriver,
}: NonMotoringConvictionsProps) => {
  const provideOtherVehicleUsePath = getFormFieldAccessPath([
    formNameSpaceKey,
    'hasNonMotoringConvictions',
  ]);
  return (
    <div className="my-6 md:my-16">
      <h3
        data-testid={`${testId}_non-motoring-convictions-label`}
        className="relative w-full font-raleway font-normal form-subtitle-compact"
      >
        {additionalDriver
          ? 'Do they have any unspent non-motoring convictions?'
          : 'Do you have any unspent non-motoring convictions?'}
        <NonMotoringConvictionsTootltip testId={testId} />
      </h3>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          id={`${testId}_non-motoring-convictions-radio`}
          testId={`${testId}_non-motoring-convictions-radio`}
          name={provideOtherVehicleUsePath}
          options={getNonMotoringConvitionsOptions(testId)}
        />
      </div>
    </div>
  );
};
