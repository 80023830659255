import { Referrer, Referrers } from '../../types';

const getReferrer = <T extends string>(
  referrers: Referrers<T>,
  referrerName: string
): Referrer<T> | undefined => {
  return Object.values(referrers).find(
    ref => ref.name.toLowerCase() === referrerName.toLowerCase()
  );
};

const getReferrerNameFromOidcState = (
  oidcStateCode: string,
  defaultReferrerName: string
) => {
  const oidcStateString = localStorage.getItem(`oidc.${oidcStateCode}`);
  const referrerName = oidcStateString
    ? JSON.parse(oidcStateString)?.data?.referrerName
    : undefined;
  return referrerName || defaultReferrerName;
};

export { getReferrer, getReferrerNameFromOidcState };
