import Button from '../../../Inputs/Button/Button';
import Card from '../../../Layout/Card/Card';
import LinearProgressBar from '../../../Progress/LinearProgressBar/LinearProgressBar';
import { Policy } from '../Policies.types';
import LoadingSpinner from '../../../Feedback/LoadingSpinner/LoadingSpinner';
import { PolicyCarDetails } from '../PolicyCarDetails';

export type ActiveAndUpcomingPoliciesCopy = {
  noPolicies: string;
  startsIn: string;
  endsIn: string;
  buttonLabel: string;
};

export type ActiveAndUpcomingPoliciesProps = {
  copy: ActiveAndUpcomingPoliciesCopy;

  isLoading: boolean;
  policies: Policy[] | undefined;
  getActivityDate: (policy: {
    state?: string | null;
    startDate?: string | null;
    endDate?: string | null;
  }) => string;
  testId: string;
  onButtonClick(policyId?: string | null): void;
};

export const ActiveAndUpcomingPolicies = ({
  copy,
  isLoading,
  policies,
  onButtonClick,
  getActivityDate,
  testId,
}: ActiveAndUpcomingPoliciesProps) => {
  const handleClick = (policyId?: string | null) => () => onButtonClick(policyId);

  if (isLoading || !policies) {
    return (
      <Card paddingLevel="small" classNames="w-full bg-content-background-prominent">
        <LoadingSpinner
          size="small"
          centered
          testId={`${testId}_active-and-upcoming_loader`}
        />
      </Card>
    );
  }

  if (!policies || policies.length === 0) {
    return (
      <Card paddingLevel="small" classNames="w-full bg-content-background-prominent">
        <span data-testid={`${testId}_active-and-upcoming_no-policies`}>
          {copy.noPolicies}
        </span>
      </Card>
    );
  }

  return (
    <div className="flex flex-col space-y-6 w-full sm:space-y-8">
      {policies?.map((policy: Policy, index: number) => (
        <Card
          key={policy.id}
          paddingLevel="small"
          classNames="bg-content-background-prominent"
          testId={`${testId}_active-and-upcoming_policy-${policy.id}`}
        >
          <PolicyCarDetails
            vehicleRegistration={policy.vehicleRegistration}
            vehicleDescription={policy.vehicleDescription}
            policyIndex={index}
            testId={`${testId}_active-and-upcoming`}
          />

          <span className="flex mt-6 mb-2 leading-[120%]">
            {policy.state?.toLowerCase() === 'active' ? 'Active policy' : 'Upcoming'}
            <span className="pl-1">{policy.id ? ` (ID ${policy.id})` : ''}</span>
          </span>
          <LinearProgressBar
            id={`active-policy-progress-bar-${index}`}
            progressPercentage={policy.policyProgress}
          />

          <span className="flex mt-2 text-sm leading-[120%] text-main-content-2">
            {getActivityDate(policy)}
          </span>

          <Button
            classNames="mt-6"
            id={`active-dashboard-policies-manage-policy-${index}`}
            text={copy.buttonLabel}
            onClick={handleClick(policy.id)}
          />
        </Card>
      ))}
    </div>
  );
};
