import merge from 'lodash.merge';

export const mergeSelectedValues = <
  T extends Record<string, unknown>,
  S extends Record<string, unknown>
>(
  target: T,
  source: S,
  selectedKeys: string[]
): T => {
  const cloneObj = Object.entries(source).reduce((result, [key, value]) => {
    if (selectedKeys.includes(key)) {
      return {
        ...result,
        [key]: value,
      };
    }

    return result;
  }, {});

  return merge({}, target, cloneObj);
};
