type SubtitleProps = {
  title: string | undefined;
  testId: string;
};

export const Subtitle = ({ title, testId }: SubtitleProps) => {
  if (!title) {
    return null;
  }
  return (
    <h3
      className="relative w-full font-raleway font-normal form-subtitle-compact"
      data-testid={`${testId}_initial-payment-subtitle`}
    >
      {title}
    </h3>
  );
};
