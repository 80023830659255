import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { CarSpecsCard } from './CarSpecsCard';
import { fieldsHaveError } from '../../../../helpers';
import { CarSpecsForm, CarSpecsInputsName } from './CarSpecsForm';
import { QuoteJourneyFormData } from '../../../../types';

export const CarSpecs = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { errors } = useFormikContext<QuoteJourneyFormData>();
  const handleEditing = () => setIsEditing(true);

  useEffect(() => {
    if (fieldsHaveError(CarSpecsInputsName, errors?.vehicle)) {
      handleEditing();
    }
  }, [errors]);

  return (
    <div className="mt-6">
      {isEditing ? <CarSpecsForm /> : <CarSpecsCard handleEditing={handleEditing} />}
    </div>
  );
};
