import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ApiError, Dayinsure, MotorPolicySummaryDto, OpenAPI } from '../../../api/v1';
import { query } from '../queryKeys';

export const useMotorPoliciesSummaryQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getPolicies = () => {
    const api = new Dayinsure(OpenAPI);

    return api.motorPolicy.getApiV1MotorPolicy1();
  };

  return useQuery<MotorPolicySummaryDto[], ApiError>(
    query.motorPoliciesSummary,
    getPolicies,
    {
      retry: (count, error: ApiError) => error.status !== 404 && count < 3,
      onError: err => {
        if (err.status === 422) {
          setGlobalError(err, {
            message: 'Something doesn’t look quite right, please go back and try again.',
          });
          return;
        }
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
