import { FormikHelpers } from 'formik';
import { GlobalErrorContext, useNavigateWithReferrer } from '@dayinsure/shared';
import { useContext } from 'react';
import { VehicleIAPIResult } from '../../../api/v1';
import { QuoteJourneyRoutes } from '../../../routes';
import { YesNoAnswer, QuoteJourneyFormData } from '../../../types';

export const useRegistrationSearchSubmit = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const navigate = useNavigateWithReferrer();

  return (
    {
      setFieldValue,
      setSubmitting,
      setFieldError,
      setTouched,
    }: FormikHelpers<QuoteJourneyFormData>,
    regSearchResponse: Promise<VehicleIAPIResult>
  ) => {
    regSearchResponse
      .then(({ detail }) => {
        if (detail && detail.abiCode) {
          setFieldValue('vehicle.type', {
            id: detail.vehicleOptions?.vehicleType?.code,
            name: detail.vehicleOptions?.vehicleType?.description,
          });
          setFieldValue('vehicle.abiCode', {
            id: detail.abiCode,
            name: detail.abiCode,
          });
          setFieldValue('vehicle.make', {
            id: detail.make,
            name: detail.make,
          });
          setFieldValue('vehicle.model', {
            id: detail.model,
            name: detail.model,
          });
          setFieldValue(
            'vehicle.steeringWheelSide',
            detail.steeringWheelSide
              ? {
                  id: detail.steeringWheelSide.code,
                  name: detail.steeringWheelSide.description,
                }
              : null
          );
          setFieldValue(
            'vehicle.numberOfSeats',
            detail.seats
              ? {
                  id: detail.seats?.toString(),
                  name: detail.seats?.toString(),
                }
              : null
          );
          setFieldValue(
            'vehicle.isImported',
            typeof detail.isImported === 'boolean'
              ? {
                  id: detail.isImported ? YesNoAnswer.YES : YesNoAnswer.NO,
                  name: detail.isImported ? 'Yes' : 'No',
                }
              : null
          );
          setFieldValue('vehicle.manufactureYear', {
            id: String(detail.registrationYear),
            name: String(detail.registrationYear),
          });
          setFieldValue('vehicle.manufactureStartYear', {
            id: String(detail.startYear),
            name: String(detail.startYear),
          });
          setFieldValue('vehicle.manufactureEndYear', {
            id: String(detail.endYear),
            name: String(detail.endYear),
          });
          setFieldValue('vehicle.numberOfDoors', {
            id: String(detail.doors),
            name: String(detail.doors),
          });
          setFieldValue('vehicle.transmission', {
            id: detail.vehicleOptions?.transmission?.code,
            name: detail.vehicleOptions?.transmission?.description,
          });
          setFieldValue('vehicle.primaryFuelType', {
            id: detail.vehicleOptions?.fuelType?.code,
            name: detail.vehicleOptions?.fuelType?.description,
          });
          setFieldValue(
            'vehicle.trackingDevice.hasTrackingDevice',
            typeof detail.hasTrackingDevice === 'boolean'
              ? {
                  id: detail.hasTrackingDevice ? YesNoAnswer.YES : YesNoAnswer.NO,
                  name: detail.hasTrackingDevice ? 'Yes' : 'No',
                }
              : null
          );
          setFieldValue(
            'vehicle.alarmImmobiliser',
            detail.alarmImmobiliser
              ? {
                  id: detail.alarmImmobiliser?.code,
                  name: detail.alarmImmobiliser?.description,
                }
              : null
          );
          setFieldValue('journeyStartPosition', QuoteJourneyRoutes.RegistrationSearch);
          setTouched({}, false);

          navigate(QuoteJourneyRoutes.CarDetails);
        } else {
          setFieldError(
            'vehicle.registrationNumber',
            "Sorry, we can't find your vehicle"
          );
        }
      })
      .catch(e => {
        if (e.status === 404) {
          setFieldError(
            'vehicle.registrationNumber',
            "Sorry, we can't find your vehicle"
          );
        } else {
          setGlobalError(e);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
};
