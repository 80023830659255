import { Tooltip } from '@dayinsure/components';

type NoClaimsDiscountTooltipProps = {
  testId: string;
};

export const NoClaimsDiscountTooltip = ({ testId }: NoClaimsDiscountTooltipProps) => (
  <div className="inline-block ml-2 sm:!relative">
    <Tooltip
      ariaLabel="hint"
      className="inline-flex top-[72px] flex-col sm:top-8 text-popup-content-1"
      position="relative"
      testId={`${testId}_no-claim-discount`}
      variant="annual"
    >
      <h4 className="mb-2 font-bold">No claims bonus</h4>
      <p className="mb-2">
        You get a no-claims bonus for every continuous year you don&apos;t claim on your
        car insurance (on a policy in your name). We will only accept your no-claim bonus:
      </p>
      <ul className="pl-4 mb-2 list-disc">
        <li>when you are the main policyholder for the insurance</li>
        <li>if it&apos;s earned on one car or insurance policy at a time</li>
        <li>if it&apos;s earned on UK policies within the last two years</li>
      </ul>
      <p className="mb-2">
        A no-claims bonus gained from another vehicle (like a motorbike or van), driving
        abroad or a company car might not be eligible.
      </p>
      <h4 className="mb-2 font-bold">How to find out about your no-claims bonus</h4>
      <p>
        Your most recent renewal will indicate how many no-claims bonus years
        you&apos;ve earned. Alternatively, contact your current insurer to find this out.
      </p>
      <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
        Got it
      </Tooltip.Close>
    </Tooltip>
  </div>
);
