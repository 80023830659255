import { useField, useFormikContext } from 'formik';
import { MotorConviction } from '../../../../../../../types/quoteJourneyForm';

export const useConvictionFields = (name: string) => {
  const { validateField, setFieldTouched, setFieldValue, setFieldError } =
    useFormikContext();
  const [convictionField, convictionMeta] = useField<MotorConviction>({ name });
  const [codeField, codeMeta] = useField<MotorConviction['code']>({
    name: `${name}.code`,
  });
  const [dateField, dateMeta] = useField<MotorConviction['date']>({
    name: `${name}.date`,
  });
  const [banReceivedField, banReceivedMeta] = useField<MotorConviction['banReceived']>({
    name: `${name}.banReceived`,
  });
  const [disqualifiedLengthInMonthsField, disqualifiedLengthInMonthsMeta] = useField<
    MotorConviction['disqualifiedLengthInMonths']
  >({
    name: `${name}.disqualifiedLengthInMonths`,
  });
  const [isSavedField, isSavedMeta] = useField<MotorConviction['isSaved']>({
    name: `${name}.isSaved`,
  });

  return {
    conviction: {
      value: convictionField.value,
      meta: convictionMeta,
      fieldName: convictionField.name,
    },
    code: {
      value: codeField.value,
      meta: codeMeta,
      fieldName: codeField.name,
    },
    date: {
      value: dateField.value,
      meta: dateMeta,
      fieldName: dateField.name,
    },
    banReceived: {
      value: banReceivedField.value,
      meta: banReceivedMeta,
      fieldName: banReceivedField.name,
    },
    disqualifiedLengthInMonthsField: {
      value: disqualifiedLengthInMonthsField.value,
      meta: disqualifiedLengthInMonthsMeta,
      fieldName: disqualifiedLengthInMonthsField.name,
    },
    isSaved: {
      value: isSavedField.value,
      meta: isSavedMeta,
      fieldName: isSavedField.name,
    },
    validateField,
    setFieldTouched,
    setFieldValue,
    setFieldError,
  };
};
