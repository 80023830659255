import { useFormikContext } from 'formik';
import { useRef } from 'react';
import { SearchAddressDetails, LabelTooltip } from '../../../../components';
import { QuoteJourneyFormData } from '../../../../types';

type HomeAddressProps = {
  id: string;
  testId: string;
};

export const HomeAddress = ({ id, testId }: HomeAddressProps) => {
  const {
    values: {
      vehicle: {
        location: { overnightLocationPostcode },
      },
    },
    setFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const isVehicleOvernightLocationProvided = useRef<boolean>(!!overnightLocationPostcode);
  const handleFindAddress = (postcode: string) => {
    if (!isVehicleOvernightLocationProvided.current) {
      setFieldValue('vehicle.location.overnightLocationPostcode', postcode);
    }
  };

  return (
    <SearchAddressDetails
      className="mb-6 md:my-12"
      id={id}
      testId={testId}
      postcodeInputName="drivers[0].homePostcode"
      addressInputName="drivers[0].homeAddress"
      addressDetailsInputName="drivers[0].address"
      postcodeLabel={
        <LabelTooltip buttonText="Got it" label="Home postcode" testId={testId}>
          <h4 className="mb-2 font-bold">Your address</h4>
          <p>This is the address where the car will be kept and insured.</p>
        </LabelTooltip>
      }
      onFindAddress={handleFindAddress}
    />
  );
};
