import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';

type PolicyInfoContactCardsProps = {
  testId: string;
  showLinks?: boolean;
  className?: string;
};

export const ContactCards = ({
  testId,
  showLinks = true,
  className,
}: PolicyInfoContactCardsProps) => (
  <>
    <section className={clsx('my-8', className)}>
      <Card
        classNames="flex items-center "
        paddingLevel="small"
        testId={`${testId}_make-claim`}
      >
        <Icon className="text-main-content-1" size="2rem" name="phone" />
        <div className="ml-6 text-main-content-1">
          All claims are handled by Mulsanne. Should you need to make a claim on your
          policy, please call Mulsanne on
          {showLinks ? (
            <a href="tel:0800 0156 166" className="ml-2 font-bold text-link">
              0800 0156 166
            </a>
          ) : (
            ' XXXXXXXXXXX'
          )}
          .
        </div>
      </Card>
    </section>

    <section>
      <Card
        classNames="flex items-center mb-16"
        paddingLevel="small"
        testId={`${testId}_RAC-phone`}
      >
        <Icon className="text-main-content-1" size="2rem" name="phone" />
        <div className="ml-6 text-main-content-1">
          If you have purchased breakdown cover and are unfortunate enough to break down
          please call RAC on
          {showLinks ? (
            <a href="tel:0330 159 0241" className="ml-2 font-bold text-link">
              0330 159 0241
            </a>
          ) : (
            ' XXXXXXXXXXX'
          )}
          .
        </div>
      </Card>
    </section>
  </>
);
