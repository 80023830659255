import { PageContainer } from '@dayinsure/components';
import { LinkCards } from './LinkCards';
import { PoliciesCarousel } from './PoliciesCarousel';

const testId = 'dashboard';

const Dashboard = () => (
  <PageContainer testId={`${testId}-page-container`}>
    <div className="mx-auto sm:min-w-tablet-container lg:min-w-desktop-container lg:max-w-desktop-container text-main-content-1">
      <div className="flex flex-col mt-8 mb-16 w-full sm:mt-12 md:mb-24 text-main-content-1">
        <h1
          className="self-center mb-8 text-xl text-center sm:mb-12"
          data-testid={`${testId}-page-title`}
        >
          Hi there. How can we help you today?
        </h1>
        <LinkCards testId={testId} />
        <PoliciesCarousel testId={testId} />
      </div>
    </div>
  </PageContainer>
);

export { Dashboard };
