import { Card, FormattedPrice, Icon, onEnter } from '@dayinsure/components';
import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  AddOnModelDto,
  CostDto,
  CoverDto,
  CoverResponseDto,
  ExcessesDto,
  InsurancePriceDto,
  PaymentPlanDto,
} from '../../api/v1';
import { PriceBreakdownDialog } from './PriceBreakdownDialog';
import { UsualPaymentFrequency } from '../../types';
import { getExcessesValues } from '../../helpers/forms/getExcessesValue/getExcessesValue';
import {
  getCardPriceSuffix,
  getCurrentPaymentPlan,
  getDisplayPrice,
  isAnnualPaymentPlan,
} from '../../helpers';
import {
  shouldIDisplayCompulsoryExcess,
  shouldIDisplayVoluntaryExcess,
} from '../../helpers/quote/cover';

type PaymentCardProps = {
  paymentPlans: PaymentPlanDto[] | undefined | null;
  insurancePrice: InsurancePriceDto | undefined;
  totalPriceIncAddOns: CostDto | undefined;
  cover: CoverResponseDto | CoverDto | undefined;
  addOns: AddOnModelDto[] | undefined | null;
  excesses: ExcessesDto | undefined;
  testId: string;
  disabled?: boolean;
  isSummary?: boolean;
  title?: string;
  isDirectDebit?: boolean;
  mta?: boolean;
  isRefund?: boolean;
  customSubtitle?: string;
  usualPaymentFrequency: UsualPaymentFrequency;
  classNames?: string;
};
export const PaymentCard = ({
  testId,
  isSummary,
  disabled = false,
  title,
  isDirectDebit = false,
  mta,
  isRefund = false,
  customSubtitle,
  usualPaymentFrequency,
  classNames,
  paymentPlans,
  cover,
  insurancePrice,
  addOns,
  totalPriceIncAddOns,
  excesses,
}: PaymentCardProps) => {
  const coverType = cover?.type?.code || '';
  const displayVoluntaryExcess = shouldIDisplayVoluntaryExcess(coverType);
  const displayCompulsoryExcess = shouldIDisplayCompulsoryExcess(coverType);
  const excessesValues = getExcessesValues(
    excesses?.compulsoryAmounts,
    excesses?.voluntaryAmounts
  );
  const isPlanAnnual = isAnnualPaymentPlan(usualPaymentFrequency?.code?.id);
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentPlan = getCurrentPaymentPlan(usualPaymentFrequency, paymentPlans);
  const displayPrice = getDisplayPrice(isPlanAnnual, currentPlan, totalPriceIncAddOns);
  const handleDialogVisibility = () => setDialogOpen((status: boolean) => !status);

  const renderPriceInfo = useMemo(() => {
    const priceSize = isSummary ? 'XL' : '2XL';
    const paymentPlanPriceSuffic = getCardPriceSuffix(isPlanAnnual, mta);
    const priceSufix = isDirectDebit ? undefined : paymentPlanPriceSuffic;
    const numberOfInstallments = currentPlan?.installmentDetails?.numberOfInstallments;
    const summaryPaymentPlanSubtitle = `${mta && !isPlanAnnual ? 'New' : 'Total price'}`;

    const summarySubtitle = isDirectDebit
      ? 'Price to pay now'
      : summaryPaymentPlanSubtitle;

    const monthlyPaymentPlanSubtitle = mta
      ? 'New monthly payments'
      : `${numberOfInstallments} monthly payments`;

    const annualPaymentPlanSubtitle = mta && isRefund ? 'Total refund' : 'Total price';

    const subtitleFromPlan = !isPlanAnnual
      ? monthlyPaymentPlanSubtitle
      : annualPaymentPlanSubtitle;
    const subtitle = isSummary ? summarySubtitle : subtitleFromPlan;
    const price = isDirectDebit
      ? currentPlan.installmentDetails?.deposit?.amount
      : displayPrice;

    return (
      <>
        <span
          className={clsx(
            'flex gap-2 items-center mb-2 text-sm leading-3 text-white/60',
            {
              'text-main-content-2': !isSummary,
            }
          )}
        >
          <Icon name="policy" />
          {customSubtitle || subtitle}
        </span>
        <FormattedPrice price={price} size={priceSize} lineThrough sufix={priceSufix} />
        <span className="flex gap-2 items-center text-sm leading-4">
          Includes 12% IPT and an admin fee payable to Everydayinsure.
        </span>
        {!isSummary && (
          <>
            <span className="flex gap-2 items-center text-sm leading-4">
              {excessesValues.compulsory && displayCompulsoryExcess
                ? `Compulsory excess ${excessesValues.compulsory}`
                : ''}
            </span>
            <span className="flex gap-2 items-center mb-2 text-sm leading-4">
              {excessesValues.voluntary && displayVoluntaryExcess
                ? `Voluntary excess ${excessesValues.voluntary}`
                : ''}
            </span>
          </>
        )}
        <span
          role="button"
          tabIndex={0}
          className="flex gap-2 items-center my-2 text-sm font-bold leading-3"
          onClick={handleDialogVisibility}
          onKeyDown={onEnter(handleDialogVisibility)}
        >
          <Icon name="info" size="0.75rem" />
          View price breakdown
        </span>
      </>
    );
  }, [
    isSummary,
    isPlanAnnual,
    mta,
    isDirectDebit,
    currentPlan.installmentDetails?.numberOfInstallments,
    currentPlan.installmentDetails?.deposit?.amount,
    isRefund,
    displayPrice,
    customSubtitle,
    excessesValues.compulsory,
    excessesValues.voluntary,
    displayCompulsoryExcess,
    displayVoluntaryExcess,
  ]);

  return (
    <div>
      {title && (
        <h3
          className="relative w-full font-raleway font-normal form-subtitle-compact"
          data-testid={`${testId}_payment-card_title`}
        >
          {title}
        </h3>
      )}
      {!isSummary ? (
        <Card
          testId={`${testId}_cards_info-card`}
          paddingLevel="small"
          classNames={clsx(`mb-6 lg:mb-8 ${classNames}`)}
        >
          <div
            className={clsx('flex relative flex-col items-center', {
              'pointer-events-none text-main-content-4': disabled,
              'text-main-content-1': !disabled,
            })}
          >
            {renderPriceInfo}
          </div>
        </Card>
      ) : (
        <div
          className={clsx({
            'text-white/20 pointer-events-none': disabled,
            'text-white': !disabled,
          })}
        >
          {renderPriceInfo}
        </div>
      )}
      <PriceBreakdownDialog
        testId={testId}
        close={handleDialogVisibility}
        open={dialogOpen}
        addOns={addOns}
        insurancePrice={insurancePrice}
        cover={cover}
        currentPlan={currentPlan}
        totalPriceIncAddOns={totalPriceIncAddOns}
        mta={mta}
      />
    </div>
  );
};
