import { NewQuoteButton } from './NewQuoteButton';
import { LoginButton } from './LoginButton';

type GuestNavigationProps = {
  customerAccessEnabled: boolean;
  isLoginPage: boolean;
  onSignIn: VoidFunction;
};

const GuestNavigation = ({
  customerAccessEnabled,
  isLoginPage,
  onSignIn,
}: GuestNavigationProps) => {
  if (!customerAccessEnabled) {
    return null;
  }

  return isLoginPage ? (
    <NewQuoteButton text="Get a new quote" />
  ) : (
    <LoginButton onClick={onSignIn} text="Log in" />
  );
};

export { GuestNavigation };
