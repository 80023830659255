import { Button, Card, Dialog, Icon } from '@dayinsure/components';

type PendingChangesModalProps = {
  isOpen: boolean;
  onClose(): void;
  testId: string;
};

export const PendingChangesModal = ({
  isOpen,
  testId,
  onClose,
}: PendingChangesModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} title="Make changes to policy">
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
      >
        <Icon name="warning" size="2rem" className="text-popup-content-1" />
        <p>
          You can’t make changes to your policy right now. If you need more help, please
          contact us through{' '}
          <button type="button" className="ml-2 font-bold text-link">
            live chat
          </button>
          .
        </p>
      </Card>

      <Button
        id={`${testId}_modal_back-button`}
        testId={`${testId}_modal_back-button`}
        fullWidth
        onClick={onClose}
        text="Go back"
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};
