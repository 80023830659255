import { forwardRef, FormEventHandler, ReactNode } from 'react';
import clsx from 'clsx';
import Label from '../../DataDisplay/Label/Label';
import Tooltip from '../../Feedback/Tooltip/Tooltip';
import TextField, { TextFieldProps } from '../../Inputs/TextField/TextField';
import FieldError from '../../Feedback/FieldError/FieldError';
import { IconNames } from '../../DataDisplay/Icon/Icon';

export type FormTextFieldProps = {
  id?: string;
  label: {
    icon: IconNames;
    text: string;
  };
  value: string;
  name: string;
  touched?: boolean;
  error?: string;
  spacingClassName?: string;
  tooltip?: string;
  tooltipHint?: string;
  tooltipCloseButton?: string;
  onChange: ((value: string) => void) & FormEventHandler<HTMLInputElement>;
  children?: ReactNode;
  uppercase?: boolean;
} & Omit<TextFieldProps, 'label' | 'error' | 'onChange'>;

export const FormTextField = forwardRef<HTMLInputElement, FormTextFieldProps>(
  (
    {
      label,
      tooltip,
      spacingClassName,
      tooltipHint = '',
      tooltipCloseButton = '',
      touched = false,
      error,
      value,
      name,
      onChange,
      children,
      uppercase,
      ...inputProps
    },
    ref
  ) => (
    <div
      className={clsx(
        'relative',
        { 'form-field-spacing': !spacingClassName },
        spacingClassName
      )}
    >
      <div className="flex space-x-2">
        <Label
          name={label.text}
          icon={label.icon}
          htmlFor={inputProps.id}
          disabled={inputProps.disabled}
          className="mb-4"
        />
        {tooltip && (
          <div className="sm:!relative">
            <Tooltip
              ariaLabel={tooltipHint}
              position="relative"
              className="flex flex-col text-popup-content-1"
              testId={inputProps.testId}
              variant="annual"
            >
              {tooltip}
              <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
                {tooltipCloseButton}
              </Tooltip.Close>
            </Tooltip>
          </div>
        )}
      </div>
      {children || (
        <TextField
          {...inputProps}
          value={value}
          name={name}
          onChange={onChange}
          error={touched && error !== undefined}
          aria-errormessage={`${inputProps.id}-error`}
          ref={ref}
          onBlur={inputProps.onBlur}
          hidden={!!children}
          upperCase={uppercase}
        />
      )}
      {touched && error && (
        <FieldError
          id={`${inputProps.id}-error`}
          error={error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  )
);
