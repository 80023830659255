/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CallingConventions {
  STANDARD = 'Standard',
  VAR_ARGS = 'VarArgs',
  ANY = 'Any',
  HAS_THIS = 'HasThis',
  EXPLICIT_THIS = 'ExplicitThis',
}