import { useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { PolicyMtaPaths } from '../../../routes';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';

export const usePolicyChangeSubmit = () => {
  const navigate = useNavigateWithReferrer();

  return (
    values: MtaJourneyFormData,
    { setSubmitting, setTouched }: FormikHelpers<MtaJourneyFormData>
  ) => {
    if (values.policyChange.changeReg) {
      navigate(PolicyMtaPaths.NewReg);
    } else if (values.policyChange.changeCar) {
      navigate(PolicyMtaPaths.NewCar);
    } else {
      navigate(PolicyMtaPaths.Drivers);
    }

    setSubmitting(false);
    setTouched({}, false);
  };
};
