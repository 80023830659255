import { Button, Card, LinearProgressBar } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';

type PolicySummaryCardProps = {
  buttonLink: string;
  testId: string;
  plateNo: string;
  carDetails: string;
  progress: number;
  i?: number;
  buttonVariant?: 'primary' | 'secondary' | 'dialogSecondary' | 'bordered' | 'ghost';
  policyState?: string;
  activityDate?: string;
  startsAt?: string;
  withBackground?: boolean;
  buttonLabel?: string;
  policyId?: string;
};
export const PolicySummaryCard = ({
  i,
  buttonVariant,
  policyState,
  buttonLink,
  testId,
  activityDate,
  startsAt,
  progress,
  plateNo,
  carDetails,
  withBackground = true,
  buttonLabel = 'View policy info',
  policyId = '',
}: PolicySummaryCardProps) => {
  const index = i || 0;
  const navigate = useNavigateWithReferrer();
  const handleNavigate = () => {
    navigate(buttonLink);
  };
  return (
    <Card paddingLevel="small" classNames="grow" withBackground={withBackground}>
      <div className="w-full" data-testid={testId}>
        <span
          className="inline-flex py-1 px-2 mb-2 font-lato text-sm leading-[120%] rounded bg-main-content-5"
          id={`${testId}_registration-plate-${index}`}
          data-testid={`${testId}_registration-plate-${index}`}
        >
          {plateNo}
        </span>
        <span
          id={`${testId}_car-details-${index}`}
          data-testid={`${testId}_car-details-${index}`}
          className="flex leading-[120%] whitespace-normal"
        >
          {carDetails}
        </span>

        <span
          id={`${testId}_summary-length-${index}`}
          data-testid={`${testId}_summary-length-${index}`}
          className="flex mt-6 mb-2 leading-[120%]"
        >
          {policyState || ''} {policyId ? ` (ID ${policyId})` : ''}
        </span>

        <LinearProgressBar
          id={`${testId}_policy-progress-bar-${index}`}
          data-testid={`${testId}_policy-progress-bar-${index}`}
          progressPercentage={progress}
        />
        {activityDate && (
          <span
            id={`${testId}_activity-date-${index}`}
            data-testid={`${testId}_activity-date-${index}`}
            className="flex mt-2 text-sm leading-[120%] text-main-content-2"
          >
            {activityDate}
          </span>
        )}
        {startsAt && (
          <span
            id={`${testId}_ends-in-${index}`}
            data-testid={`${testId}_ends-in-${index}`}
            className="flex mt-2 text-sm leading-[120%] text-main-content-2"
          >
            {startsAt}
          </span>
        )}

        <Button
          variant={buttonVariant || 'secondary'}
          classNames="w-full sm:w-fit self-endi put shrink-0 mt-6"
          id={`${testId}_manage-policy-${index}`}
          data-testid={`${testId}_manage-policy-${index}`}
          text={buttonLabel}
          onClick={handleNavigate}
        />
      </div>
    </Card>
  );
};
