import { Button, Card, Dialog } from '@dayinsure/components';
import { PackageInformationDetailedDto } from '../../../../../api/v2';

type StandardDetailsModalProps = {
  isOpen: boolean;
  testId: string;
  content: PackageInformationDetailedDto[];
  onClose: () => void;
};

export const StandardDetailsModal = ({
  isOpen,
  testId,
  content,
  onClose,
}: StandardDetailsModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} title="Included as standard">
      <Card
        testId={`${testId}-modal`}
        paddingLevel="small"
        classNames="!h-full flex-1 bg-content-background-prominent mb-6 flex flex-col justify-center gap-4 text-left text-sm"
      >
        {content.map((item, index) => (
          <div key={`item-${index.toString()}`}>
            <div className="mb-2 font-bold">{item.title}</div>
            <div className="mb-2">{item.description}</div>
          </div>
        ))}
      </Card>

      <Button
        id={`${testId}_modal_back-button`}
        testId={`${testId}_modal_back-button`}
        fullWidth
        onClick={onClose}
        text="Close"
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};
