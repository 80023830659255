import { ListboxOption } from '@dayinsure/components';
import { getIn, useFormikContext } from 'formik';
import get from 'lodash.get';
import { useCallback, useMemo } from 'react';
import {
  baseOptionDtoToViewOptions,
  getFormFieldAccessPath,
  STUDENT_EMPLOYMENT_STATUS_CODE,
} from '../../helpers';
import { useEmploymentStatusesQuery } from '../../hooks';
import { DriverFormData, Occupation, QuoteJourneyFormData } from '../../types';
import { LabelTooltip } from '../Common';
import { FormFieldBox, FormListBox } from '../FormsFields';
import { AnotherJobRadio } from './AnotherJobRadio';
import { IndustryJobFields } from './IndustryJobFields';
import {
  anotherJobRadiosStatuses,
  employedAdditionalFieldsStatuses,
} from './OccupationFields.const';
import { StudentJobField } from './StudentJobField';

type OccupationFieldsProps = {
  formNameSpaceKey?: string;
  testId: string;
  additionalDriver?: boolean;
};

export const OccupationFields = <T extends QuoteJourneyFormData | DriverFormData>({
  formNameSpaceKey,
  testId,
  additionalDriver,
}: OccupationFieldsProps) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<T>();
  const { data: employmentStatuses } = useEmploymentStatusesQuery();

  const occupationsPath = getFormFieldAccessPath([formNameSpaceKey, 'occupations']);
  const primaryOccupationPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'occupations[0]',
  ]);
  const primaryOccupationEmploymentTypePath = getFormFieldAccessPath([
    formNameSpaceKey,
    'occupations[0]',
    'employmentStatus',
  ]);

  const isStudent =
    getIn(values, primaryOccupationEmploymentTypePath)?.id ===
    STUDENT_EMPLOYMENT_STATUS_CODE;

  const currentDriver = formNameSpaceKey ? get(values, formNameSpaceKey) : values;
  const occupationsValue = currentDriver?.occupations || undefined;
  const currentEmploymentStatus = occupationsValue
    ? occupationsValue[0].employmentStatus?.name
    : '';
  const currentAnotherJobStatus = occupationsValue
    ? occupationsValue[0].anotherJob?.name === 'Yes'
    : false;
  const defaultValue = useMemo(
    () =>
      occupationsValue && occupationsValue?.length > 0
        ? occupationsValue[0]
        : { employmentStatus: null, type: null, anotherJob: null, industry: null },
    [occupationsValue]
  );

  const employmentStatusesOptions = useMemo(
    () =>
      employmentStatuses?.map(
        baseOptionDtoToViewOptions(`${testId}_employment-status`)
      ) || [],
    [employmentStatuses, testId]
  );

  const changeEmploymentStatusWithAdditionalFields = useCallback(
    (currentObjVal: Occupation) => {
      const additionalFieldsDefault = {
        industry: null,
        type: null,
        anotherJob: null,
        isPrimary: true,
      };
      const newPrimaryOccupation = {
        ...additionalFieldsDefault,
        employmentStatus: currentObjVal?.employmentStatus || null,
      };
      return setFieldValue(primaryOccupationPath, newPrimaryOccupation);
    },
    [primaryOccupationPath, setFieldValue]
  );
  const changeEmploymentStatusWithBasicFields = useCallback(
    (currentObjVal: Occupation, name: string) => {
      const employmentStatus = currentObjVal?.employmentStatus ?? null;
      const basicVal =
        name === 'Voluntary work'
          ? { employmentStatus, anotherJob: null, isPrimary: true }
          : { employmentStatus, isPrimary: true };
      return setFieldValue(primaryOccupationPath, basicVal);
    },
    [primaryOccupationPath, setFieldValue]
  );
  const employmentStatusOnChange = useCallback(
    (value: ListboxOption) => {
      const { name } = value;
      const currentObjVal = occupationsValue && occupationsValue[0];
      setFieldTouched(`${occupationsPath}[0]`, false);
      setFieldTouched(`${occupationsPath}[1]`, false);
      if (
        !anotherJobRadiosStatuses.includes(name) &&
        occupationsValue &&
        occupationsValue[1]
      ) {
        setFieldValue(occupationsPath, [defaultValue]);
      }
      if (employedAdditionalFieldsStatuses.includes(name)) {
        return changeEmploymentStatusWithAdditionalFields(currentObjVal);
      }
      return changeEmploymentStatusWithBasicFields(currentObjVal, name);
    },
    [
      changeEmploymentStatusWithAdditionalFields,
      changeEmploymentStatusWithBasicFields,
      defaultValue,
      occupationsPath,
      occupationsValue,
      setFieldTouched,
      setFieldValue,
    ]
  );

  return (
    <div data-testid={testId}>
      <h3 className="flex relative w-full font-raleway font-normal form-subtitle-compact">
        {additionalDriver ? 'Their main occupation' : 'Your main occupation'}
      </h3>
      <FormFieldBox>
        <FormListBox
          onChangeOptional={employmentStatusOnChange}
          id={`${testId}_employment-status-dropdown`}
          testId={`${testId}_employment-status-dropdown`}
          unselectedMessage="Please select"
          name={primaryOccupationEmploymentTypePath}
          options={employmentStatusesOptions}
          icon="briefcase"
          label={
            <LabelTooltip
              testId={`${testId}_employment-status`}
              label="Employment status"
              buttonText="Got it"
            >
              <h4 className="mb-2 font-bold">Your occupation status</h4>
              <p>
                Please choose the employment status that best reflects your occupation.
              </p>
            </LabelTooltip>
          }
        />
      </FormFieldBox>
      {isStudent && (
        <StudentJobField formNameSpaceKey={formNameSpaceKey} testId={testId} />
      )}
      {!isStudent &&
        currentEmploymentStatus &&
        employedAdditionalFieldsStatuses.includes(currentEmploymentStatus) && (
          <IndustryJobFields
            formNameSpaceKey={formNameSpaceKey}
            testId={testId}
            index={0}
          />
        )}
      {currentEmploymentStatus &&
        anotherJobRadiosStatuses.includes(currentEmploymentStatus) && (
          <>
            <AnotherJobRadio<T>
              formNameSpaceKey={formNameSpaceKey}
              defaultValue={defaultValue}
              testId={testId}
              additionalDriver={additionalDriver}
            />
            {currentAnotherJobStatus && (
              <IndustryJobFields
                formNameSpaceKey={formNameSpaceKey}
                testId={testId}
                index={1}
              />
            )}
          </>
        )}
    </div>
  );
};
