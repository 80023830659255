import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
  OptionCodeDto,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useVehicleOwnerQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getVehicleOwner = () => {
    const api = new DayinsureAPIv2(OpenAPI);

    return api.product.getApiV2ProductVehicleOwner('motor', 'latest');
  };

  return useQuery<OptionCodeDto[], ApiError>(query.vehicleOwner, getVehicleOwner, {
    keepPreviousData: true,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
