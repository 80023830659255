import { formatInUKLocalTime } from '@dayinsure/shared';
import { PolicyState } from '../../../api/v1';

export type PolicyActivityDate = {
  state?: string | null;
  startDate?: string | null;
  endDate?: string | null;
};

export const getActivityDate = (policy?: PolicyActivityDate | null) => {
  if (policy?.state?.toLowerCase() === PolicyState.ACTIVE.toLowerCase()) {
    return `Ends ${formatInUKLocalTime(
      'dd/MM/yyyy',
      policy.endDate
    )} at ${formatInUKLocalTime('HH:mm', policy.endDate)}`;
  }

  if (policy?.state?.toLowerCase() === PolicyState.UPCOMING.toLowerCase()) {
    return `Starts ${formatInUKLocalTime(
      'dd/MM/yyyy',
      policy.startDate
    )} at ${formatInUKLocalTime('HH:mm', policy.startDate)}`;
  }

  return '';
};
