import { useField } from 'formik';
import { AccidentOrClaimType } from '../../../../../../types/quoteJourneyForm';
import { AddAccidentOrClaimForm } from './AddAccidentOrClaimForm';
import { AccidentOrClaimCard } from './AccidentOrClaimCard';

type AccidentsOrClaimsListProps = {
  name: string;
  testId: string;
  shouldDisplayCancelButton: boolean;
  additionalDriver?: boolean;
  onRemove: (accidentOrClaim: AccidentOrClaimType) => void;
};

export const AccidentsOrClaimsListItem = ({
  name,
  testId,
  shouldDisplayCancelButton,
  additionalDriver,
  onRemove,
}: AccidentsOrClaimsListProps) => {
  const [field] = useField<AccidentOrClaimType>({ name });
  const handleRemove = () => onRemove(field.value);
  const isAdding = !field.value.isSaved;

  return isAdding ? (
    <AddAccidentOrClaimForm
      name={name}
      testId={testId}
      onCancel={handleRemove}
      shouldDisplayCancelButton={shouldDisplayCancelButton}
      additionalDriver={additionalDriver}
    />
  ) : (
    <AccidentOrClaimCard
      accidentOrClaim={field.value}
      testId={testId}
      onRemoveAccidentOrClaim={handleRemove}
    />
  );
};
