import { useParams } from 'react-router-dom';
import { Card, ContentContainer, PageContainer } from '@dayinsure/components';
import { useState } from 'react';
import { Form, useFormikContext } from 'formik';

import { ContactCard } from './ContactCard';
import { PolicyChangeButtons } from './Buttons';
import { PolicyChangesForm } from './PolicyChangesForm';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { PolicyChangeModal } from './PolicyChangeModal';

const modalCopyOptions = {
  REG: 'To change the reg on your current car (not changing the car), we’ll need to check details are correct and let you know of any problems. Making changes may affect the cost of the policy and a new quote will need to be calculated.',
  OTHER:
    'Making changes may affect the cost of the policy and a new quote will need to be calculated.',
};

const getModalCopy = (regChange: boolean) =>
  regChange ? modalCopyOptions.REG : modalCopyOptions.OTHER;

const testId = 'policy-change';

export const PolicyChanges = () => {
  const { id } = useParams<{ id: string }>();
  const { values, submitForm } = useFormikContext<MtaJourneyFormData>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [regChange, setRegChange] = useState<boolean>(false);
  const [isPolicyModalOpen, setPolicyModalOpen] = useState<boolean>(false);

  const showPolicyModal = () => {
    setPolicyModalOpen(true);
    setRegChange(false);
  };

  const showRegistrationModal = () => {
    setRegChange(true);
    setPolicyModalOpen(true);
  };

  const handleCloseModal = () => {
    setPolicyModalOpen(false);
  };

  const handleContinueClick = () => {
    if (values.policyChange.changeReg) {
      showRegistrationModal();
    } else {
      showPolicyModal();
    }
  };

  const handleConfirm = () => submitForm();

  return (
    <PageContainer className="font-raleway">
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        What do you want to change?
      </h1>
      <ContentContainer>
        <Card
          testId={`${testId}_check-info_card`}
          paddingLevel="small"
          classNames="mb-6 lg:mb-8"
        >
          <span>Select what changes you want to make to your policy.</span>
        </Card>
        <Form>
          <PolicyChangesForm onFormChange={setIsValid} />
          <ContactCard />
          <PolicyChangeButtons
            policyId={id || ''}
            isValid={isValid}
            onContinueClick={handleContinueClick}
          />
          <PolicyChangeModal
            isOpen={isPolicyModalOpen}
            testId={testId}
            onClose={handleCloseModal}
            onConfirm={handleConfirm}
            message={getModalCopy(regChange)}
          />
        </Form>
      </ContentContainer>
    </PageContainer>
  );
};
