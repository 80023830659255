import { Card, Icon } from '@dayinsure/components';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import clsx from 'clsx';
import { ReviewPageSummaryCardType } from '../QuoteDetailsReview/QuoteDetailsReview.types';

type ReviewPageSummaryCardProps = ReviewPageSummaryCardType;

export const ReviewPageSummaryCard = ({
  title,
  testId,
  editLink,
  isNarrow,
  items = [],
}: ReviewPageSummaryCardProps) => {
  const navigate = useNavigateWithReferrer();

  const onEdit = () => editLink && navigate(editLink);

  return (
    <Card
      testId={`${testId}_card`}
      classNames={clsx('bg-content-background-prominent', {
        [`grow w-full h-auto md:flex-1 md:w-auto`]: isNarrow,
        'w-full': !isNarrow,
      })}
    >
      <div className="flex justify-between">
        <h3 className="font-raleway text-lg font-normal">{title}</h3>
        {editLink && (
          <button
            className="flex items-center text-sm font-semibold leading-[14px]"
            data-testid={`${testId}_edit-button`}
            onClick={onEdit}
            type="button"
          >
            <Icon name="edit" className="mr-2" size="12px" />
            Edit
          </button>
        )}
      </div>
      {items.map(({ testId: itemTestId, ...rest }) => (
        <Card.CardListItem
          key={`${testId}_${itemTestId}_list-item`}
          label={rest.title}
          icon={rest.icon}
          testId={`${testId}_${itemTestId}_list-item`}
        >
          {rest.content}
        </Card.CardListItem>
      ))}
    </Card>
  );
};
