import { useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { useLocation } from 'react-router-dom';
import { QuoteJourneyPath, QuoteJourneyRoutes, quoteJourneyMap } from '../../../routes';
import { QuoteJourneyFormData } from '../../../types';
import { isJourneyRoute } from '../../../helpers';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';

export const useNextStepSubmit = (currentQuoteStep: QuoteJourneyRoutes | null) => {
  const navigate = useNavigateWithReferrer();
  const next = quoteJourneyMap.getNext(currentQuoteStep);
  const { pathname } = useLocation();

  const createNextStepRoute = (nextPath: QuoteJourneyPath) => {
    if (!currentQuoteStep || !nextPath) {
      return null;
    }
    if (isJourneyRoute<QuoteJourneyRoutes>(nextPath)) {
      return pathname?.replace(currentQuoteStep, nextPath);
    }
    return nextPath;
  };

  return ({
    setSubmitting,
    setTouched,
  }: FormikHelpers<QuoteJourneyFormData> | FormikHelpers<MtaJourneyFormData>) => {
    const route = createNextStepRoute(next);
    if (route) {
      navigate(route);
    }
    setSubmitting(false);
    setTouched({}, false);
  };
};
