/* eslint-disable */

/**
 * This code was provided by Dayinsure
 *
 * We removed the dependency on string js to slim down the package size
 */

import { isValid, format } from 'date-fns';

const GB_DLN_REGEX =
  /^(?=.{16}$)[A-Za-z]{1,5}9{0,4}[0-9](?:[05][1-9]|[16][0-2])(?:[0][1-9]|[12][0-9]|3[01])[0-9](?:99|[A-Za-z][A-Za-z9])(?![IOQYZioqyz01_])\w[A-Za-z]{2}/;
const GB_DLN_EXPECTED_LENGTH = 16;

const validationResult = (
  isValid: boolean,
  message: string,
  invalidFields?: string[]
) => ({
  isValid,
  message,
  invalidFields,
});

const isDrivingLicenceNumberFirstnameValid = (
  firstname: string,
  drivingLicenceNumber: string
): boolean => {
  return (
    drivingLicenceNumber.length === GB_DLN_EXPECTED_LENGTH &&
    drivingLicenceNumber.toUpperCase()[11] === firstname.toUpperCase()[0]
  );
};

const isDrivingLicenceNumberSurnameValid = (
  surname: string,
  drivingLicenceNumber: string
) => {
  let result;
  surname = surname.toUpperCase();
  surname = surname.replace(/\s/g, '');

  let find = '-';
  let re = new RegExp(find, 'g');
  surname = surname.replace(re, '');

  find = 'MAC';
  re = RegExp(find, 'gi');
  const partialSurname = surname.substr(0, 3);
  const updatedSurname = partialSurname.replace(re, 'MC');
  surname = surname.replace(partialSurname, updatedSurname);

  find = "'";
  re = RegExp(find, 'g');
  surname = surname.replace(re, '');

  // If surname is less than 5 characters pad the remaining with 9
  if (surname.length < 5) {
    const paddingAmmount = surname.length + (5 - surname.length);
    result = surname.padEnd(paddingAmmount, '9');
  } else {
    result = surname.substring(0, 5);
  }

  return drivingLicenceNumber.toUpperCase().startsWith(result);
};

const getDrivingLicenceNumberDateOfBirthMonthDigits = (dateOfBirth: number) => {
  if (!dateOfBirth || !isValid(new Date(dateOfBirth))) {
    return null;
  }

  const dob = new Date(dateOfBirth);

  const maleDigits = format(dob, 'MM');
  const femaleDigits = [+maleDigits[0] + 5, maleDigits[1]].join('');

  return {
    MaleDigits: maleDigits,
    FemaleDigits: femaleDigits,
  };
};

const dateToUTC = (dateString: string) => {
  const date = new Date(dateString);

  return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

const isDrivingLicenceNumberDateOfBirthValid = (
  dateOfBirth: string,
  drivingLicenceNumber: string
) => {
  if (!dateOfBirth || !isValid(new Date(dateOfBirth))) {
    return false;
  }
  const dob = dateToUTC(dateOfBirth);
  const yearOfBirthDigits = format(dob, 'yy');
  const dayOfBirthDigits = format(dob, 'dd');
  const monthOfBirthDigits = getDrivingLicenceNumberDateOfBirthMonthDigits(dob);
  const isMonthValid =
    monthOfBirthDigits?.['FemaleDigits'] === drivingLicenceNumber.substring(6, 8) ||
    monthOfBirthDigits?.['MaleDigits'] === drivingLicenceNumber.substring(6, 8);

  return (
    isMonthValid &&
    yearOfBirthDigits === [drivingLicenceNumber[5], drivingLicenceNumber[10]].join('') &&
    dayOfBirthDigits === drivingLicenceNumber.substring(8, 10)
  );
};

const validateGbDrivingLicenceNumber = (
  firstname: string,
  surname: string,
  dateOfBirth: string,
  drivingLicenceNumber?: string
) => {
  if (
    !drivingLicenceNumber ||
    drivingLicenceNumber.length !== GB_DLN_EXPECTED_LENGTH ||
    !GB_DLN_REGEX.test(drivingLicenceNumber)
  ) {
    return validationResult(
      false,
      drivingLicenceNumber + ' is not a valid GB driving licence number.'
    );
  }

  if (surname && !isDrivingLicenceNumberSurnameValid(surname, drivingLicenceNumber)) {
    return validationResult(
      false,
      drivingLicenceNumber + ' is not a valid GB driving licence number.',
      ['surname']
    );
  }

  if (
    firstname &&
    !isDrivingLicenceNumberFirstnameValid(firstname, drivingLicenceNumber)
  ) {
    return validationResult(
      false,
      drivingLicenceNumber + ' is not a valid GB driving licence number.',
      ['firstname']
    );
  }

  if (
    dateOfBirth &&
    !isDrivingLicenceNumberDateOfBirthValid(dateOfBirth, drivingLicenceNumber)
  ) {
    return validationResult(
      false,
      drivingLicenceNumber + ' is not a valid GB driving licence number.',
      ['dateOfBirth']
    );
  }

  return validationResult(
    true,
    drivingLicenceNumber + ' is a valid GB driving licence number.'
  );
};

export const validateDrivingLicence = (
  firstname: string,
  surname: string,
  dateOfBirth: string,
  drivingLicenceNumber?: string
) => {
  return validateGbDrivingLicenceNumber(
    firstname,
    surname,
    dateOfBirth,
    drivingLicenceNumber
  ).isValid;
};
