import { useMutation } from 'react-query';
import {
  Dayinsure as DayInsureAPIv1,
  OpenAPI,
  ConvertToCustomerRequest,
} from '../../../api/v1';

export const useV1PutConvertGuestToCustomerMutation = () => {
  const signUp = ({
    personId,
    accountData,
  }: {
    personId: string;
    accountData: ConvertToCustomerRequest;
  }) => {
    const api = new DayInsureAPIv1(OpenAPI);

    return api.person.putApiV1PersonConvertToCustomer(personId, accountData);
  };

  return useMutation({
    mutationFn: signUp,
  });
};
