import { Card } from '@dayinsure/components';
import { EditCardLink, VehicleDetailItem } from '../../Common';
import { Vehicle } from '../../../types';

type CarDetailsCardProps = {
  vehicle: Partial<Vehicle>;
  testId: string;
  title?: string;
  carDetailsEditLinkPath?: string;
};

export const CarDetailsCard = ({
  vehicle: {
    registrationNumber,
    model,
    manufactureYear,
    primaryFuelType,
    transmission,
    numberOfDoors,
    make,
  },
  carDetailsEditLinkPath,
  testId,
  title = 'Car details',
}: CarDetailsCardProps) => {
  return (
    <Card
      classNames="bg-content-background-prominent"
      testId={`${testId}_car-details-card`}
    >
      <div className="flex justify-between">
        <h3 data-testid={`${testId}_title`} className="font-raleway text-lg font-normal">
          {title}
        </h3>
        {carDetailsEditLinkPath && (
          <EditCardLink testId={`${testId}_edit-button`} to={carDetailsEditLinkPath}>
            Edit
          </EditCardLink>
        )}
      </div>
      {registrationNumber && (
        <VehicleDetailItem
          title={registrationNumber}
          className="mt-8"
          testId={`${testId}_vehicle-registration-number-make-and-model-item`}
        >
          {`${make?.name} ${model?.name}`}
        </VehicleDetailItem>
      )}
      {manufactureYear && (
        <Card.CardListItem
          testId={`${testId}_vehicle-year-of-manufacture-item`}
          label="Year of manufacture"
          icon="date"
          labelClassName="text-main-content-2"
        >
          {manufactureYear.name}
        </Card.CardListItem>
      )}
      {primaryFuelType && (
        <Card.CardListItem
          testId={`${testId}_vehicle-fuel-type-item`}
          label="Fuel type"
          icon="funnel"
          labelClassName="text-main-content-2"
        >
          {primaryFuelType.name}
        </Card.CardListItem>
      )}
      {transmission && (
        <Card.CardListItem
          testId={`${testId}_vehicle-transmission-item`}
          label="Transmission"
          icon="spanner"
          labelClassName="text-main-content-2"
        >
          {transmission.name}
        </Card.CardListItem>
      )}
      {numberOfDoors && (
        <Card.CardListItem
          testId={`${testId}_vehicle-doors-item`}
          label="Number of doors"
          icon="door"
          labelClassName="text-main-content-2"
        >
          {numberOfDoors.name}
        </Card.CardListItem>
      )}
    </Card>
  );
};
