import { PasswordValidation } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import {
  lowercaseRegex,
  numberRegex,
  symbolRegex,
  uppercaseRegex,
} from '@dayinsure/shared';
import { FormPasswordField, FormFieldBox } from '../../../../../components';
import { ReaptchaInput } from '../ReaptchaInput';
import { QuoteJourneyFormData } from '../../../../../types';

const passwordValidationCopy = {
  minCharacters: 'Minimum eight characters',
  uppercase: 'One uppercase letter',
  lowercase: 'One lowercase letter',
  number: 'One number',
  symbol: 'One symbol',
  passwordMatch: 'Passwords match',
};

const passwordValidationRegExpsConfig = {
  uppercaseRegex,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
};

type CreateAccountFormProps = {
  testId: string;
};

export const CreateAccountForm = ({ testId }: CreateAccountFormProps) => {
  const { values } = useFormikContext<QuoteJourneyFormData>();
  return (
    <>
      <FormFieldBox>
        <FormPasswordField
          id="create-account-password-input"
          name="signUp.password"
          ariaLabel="Choose a password"
          label={{ icon: 'lock', text: 'Password' }}
          placeholder="Choose a password"
          spacingClassName="mb-8"
        />

        <FormPasswordField
          id="create-account-confirm-password-input"
          name="signUp.retypePassword"
          ariaLabel="Choose a password"
          label={{ icon: 'lock', text: 'Re-type password' }}
          placeholder="Re-type password"
          spacingClassName="mb-8"
        />
      </FormFieldBox>

      <PasswordValidation
        password={values.signUp.password}
        confirmPassword={values.signUp.retypePassword}
        copy={passwordValidationCopy}
        regexpsConfig={passwordValidationRegExpsConfig}
      />

      <ReaptchaInput testId={`${testId}_create-account`} />
    </>
  );
};
