import { FormCheckbox } from '../../FormsFields';

type DetailsCorrectProps = {
  testId: string;
};

export const DetailsCorrect = ({ testId }: DetailsCorrectProps) => {
  return (
    <div>
      <h3
        className="mt-8 mb-6 font-raleway text-lg font-normal"
        data-testid={`${testId}_are-details-correct_title`}
      >
        Are all the car details correct?
      </h3>
      <FormCheckbox
        displayText="I confirm all the details are correct"
        name="vehicle.areDetailsCorrect"
        id={`${testId}_are-details-correct_input`}
        testId={`${testId}_are-details-correct_input`}
      />
    </div>
  );
};
