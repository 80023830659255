import { useField, useFormikContext } from 'formik';
import { Button } from '@dayinsure/components';
import { AccidentOrClaimType } from '../../../../../types/quoteJourneyForm';
import { AccidentsOrClaimsListItem } from './AccidentsOrClaimsListItem';
import { getEmptyAccidentOrClaim } from '../../../../../helpers';

type AccidentOrClaimFieldSetProps = {
  name: string;
  testId: string;
  additionalDriver?: boolean;
};

export const AccidentOrClaimFieldSet = ({
  name,
  testId,
  additionalDriver,
}: AccidentOrClaimFieldSetProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField<AccidentOrClaimType[]>({ name });
  const isAdding = field.value.length && field.value.some(({ isSaved }) => !isSaved);

  const handleRemoveAccidentOrClaim = (accidentOrClaimToRemove: AccidentOrClaimType) => {
    const filteredAccidentOrClaim = field.value.filter(
      accidentOrClaim => accidentOrClaim.id !== accidentOrClaimToRemove.id
    );

    if (filteredAccidentOrClaim.length === 0) {
      setFieldValue(field.name, [getEmptyAccidentOrClaim()]);
    } else {
      setFieldValue(field.name, filteredAccidentOrClaim);
    }
    setFieldTouched(field.name, false);
  };

  const handleAddConviction = () => {
    const newConvictions = [...field.value, getEmptyAccidentOrClaim()];
    setFieldValue(field.name, newConvictions);
  };

  return (
    <div>
      {field.value.map((accidentOrClaim, index) => (
        <AccidentsOrClaimsListItem
          key={accidentOrClaim.id}
          name={`${name}[${index}]`}
          testId={`${testId}_accident-or-claim-${index}`}
          shouldDisplayCancelButton={index !== 0}
          additionalDriver={additionalDriver}
          onRemove={handleRemoveAccidentOrClaim}
        />
      ))}
      {!isAdding && (
        <Button
          variant="bordered"
          id={`${testId}_add-button`}
          testId={`${testId}_add-button`}
          onClick={handleAddConviction}
          text="Add another claim"
        />
      )}
    </div>
  );
};
