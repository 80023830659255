import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { DriverFormData, QuoteJourneyFormData } from '../../../../types';
import { DriverListItem } from './DriverListItem';
import { isYes } from '../../../../helpers';
import { QuoteJourneyRoutes } from '../../../../routes';

type DriverListProps = {
  testId: string;
  onRemoveDriver: (driverId: string) => () => void;
};

export const DriverList = ({ testId, onRemoveDriver }: DriverListProps) => {
  const navigate = useNavigate();
  const {
    values: { drivers },
  } = useFormikContext<QuoteJourneyFormData>();

  const handleEditPolicyholder = () => {
    navigate(`../../${QuoteJourneyRoutes.YourDetails}`);
  };

  const handleEditAdditionalDriver = (driverId: string) => () =>
    navigate(`${driverId}/details`);

  const handleEditDriver = (driver: DriverFormData) =>
    isYes(driver.isProposer?.id)
      ? handleEditPolicyholder
      : handleEditAdditionalDriver(driver.id);

  return (
    <div className="mb-8 lg:mb-12">
      {drivers.map(driver => (
        <DriverListItem
          key={driver.id}
          driver={driver}
          testId={testId}
          onEditDriver={handleEditDriver(driver)}
          onRemoveDriver={onRemoveDriver(driver.id)}
        />
      ))}
    </div>
  );
};
