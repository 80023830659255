import { mergeSelectedValues, usePrevious } from '@dayinsure/shared';
import { useFormikContext } from 'formik';
import get from 'lodash.get';
import { useMemo, useEffect } from 'react';
import isEqual from 'lodash.isequal';
import { Proposer, DriverFormData, QuoteJourneyFormData } from '../types';

export type CommonKeys = Extract<keyof Proposer, keyof DriverFormData>[];

export const useCloneToProposer = (
  propertiesToClone: CommonKeys,
  targetKey: string,
  sourceKey: string
) => {
  const { values, setFieldValue } = useFormikContext<QuoteJourneyFormData>();
  const target = get(values, targetKey);
  const source = get(values, sourceKey);
  const result = useMemo(
    () => mergeSelectedValues(target, source, propertiesToClone),
    [source, target, propertiesToClone]
  );
  const previousResult = usePrevious(result);

  useEffect(() => {
    if (!isEqual(previousResult, result)) {
      setFieldValue(targetKey, result);
    }
  }, [previousResult, result, setFieldValue, targetKey]);
};
