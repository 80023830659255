import { DriverListItem } from './DriverListItem';
import { Driver } from '../../../../../types/mtaJourneyForm';
import { DriverFormData } from '../../../../../types';

type DriverListProps = {
  drivers: (Driver | DriverFormData)[];
  onDriverRemove: (driverId: string) => void;
  testId: string;
};

export const DriverList = ({ drivers, testId, onDriverRemove }: DriverListProps) => (
  <div className="mb-8 lg:mb-12" data-testid={`${testId}_drivers-list`}>
    {drivers.map(driver => (
      <DriverListItem
        key={driver.id}
        driver={driver}
        testId={testId}
        onDriverRemove={() => onDriverRemove(driver.id)}
      />
    ))}
  </div>
);
