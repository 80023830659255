import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import { Dayinsure as DayinsureAPIv2, OpenAPI } from '../../../api/v2';
import { ApiError, OptionCodeDto } from '../../../api/v1';
import { query } from '../queryKeys';

export const useEmploymentStatusesQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getEmploymentStatuses = () => {
    const api = new DayinsureAPIv2(OpenAPI);
    return api.product.getApiV2ProductEmploymentStatuses('motor', 'latest');
  };
  return useQuery<OptionCodeDto[], ApiError>(
    query.employmentStatuses,
    getEmploymentStatuses,
    {
      keepPreviousData: true,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
