import { AccordionItemType, AccordionType } from '../../../types/Accordion';
import { AccordionItem } from './AccordionItem';

export const Accordion = ({ items }: AccordionType) => {
  return (
    <div className="mx-auto max-w-2xl">
      {items.map((item: AccordionItemType, index) => (
        <AccordionItem
          key={`${index.toString()}`}
          title={item.title}
          description={item.description}
        />
      ))}
    </div>
  );
};

export default Accordion;
