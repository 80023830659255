import { format } from 'date-fns';
import { DriverDto, PolicyDriverDto } from '../../api/v1';
import { displayJobTitleBaseOnEmploymentStatus } from '../forms';

export const getDriverDescription = (driver: DriverDto | PolicyDriverDto) => {
  const driverName = `${driver.firstName} ${driver.lastName}`;
  const mainDriver = `${driver.isMainDriver ? ' [main driver]' : ''}`;
  const driverBirthDate = driver.dateOfBirth
    ? `(born ${format(new Date(driver.dateOfBirth), 'dd/MM/yyyy')})`
    : '';

  const primaryOccupation = driver.occupations?.find(occupation => occupation.isPrimary);

  const jobTitle =
    primaryOccupation &&
    displayJobTitleBaseOnEmploymentStatus(primaryOccupation.employmentStatus?.code)
      ? `: ${primaryOccupation?.type?.description}`
      : '';

  return `${driverName}${mainDriver}${jobTitle} ${driverBirthDate}`;
};
