import { useEffect, useState } from 'react';
import { Referrer } from '../types';

const addBodyClass = (className: string) => document.body.classList.add(className);
const removeBodyClass = (className: string) => document.body.classList.remove(className);
const setFavicon = (url: string) => {
  const faviconEl = document.getElementById('favicon') as HTMLLinkElement;
  if (faviconEl) {
    faviconEl.href = url;
  }
};
const setTitle = (title: string) => {
  const titleEl = document.getElementById('site-title') as HTMLTitleElement;
  if (titleEl) {
    titleEl.textContent = title;
  }
};
const setDescription = (title: string) => {
  const descriptionEl = document.getElementById('site-description') as HTMLMetaElement;
  if (descriptionEl) {
    descriptionEl.textContent = title;
  }
};

const useTheme = <T extends string>(referrer: Referrer<T>): void => {
  const [currentTheme, setCurrentTheme] = useState<string | undefined>(undefined);

  useEffect(() => {
    const themeClassname = referrer.theme;
    const themeFaviconUrl = referrer.pageMeta.faviconUrl;
    const themeSiteTitle = referrer.pageMeta.title;
    const themeSiteDescription = referrer.pageMeta.description;

    const changeTheme = () => {
      addBodyClass(themeClassname);
      setCurrentTheme(themeClassname);
      setTitle(themeSiteTitle);
      setDescription(themeSiteDescription);
      setFavicon(themeFaviconUrl);
    };

    if (currentTheme === undefined) {
      changeTheme();
    } else if (currentTheme !== themeClassname) {
      removeBodyClass(currentTheme);
      changeTheme();
    }
  }, [currentTheme, referrer.theme, referrer.pageMeta]);
};

export { useTheme };
