type PolicyCarDetailsProps = {
  vehicleRegistration?: string | null;
  vehicleDescription?: string | null;
  policyIndex: number;
  testId: string;
};

export const PolicyCarDetails = ({
  vehicleRegistration,
  vehicleDescription,
  policyIndex,
  testId,
}: PolicyCarDetailsProps) => (
  <>
    <span
      className="inline-flex py-1 px-2 mb-2 font-lato text-sm leading-[120%] rounded bg-main-content-5"
      data-testid={`${testId}_registration-plate-${policyIndex}`}
    >
      {vehicleRegistration}
    </span>
    <span
      className="flex leading-[120%] whitespace-normal"
      data-testid={`${testId}_car-details-${policyIndex}`}
    >
      {vehicleDescription}
    </span>
  </>
);
