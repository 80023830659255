import { useMutation } from 'react-query';
import {
  Dayinsure as DayInsureAPIv2,
  OpenAPI,
  SendPasswordResetRequest,
  IAPIResult,
} from '../../../api/v2';

type Payload = {
  email: string;
  requestBody?: SendPasswordResetRequest;
};

export const useSendPasswordResetMutation = (
  onSuccess?: (data: IAPIResult) => void,
  onError?: (error: unknown) => void
) => {
  const sendPasswordResetLink = (payload: Payload) => {
    const api = new DayInsureAPIv2(OpenAPI);

    return api.person.postApiV2PersonSendPasswordReset(
      payload.email,
      payload.requestBody
    );
  };

  return useMutation(sendPasswordResetLink, {
    mutationKey: 'reset-password-link',
    onSuccess,
    onError,
  });
};
