import { Card, Icon } from '@dayinsure/components';
import { onEnterKey } from '@dayinsure/components/src/types/events';
import { StoredPaymentMethod } from '../../../../api/v1/models/StoredPaymentMethod';

type CreditCardProps = {
  testId: string;
  card: StoredPaymentMethod;
  index: number;
  count: number;
  onRemove: (id: string) => void;
};

const getYear = (year: string, short = false) =>
  short && year.length === 4 ? year.slice(-2) : year;
const getHashedCardNumber = (lastFour: string) => `XXXX XXXX XXXX ${lastFour}`;

export const CreditCard = ({ testId, card, count, index, onRemove }: CreditCardProps) => (
  <>
    {card && (
      <Card
        testId={`${testId}_saved-card`}
        key={card.id}
        paddingLevel="small"
        classNames="mb-6 lg:mb-8 bg-content-background-prominent w-full"
      >
        <div className="flex items-start">
          <h2 className="mb-4 text-lg">Saved card</h2>

          {count > 1 && (
            <div
              role="button"
              id={`saved-card-remove-${index}`}
              tabIndex={-1}
              className="flex gap-2 items-center pt-1 ml-auto text-sm font-semibold leading-3 text-main-content-1"
              onClick={() => onRemove(card?.id || '')}
              onKeyDown={onEnterKey(() => {
                onRemove(card?.id || '');
              })}
            >
              <Icon name="remove" />
              Remove
            </div>
          )}
        </div>
        <span className="flex gap-2 items-center text-sm leading-3 text-main-content-2">
          <Icon name="card" />
          Card number
        </span>
        <span id={`saved-cards-number-${index}`}>
          {getHashedCardNumber(card?.lastFour || '')}
        </span>

        <span className="flex gap-2 items-center mt-4 text-sm leading-3 text-main-content-2">
          <Icon name="date" />
          Expiry date
        </span>
        <div id={`saved-card-expiry-${index}`} className="pt-1">
          {card.expiryMonth}/{getYear(card?.expiryYear || '', true)}
        </div>
      </Card>
    )}
  </>
);
