import { mergeSelectedValues } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import get from 'lodash.get';
import { Proposer, DriverFormData, QuoteJourneyFormData } from '../types';

export type CommonKeys = Extract<keyof Proposer, keyof DriverFormData>[];

export const useCloneToProposerSubmitHandler =
  (propertiesToClone: CommonKeys, targetKey: string, sourceKey: string) =>
  (values: QuoteJourneyFormData, formikHelpers: FormikHelpers<QuoteJourneyFormData>) => {
    const target = get(values, targetKey);
    const source = get(values, sourceKey);
    const result = mergeSelectedValues(target, source, propertiesToClone);

    formikHelpers.setFieldValue(targetKey, result);
  };
