import { useParams } from 'react-router-dom';

export const DriverHome = () => {
  const { id, driverId } = useParams<{ id: string; driverId: string }>();
  return (
    <div>
      Driver home policy id: {id} driver id: {driverId}
    </div>
  );
};
