import { DEFAULT_TOBA_URL } from '../../../../constants/apps';

export const AgreementInfo = () => {
  return (
    <div className="mt-12 font-lato text-sm text-center text-main-content-1">
      By continuing, I confirm I have read and agree with the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="font-semibold underline text-link"
        href="https://www.dayinsure.com/dayinsure-privacy-policy/"
      >
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        className="font-semibold underline text-link"
        href={DEFAULT_TOBA_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Business
      </a>
      .
    </div>
  );
};
