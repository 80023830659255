import { useMutation } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  Dayinsure as DayInsureAPIv2,
  OpenAPI,
  PersonRequest,
  ApiError,
} from '../../../api/v2';

export const useV2PostPersonMutation = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const signUp = (personData: PersonRequest) => {
    const api = new DayInsureAPIv2(OpenAPI);

    return api.person.postApiV2Person(personData);
  };

  return useMutation({
    mutationFn: signUp,
    onError: (err: ApiError) => {
      if (err.status === 422) {
        setGlobalError(err, {
          message: 'Something doesn’t look quite right, please go back and try again.',
        });
      }
    },
  });
};
