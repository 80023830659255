import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { FormCheckbox } from '../../../../components';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';

type PolicyChangeFormProps = {
  onFormChange: (formValid: boolean) => unknown;
  testId?: string;
};

const handleOptionChange = (formValues: MtaJourneyFormData) =>
  Object.values(formValues.policyChange).includes(true);

export const PolicyChangesForm = ({
  onFormChange,
  testId = 'policy-change',
}: PolicyChangeFormProps) => {
  const { values, setFieldValue } = useFormikContext<MtaJourneyFormData>();
  const prevValuesRef = useRef(values);

  useEffect(() => {
    const prevValues = prevValuesRef.current;
    if (values.policyChange.changeCar && !prevValues.policyChange.changeCar) {
      setFieldValue('policyChange.changeReg', false);
    } else if (values.policyChange.changeReg && !prevValues.policyChange.changeReg) {
      setFieldValue('policyChange.changeCar', false);
    }

    prevValuesRef.current = values;
    const isFormValid = handleOptionChange(values);
    onFormChange(isFormValid);
  }, [onFormChange, setFieldValue, values]);

  return (
    <>
      <section>
        <h3
          className="mt-12 mb-6 font-raleway text-lg font-normal"
          data-testid={`${testId}_form-subtitle`}
        >
          Car
        </h3>
        <div className="mb-8">
          <FormCheckbox
            additionalIcon="car"
            displayText="Change the car"
            name="policyChange.changeCar"
            id={`${testId}_car_checkbox-input`}
            testId={`${testId}_car_checkbox-input`}
            enlarged
          />
        </div>
        <FormCheckbox
          additionalIcon="registration"
          displayText="Just change the reg"
          name="policyChange.changeReg"
          id={`${testId}_reg_checkbox-input`}
          testId={`${testId}_reg_checkbox-input`}
          enlarged
        />
      </section>
      <section>
        <h3
          className="mt-12 mb-6 font-raleway text-lg font-normal"
          data-testid={`${testId}_drivers-subtitle`}
        >
          Drivers
        </h3>
        <FormCheckbox
          additionalIcon="user-group"
          displayText="Add / remove drivers"
          name="policyChange.changeDrivers"
          id={`${testId}_drivers-checkbox-input`}
          testId={`${testId}_drivers-checkbox-input`}
          enlarged
        />
      </section>
    </>
  );
};
