export enum AddOnType {
  PROTECTED_NCB = 'PROTECTED_NCB',
  LEGAL_EXPENSES = 'LEGAL_EXPENSES',
  COURTESY_CAR_ENHANCED = 'COURTESY_CAR_ENHANCED',
  KEYS = 'KEYS',
  BREAKDOWN_1 = 'BREAKDOWN_1',
  BREAKDOWN_2 = 'BREAKDOWN_2',
  BREAKDOWN_3 = 'BREAKDOWN_3',
  BREAKDOWN_4 = 'BREAKDOWN_4',
}
