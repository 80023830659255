import { useQuery } from 'react-query';
import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { query } from '../queryKeys';
import {
  ApiError,
  CoverDto,
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
} from '../../../api/v2';

export const useGetProductSchemaCover = (coverType: string) => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const getProductSchemaCover = () => {
    if (!coverType) {
      throw new Error('No cover type');
    }
    const api = new DayinsureAPIv2(OpenAPI);
    return api.product.getApiV2ProductSchemeCover(
      'motor',
      'latest',
      'everyday_scheme',
      coverType
    );
  };
  return useQuery<CoverDto, ApiError>(
    query.productSchemaCover(coverType || ''),
    getProductSchemaCover,
    {
      keepPreviousData: true,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
