type FeesAndChargesRowProps = {
  text: string;
  value: string | number;
};

export const FeesAndChargesRow = ({ text, value }: FeesAndChargesRowProps) => {
  return (
    <div className="flex justify-between font-lato text-sm leading-5">
      <p>{text}</p>
      <p className="font-bold">{`£${value}`}</p>
    </div>
  );
};
