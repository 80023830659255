import { Button, Card } from '@dayinsure/components';
import { Form } from 'formik';
import { QuoteJourneyLayout } from '../../../components';
import {
  Licence,
  DriveAnotherCar,
  Qualifications,
  NotifableMedicalConditions,
  NonMotoringConvictions,
  DrivingConvictions,
  AccidentOrClaim,
} from '../YourLicenseAndHistory';

const testId = 'additional-driver_licence-and-history';
export const AdditionalDriverLicenceAndHistory = () => {
  return (
    <QuoteJourneyLayout>
      <h1
        className="mb-8 text-xl text-center md:text-left"
        data-testid={`${testId}_title`}
      >
        Additional driver’s licence and history
      </h1>
      <Card
        testId={`${testId}_cards_info-card`}
        paddingLevel="small"
        classNames="mb-6 lg:mb-8"
      >
        <span>We need some details about their driving licence and history.</span>
      </Card>
      <div className="mb-16 sm:mb-24">
        <Form>
          <Licence additionalDriver testId={testId} />
          <DriveAnotherCar additionalDriver testId={testId} />
          <Qualifications additionalDriver testId={testId} />
          <NotifableMedicalConditions additionalDriver testId={testId} />
          <AccidentOrClaim additionalDriver testId={testId} />
          <DrivingConvictions additionalDriver testId={testId} />
          <NonMotoringConvictions additionalDriver testId={testId} />
          <Button
            id={`${testId}_continue_button`}
            testId={`${testId}_continue_button`}
            submit
            fullWidth
            icon={{ name: 'arrow-right', position: 'right' }}
            text="Continue"
          />
        </Form>
      </div>
    </QuoteJourneyLayout>
  );
};
