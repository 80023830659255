import { YesNoAnswer } from '../../../../../types';
import { Vehicle } from '../../../../../api/v1';

export const parseVehicleToFormikState = (vehicle?: Vehicle) => {
  if (!vehicle) {
    return null;
  }

  return {
    type: {
      id: vehicle.vehicleOptions?.vehicleType?.code,
      name: vehicle.vehicleOptions?.vehicleType?.description,
    },
    abiCode: {
      id: vehicle.abiCode,
      name: vehicle.abiCode,
    },
    make: {
      id: vehicle.make,
      name: vehicle.make,
    },
    model: {
      id: vehicle.model,
      name: vehicle.model,
    },
    manufactureYear: {
      id: String(vehicle.registrationYear),
      name: String(vehicle.registrationYear),
    },
    manufactureStartYear: {
      id: String(vehicle.startYear),
      name: String(vehicle.startYear),
    },
    manufactureEndYear: {
      id: String(vehicle.endYear),
      name: String(vehicle.endYear),
    },
    numberOfDoors: {
      id: String(vehicle.doors),
      name: String(vehicle.doors),
    },
    transmission: {
      id: vehicle.vehicleOptions?.transmission?.code,
      name: vehicle.vehicleOptions?.transmission?.description,
    },
    primaryFuelType: {
      id: vehicle.vehicleOptions?.fuelType?.code,
      name: vehicle.vehicleOptions?.fuelType?.description,
    },
    numberOfSeats: vehicle.seats
      ? {
          id: vehicle.seats?.toString(),
          name: vehicle.seats?.toString(),
        }
      : null,
    steeringWheelSide: vehicle.steeringWheelSide
      ? {
          id: vehicle.steeringWheelSide.code,
          name: vehicle.steeringWheelSide.description,
        }
      : null,
    isImported:
      typeof vehicle.isImported === 'boolean'
        ? {
            id: vehicle.isImported ? YesNoAnswer.YES : YesNoAnswer.NO,
            name: vehicle.isImported ? 'Yes' : 'No',
          }
        : null,
    trackingDevice: {
      hasTrackingDevice:
        typeof vehicle.hasTrackingDevice === 'boolean'
          ? {
              id: vehicle.hasTrackingDevice ? YesNoAnswer.YES : YesNoAnswer.NO,
              name: vehicle.hasTrackingDevice ? 'Yes' : 'No',
            }
          : null,
    },
    alarmImmobiliser: vehicle.alarmImmobiliser
      ? {
          id: vehicle.alarmImmobiliser?.code,
          name: vehicle.alarmImmobiliser?.description,
        }
      : null,
  };
};
