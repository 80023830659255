import { useContext } from 'react';
import { useQuery } from 'react-query';
import { GlobalErrorContext } from '@dayinsure/shared';
import {
  AddressDto,
  ApiError,
  Dayinsure as DayinsureAPIv3,
  OpenAPI,
} from '../../../api/v3';
import { query } from '../queryKeys';

export const useAddressLookupQuery = (postcode: string | undefined) => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const api = new DayinsureAPIv3(OpenAPI);

  const getAddressList = (): Promise<AddressDto[]> => {
    return api.address.getApiV3Address(postcode || '');
  };

  return useQuery<AddressDto[], ApiError>(
    query.addressLookup(postcode || ''),
    getAddressList,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
