type BreakdownFourProps = {
  testId: string;
};

export const BreakdownFour = ({ testId }: BreakdownFourProps) => {
  return (
    <div className="font-lato text-sm leading-5 text-main-content-1" data-testid={testId}>
      <ul className="pl-4 list-disc">
        <li>Assistance at the side of the road</li>
        <li>Recovery to any UK destination</li>
        <li>Assistance at your home</li>
        <li>Hire car</li>
        <li>Overnight accommodation</li>
        <li>Breakdown assistance in Europe</li>
      </ul>
    </div>
  );
};
