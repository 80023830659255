import { ReferenceCodeDto } from '../../../api/v1';

export const occupationsCodeTransform = (
  referenceCode: ReferenceCodeDto | undefined,
  limit: number
) => {
  if (referenceCode?.code) {
    const codeLength = referenceCode.code.length;
    const gap = limit - codeLength;
    const missingSigns = '0'.repeat(gap);
    return { code: missingSigns + referenceCode.code };
  }
  return undefined;
};
