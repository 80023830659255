import { FieldError, Label, Listbox, IconNames } from '@dayinsure/components';
import {
  getAvailableHoursForToday,
  getAvailableMinutesForCurrentHour,
  getHours,
  getMinutes,
} from '@dayinsure/shared';
import { useField } from 'formik';
import { useMemo, useRef } from 'react';
import { useFocusOnError } from '../../../hooks';

type FormTimeSelectProps = {
  label: {
    icon: IconNames;
    text: string;
  };
  hourId: string;
  hourName: string;
  minuteId: string;
  minuteName: string;
  isToday?: boolean;
  disabled?: boolean;
};

export const FormTimeSelect = ({
  label,
  hourId,
  hourName,
  minuteId,
  minuteName,
  isToday,
  disabled,
}: FormTimeSelectProps) => {
  const [hourField, hourMeta] = useField({ id: hourId, name: hourName });
  const [minuteField, minuteMeta] = useField({ id: minuteId, name: minuteName });
  const controlRef = useRef<HTMLDivElement | null>(null);
  useFocusOnError({ fieldRef: controlRef, name: hourName });
  useFocusOnError({ fieldRef: controlRef, name: minuteName });

  const hoursOptions = useMemo(() => {
    if (isToday) {
      return getAvailableHoursForToday();
    }

    return getHours();
  }, [isToday]);

  const minuteOptions = useMemo(() => {
    const minutesInterval = 5;
    const currentHour = new Date().getHours().toString();

    if (isToday && hourField.value && hourField.value.id === currentHour) {
      return getAvailableMinutesForCurrentHour(minutesInterval);
    }

    return getMinutes(minutesInterval);
  }, [isToday, hourField.value]);

  return (
    <div className="relative form-field-spacing" ref={controlRef}>
      <Label
        disabled={disabled}
        name={label.text}
        icon={label.icon}
        htmlFor={hourId}
        className="mb-4"
      />
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="relative">
          <Listbox
            id={hourId}
            testId={hourId}
            name={hourField.name}
            value={hourField.value}
            options={hoursOptions}
            noOptionsMessage=""
            unselectedMessage="Hour"
            onChange={value => {
              hourField.onChange({ target: { value, name: hourField.name } });
            }}
            onBlur={() => {
              hourField.onBlur({ target: { name: hourField.name } });
            }}
            error={hourMeta.touched && hourMeta.error !== undefined}
            disabled={disabled}
            variant="annual"
          />
          {hourMeta.touched && hourMeta.error && (
            <FieldError
              id={`${hourId}-error`}
              error={hourMeta.error || ''}
              className="absolute right-0 mt-2"
            />
          )}
        </div>
        <div className="relative">
          <Listbox
            id={minuteId}
            testId={minuteId}
            name={minuteField.name}
            value={minuteField.value}
            options={minuteOptions}
            noOptionsMessage=""
            unselectedMessage="Minute"
            onChange={value => {
              minuteField.onChange({ target: { value, name: minuteField.name } });
            }}
            onBlur={() => {
              minuteField.onBlur({ target: { name: minuteField.name } });
            }}
            error={minuteMeta.touched && minuteMeta.error !== undefined}
            disabled={disabled}
            variant="annual"
          />
          {minuteMeta.touched && minuteMeta.error && (
            <FieldError
              id={`${minuteId}-error`}
              error={minuteMeta.error || ''}
              className="absolute right-0 mt-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};
