import { useEffect, useMemo } from 'react';
import { ListboxOption } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { FormListBox, FormFieldBox } from '../../../../components';
import { DriverFormData, QuoteJourneyFormData, YesNoAnswer } from '../../../../types';

const fixDateDisplay = (dateIngredient: string) =>
  dateIngredient.length === 1 ? `0${dateIngredient}` : dateIngredient;

const driverToOption = ({ id, firstName, lastName, dateOfBirth }: DriverFormData) => {
  const dob = dateOfBirth
    ? `(${fixDateDisplay(dateOfBirth?.day)}/${fixDateDisplay(dateOfBirth?.month)}/${
        dateOfBirth?.year
      })`
    : '';

  return {
    id,
    name: `${firstName} ${lastName} ${dob}`,
  };
};

type MainDriverSelectProps = {
  testId: string;
};

export const MainDriverSelect = ({ testId }: MainDriverSelectProps) => {
  const {
    setFieldValue,
    values: { drivers },
    setFieldTouched,
  } = useFormikContext<QuoteJourneyFormData>();

  const mainDriverOptions = useMemo(() => drivers.map(driverToOption), [drivers]);

  const handleChangeMainDriver = (option: ListboxOption) => {
    const updatedDrivers = drivers.map((driver): DriverFormData => {
      const isMainDriver =
        driver.id === option.id
          ? { id: YesNoAnswer.YES, name: 'Yes' }
          : { id: YesNoAnswer.NO, name: 'No' };
      return {
        ...driver,
        isMainDriver,
      };
    });
    setFieldValue('drivers', updatedDrivers);
  };
  useEffect(() => {
    setFieldTouched('mainDriver', false);
  }, [setFieldTouched]);

  return (
    <FormFieldBox className="mb-12">
      <h3
        className="mb-6 font-raleway text-lg font-normal md:mb-12"
        data-testid={`${testId}_main-driver-label`}
      >
        Who will be the main driver?
      </h3>
      <div className="flex relative flex-col mb-4 md:mb-8">
        <FormListBox
          id={`${testId}_main-driver-select`}
          testId={`${testId}_main-driver-select`}
          icon="user"
          label="Driver"
          name="mainDriver"
          options={mainDriverOptions}
          unselectedMessage="Please select"
          onChangeOptional={handleChangeMainDriver}
        />
      </div>
    </FormFieldBox>
  );
};
