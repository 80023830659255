import { FormTextField } from '../../../../../components';
import { capitalizeMask } from '../../../../../helpers';

type RegistrationPlateProps = {
  onBlur: () => void;
  testId: string;
};

export const NewRegistrationPlate = ({ testId, onBlur }: RegistrationPlateProps) => {
  return (
    <div className="my-12">
      <h3
        className="mb-6 font-raleway text-lg font-normal"
        data-testid={`${testId}_reg-plate-subtitle`}
      >
        What is the new reg for your current car?
      </h3>

      <div className="relative form-field-spacing">
        <div className="grid grid-cols-2 gap-6 h-max sm:flex">
          <FormTextField
            label={{ text: 'Car reg', icon: 'registration' }}
            testId={`${testId}_car-reg-input`}
            id={`${testId}_car-reg-input`}
            name="newReg.regPlate"
            placeholder="Enter car reg"
            onInput={capitalizeMask}
            onBlur={onBlur}
          />
        </div>
      </div>
    </div>
  );
};
