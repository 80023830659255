import { SelectRadioOption } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import get from 'lodash.get';
import { useCallback } from 'react';
import { FormRadioSelect, FormFieldBox } from '../../../../components';
import {
  getEmptyConviction,
  getFormFieldAccessPath,
  isYes,
  getYesNoOptions,
} from '../../../../helpers';
import { DriverFormData, QuoteJourneyFormData, YesNoAnswer } from '../../../../types';
import { DrivingConvictionsFieldSet } from './DrivingConvictionsFieldSet';
import { DrivingConvictionsTooltip } from './DrivingConvictionsTooltip';

type DrivingConvictionsProps = {
  testId: string;
  formNameSpaceKey?: string;
  additionalDriver?: boolean;
};

export const DrivingConvictions = <T extends QuoteJourneyFormData | DriverFormData>({
  testId,
  additionalDriver,
  formNameSpaceKey,
}: DrivingConvictionsProps) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<T>();
  const currentDriver: DriverFormData = formNameSpaceKey
    ? get(values, formNameSpaceKey)
    : values;

  const {
    motorConvictions: { hasMotorConvictions },
  } = currentDriver;

  const hasMotorConvictionsPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'motorConvictions',
    'hasMotorConvictions',
  ]);
  const motorConvictionsPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'motorConvictions',
    'motorConvictions',
  ]);

  const handleRadioChange = useCallback(
    (option: SelectRadioOption) => {
      const { id } = option;
      if (id === YesNoAnswer.NO) {
        setFieldValue(motorConvictionsPath, []);
      } else {
        setFieldValue(motorConvictionsPath, [getEmptyConviction()]);
      }
      setFieldTouched(motorConvictionsPath, false);
    },
    [motorConvictionsPath, setFieldTouched, setFieldValue]
  );

  return (
    <div className="my-6 md:my-12">
      <h3
        className="relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_convictions-label`}
      >
        {additionalDriver
          ? 'Have they had any driving-related convictions, endorsements, penalties, disqualifications or bans in the past 5 years?'
          : 'Have you had any driving-related convictions, endorsements, penalties, disqualifications or bans in the past 5 years?'}
        <DrivingConvictionsTooltip testId={testId} />
      </h3>
      <div className="mb-4 md:mb-8">
        <FormFieldBox>
          <FormRadioSelect
            onChangeOptional={handleRadioChange}
            id={`${testId}_provide-convictions-radio`}
            testId={`${testId}_provide-convictions-radio`}
            name={hasMotorConvictionsPath}
            options={getYesNoOptions(`${testId}_has-convictions`)}
          />
        </FormFieldBox>
        {isYes(hasMotorConvictions?.id) && (
          <DrivingConvictionsFieldSet
            name={motorConvictionsPath}
            testId={`${testId}_convictions`}
          />
        )}
      </div>
    </div>
  );
};
