import { Header } from '@dayinsure/components';
import { AccountDrawer } from './AccountDrawer';
import { MenuDrawer } from './MenuDrawer';
import { NavLink } from '../NavLink';
import { AccountMenu } from './AccountMenu';

const FullAccountNavigation = () => (
  <Header.ResponsiveNavigation
    mobileContent={
      <>
        <MenuDrawer />
        <AccountDrawer />
      </>
    }
    desktopContent={
      <>
        <NavLink
          id="header-dashboard-btn"
          linkText="Dashboard"
          to="/dashboard"
          icon={{ name: 'dashboard' }}
        />
        <NavLink
          id="header-policies-btn"
          linkText="Policies"
          to="/policies"
          icon={{ name: 'policies' }}
        />
        <NavLink
          id="header-help-btn"
          linkText="Help"
          to="/help"
          icon={{ name: 'help' }}
        />
        <AccountMenu />
      </>
    }
  />
);

export { FullAccountNavigation };
