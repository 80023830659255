import { Card } from '@dayinsure/components';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { FeesAndChargesRow as Row } from './FeesAndChargesRow';
import { FeesForDisplayDto } from '../../../../api/v1';

type FeesAndChargesProps = {
  testId: string;
  isPlannAnnual: boolean;
  feesForDisplay?: FeesForDisplayDto;
  className?: string;
};

export const FeesAndCharges = ({
  testId,
  className,
  isPlannAnnual,
  feesForDisplay,
}: FeesAndChargesProps) => {
  const renderCommonFees = useMemo(() => {
    return (
      <>
        {feesForDisplay?.cancellationFeeBeforeAndDuringCoolingOff && (
          <Row
            text="Cancellation within 14 days of buying or renewing your policy"
            value={Number(
              feesForDisplay?.cancellationFeeBeforeAndDuringCoolingOff.amount
            )}
          />
        )}
        {feesForDisplay?.cancellationFeeAfterCoolingOff && (
          <Row
            text="Cancellation after 14 days of buying or renewing your policy"
            value={Number(feesForDisplay?.cancellationFeeAfterCoolingOff?.amount)}
          />
        )}
        <Row text="Set-up fee (non-refundable)" value={50} />
        {feesForDisplay?.mtaFee && (
          <Row text="Amendment fee" value={Number(feesForDisplay?.mtaFee?.amount)} />
        )}
      </>
    );
  }, [
    feesForDisplay?.cancellationFeeAfterCoolingOff,
    feesForDisplay?.cancellationFeeBeforeAndDuringCoolingOff,
    feesForDisplay?.mtaFee,
  ]);
  return (
    <Card
      classNames={clsx(
        'mt-6 lg:mt-8 bg-content-background-prominent',
        className
      )}
      testId={`${testId}_fees-and-charges_card`}
    >
      <h3 className="mb-6 font-raleway text-lg">Other fees and charges</h3>
      {isPlannAnnual ? (
        renderCommonFees
      ) : (
        <>
          <Row text="Missed payment fee" value={25} />
          {renderCommonFees}
        </>
      )}
    </Card>
  );
};
