/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ValidatePersonRequest } from '../models/ValidatePersonRequest';
import type { ValidationResultIAPIResult } from '../models/ValidationResultIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ValidatePersonService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Validates a person
   * @param requestBody A request for validating a person.
   * @returns ValidationResultIAPIResult Success
   * @throws ApiError
   */
  public postApiV1ValidatePerson(
    requestBody?: ValidatePersonRequest,
  ): CancelablePromise<ValidationResultIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/validate-person',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

}