import { Form, Formik, FormikHelpers } from 'formik';
import {
  Button,
  Card,
  ErrorDialog,
  PageContainer,
  PasswordValidation,
} from '@dayinsure/components';
import {
  lowercaseRegex,
  NavigateWithReferrer,
  numberRegex,
  symbolRegex,
  uppercaseRegex,
  useNavigateWithReferrer,
  useSignIn,
} from '@dayinsure/shared';
import React, { useState } from 'react';
import { Dayinsure, OpenAPI, PersonType } from '../../../../api/v2';
import {
  SignupFormData,
  signUpFormInitialData,
  SignUpValidationSchema,
} from '../../../../forms';
import { FormPasswordField, FormTextField } from '../../../../components';
import { usePerson, useReferrer } from '../../../../hooks';
import { ReaptchaInput } from '../../../Quote/Review/CreateAccount/ReaptchaInput';

const testId = 'create-account';

const passwordValidationCopy = {
  minCharacters: 'Minimum eight characters',
  uppercase: 'One uppercase letter',
  lowercase: 'One lowercase letter',
  number: 'One number',
  symbol: 'One symbol',
  passwordMatch: 'Passwords match',
};

const passwordValidationRegExpsConfig = {
  uppercaseRegex,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
};

export const SignUp = () => {
  const referrer = useReferrer();
  const navigate = useNavigateWithReferrer();
  const { isFullAccount, isGuest } = usePerson();
  const { signIn } = useSignIn(referrer.name, isGuest);

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const handleSubmit = async (
    values: SignupFormData,
    { setSubmitting, setTouched }: FormikHelpers<SignupFormData>
  ) => {
    const api = new Dayinsure(OpenAPI);
    await api.person
      .postApiV2Person({
        type: PersonType.CUSTOMER,
        email: values.signUp.emailAddress,
        password: values.signUp.password,
        verificationTypes: ['email'],
        identityProvider: referrer.identityProviderName,
        referrer: referrer.name,
        confirmationUrl: `${window.origin}/auth/sign-up/verify`,
        resetPasswordUrl: `${window.origin}/auth/forgotten-password/reset`,
      })
      .then(response => {
        if (response.id) {
          navigate(
            {
              pathname: `./link-sent`,
            },
            { state: { email: values.signUp.emailAddress, id: response.id } }
          );
        } else {
          setErrorDialogOpen(true);
        }
      })
      .catch(() => {
        setErrorDialogOpen(true);
      })
      .finally(() => {
        setSubmitting(false);
        setTouched({});
      });
  };

  const handleGoBack = () => navigate(-1);

  const closeErrorDialog = () => setErrorDialogOpen(false);

  if (isFullAccount) {
    return <NavigateWithReferrer to={{ pathname: '/dashboard' }} />;
  }

  return (
    <PageContainer className="pt-0">
      <Button
        testId="car-details_back-button"
        id="car-details_back-button"
        variant="ghost"
        onClick={handleGoBack}
        text="Back"
        classNames="mt-12 mb-8"
        icon={{
          position: 'left',
          name: 'arrow-left',
          size: '.75rem',
        }}
      />
      <Formik<SignupFormData>
        initialValues={signUpFormInitialData}
        validationSchema={SignUpValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid, dirty, handleBlur }) => (
          <Form className="flex flex-col mx-auto mb-16 w-full md:max-w-tablet-container lg:mb-24 lg:max-w-desktop-container text-main-content-1">
            <h1 className="self-center mt-2 mb-8 text-xl leading-10 text-center sm:mt-6 sm:mb-12">
              Create an account
            </h1>
            <Card paddingLevel="large" classNames="bg-content-background-prominent">
              <FormTextField
                id={`${testId}_email-input`}
                testId={`${testId}_email-input`}
                name="signUp.emailAddress"
                label={{ icon: 'email', text: 'Email address' }}
                placeholder="Enter your email address"
                spacingClassName="mb-8"
                onBlur={handleBlur}
              />

              <FormPasswordField
                id={`${testId}_password-input`}
                testId={`${testId}_password-input`}
                name="signUp.password"
                ariaLabel="Choose a password"
                label={{ icon: 'lock', text: 'Password' }}
                placeholder="Choose a password"
                spacingClassName="mb-8"
                onBlur={handleBlur}
              />

              <FormPasswordField
                id={`${testId}_confirm-password-input`}
                testId={`${testId}_confirm-password-input`}
                name="signUp.retypePassword"
                ariaLabel="Choose a password"
                label={{ icon: 'lock', text: 'Re-type password' }}
                placeholder="Re-type password"
                spacingClassName="mb-8"
                onBlur={handleBlur}
              />

              <PasswordValidation
                password={values.signUp.password}
                confirmPassword={values.signUp.retypePassword}
                copy={passwordValidationCopy}
                regexpsConfig={passwordValidationRegExpsConfig}
              />

              <ReaptchaInput className="mb-8 md:mb-12" testId="create-account" />

              <Button
                id={`${testId}_confirm-cta-btn`}
                testId={`${testId}_confirm-cta-btn`}
                submit
                text="Create an account"
                fullWidth
                classNames="mb-6 sm:mb-8"
                loading={isSubmitting}
                disabled={!isValid || !dirty || !values.signUp.reaptcha}
              />
              <Button
                id={`${testId}_login-existing-btn`}
                testId={`${testId}_login-existing-btn`}
                text="Log in to existing account"
                onClick={() => signIn()}
                variant="secondary"
                fullWidth
              />
            </Card>
          </Form>
        )}
      </Formik>
      <ErrorDialog
        open={errorDialogOpen}
        onClose={closeErrorDialog}
        title="Sorry, there was a problem"
        error="There was a problem creating your account"
        ctaId="close-signup-error"
        ctaMessage="Go back and try again"
      />
    </PageContainer>
  );
};
