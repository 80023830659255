import { YesNoAnswer, YesNoDataEntry } from '../../../../types';

export const provideLicenceOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_provide-licence-number-option-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_provide-licence-number-option-no`,
  },
];

export const licenceTypesWithNumber = ['UK_FULL', 'UK_AUTO', 'UK_PROV'];
