const getObjectPath = (endKey: string, firstKey: string) => {
  const dateFields = ['parsedDate', 'day', 'month', 'year'];
  if (dateFields.includes(endKey)) {
    return firstKey;
  }
  return `${firstKey}.${endKey}`;
};
export const getFirstErrorPath = <T extends Record<string, any>>(obj: T): string => {
  const firstKey = Object.keys(obj)[0];
  const firstKeyValue = obj[firstKey];
  if (typeof firstKeyValue === 'object') {
    if (Array.isArray(firstKeyValue)) {
      const errorIndex = firstKeyValue.findIndex(
        (el: unknown) => el !== undefined && el !== 'empty'
      );
      if (typeof firstKeyValue[errorIndex] === 'string') {
        return `${firstKey}[${errorIndex}]`;
      }
      const arrayEnd = getFirstErrorPath(firstKeyValue[errorIndex]);
      return `${firstKey}[${errorIndex}].${arrayEnd}`;
    }
    const endKey = getFirstErrorPath(firstKeyValue);
    return getObjectPath(endKey, firstKey);
  }
  return firstKey;
};
