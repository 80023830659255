import { FormikErrors } from 'formik';

export const fieldsHaveError = <T>(
  fields: Record<string, string>,
  vehicleErrors?: FormikErrors<T>
): boolean => {
  if (!vehicleErrors) {
    return false;
  }
  const fildsNamesArray = Object.values(fields);

  return Object.keys(vehicleErrors).some(errorKey => fildsNamesArray.includes(errorKey));
};
