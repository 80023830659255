import { Button, Card, Dialog, Icon } from '@dayinsure/components';

type NewRegConfirmationModalProps = {
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
  testId: string;
  message: string;
};

export const NewRegConfirmationModal = ({
  isOpen,
  testId,
  onClose,
  onConfirm,
  message,
}: NewRegConfirmationModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} title="Something went wrong">
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
      >
        <Icon name="warning" size="2rem" className="text-popup-content-1" />
        <p>{message}</p>
      </Card>

      <Button
        id={`${testId}_modal_continue-button`}
        testId={`${testId}_modal_continue-button`}
        fullWidth
        onClick={onConfirm}
        text="I confirm, continue"
        classNames="mt-6 md:mt-8"
      />

      <Button
        id={`${testId}_modal_back-button`}
        testId={`${testId}_modal_back-button`}
        fullWidth
        onClick={onClose}
        text="Go back"
        classNames="mt-6 md:mt-8"
        variant="dialogSecondary"
      />
    </Dialog>
  );
};
