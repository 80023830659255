import { ReactNode } from 'react';

export type PageContainerProps = {
  children?: ReactNode;
  className?: string;
  testId?: string;
};

export const PageContainer = ({ children, className, testId }: PageContainerProps) => {
  return (
    <div
      className={`px-6 md:px-0 lg:px-6 mx-auto sm:max-w-tablet-page md:min-w-tablet-page lg:max-w-desktop-page ${className}`}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
