import { useNavigateWithReferrer } from '@dayinsure/shared';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { QuoteJourneyRoutes, quoteJourneyMap } from '../../../routes';
import { QuoteJourneyFormData } from '../../../types/quoteJourneyForm';
import { QuoteJourneyURLParams } from '../../../types/QuoteJourneyURLParams';

export const useDriversSubmit = (currentQuoteStep: QuoteJourneyRoutes | null) => {
  const navigate = useNavigateWithReferrer();
  const next = quoteJourneyMap.getNext(currentQuoteStep);
  const params = useParams<QuoteJourneyURLParams>();

  const createDriverRoute = () => {
    const paramsCopy = JSON.stringify(params);
    const paramsArr = paramsCopy.split('/');
    if (paramsArr?.length >= 2) {
      return next?.replace(':driverId', paramsArr[1]);
    }
    return next;
  };

  return ({ setSubmitting, setTouched }: FormikHelpers<QuoteJourneyFormData>) => {
    if (next) {
      const route = createDriverRoute();
      if (route) {
        navigate(route);
      }
    }
    setSubmitting(false);
    setTouched({}, false);
  };
};
