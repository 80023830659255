import { useNavigateWithReferrer } from '@dayinsure/shared';
import { Button } from '@dayinsure/components';

type PolicyChangeButtonsProps = {
  policyId: string;
  testId?: string;
  isValid: boolean;
  onContinueClick: () => void;
};

export const PolicyChangeButtons = ({
  policyId,
  testId = 'policy-change',
  isValid = false,
  onContinueClick,
}: PolicyChangeButtonsProps) => {
  const navigate = useNavigateWithReferrer();

  const handleBackToPolicy = () => {
    navigate(`/policies/${policyId}`);
  };

  return (
    <nav>
      <Button
        classNames="mb-8"
        disabled={!isValid}
        id={`${testId}_continue-button`}
        testId={`${testId}_continue-button`}
        fullWidth
        text="Continue"
        icon={{ name: 'arrow-right', position: 'right' }}
        onClick={onContinueClick}
      />

      <Button
        variant="secondary"
        classNames="w-full mb-16"
        id={`${testId}_back-to-policy-button`}
        testId={`${testId}_back-to-policy-button`}
        text="Back to policy"
        onClick={handleBackToPolicy}
      />
    </nav>
  );
};
