import { FormattedAddressDto } from '../../../api/v1';

export const getDriverAddress = (address?: FormattedAddressDto): string => {
  if (!address) {
    return '';
  }

  const { line1 = '', line2 = '', line3 = '', postCode = '' } = address;
  return `${line1} ${line2}, ${line3}. ${postCode}`;
};
