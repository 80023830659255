import { useMutation } from 'react-query';
import {
  Dayinsure as DayInsureAPIv1,
  OpenAPI,
  UpdatePersonRequest,
} from '../../../api/v1';

export const useV1PutPersonMutation = () => {
  const signUp = ({
    personId,
    personData,
  }: {
    personId: string;
    personData: UpdatePersonRequest;
  }) => {
    const api = new DayInsureAPIv1(OpenAPI);

    return api.person.putApiV1Person(personId, personData);
  };

  return useMutation({
    mutationFn: signUp,
  });
};
