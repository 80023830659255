import { Formik, FormikProps, FormikConfig } from 'formik';
import { ReactNode, MouseEvent, KeyboardEvent } from 'react';

type Props<Values> = {
  children: ReactNode;
} & FormikConfig<Values>;

const isElement = (target: EventTarget | null): target is Element => {
  return target instanceof Element;
};

const isButton = (target: EventTarget | null): target is HTMLButtonElement => {
  return target instanceof HTMLButtonElement;
};

const isSubmitButton = (target: EventTarget | null) =>
  (isButton(target) && target.type === 'submit') ||
  isButton(isElement(target) ? target?.closest('[type="submit"]') : null);

export const NestedForm = <Values extends Record<string, unknown>>({
  initialValues,
  onSubmit,
  children,
  validationSchema,
}: Props<Values>) => {
  const checkForSubmitButton = (form: FormikProps<Values>) => {
    return ({ nativeEvent: { target } }: MouseEvent | KeyboardEvent) => {
      if (isSubmitButton(target)) {
        form.handleSubmit();
      }
    };
  };

  const onKeyDownCapture = (form: FormikProps<Values>) => {
    return (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (
          e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLSelectElement ||
          (e.target instanceof HTMLButtonElement && e.target.type !== 'button')
        ) {
          e.preventDefault(); // Prevents outer form from submitting
          form.handleSubmit();
        }
      } else if (e.key === 'Space') {
        checkForSubmitButton(form)(e);
      }
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {form => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <div
          className="w-full"
          role="form"
          onKeyDownCapture={onKeyDownCapture(form)}
          onClick={checkForSubmitButton(form)}
        >
          {children}
        </div>
      )}
    </Formik>
  );
};
