import { useMemo } from 'react';
import { useCoverOptionsQuery } from '../../../../hooks';
import { FormListBox, FormFieldBox } from '../../../../components';
import { getFormFieldAccessPath, numberToCurrencyOptions } from '../../../../helpers';
import { VoluntaryExcessTooltip } from './VoluntaryExcessTooltip';

type VoluntaryExcessProps = {
  testId: string;
};
export const VoluntaryExcess = ({ testId }: VoluntaryExcessProps) => {
  const { data: coverOptions } = useCoverOptionsQuery();

  const voluntaryExcessOptions = useMemo(() => {
    const voluntaryExcessAmountsArr = coverOptions?.voluntaryExcessAmounts || [];
    return voluntaryExcessAmountsArr.map(
      numberToCurrencyOptions(`${testId}_voluntary-excess`)
    );
  }, [coverOptions?.voluntaryExcessAmounts, testId]);

  const provideVoluntaryExcessPath = getFormFieldAccessPath([
    'excesses',
    'voluntaryAmounts[0]',
    'total',
  ]);

  return (
    <div data-testid={testId}>
      <h3 className="relative w-full font-raleway font-normal form-subtitle-compact">
        How much voluntary excess do you want to{' '}
        <span className="inline-block">
          add?
          <VoluntaryExcessTooltip testId={testId} />
        </span>
      </h3>
      <FormFieldBox>
        <FormListBox
          id={`${testId}_voluntary-excess-dropdown`}
          testId={`${testId}_voluntary-excess-dropdown`}
          unselectedMessage="Please select"
          name={provideVoluntaryExcessPath}
          options={voluntaryExcessOptions}
          icon="addexcess"
          label="Voluntary excess amount"
        />
      </FormFieldBox>
    </div>
  );
};
