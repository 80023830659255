import { Card, ContentContainer } from '@dayinsure/components';
import { Form } from 'formik';
import {
  BackButton,
  CarLocation,
  CarOvernightLocation,
  CarUsageMiles,
  CarUsageType,
  MtaChangeButtons,
} from '../../../../components';
import { PolicyMtaPaths } from '../../../../routes';

const testId = 'new-car-usage';

export const NewCarUsage = () => {
  return (
    <div className="font-raleway">
      <BackButton customBackLink={`../${PolicyMtaPaths.NewCar}`} />
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_form-title`}
      >
        Your new car
      </h1>
      <ContentContainer>
        <Card
          testId={`${testId}_check-info_card`}
          paddingLevel="small"
          classNames="mb-6 lg:mb-8"
        >
          <span>Please tell us how the car will be used below.</span>
        </Card>

        <Form>
          <CarUsageType testId={testId} />
          <CarUsageMiles testId={testId} />
          <CarOvernightLocation testId={testId} />
          <CarLocation testId={testId} />
          <MtaChangeButtons />
        </Form>
      </ContentContainer>
    </div>
  );
};
