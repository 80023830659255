import { ReactNode, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { NavigateWithReferrer, useSignIn } from '@dayinsure/shared';
import { LoadingSpinner } from '@dayinsure/components';
import { usePerson, useReferrer } from '../../../hooks';

type ProtectedRouteProps = {
  redirect?: string;
  children: ReactNode;
  isAvaliableForGuset?: boolean;
};

export const AccountProtectedRoute = ({
  redirect = '/',
  children,
  isAvaliableForGuset,
}: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { person, isGuest, isSignedOff, logOutIsInProgress } = usePerson();
  const referrer = useReferrer();
  const { signIn } = useSignIn(referrer.name, isGuest);

  const shouldRefresh = !!user?.refresh_token;
  const cannotRefresh = !user?.refresh_token;

  useEffect(() => {
    if (!isAuthenticated && cannotRefresh && isSignedOff && !logOutIsInProgress) {
      signIn();
    }
  }, [isAuthenticated, cannotRefresh, signIn, isSignedOff, logOutIsInProgress]);

  const showLoader =
    (!isAuthenticated && (shouldRefresh || cannotRefresh)) || isLoading || !person;

  if (showLoader) {
    return (
      <LoadingSpinner
        testId="protected-route_loading-spinner"
        centered
        className="mt-8"
      />
    );
  }

  if (!isAvaliableForGuset && isGuest) {
    return <NavigateWithReferrer to={{ pathname: redirect }} replace />;
  }
  return <>{children}</>;
};
