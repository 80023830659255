import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ApiError, Dayinsure, OpenAPI, OptionCodeDto } from '../../../api/v2';
import { query } from '../queryKeys';

export const useModificationsQuery = (searchTerm: string) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getModifications = () => {
    const api = new Dayinsure(OpenAPI);
    const queryLength = searchTerm.length;
    const take = queryLength > 2 ? undefined : 5;
    return api.product.getApiV2ProductVehicleModifications(
      'motor',
      'latest',
      searchTerm,
      take
    );
  };

  return useQuery<OptionCodeDto[], ApiError>(
    query.vehicleModifications(searchTerm),
    getModifications,
    {
      keepPreviousData: true,
      onError: err => {
        if (err.status === 422) {
          setGlobalError(err, {
            message: 'Something doesn’t look quite right, please go back and try again.',
          });
          return;
        }

        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
