import { OnOffDataEntry, OnOffAnswer } from '../../../types';

export const getOnOffOptions = (testId: string): OnOffDataEntry[] => [
  {
    id: OnOffAnswer.ON,
    name: 'On',
    testId: `${testId}_option-on`,
  },
  {
    id: OnOffAnswer.OFF,
    name: 'Off',
    testId: `${testId}_option-off`,
  },
];
