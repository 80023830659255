import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  ContentContainer,
  Icon,
  PageContainer,
} from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import clsx from 'clsx';
import {
  MtaChangeButtons,
  BackButton,
  Loader,
  RemoveDriverModal,
  MtaCoverStartDate,
} from '../../../../components';
import { DriverList } from './DriverList';
import { usePolicyQuery } from '../../../../hooks';
import { MainDriverSelect } from './DriverList/MainDriverSelect';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { getDriversBackLink, parseDriver, parseStateDriverWithId } from './Drivers.utils';
import { NEW_DRIVER_ID } from '../../../../helpers';
import { InsuranceDeclined } from './InsuranceDeclined';
import { useMainDriver } from '../../../../hooks/useMainDriver';

const testId = 'mta-drivers';

export const Drivers = () => {
  useMainDriver();
  const navigate = useNavigateWithReferrer();
  const { id } = useParams<{ id: string }>();
  const { data: policy } = usePolicyQuery(id);
  const { setFieldValue, values } = useFormikContext<MtaJourneyFormData>();
  const [driverIdToremove, setDriverIdToremove] = useState<string | null>(null);
  const [isRemoveDriverModalOpen, setIsRemoveDriverModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const filteredOutUnfinishedNewDrivers = values.drivers.driversToAdd.filter(
      driver => driver.id !== NEW_DRIVER_ID
    );
    setFieldValue('drivers.driversToAdd', filteredOutUnfinishedNewDrivers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (policy?.drivers) {
      setFieldValue('drivers.drivers', policy.drivers.map(parseDriver));
    }
  }, [policy?.drivers, setFieldValue]);

  const newDrivers = useMemo(
    () => values.drivers.driversToAdd.map(parseStateDriverWithId),
    [values.drivers.driversToAdd]
  );

  const driversToDisplay = [
    ...values.drivers.drivers.filter(
      driver => !values.drivers.driversToRemove.includes(driver.id)
    ),
    ...values.drivers.driversToAdd,
  ];

  if (!policy) {
    return <Loader />;
  }

  const continueIsDisabled =
    JSON.stringify(policy?.drivers) === JSON.stringify(driversToDisplay);
  const displayMainDriverSelect = driversToDisplay.length > 1;
  const isAddDriverButtonHidden = driversToDisplay.length >= 5;
  const handleOpenRemoveDriverModal = (driverId: string) => {
    setDriverIdToremove(driverId);
    setIsRemoveDriverModalOpen(true);
  };

  const handleCloseRemoveDriverModal = () => {
    setDriverIdToremove(null);
    setIsRemoveDriverModalOpen(false);
  };

  const handleRemoveDriver = () => {
    if (values.drivers.driversToAdd.some(driver => driver.id === driverIdToremove)) {
      setFieldValue(
        'drivers.driversToAdd',
        values.drivers.driversToAdd.filter(driver => driver.id !== driverIdToremove)
      );
    } else {
      setFieldValue('drivers.driversToRemove', [
        ...values.drivers.driversToRemove,
        driverIdToremove,
      ]);
      const filteredDrivers = values.drivers.drivers.filter(
        option => option.id !== driverIdToremove
      );
      setFieldValue('drivers.drivers', filteredDrivers);
    }

    setDriverIdToremove(null);
    setIsRemoveDriverModalOpen(false);
  };

  const handleAddDriver = () => navigate(NEW_DRIVER_ID);

  const displayCoverStartDate = !(
    values.policyChange.changeReg || values.policyChange.changeCar
  );

  return (
    <PageContainer className="font-raleway">
      <BackButton customBackLink={getDriversBackLink(values.policyChange)} />
      <h1
        className="my-6 text-xl text-center md:my-8 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Drivers
      </h1>

      <ContentContainer>
        <Card
          testId={`${testId}_info_card`}
          paddingLevel="small"
          classNames="mb-6 lg:mb-8"
        >
          <span>Add or remove drivers below.</span>
        </Card>
        <Form>
          {displayCoverStartDate && <MtaCoverStartDate testId={testId} />}
          <DriverList
            drivers={driversToDisplay}
            testId={testId}
            onDriverRemove={handleOpenRemoveDriverModal}
          />
          {!isAddDriverButtonHidden && (
            <Button
              id={`${testId}_add-driver_button`}
              testId={`${testId}_add-driver_button`}
              text="Add another driver"
              variant="bordered"
              onClick={handleAddDriver}
              classNames="mb-12"
            />
          )}

          {isAddDriverButtonHidden && (
            <Card
              classNames={clsx('flex items-center', 'mb-8', 'mt-6', 'lg:mt-8')}
              paddingLevel="small"
              testId={`${testId}_max-drivers_message`}
            >
              <Icon name="warning" size="2rem" className="text-popup-content-1" />
              <p className="ml-6 text-main-content-1">
                You can’t add any more drivers. You have reached the maximum number of
                drivers that can be added to a policy.
              </p>
            </Card>
          )}
          {displayMainDriverSelect && <MainDriverSelect testId={testId} />}
          {newDrivers.length > 0 && <InsuranceDeclined testId={testId} />}
          <MtaChangeButtons disabled={continueIsDisabled} />
        </Form>
      </ContentContainer>
      <RemoveDriverModal
        isOpen={isRemoveDriverModalOpen}
        testId={testId}
        onClose={handleCloseRemoveDriverModal}
        onConfirm={handleRemoveDriver}
      />
    </PageContainer>
  );
};
