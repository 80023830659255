import {
  IconNames,
  ComboboxProps,
  Combobox,
  FieldError,
  Label,
  Tooltip,
} from '@dayinsure/components';

type ModificationSearchFieldProps = {
  label: {
    icon: IconNames;
    text: string;
  };
  closeTooltip?: string;
  hint?: string;
  tooltip?: string;
  touched?: boolean;
  error?: string;
} & Omit<ComboboxProps, 'error'>;

export const ModificationSearchField = ({
  label,
  tooltip,
  closeTooltip = 'Close',
  hint = 'hint',
  error,
  id,
  disabled,
  touched,
  ...comboboxProps
}: ModificationSearchFieldProps) => {
  return (
    <div className="relative form-field-spacing">
      <div className="flex space-x-2">
        <Label
          name={label.text}
          icon={label.icon}
          htmlFor={id}
          disabled={disabled}
          className="mb-4"
        />
        {tooltip && (
          <Tooltip
            ariaLabel={hint}
            position="relative"
            className="flex flex-col text-popup-content-1"
            variant="annual"
          >
            {tooltip}
            <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
              {closeTooltip}
            </Tooltip.Close>
          </Tooltip>
        )}
      </div>
      <Combobox {...comboboxProps} id={id} error={touched && error !== undefined} />
      {touched && !!error && (
        <FieldError id={`${id}-error`} error={error} className="absolute right-0 mt-2" />
      )}
    </div>
  );
};
