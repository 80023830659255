import { useNavigateWithReferrer } from '@dayinsure/shared';
import { Button } from '@dayinsure/components';
import { AccountPaths } from '../../../../../routes';

type AddCardNavButtonsProps = {
  testId: string;
  disabled?: boolean;
  isLoading?: boolean;
};
export const AddCardNavButtons = ({ testId, disabled, isLoading }: AddCardNavButtonsProps) => {
  const navigate = useNavigateWithReferrer();
  const cancel = () => {
    navigate(`/account/${AccountPaths.PaymentMethods}`);
  };
  return (
    <nav>
      <Button
        classNames="mb-8 mt-4"
        id={`${testId}_add-new-card-button`}
        testId={`${testId}_add-new-card-button`}
        submit
        fullWidth
        text="Add new card"
        loading={isLoading}
        disabled={disabled}
      />
      <Button
        classNames="mb-24 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full"
        id={`${testId}_cancel-button`}
        testId={`${testId}_cancel-button`}
        fullWidth
        text="Cancel"
        onClick={cancel}
        variant="bordered"
      />
    </nav>
  );
};
