/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Policy Transaction Type.
 */
export enum PolicyTransactionType {
  NEW_BUSINESS = 'NewBusiness',
  MID_TERM_ADJUSTMENT = 'MidTermAdjustment',
  RENEWAL = 'Renewal',
  CANCELLATION = 'Cancellation',
  VOIDANCE = 'Voidance',
  LOCK_CHANGE = 'LockChange',
  POLICYHOLDER_ACCOUNT_UPDATE = 'PolicyholderAccountUpdate',
}