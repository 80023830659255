import { FieldError } from '@dayinsure/components';
import { useField } from 'formik';
import { useRef } from 'react';
import { useFocusOnError } from '../../../../../hooks';
import { CoverOption, CoverTypeOptions, CoverTypeOptionsProps } from './CoverTypeOptions';

type FormCoverTypeRadioExtendedProps = {
  id: string;
  name: string;
  onChangeOptional?: (option: CoverOption) => void;
  isInputChangeEnabled?: boolean;
};

type FormCoverTypeRadioProps = Omit<
  CoverTypeOptionsProps,
  'value' | 'onChange' | 'onBlur' | 'error'
> &
  FormCoverTypeRadioExtendedProps;

export const FormCoverTypeRadio = ({
  testId,
  onChangeOptional,
  isInputChangeEnabled = true,
  ...inputProps
}: FormCoverTypeRadioProps) => {
  const [field, meta] = useField(inputProps);
  const fieldRef = useRef<HTMLDivElement | null>(null);
  useFocusOnError({ fieldRef, name: inputProps.name });
  const onChange = (option: CoverOption) => {
    if (onChangeOptional) {
      onChangeOptional(option);
    }

    if (isInputChangeEnabled) {
      field.onChange({ target: { value: option, name: field.name } });
    }
  };

  return (
    <div className="relative form-field-spacing" data-testid={testId} ref={fieldRef}>
      <CoverTypeOptions
        testId={testId}
        {...inputProps}
        {...field}
        onChange={onChange}
        error={meta.touched && meta.error !== undefined}
        aria-errormessage={`${inputProps.id}_error`}
      />
      {meta.touched && meta.error && (
        <FieldError
          testId={`${inputProps.id}_error`}
          id={`${inputProps.id}_error`}
          error={meta.error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
