import { Card } from '@dayinsure/components';
import { VehicleDetailItem } from '../../../../../components';
import { VehicleDto } from '../../../../../api/v1';

type CarDetailsCardProps = {
  testId: string;
  vehicle: VehicleDto;
};

export const CarDetailsCard = ({ testId, vehicle }: CarDetailsCardProps) => {
  return (
    <Card testId={`${testId}_car-details-card`} classNames="w-full mb-6">
      <h3 className="font-raleway text-lg font-normal" data-testid={`${testId}_title`}>
        Car details
      </h3>
      {vehicle.registrationNumber && vehicle.make && vehicle.model && (
        <VehicleDetailItem
          title={vehicle.registrationNumber}
          testId={`${testId}_vehicle-make-and-model-item`}
          className="mt-4 md:mt-6"
        >
          {`${vehicle.make} ${vehicle.model}`}
        </VehicleDetailItem>
      )}
      <Card.CardListItem
        label="Year of manufacture"
        icon="date"
        testId={`${testId}_vehicle-year-of-manufacture-item`}
      >
        {vehicle.manufactureYear}
      </Card.CardListItem>
      <Card.CardListItem
        label="Fuel type"
        icon="funnel"
        testId={`${testId}_vehicle-fuel-type-item`}
      >
        {vehicle.primaryFuelType?.description || vehicle.primaryFuelType?.code}
      </Card.CardListItem>
      <Card.CardListItem
        label="Transmission"
        icon="spanner"
        testId={`${testId}_vehicle-transmission-item`}
      >
        {vehicle.transmission?.description || vehicle.transmission?.code}
      </Card.CardListItem>
      <Card.CardListItem
        label="Number of doors"
        icon="door"
        testId={`${testId}_vehicle-doors-item`}
      >
        {vehicle.numberOfDoors}
      </Card.CardListItem>
    </Card>
  );
};
