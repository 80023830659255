import {
  FieldError,
  Label,
  IconNames,
  PasswordField,
  PasswordFieldProps,
} from '@dayinsure/components';
import clsx from 'clsx';
import { useField } from 'formik';
import { useRef } from 'react';
import { useFocusOnError } from '../../../hooks';

type FormPasswordFieldProps = {
  label: {
    icon: IconNames;
    text: string;
  };
  withErrors?: boolean;
  spacingClassName?: string;
} & Omit<PasswordFieldProps, 'label' | 'error' | 'onChange'>;

export const FormPasswordField = ({
  label,
  withErrors = true,
  spacingClassName,
  ...passwordProps
}: FormPasswordFieldProps) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [field, meta] = useField(passwordProps);
  useFocusOnError({ fieldRef, name: field.name });

  return (
    <div
      className={clsx(
        'relative',
        { 'form-field-spacing': !spacingClassName },
        spacingClassName
      )}
    >
      <div className="flex space-x-2">
        <Label
          name={label.text}
          icon={label.icon}
          htmlFor={passwordProps.id}
          disabled={passwordProps.disabled}
          className={clsx('mb-4', {
            'text-popup-content-1': passwordProps.variant === 'dialog',
          })}
        />
      </div>
      <PasswordField
        {...passwordProps}
        {...field}
        ref={fieldRef}
        error={withErrors && meta.touched && meta.error !== undefined}
        aria-errormessage={`${passwordProps.id}-error`}
      />
      {withErrors && meta.touched && meta.error && (
        <FieldError
          id={`${passwordProps.id}-error`}
          error={meta.error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
