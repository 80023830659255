import { BreakdownPackage } from './BreakdownOption.const';
import { AddOnType } from '../../AddOns.types';

export const getIncludedPackagesBaseOnBreakdownType = (type: string) => {
  let includedIndex: number;
  switch (type) {
    case AddOnType.BREAKDOWN_1:
      includedIndex = 1;
      break;
    case AddOnType.BREAKDOWN_2:
      includedIndex = 2;
      break;
    case AddOnType.BREAKDOWN_3:
      includedIndex = 4;
      break;
    case AddOnType.BREAKDOWN_4:
      includedIndex = 5;
      break;
    default:
      includedIndex = 0;
  }

  return (option: BreakdownPackage, index: number): BreakdownPackage => ({
    ...option,
    isIncluded: index <= includedIndex,
  });
};
