import { useLayoutEffect } from 'react';
import { useFormikContext } from 'formik';
import { isNull } from '@dayinsure/core';
import { FormRadioSelect } from '../../../../components';
import { getFormFieldAccessPath, getYesNoOptions } from '../../../../helpers';
import { ProtectYourClaimsDiscountTooltip } from './ProtectYourClaimsDiscountTooltip';
import { QuoteJourneyFormData } from '../../../../types';

type ProtectYourClaimsDiscountProps = {
  testId: string;
};
export const ProtectYourClaimsDiscount = ({ testId }: ProtectYourClaimsDiscountProps) => {
  const { values } = useFormikContext<QuoteJourneyFormData>();
  const { setFieldValue, setFieldTouched } = useFormikContext<QuoteJourneyFormData>();
  const provideProtectYourClaimsDiscountPath = getFormFieldAccessPath([
    'protectNoClaimsDiscount',
  ]);

  const resetProtectNoClaimsDiscount =
    isNull(values.cover?.noClaimsHistory?.yearsNoClaimsBonus) ||
    values.cover?.noClaimsHistory?.yearsNoClaimsBonus?.id === '0';

  useLayoutEffect(() => {
    if (resetProtectNoClaimsDiscount) {
      setFieldValue(provideProtectYourClaimsDiscountPath, null);
    }
    setFieldTouched(provideProtectYourClaimsDiscountPath, false);
  }, [
    provideProtectYourClaimsDiscountPath,
    resetProtectNoClaimsDiscount,
    setFieldTouched,
    setFieldValue,
    values.cover?.noClaimsHistory?.yearsNoClaimsBonus,
  ]);

  if (resetProtectNoClaimsDiscount) {
    return null;
  }

  return (
    <div className="my-6 md:my-16">
      <h2
        data-testid={`${testId}_protect-your-claims-bonus-label`}
        className="relative w-full font-raleway font-normal form-subtitle-compact"
      >
        Do you want to protect your No Claims{' '}
        <span className="inline-block">
          Bonus?
          <ProtectYourClaimsDiscountTooltip testId={testId} />
        </span>
      </h2>
      <div className="mb-4 md:mb-8">
        <FormRadioSelect
          id={`${testId}_protect-your-claims-bonus-radio`}
          testId={`${testId}_protect-your-claims-bonus-radio`}
          name={provideProtectYourClaimsDiscountPath}
          options={getYesNoOptions(`${testId}_protect-your-claims-bonus`)}
        />
      </div>
    </div>
  );
};
