/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum LineItemType {
  INSURANCE_PREMIUM = 'InsurancePremium',
  PREMIUM_IPT = 'PremiumIPT',
  BASIC_BREAKDOWN = 'BasicBreakdown',
  BASIC_BREAKDOWN_IPT = 'BasicBreakdownIPT',
  PREMIUM_BREAKDOWN = 'PremiumBreakdown',
  PREMIUM_BREAKDOWN_IPT = 'PremiumBreakdownIPT',
  EUROPEAN_COVER = 'EuropeanCover',
  EUROPEAN_COVER_IPT = 'EuropeanCoverIPT',
  ADMIN_FEE = 'AdminFee',
  EXCESS_PROTECTION = 'ExcessProtection',
  EXCESS_PROTECTION_IPT = 'ExcessProtectionIPT',
}