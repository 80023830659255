import { Card, Icon } from '@dayinsure/components';

type InfoCardPropsType = {
  testId?: string;
  costMessage: string;
};

export const InfoCard = ({ testId, costMessage }: InfoCardPropsType) => (
  <section>
    <Card classNames="flex items-center mt-12 mb-8" paddingLevel="small" testId={testId}>
      <Icon className="text-main-content-1" size="2rem" name="info" />

      <div
        className="ml-6 text-main-content-1"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: costMessage }}
      />
    </Card>
  </section>
);
