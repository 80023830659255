import { useFormikContext, getIn } from 'formik';
import { ListboxOption, SelectRadioOption } from '@dayinsure/components';
import { useMemo, useCallback } from 'react';
import get from 'lodash.get';
import {
  FormListBox,
  FormRadioSelect,
  FormTextField,
  FormTooltip,
  FormFieldBox,
} from '../../../../components';
import { useDriverOptionsQuery, useLicenceDurationsQuery } from '../../../../hooks';
import {
  baseOptionDtoToViewOptions,
  optionValueDtoToOptions,
  getFormFieldAccessPath,
  isYes,
} from '../../../../helpers';
import { licenceTypesWithNumber, provideLicenceOptions } from './Licence.const';
import { DriverFormData, QuoteJourneyFormData, YesNoAnswer } from '../../../../types';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';

type LicenceProps = {
  testId: string;
  formNameSpaceKey?: string;
  additionalDriver?: boolean;
};

export const Licence = <T extends QuoteJourneyFormData | MtaJourneyFormData>({
  testId,
  formNameSpaceKey,
  additionalDriver,
}: LicenceProps) => {
  const { data: driverOptionsData } = useDriverOptionsQuery();
  const { data: licenceDurationsData } = useLicenceDurationsQuery();
  const { setFieldTouched, setFieldValue, values } = useFormikContext<T>();
  const currentDriver: DriverFormData = formNameSpaceKey
    ? get(values, formNameSpaceKey)
    : values;
  const licenceTypeOptions = useMemo(
    () =>
      driverOptionsData?.licenceTypes?.map(
        baseOptionDtoToViewOptions(`${testId}_licence-type`)
      ) ?? [],
    [driverOptionsData?.licenceTypes, testId]
  );

  const yearsHeldLicenceOptions = useMemo(
    () =>
      licenceDurationsData?.yearsHeldLicence?.map(
        optionValueDtoToOptions(`${testId}_licence-years-hold`)
      ) ?? [],
    [licenceDurationsData?.yearsHeldLicence, testId]
  );

  const monthsHeldLicenceOptions = useMemo(
    () =>
      licenceDurationsData?.monthsHeldLicence?.map(
        optionValueDtoToOptions(`${testId}_licence-months-hold`)
      ) ?? [],
    [licenceDurationsData?.monthsHeldLicence, testId]
  );

  const provideLicencePath = getFormFieldAccessPath([
    formNameSpaceKey,
    'drivingLicence',
    'provideLicence',
  ]);

  const provideLicenceNumberPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'drivingLicence',
    'number',
  ]);

  const provideLicenceTypePath = getFormFieldAccessPath([
    formNameSpaceKey,
    'drivingLicence',
    'type',
  ]);

  const provideLicenceYearsHeldPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'drivingLicence',
    'yearsHeld',
  ]);

  const provideLicenceMonthsHeldPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'drivingLicence',
    'monthsHeld',
  ]);

  const handleProvideLicenceChange = useCallback(
    (option: SelectRadioOption) => {
      if (option.id === YesNoAnswer.NO) {
        setFieldValue(provideLicenceNumberPath, '');
      }
      setFieldTouched(provideLicenceNumberPath, false);
    },
    [provideLicenceNumberPath, setFieldTouched, setFieldValue]
  );

  const handleLicenceHoldYearsChange = (option: ListboxOption) => {
    if (option.id !== '0') {
      setFieldValue(provideLicenceMonthsHeldPath, null);
      setFieldTouched(provideLicenceMonthsHeldPath, false);
    }
  };

  const displayMonthsHeldLicence = getIn(values, provideLicenceYearsHeldPath)?.id === '0';

  const isLicenceNumberApplicable = () => {
    return (
      currentDriver.drivingLicence.type?.id &&
      licenceTypesWithNumber.includes(currentDriver.drivingLicence.type?.id)
    );
  };

  return (
    <div>
      <FormFieldBox>
        <h3
          className="mb-6 font-raleway text-lg font-normal md:mb-12"
          data-testid={`${testId}_licence-type-label`}
        >
          {additionalDriver
            ? 'What type of licence do they hold?'
            : 'What type of licence do you hold?'}
          <FormTooltip testId={`${testId}_licence-type`}>
            <h4 className="mb-2 font-bold">Licence type</h4>
            <p className="mb-2">
              It&apos;s the responsibility of the proposer to ensure that all drivers
              added to this policy hold valid licences that entitle them to drive the
              insured vehicle legally.
            </p>
            <p className="mb-2">
              Please note: If the type of licence you hold is not shown here, then
              unfortunately we are unable to provide a quotation.
            </p>
          </FormTooltip>
        </h3>
        <div className="flex relative flex-col mb-4 md:mb-8">
          <FormListBox
            unselectedMessage="Please select"
            name={provideLicenceTypePath}
            options={licenceTypeOptions}
            id={`${testId}_licence-type-select`}
            testId={`${testId}_licence-type-select`}
            icon="card"
            label="Licence type"
          />
        </div>
      </FormFieldBox>
      {isLicenceNumberApplicable() && (
        <FormFieldBox>
          <h3
            data-testid={`${testId}_provide-licence-number-label`}
            className="relative w-full font-raleway font-normal form-subtitle-compact"
          >
            {additionalDriver
              ? 'Do you want to provide their driving licence number?'
              : 'Do you want to provide your driving licence number?'}
            <FormTooltip testId={`${testId}_provide-licence-number`}>
              <h4 className="font-bold">Finding your licence number</h4>
              <p>
                It’s in section 5 of your driving licence photocard. The number has 16
                characters, beginning with letters from your surname.
              </p>
            </FormTooltip>
          </h3>
          <div className="mb-4 md:mb-8">
            <FormRadioSelect
              onChangeOptional={handleProvideLicenceChange}
              id={`${testId}_provide-licence-number-radio`}
              testId={`${testId}_provide-licence-number-radio`}
              name={provideLicencePath}
              options={provideLicenceOptions(testId)}
            />
          </div>

          {isYes(currentDriver?.drivingLicence.provideLicence?.id) && (
            <div className="mb-4 md:mb-8">
              <FormTextField
                label={{ text: 'Licence number', icon: 'card' }}
                testId={`${testId}_provide-licence-number-input`}
                id={`${testId}_provide-licence-number-input`}
                name={provideLicenceNumberPath}
                placeholder="Enter 16 digits"
              />
            </div>
          )}
        </FormFieldBox>
      )}

      <FormFieldBox>
        <h3
          className="mb-6 font-raleway text-lg font-normal md:mb-12"
          data-testid={`${testId}_licence-hold-time-label`}
        >
          {additionalDriver
            ? 'How long have they held their licence for?'
            : 'How long have you held your licence for?'}
        </h3>
        <div className="flex relative flex-col mb-4 md:mb-8">
          <FormListBox
            unselectedMessage="Please select"
            name={provideLicenceYearsHeldPath}
            options={yearsHeldLicenceOptions}
            id={`${testId}_licence-hold-years-select`}
            testId={`${testId}_licence-hold-years-select`}
            icon="date"
            label="Number of years"
            onChangeOptional={handleLicenceHoldYearsChange}
          />
        </div>
      </FormFieldBox>
      {displayMonthsHeldLicence && (
        <FormFieldBox>
          <div className="flex relative flex-col mb-4 md:mb-8">
            <FormListBox
              unselectedMessage="Please select"
              name={provideLicenceMonthsHeldPath}
              options={monthsHeldLicenceOptions}
              id={`${testId}_licence-hold-months-select`}
              testId={`${testId}_licence-hold-months-select`}
              icon="date"
              label="Number of months"
            />
          </div>
        </FormFieldBox>
      )}
    </div>
  );
};
