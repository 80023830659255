import { useContext } from 'react';
import { useQuery } from 'react-query';
import { GlobalErrorContext } from '@dayinsure/shared';
import { query } from '../queryKeys';
import {
  AddressDto,
  ApiError,
  Dayinsure as DayinsureAPIv3,
  OpenAPI,
} from '../../../api/v3';

export const useAddressDetailQuery = (postkey: string | undefined) => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const api = new DayinsureAPIv3(OpenAPI);

  const getAddressList = () => {
    return api.address.getApiV3AddressDetail(postkey || '');
  };

  return useQuery<AddressDto, ApiError>(
    query.addressDetail(postkey || ''),
    getAddressList,
    {
      refetchOnWindowFocus: false,
      enabled: !!postkey,
      retry: (count, error: ApiError) => error.status !== 404 && count < 3,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
