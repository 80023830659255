import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv2,
  EndorsementsDto,
  OpenAPI,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useEndorsementsQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getEndorsements = () => {
    const api = new DayinsureAPIv2(OpenAPI);
    return api.product.getApiV2ProductSchemeEndorsements(
      'motor',
      'latest',
      'everyday_scheme'
    );
  };
  return useQuery<Array<EndorsementsDto>, ApiError>(query.endorsements, getEndorsements, {
    keepPreviousData: true,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
