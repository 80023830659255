import { Card, Icon } from '@dayinsure/components';
import { format } from 'date-fns';
import { Driver } from '../../../../../../types/mtaJourneyForm';
import { DriverFormData } from '../../../../../../types';
import { isDriverType, isDriverPolicyHolder } from '../DriverList.utils';

type DriverListItemProps = {
  driver: Driver | DriverFormData;
  testId: string;
  onDriverRemove: () => void;
};

export const DriverListItem = ({
  driver,
  testId,
  onDriverRemove,
}: DriverListItemProps) => {
  const isDriver = isDriverType(driver);
  const isPolicyHolder = isDriverPolicyHolder(driver);
  const name = `${driver.firstName} ${driver.lastName}`;
  const dob = isDriver ? driver.dateOfBirth : driver.dateOfBirth?.parsedDate;
  const birthdate = format(new Date(dob || ''), 'dd/MM/yyyy');
  const title = isPolicyHolder ? 'Policyholder' : 'Additional driver';

  return (
    <Card classNames="mb-8" testId={`${testId}_list-item`}>
      <div className="flex justify-between">
        <h3 className="font-raleway text-lg font-normal">{title}</h3>
        <div className="flex flex-col items-end md:flex-row">
          {!isPolicyHolder && (
            <button
              className="flex items-center mr-0 mb-2 text-sm font-semibold leading-[14px] md:mr-4 md:mb-0"
              data-testid={`${testId}_remove-driver-button`}
              onClick={onDriverRemove}
              type="button"
            >
              <Icon name="remove" className="mr-2" size="12px" />
              Remove
            </button>
          )}
        </div>
      </div>
      <Card.CardListItem label="Name" icon="user" testId={`${testId}_list-item_name`}>
        {name}
      </Card.CardListItem>
      <Card.CardListItem
        label="Date of birth"
        icon="date"
        testId={`${testId}_list-item_birthday`}
      >
        {birthdate}
      </Card.CardListItem>
      <Card.CardListItem
        label="Licence type"
        icon="card"
        testId={`${testId}_list-item_licence-type`}
      >
        {isDriver
          ? driver.drivingLicence?.type?.description || driver.drivingLicence?.type?.code
          : driver.drivingLicence?.type?.name}
      </Card.CardListItem>
    </Card>
  );
};
