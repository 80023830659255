import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, ErrorDialog, PageContainer } from '@dayinsure/components';
import { useReferrer } from '../../../../../hooks';
import { OpenAPI, Dayinsure } from '../../../../../api/v2';

const testId = 'account-verification-failure';

type LocationState = {
  emailConfirmationToken?: string;
  personId?: string;
};

export const VerificationFailure = () => {
  const referrer = useReferrer();
  const state = useLocation().state as LocationState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLinkResent, setIsLinkResent] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const handleOpenErrorDialog = () => {
    setErrorDialogOpen(true);
    setIsLinkResent(false);
  };

  const handleCloseErrorDialog = () => setErrorDialogOpen(false);

  const handleResendLink = () => {
    if (!state?.personId || !state?.emailConfirmationToken) {
      setErrorDialogOpen(true);
    } else {
      setIsSubmitting(true);

      // Temporarily remove the auth token, as the user could be signed in as a guest,
      // but this endpoint doesn't allow auth tokens.
      const storedToken = OpenAPI.TOKEN;
      OpenAPI.TOKEN = undefined;
      const api = new Dayinsure(OpenAPI);

      api.person
        .postApiV2PersonConfirmAccountEmail(state.personId, {
          referrer: referrer.name,
          tenant: referrer.identityProviderName,
          confirmationUrl: `${window.origin}/auth/sign-up/verify`,
          returnUrl: `${window.location.href}`,
        })
        .then(response => {
          if (response.isSuccess) {
            setIsLinkResent(true);
          } else {
            handleOpenErrorDialog();
          }
        })
        .catch(handleOpenErrorDialog)
        .finally(() => {
          setIsSubmitting(false);
          OpenAPI.TOKEN = storedToken;
        });
    }
  };

  return (
    <PageContainer className="pt-6">
      <div className="flex flex-col mx-auto mb-16 w-full md:max-w-tablet-container lg:mb-24 lg:max-w-desktop-container text-main-content-1">
        <h1
          className="self-center mt-2 mb-8 text-xl leading-10 text-center sm:mt-6 sm:mb-12"
          data-testid={`${testId}_title`}
        >
          Secure link expired
        </h1>

        <Card paddingLevel="large" classNames="bg-content-background-prominent">
          <Card
            paddingLevel="small"
            classNames="bg-content-background-prominent bg-opacity-5"
          >
            <p className="rounded-lg" data-testid={`${testId}_card-text`}>
              The secure link we sent you has expired. Click to resend a new link to your
              email.
            </p>
          </Card>
          <Button
            classNames="mt-8 md:mt-12"
            id={`${testId}_resend-link-button`}
            testId={`${testId}_resend-link-button`}
            text="Resend link"
            onClick={handleResendLink}
            fullWidth
            loading={isSubmitting}
            disabled={isLinkResent}
          />
        </Card>
      </div>
      <ErrorDialog
        open={errorDialogOpen}
        onClose={handleCloseErrorDialog}
        title="Sorry, there was a problem"
        error="There was a problem sending your verification email"
        ctaId="close-account-details-error"
        ctaMessage="Go back and try again"
      />
    </PageContainer>
  );
};
