import { LabelTooltip } from '../../../../components';

type NumberOfChildrenTooltipProps = {
  testId: string;
};

export const NumberOfChildrenTooltip = ({ testId }: NumberOfChildrenTooltipProps) => {
  return (
    <LabelTooltip
      buttonText="Got it"
      label="Number of children"
      testId={`${testId}_children-in-household-label`}
    >
      <h4 className="mb-2 font-bold">Number of children under 16</h4>
      <p className="mb-2">
        Please tell us about any children under 16 who are currently living with you,
        including:
      </p>
      <ul className="pl-4 list-disc">
        <li>Your own children (including adopted and long-term fostered children)</li>
        <li>Your partner’s children</li>
      </ul>
    </LabelTooltip>
  );
};
