import { getReferrerNameFromOidcState, NavigateWithReferrer } from '@dayinsure/shared';
import { useSearchParams } from 'react-router-dom';

export const Logout = () => {
  const [searchParams] = useSearchParams();
  const oidcStateParam = searchParams.get('state');

  if (oidcStateParam) {
    const referrerName = getReferrerNameFromOidcState(oidcStateParam, 'dayinsure');
    return (
      <NavigateWithReferrer
        to={{ pathname: '/quote', search: `referrer=${referrerName}` }}
      />
    );
  }

  return <NavigateWithReferrer to={{ pathname: '/quote' }} />;
};
