import { useContext, useState } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv1,
  OpenAPI,
  OrderDto,
} from '../../../api/v1';
import { query } from '../queryKeys';
import { ORDER_STATES } from '../../../constants/payments';

export const useGetOrder = (
  id: string | undefined | null,
  enabled: boolean,
  onOrderMaxAttemptReached: () => void
) => {
  const [attemptsNo, setAttemptsNo] = useState(0);
  const [completed, setCompleted] = useState(false);
  const { setGlobalError } = useContext(GlobalErrorContext);
  const getOrder = () => {
    if (!id) {
      throw new Error('No order ID');
    }
    const api = new DayinsureAPIv1(OpenAPI);
    return api.order.getApiV1Order(id);
  };

  return useQuery<OrderDto & { completed?: boolean }, ApiError>(
    query.order(id || ''),
    getOrder,
    {
      keepPreviousData: true,
      enabled: id !== null && id !== undefined && enabled,
      cacheTime: 0,
      refetchInterval: !completed ? 2000 : false,
      onSuccess: data => {
        setAttemptsNo((attempts: number) => attempts + 1);
        if (
          data.status === ORDER_STATES.authorised ||
          data.status === ORDER_STATES.complete
        ) {
          setCompleted(true);
          return { data, completed };
        }

        if (attemptsNo > 3) {
          setCompleted(true);
          onOrderMaxAttemptReached();
        }
        return null;
      },
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
