/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MethodAttributes {
  REUSE_SLOT = 'ReuseSlot',
  PRIVATE_SCOPE = 'PrivateScope',
  PRIVATE = 'Private',
  FAM_ANDASSEM = 'FamANDAssem',
  ASSEMBLY = 'Assembly',
  FAMILY = 'Family',
  FAM_ORASSEM = 'FamORAssem',
  PUBLIC = 'Public',
  MEMBER_ACCESS_MASK = 'MemberAccessMask',
  UNMANAGED_EXPORT = 'UnmanagedExport',
  STATIC = 'Static',
  FINAL = 'Final',
  VIRTUAL = 'Virtual',
  HIDE_BY_SIG = 'HideBySig',
  NEW_SLOT = 'NewSlot',
  VTABLE_LAYOUT_MASK = 'VtableLayoutMask',
  CHECK_ACCESS_ON_OVERRIDE = 'CheckAccessOnOverride',
  ABSTRACT = 'Abstract',
  SPECIAL_NAME = 'SpecialName',
  RTSPECIAL_NAME = 'RTSpecialName',
  PINVOKE_IMPL = 'PinvokeImpl',
  HAS_SECURITY = 'HasSecurity',
  REQUIRE_SEC_OBJECT = 'RequireSecObject',
  RESERVED_MASK = 'ReservedMask',
}