import { Card, ContentContainer } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { QuoteDetailsReview } from '../../../Quote/Review/QuoteDetailsReview';
import { ConditionsCard } from '../../../../components/Quote';
import { Documents } from '../../../../components/Documents';
import { PaymentCard } from '../../../../components/PaymentCard';
import { ReviewButtons } from './ReviewButtons/ReviewButtons';
import { usePaymentInfo } from '../../../../hooks';
import { BackButton, ExcessesCard } from '../../../../components';
import { PolicyMtaPaths } from '../../../../routes';
import { FeesAndCharges } from '../../../Quote/Review/FeesAndCharges';
import { PaymentPlansEnum, PaymentTypesEnum } from '../../../../constants/payments';
import { QuoteJourneyFormData } from '../../../../types';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { PaymentPlanDto } from '../../../../api/v1';
import { NewMonthlyPayments } from '../../../Quote';

const testId = 'review';

const isAnnualPaymentPlan = (paymentPlans?: PaymentPlanDto[] | null) => {
  return (
    paymentPlans?.length === 1 && paymentPlans[0].type?.code === PaymentPlansEnum.ANN
  );
};

export const Review = () => {
  const { data, isLoading } = usePaymentInfo();
  const {
    values: { usualPaymentFrequency },
  } = useFormikContext<QuoteJourneyFormData | MtaJourneyFormData>();
  const isPlanAnnual =
    isAnnualPaymentPlan(data?.paymentPlans) ||
    usualPaymentFrequency?.code?.id === PaymentTypesEnum.ANNUAL;

  return (
    <div className="font-raleway">
      <BackButton customBackLink={`../${PolicyMtaPaths.PolicyConditions}`} />
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_form-title`}
      >
        Review
      </h1>
      <ContentContainer>
        <Card
          testId={`${testId}_cards_info-card`}
          paddingLevel="small"
          classNames="mb-8 lg:mb-12"
        >
          <span>Please check all the details below are correct before purchasing.</span>
        </Card>
        <QuoteDetailsReview testId={testId} mta />
        {data && !isPlanAnnual && (
          <div className="my-6 md:my-12">
            <NewMonthlyPayments
              usualPaymentFrequency={usualPaymentFrequency}
              testId={testId}
              paymentPlans={data.paymentPlans}
              insurancePrice={data.insurancePrice}
              smallSize
            />
          </div>
        )}
        <ConditionsCard testId={testId} />
        <Documents testId={testId} />
        <FeesAndCharges
          isPlannAnnual={isPlanAnnual}
          testId={testId}
          feesForDisplay={data?.insurancePrice?.feesForDisplay}
        />
        <ExcessesCard testId={testId} noEdit />

        <div className="my-6 md:my-12">
          {data && (
            <PaymentCard
              addOns={data.addOns}
              insurancePrice={data.insurancePrice}
              paymentPlans={data.paymentPlans}
              cover={data.cover}
              totalPriceIncAddOns={data.totalPriceIncAddOns}
              excesses={data.excesses}
              disabled={isLoading}
              testId={testId}
              mta
              usualPaymentFrequency={usualPaymentFrequency}
            />
          )}
        </div>

        <Form>
          <ReviewButtons />
        </Form>
      </ContentContainer>
    </div>
  );
};
