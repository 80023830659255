export const resetPasswordCopy = {
  title: 'Reset password',
  newPasswordLabel: 'New password',
  newPasswordPlaceholder: 'Choose a password',
  newPasswordAriaLabel: 'Enter your new password',
  confirmPasswordLabel: 'Re-type new password',
  confirmPasswordPlaceholder: 'Re-type password',
  confirmPasswordAriaLabel: 'Re-type your new password',
  ctaButton: 'Submit new password',
  errorTitle: 'Sorry, there was a problem',
  errorMsg: 'There was a error resetting your password',
  errorCta: 'Go back and try again',
};

export const passwordValidationCopy = {
  minCharacters: 'Minimum eight characters',
  uppercase: 'One uppercase letter',
  lowercase: 'One lowercase letter',
  number: 'One number',
  symbol: 'One symbol',
  passwordMatch: 'Passwords match',
};
