import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
  OptionCodeDto,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useImmobiliserQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getImmobilisers = () => {
    const api = new DayinsureAPIv2(OpenAPI);
    return api.product.getApiV2ProductCanbusImmobilisers('motor', 'latest');
  };
  return useQuery<Array<OptionCodeDto>, ApiError>(query.immobilisers, getImmobilisers, {
    keepPreviousData: true,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
