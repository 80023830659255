import { FormikHelpers, useFormikContext } from 'formik';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import { NestedForm } from '../../../components';
import {
  DriverFormData,
  QuoteJourneyFormData,
  QuoteJourneyURLParams,
} from '../../../types';
import { getValidationSchemaFromAdditionalDriverRoute } from '../../../forms';
import { NEW_DRIVER_ID, getEmptyAdditionalDriver } from '../../../helpers';
import { quoteJourneyMap, QuoteJourneyRoutes } from '../../../routes';

export const AdditionalDriverWrapper = () => {
  const { pathname } = useLocation();
  const { driverId, ...address } = useParams<QuoteJourneyURLParams>();
  const navigate = useNavigateWithReferrer();
  const {
    values: { drivers: mainFormDrivers },
    setFieldValue: setMainFormFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const getDriverDataFromValues = useCallback(
    (driverDataId: string | undefined) => {
      const driverIndex = mainFormDrivers.findIndex(
        (driver: DriverFormData) => driver?.id === driverDataId
      );
      const driver = mainFormDrivers[driverIndex];

      return {
        driver,
        driverIndex,
      };
    },
    [mainFormDrivers]
  );

  const getNewDriverData = useCallback(() => {
    const driver = getEmptyAdditionalDriver();
    const driverIndex = mainFormDrivers.length;

    return {
      driver,
      driverIndex,
    };
  }, [mainFormDrivers.length]);

  const { driver, driverIndex } = useMemo(() => {
    if (driverId === NEW_DRIVER_ID) {
      return getNewDriverData();
    }
    return getDriverDataFromValues(driverId);
  }, [getDriverDataFromValues, getNewDriverData, driverId]);

  const saveDriver = (
    values: DriverFormData,
    { setTouched }: FormikHelpers<DriverFormData>
  ) => {
    const route = JSON.stringify(address);
    if (route.includes(QuoteJourneyRoutes.DriverDetails)) {
      const nextRoute = quoteJourneyMap.getNext(QuoteJourneyRoutes.DriverDetails) || './';
      navigate(nextRoute);
    } else {
      const nextRoute =
        quoteJourneyMap.getNext(QuoteJourneyRoutes.DriverLicenceAndHistory) || './';
      setMainFormFieldValue(`drivers[${driverIndex}]`, values);
      navigate(nextRoute);
    }
    setTouched({}, false);
  };
  return (
    <>
      <NestedForm<DriverFormData>
        initialValues={driver}
        onSubmit={saveDriver}
        validationSchema={getValidationSchemaFromAdditionalDriverRoute(pathname)}
      >
        <Outlet />
      </NestedForm>
    </>
  );
};
