import { Icon, IconNames } from '@dayinsure/components';
import { LinkWithReferrer } from '@dayinsure/shared';
import { useMatch, useResolvedPath } from 'react-router-dom';

export type NavLinkProps = {
  id: string;
  /** The text displayed on the link */
  linkText: string;
  /** The route path for the link to navigate to */
  to: string;
  /** The icon which will be displayed alongside the link */
  icon: {
    name: IconNames;
  };
};

const NavLink = ({ id, linkText, to, ...props }: NavLinkProps): JSX.Element => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <LinkWithReferrer
      id={id}
      className={`text-md h-4 inline-flex gap-2 items-center text-header-content-2 font-semibold ${
        match ? 'opacity-100' : 'opacity-60'
      }`}
      to={to}
    >
      <Icon
        name={props.icon.name}
        className={match ? 'text-header-content-1' : 'opacity-60'}
      />
      {linkText}
    </LinkWithReferrer>
  );
};

export { NavLink };
