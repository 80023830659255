import { useNavigateWithReferrer } from '@dayinsure/shared';
import { ReactNode, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import { MtaJourneyFormData } from '../../../types/mtaJourneyForm';
import { getEmptyAdditionalDriver, NEW_DRIVER_ID } from '../../../helpers';
import { Loader } from '../../Common';
import { PolicyMtaPaths } from '../../../routes';

type MtaAdditionalDriverGuardProps = {
  children: ReactNode;
};

export const MtaAdditionalDriverGuard = ({ children }: MtaAdditionalDriverGuardProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigateWithReferrer();
  const { values, setFieldValue } = useFormikContext<MtaJourneyFormData>();

  const driverToAddIndex = values.drivers.driversToAdd.findIndex(
    driver => driver.id === NEW_DRIVER_ID
  );
  const isEmptyDriver = driverToAddIndex !== -1;

  useEffect(() => {
    if (!isEmptyDriver && pathname.includes(PolicyMtaPaths.DriverDetails)) {
      const emptyDriver = getEmptyAdditionalDriver(NEW_DRIVER_ID);
      const driversToAdd = [...values.drivers.driversToAdd, emptyDriver];
      setFieldValue('drivers.driversToAdd', driversToAdd);
    }

    if (!isEmptyDriver && pathname.includes(PolicyMtaPaths.DriverLicenceAndHistory)) {
      navigate(`../../../${PolicyMtaPaths.PolicyChange}`);
    }
  }, [isEmptyDriver, navigate, pathname, setFieldValue, values.drivers.driversToAdd]);

  return isEmptyDriver ? <>{children}</> : <Loader />;
};
