import { Card } from '@dayinsure/components';
import { EditCardLink } from '../../EditCardLink';
import { QuoteJourneyRoutes } from '../../../../routes';

type CoverTypeItemProps = {
  type: string;
};
export const CoverTypeItem = ({ type }: CoverTypeItemProps) => {
  return (
    <Card.CardListItem
      testId="quote-summary-card_edit-cover-type-item"
      label="Cover type"
      asideItem={
        <EditCardLink
          to={QuoteJourneyRoutes.Cover}
          testId="quote-summary-card_edit-cover-type-link"
          className="text-white"
        >
          Edit
        </EditCardLink>
      }
      icon="policy"
    >
      <span className="block">{type}</span>
    </Card.CardListItem>
  );
};
