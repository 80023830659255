import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import {
  ApiError,
  OptionCodeDto,
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useEmploymentIndustriesQuery = (empIndustriesQuery: string) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getEmploymentIndustries = () => {
    const api = new DayinsureAPIv2(OpenAPI);
    const queryLength = empIndustriesQuery.length;
    const take = queryLength > 2 ? undefined : 5;
    return api.product.getApiV2ProductEmploymentIndustries(
      'motor',
      'latest',
      empIndustriesQuery,
      take
    );
  };
  return useQuery<OptionCodeDto[], ApiError>(
    query.employmentIndustries(empIndustriesQuery),
    getEmploymentIndustries,
    {
      keepPreviousData: true,
      cacheTime: 900000, // 15 mins
      retry: false,
      refetchOnWindowFocus: false,
      onError: err => {
        if (err.status === 422) {
          setGlobalError(err, {
            message: 'Something doesn’t look quite right, please go back and try again.',
          });
          return;
        }
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
