/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ParameterAttributes {
  NONE = 'None',
  IN = 'In',
  OUT = 'Out',
  LCID = 'Lcid',
  RETVAL = 'Retval',
  OPTIONAL = 'Optional',
  HAS_DEFAULT = 'HasDefault',
  HAS_FIELD_MARSHAL = 'HasFieldMarshal',
  RESERVED3 = 'Reserved3',
  RESERVED4 = 'Reserved4',
  RESERVED_MASK = 'ReservedMask',
}