import { ReactNode, forwardRef, ForwardedRef } from 'react';
import clsx from 'clsx';

type FormFieldBoxProps = {
  children: ReactNode;
  className?: string;
  testId?: string;
};

export const FormFieldBox = forwardRef(
  (
    { children, className, testId }: FormFieldBoxProps,
    ref?: ForwardedRef<HTMLDivElement>
  ) => (
    <div className={clsx('mb-6 md:mb-12', className)} data-testid={testId} ref={ref}>
      {children}
    </div>
  )
);
