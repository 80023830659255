/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressDto } from '../models/AddressDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AddressService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * GetAddressByPostCode - Returns a list of addresses for a given postcode each with an accompanying postkey to uniquely identify the address and a short summary
   * @param postcode REQUIRED - A valid UK Postcode e.g. A9 9AA
   * @param houseNumber OPTIONAL - The house number of the desired address used to restrict matching results
   * @returns AddressDto Success
   * @throws ApiError
   */
  public getApiV3Address(
    postcode: string,
    houseNumber?: string,
  ): CancelablePromise<Array<AddressDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/address/{postcode}',
      path: {
        'postcode': postcode,
      },
      query: {
        'houseNumber': houseNumber,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * GetAddressByPostKey - Gets a detailed address given a postkey (returned from an address postcode lookup)
   * @param postkey REQUIRED - A unique string identifier for an address (returned from an address summary)
   * @returns AddressDto Success
   * @throws ApiError
   */
  public getApiV3AddressDetail(
    postkey: string,
  ): CancelablePromise<AddressDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/address/{postkey}/detail',
      path: {
        'postkey': postkey,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

}