import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { Driver, MtaJourneyFormData } from '../types/mtaJourneyForm';
import { DriverFormData } from '../types';
import { isDriverType } from '../pages/Policies/PoliciesMta/Drivers/DriverList/DriverList.utils';

const driverToOption = (driver: Driver | DriverFormData) => {
  const dob = isDriverType(driver) ? driver.dateOfBirth : driver.dateOfBirth?.parsedDate;
  return {
    id: driver.id || '',
    name: `${driver.firstName} ${driver.lastName} 
    (${format(new Date(dob || ''), 'dd/MM/yyyy')})`,
  };
};
export const useMainDriver = () => {
  const { setFieldValue, values, setFieldTouched } =
    useFormikContext<MtaJourneyFormData>();
  const drivers = useMemo(
    () => [
      ...values.drivers.drivers.filter(
        driver => !values.drivers.driversToRemove.includes(driver.id)
      ),
      ...values.drivers.driversToAdd,
    ],
    [values.drivers.drivers, values.drivers.driversToAdd, values.drivers.driversToRemove]
  );
  const mainDriverOptions = useMemo(() => drivers.map(driverToOption), [drivers]);

  useEffect(() => {
    if (!values.drivers.mainDriver) {
      setFieldTouched('mainDriver', false);
      const mainDriver = drivers.find(driver => driver.isMainDriver);
      const mainDriverOption = mainDriverOptions.find(
        option => option.id === mainDriver?.id
      );
      setFieldValue('drivers.mainDriver', mainDriverOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drivers]);

  return { mainDriverOptions };
};
