import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentContainer, Card } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { NewRegCarDetailsCards } from './NewRegCarDetailsCards';
import { TheSameCar } from './TheSameCar';
import { usePolicyQuery, useRegistrationSearchQuery } from '../../../../hooks';
import { NewRegistrationPlate } from './NewRegistrationPlate';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { NewRegConfirmationModal } from './NewRegConfirmationModal';
import {
  BackButton,
  Loader,
  MtaChangeButtons,
  MtaCoverStartDate,
  SameRegistrationPlateCard,
} from '../../../../components';
import { PolicyMtaPaths } from '../../../../routes';

const testId = 'mta-new-reg';
const message =
  'The details for the new reg don’t match your car. Please confirm you’re not changing your car and the new reg is for the same car. We’ll contact you if we can’t verify these details.';
const backLink = `../${PolicyMtaPaths.PolicyChange}`;

export const NewReg = () => {
  const { id } = useParams<{ id: string }>();
  const { data: policy } = usePolicyQuery(id);
  const {
    values,
    setFieldError,
    setFieldValue,
    setFieldTouched,
    submitForm,
    isValid,
    validateForm,
  } = useFormikContext<MtaJourneyFormData>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const registrationSearchQuery = useRegistrationSearchQuery();

  useEffect(() => {
    if (policy?.vehicle) {
      setFieldValue('newReg.vehicleId', policy?.vehicle.id);
      setFieldValue('newReg.abiCode', policy?.vehicle.abiCode);
    }
  }, [policy, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      'isSameRegPlate',
      policy?.vehicle?.registrationNumber === values.newReg.regPlate
    );
  }, [setFieldValue, policy?.vehicle?.registrationNumber, values.newReg.regPlate]);

  if (!policy) {
    return null;
  }

  const handleSearchCar = async (): Promise<null | boolean> => {
    try {
      setIsLoading(true);
      const carDetails = await registrationSearchQuery(values.newReg.regPlate || '');
      if (carDetails.detail) {
        setFieldValue('newReg.regPlateValid', true);
        setFieldTouched('newReg.regPlateValid', false, false);
        return values.newReg.abiCode !== carDetails.detail.abiCode;
      }
    } catch (error) {
      setFieldValue('newReg.regPlateValid', false, false);
      setFieldError('newReg.regPlate', "Sorry we can't find this vehicle");
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  const navigateToGetQuote = async () => {
    await submitForm();
    setModalOpen(false);
  };

  const handleContinue = async () => {
    const errors = await validateForm();
    if (Object.entries(errors).length > 0) {
      await submitForm();
      return;
    }
    setFieldTouched('newReg.regPlate', true);
    const regMatched = await handleSearchCar();

    if (regMatched === null) {
      return;
    }

    if (isValid) {
      if (regMatched) {
        setModalOpen(true);
      } else {
        await navigateToGetQuote();
      }
    } else {
      await submitForm();
    }
  };

  const backToPolicyChange = () => {
    setModalOpen(false);
  };

  return (
    <div className="font-raleway">
      <BackButton customBackLink={backLink} />
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_form-title`}
      >
        Your new reg
      </h1>

      <ContentContainer>
        <Card
          testId={`${testId}_check-info_card`}
          paddingLevel="small"
          classNames="mb-6 lg:mb-8"
        >
          <span>Please enter the new reg for your current car below.</span>
        </Card>

        <Form>
          <MtaCoverStartDate testId={testId} />
          {policy?.vehicle && (
            <NewRegCarDetailsCards testId={testId} vehicle={policy.vehicle} />
          )}
          <TheSameCar testId={testId} />
          <div className="relative">
            <NewRegistrationPlate onBlur={handleSearchCar} testId={testId} />
            {values.isSameRegPlate && (
              <SameRegistrationPlateCard testId={testId} className="mb-12 md:mb-16" />
            )}
            <div className="absolute top-14 right-[50px] md:top-10 md:left-[50px]">
              {isLoading && <Loader />}
            </div>
          </div>
          <MtaChangeButtons
            onContinue={handleContinue}
            disabled={values.isSameRegPlate}
          />
        </Form>
      </ContentContainer>
      <NewRegConfirmationModal
        isOpen={modalOpen}
        onClose={backToPolicyChange}
        onConfirm={navigateToGetQuote}
        testId={testId}
        message={message}
      />
    </div>
  );
};
