import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import { Dayinsure as DayinsureAPIv2, OpenAPI } from '../../../api/v2';
import { query } from '../queryKeys';
import { ApiError, OptionCodeDto } from '../../../api/v1';

export const useMedicalConditionsOptionsQuery = (enabled: boolean) => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const getMedicalConditions = () => {
    const api = new DayinsureAPIv2(OpenAPI);
    return api.product.getApiV2ProductMedicalConditions('motor', 'latest');
  };
  return useQuery<OptionCodeDto[], ApiError>(
    query.medicalConditionsOptions,
    getMedicalConditions,
    {
      keepPreviousData: true,
      enabled,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
