import { CheckboxSelect, CheckboxSelectProps, FieldError } from '@dayinsure/components';
import { useField } from 'formik';

type FormCheckboxSelectProps = Omit<
  CheckboxSelectProps,
  'value' | 'onChange' | 'onBlur' | 'error' | 'onClick'
>;

export const FormCheckboxSelect = ({
  ...checkboxSelectProps
}: FormCheckboxSelectProps) => {
  const [field, meta] = useField(checkboxSelectProps);

  return (
    <div className="relative form-field-spacing">
      <CheckboxSelect
        {...checkboxSelectProps}
        {...field}
        onChange={value => {
          field.onChange({ target: { value, name: field.name } });
        }}
        onBlur={() => {
          field.onBlur({ target: { name: field.name } });
        }}
        error={meta.touched && meta.error !== undefined}
        aria-errormessage={`${checkboxSelectProps.id}-error`}
      />
      {meta.touched && meta.error && (
        <FieldError
          id={`${checkboxSelectProps.id}-error`}
          error={meta.error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
