import { useMemo } from 'react';
import { FormListBox, LabelTooltip } from '../../../../components';
import { useVehicleOwnerQuery } from '../../../../hooks';
import { baseOptionDtoToViewOptions } from '../../../../helpers';

type RegisteredKeeperLegalOwnerProps = {
  testId: string;
};

export const RegisteredKeeperLegalOwner = ({
  testId,
}: RegisteredKeeperLegalOwnerProps) => {
  const { data } = useVehicleOwnerQuery();

  const registeredKeeperOptions = useMemo(
    () => data?.map(baseOptionDtoToViewOptions(`${testId}_registered-keeper`)) || [],
    [data, testId]
  );

  const vehicleOwnerOptions = useMemo(
    () => data?.map(baseOptionDtoToViewOptions(`${testId}_legal-owner`)) || [],
    [data, testId]
  );

  return (
    <div>
      <h3
        className="mb-6 font-raleway text-lg font-normal md:mb-12"
        data-testid={`${testId}_registerd-keeper_title`}
      >
        Who is (or will be) the registered keeper?
      </h3>
      <div className="flex relative flex-col mb-6 md:mb-12">
        <FormListBox
          unselectedMessage="Please select"
          name="vehicle.ownership.registerdKeeper"
          options={registeredKeeperOptions}
          id={`${testId}_registerd-keeper_select`}
          testId={`${testId}_registerd-keeper_select`}
          icon="user"
          label={
            <LabelTooltip
              testId={`${testId}_registerd-keeper-tooltip`}
              label="Registered keeper"
            >
              <h4 className="mb-2 font-bold">Registered keeper</h4>
              The registered keeper is the person whose name appears in the vehicle&apos;s
              log book (V5C). The owner of the car and the registered keeper can be
              different people. For example, a car might be owned by the company you work
              for, but as an employee you might be the registered keeper. Check whether
              you&apos;re the vehicles&apos;s registered keeper by looking at who&apos;s
              name is on the vehicle&apos;s log book (V5C).
            </LabelTooltip>
          }
        />
      </div>

      <h3
        className="mb-6 font-raleway text-lg font-normal md:mb-12"
        data-testid={`${testId}_legal-owner_title`}
      >
        Who is (or will be) the legal owner?
      </h3>
      <div className="flex relative flex-col mb-6 md:mb-12">
        <FormListBox
          unselectedMessage="Please select"
          name="vehicle.ownership.legalOwner"
          options={vehicleOwnerOptions}
          id={`${testId}_legal-owner_select`}
          testId={`${testId}_legal-owner_select`}
          icon="user"
          label={
            <LabelTooltip testId={`${testId}_legal-owner-tooltip`} label="Legal owner">
              <h4 className="mb-2 font-bold">Car ownership</h4>
              You&apos;re the legal owner if you&apos;re registered with the Driver and
              Vehicle Standards Agency (DVSA) as having bought the car or received it as a
              gift. The owner of the car and the registered keeper can be different
              people. For example, a car might be owned by the company you work for, but
              as an employee you might be the registered keeper. Check whether you&apos;re
              the car&apos;s registered keeper by looking at who&apos;s name is on the
              vehicle&apos;s log book (V5C).
            </LabelTooltip>
          }
        />
      </div>
    </div>
  );
};
