import { emailRegex } from '@dayinsure/shared';
import * as Yup from 'yup';

export const ForgotPasswordValidationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .matches(emailRegex, {
      message: 'Please check this',
      excludeEmptyString: true,
    })
    .required('Required'),
});
