import { useFormikContext, getIn } from 'formik';
import get from 'lodash.get';
import { Button, IconNames } from '@dayinsure/components';
import { useCallback, useMemo } from 'react';
import { FormValuesListFieldArr } from './FormValuesListFieldArr';
import { FieldOption } from './types';
import { QuoteJourneyFormData } from '../../../types';
import { sortByKey } from '../../../helpers';

type FormValuesListFieldProps = {
  name: string;
  options: FieldOption[];
  title: string;
  iconName: IconNames;
  description?: string;
  testId: string;
};
export const FormValuesListField = ({
  name,
  options,
  title,
  iconName,
  description,
  testId,
}: FormValuesListFieldProps) => {
  const { values, touched, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext<QuoteJourneyFormData>();
  const currentValue: FieldOption[] = get(values, name);
  const valuesArrLength = currentValue.length;
  const isAddingValue = !currentValue[valuesArrLength - 1];

  const sortedOptions = sortByKey<FieldOption>('name', options);
  const filteredOptions = useMemo(
    () =>
      sortedOptions.filter(
        (option: FieldOption) =>
          !currentValue.find((valueOption: FieldOption) => valueOption?.id === option?.id)
      ),
    [sortedOptions, currentValue]
  );

  const handleRemoveOption = useCallback(
    (option: FieldOption) => {
      const removedOption = options.find(
        avaliableOption => avaliableOption.id === option.id
      );
      if (removedOption) {
        const touchedFiledArray = getIn(touched, name);

        const editedFieldValue = [...currentValue].filter(fieldVal => {
          return fieldVal?.id !== removedOption?.id;
        });
        const newFieldValue = editedFieldValue.length > 0 ? editedFieldValue : [null];

        const lastItemTouched = touchedFiledArray?.at(-1);
        const isInputVisible = currentValue.some(val => val === null);
        const isTouched = isInputVisible ? lastItemTouched : false;

        setFieldTouched(`${name}[${newFieldValue.length - 1}]`, isTouched);
        setFieldValue(name, newFieldValue, true);
      }
    },
    [currentValue, name, options, setFieldTouched, setFieldValue, touched]
  );

  const addAnotherEl = () => {
    if (!currentValue[valuesArrLength - 1]) {
      setFieldError(`${name}.[${valuesArrLength - 1}]`, 'Required');
      setFieldTouched(`${name}.[${valuesArrLength - 1}]`);
    } else {
      setFieldValue(`${name}.[${valuesArrLength}]`, null);
      setFieldTouched(`${name}.[${valuesArrLength}]`, false);
    }
  };

  const cancelAddAnotherOption = () => {
    const updatedValue = currentValue.slice(0, -1);
    setFieldValue(name, updatedValue);
  };

  const displayAddButton = !isAddingValue && filteredOptions.length > 0;

  return (
    <div>
      <FormValuesListFieldArr
        value={currentValue}
        name={name}
        icon={iconName}
        title={title}
        description={description}
        options={filteredOptions}
        testId={testId}
        onRemove={handleRemoveOption}
      />
      {displayAddButton && (
        <Button
          variant="bordered"
          id={`${testId}_add-button`}
          testId={`${testId}_add-button`}
          onClick={addAnotherEl}
          text={`Add another ${title.toLowerCase()}`}
        />
      )}
      {isAddingValue && valuesArrLength > 1 && (
        <Button
          variant="bordered"
          id={`${testId}_cancel-button`}
          testId={`${testId}_cancel-button`}
          onClick={cancelAddAnotherOption}
          text="Cancel"
        />
      )}
    </div>
  );
};
