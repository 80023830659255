import { useMutation } from 'react-query';
import {
  Dayinsure as DayInsureAPIv2,
  OpenAPI,
  ConfirmPasswordResetRequest,
  IAPIResult,
  ApiError,
} from '../../../api/v2';

export type ConfirmPasswordResetRequestPayload = {
  personId: string;
  requestBody?: ConfirmPasswordResetRequest;
};

export const useConfirmPasswordResetMutation = (
  onSuccess: (data: IAPIResult) => void,
  onError: (error: ApiError) => void
) => {
  const sendPasswordResetLink = (payload: ConfirmPasswordResetRequestPayload) => {
    const api = new DayInsureAPIv2(OpenAPI);

    return api.person.postApiV2PersonConfirmPasswordReset(
      payload.personId,
      payload.requestBody
    );
  };
  return useMutation(sendPasswordResetLink, {
    mutationKey: 'confirm-reset-password',
    onSuccess,
    onError,
  });
};
