import { SelectRadioOption } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import get from 'lodash.get';
import { useCallback } from 'react';
import { FormRadioSelect, FormFieldBox } from '../../../../components';
import {
  getEmptyAccidentOrClaim,
  getFormFieldAccessPath,
  isYes,
  getYesNoOptions,
} from '../../../../helpers';
import { DriverFormData, QuoteJourneyFormData, YesNoAnswer } from '../../../../types';
import { AccidentOrClaimFieldSet } from './AccidentOrClaimFieldSet';
import { AccidentOrClaimTooltip } from './AccidentOrClaimTooltip';

type AccidentOrClaimProps = {
  testId: string;
  formNameSpaceKey?: string;
  additionalDriver?: boolean;
};

export const AccidentOrClaim = <T extends QuoteJourneyFormData | DriverFormData>({
  testId,
  additionalDriver,
  formNameSpaceKey,
}: AccidentOrClaimProps) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<T>();
  const currentDriver: DriverFormData = formNameSpaceKey
    ? get(values, formNameSpaceKey)
    : values;

  const {
    accidentsOrClaims: { hasAccidentOrClaimInLast5Years },
  } = currentDriver;

  const hasAccidentOrClaimInLast5YearsPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'accidentsOrClaims',
    'hasAccidentOrClaimInLast5Years',
  ]);
  const selfDeclaredPreviousMotorClaimsPath = getFormFieldAccessPath([
    formNameSpaceKey,
    'accidentsOrClaims',
    'selfDeclaredPreviousMotorClaims',
  ]);

  const handleRadioChange = useCallback(
    (option: SelectRadioOption) => {
      const { id } = option;
      if (id === YesNoAnswer.NO) {
        setFieldValue(selfDeclaredPreviousMotorClaimsPath, []);
      } else {
        setFieldValue(selfDeclaredPreviousMotorClaimsPath, [getEmptyAccidentOrClaim()]);
      }
      setFieldTouched(selfDeclaredPreviousMotorClaimsPath, false);
    },
    [selfDeclaredPreviousMotorClaimsPath, setFieldTouched, setFieldValue]
  );

  return (
    <div className="my-6 md:my-12">
      <h3
        className="relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_accident-or-claim-label`}
      >
        {additionalDriver
          ? 'Have they had an accident or claim in the last 5 years?'
          : 'Have you had an accident or claim in the last 5 years?'}
        <AccidentOrClaimTooltip testId={testId} />
      </h3>
      <div className="mb-4 md:mb-8">
        <FormFieldBox>
          <FormRadioSelect
            onChangeOptional={handleRadioChange}
            id={`${testId}_provide-accident-or-claim-radio`}
            testId={`${testId}_provide-accident-or-claim-radio`}
            name={hasAccidentOrClaimInLast5YearsPath}
            options={getYesNoOptions(`${testId}_has-accident-or-claim-in-last-5-years`)}
          />
        </FormFieldBox>
        {isYes(hasAccidentOrClaimInLast5Years?.id) && (
          <AccidentOrClaimFieldSet
            name={selfDeclaredPreviousMotorClaimsPath}
            testId={`${testId}_accidents-or-claims`}
            additionalDriver={additionalDriver}
          />
        )}
      </div>
    </div>
  );
};
