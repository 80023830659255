export type EditPasswordFormData = {
  currentPassword: string;
  newPassword: string;
  retypePassword: string;
};

export const editPasswordInitialData: EditPasswordFormData = {
  currentPassword: '',
  newPassword: '',
  retypePassword: '',
};
