import { Button, Card, Dialog, Icon } from '@dayinsure/components';
import React from 'react';

type RemoveDriverModalProps = {
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
  testId: string;
};

export const RemoveDriverModal = ({
  isOpen,
  testId,
  onClose,
  onConfirm,
}: RemoveDriverModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} title="Remove this driver?">
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
      >
        <Icon name="warning" size="2rem" className="text-popup-content-1" />
        <p>You will lose all the details you have entered for this driver.</p>
      </Card>
      <Button
        id={`${testId}_remove-driver-modal_cancel-button`}
        testId={`${testId}_remove-driver-modal_cancel-button`}
        fullWidth
        onClick={onClose}
        text="No, go back"
        classNames="mt-6 md:mt-8"
      />
      <Button
        id={`${testId}_remove-driver-modal_confirm-button`}
        testId={`${testId}_remove-driver-modal_confirm-button`}
        fullWidth
        onClick={onConfirm}
        text="Yes, remove driver"
        classNames="mt-6 md:mt-8"
        variant="dialogSecondary"
      />
    </Dialog>
  );
};
