import { Card, Icon } from '@dayinsure/components';

type SupportCardProps = {
  testId: string;
};

export const SupportCard = ({ testId }: SupportCardProps) => {
  return (
    <Card classNames="flex items-center mb-8" paddingLevel="small" testId={testId}>
      <Icon className="text-main-content-1" size="2rem" name="chat" />
      <div className="ml-6 font-raleway text-main-content-1">
        We’re best contacted via live chat or by emailing{' '}
        <a
          className="font-semibold underline text-link"
          href="mailto:support@dayinsure.com"
        >
          support@dayinsure.com
        </a>
        . However, if you do need to call us our number is{' '}
        <a
          href="tel:0333 005 0944"
          className="font-semibold underline whitespace-nowrap text-link"
        >
          0333 005 0944
        </a>
        .
      </div>
    </Card>
  );
};
