import { Button } from '@dayinsure/components';
import { Form } from 'formik';
import { QuoteJourneyLayout, TopCards } from '../../../components';
import { HomeAddress } from './HomeAddress';
import { IsHomeOwner } from './IsHomeOwner';
import { NumericValueListbox } from './NumericValueListbox';
import { useCloneToProposer } from '../../../hooks';
import { NumberOfChildrenTooltip } from './NumberOfChildrenTooltip';

const id = 'your-home';
const testId = 'your-home';

export const YourHome = () => {
  useCloneToProposer(
    ['homePostcode', 'homeAddress', 'address'],
    'proposer',
    'drivers[0]'
  );

  return (
    <QuoteJourneyLayout>
      <h1
        className="mb-8 text-xl text-center md:text-left"
        data-testid={`${testId}_title`}
      >
        Your home
      </h1>
      <TopCards
        testId={`${testId}_cards`}
        infoCardText="We need some details about your home."
      />
      <div className="mb-16 sm:mb-24">
        <Form>
          <HomeAddress id={id} testId={testId} />
          <IsHomeOwner testId={testId} />
          <NumericValueListbox
            testId={`${testId}_number-of-vehicles-in-household-listbox`}
            name="proposer.numberOfVehiclesInHousehold"
            icon="car"
            label="Number of cars"
            header="How many cars, including this one, are usually kept at your home?"
          />
          <NumericValueListbox
            testId={`${testId}_children-in-household-listbox`}
            name="proposer.childrenUnder16InHousehold"
            icon="child"
            label={<NumberOfChildrenTooltip testId={testId} />}
            header="How many children under 16 live at your home?"
          />
          <Button
            id={`${testId}_continue-button`}
            testId={`${testId}_continue-button`}
            submit
            fullWidth
            icon={{ name: 'arrow-right', position: 'right' }}
            text="Continue"
          />
        </Form>
      </div>
    </QuoteJourneyLayout>
  );
};
