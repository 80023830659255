import { useAuth } from 'react-oidc-context';
import { Avatar, Icon, Menu } from '@dayinsure/components';
import {
  LinkWithReferrer,
  useSearchParamsToBuildPermittedQueryParams,
} from '@dayinsure/shared';
import clsx from 'clsx';
import { useReferrer } from '../../../../../hooks';
import { AccountPaths } from '../../../../../routes';

const linkClasses = (active: boolean) =>
  clsx(
    'flex gap-3 items-center py-4 px-8 w-full text-md font-semibold leading-4 text-menu-content-1 ',
    { 'bg-menu-content-2': active }
  );

const AccountMenu = (): JSX.Element => {
  const referrer = useReferrer();
  const { signoutRedirect, user } = useAuth();

  const completeQueryParamsFromJourney = useSearchParamsToBuildPermittedQueryParams();

  const handleSignOut = () =>
    signoutRedirect({
      id_token_hint: user?.id_token,
      post_logout_redirect_uri: `${window.origin}/auth/logout`,
      state: {
        referrerName: referrer.name,
        completeQueryParamsFromJourney,
      },
    });

  return (
    <Menu
      position="left"
      trigger={
        <button id="account-user-avatar-btn-menu" type="button" aria-label="Account menu">
          <Avatar />
        </button>
      }
    >
      <Menu.Item>
        {({ active }) => (
          <LinkWithReferrer
            data-testid="account-menu-account-details-btn-menu"
            to={`/account/${AccountPaths.Details}`}
            className={clsx(linkClasses(active), 'mt-4')}
          >
            <Icon name="user" />
            Account details
          </LinkWithReferrer>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <LinkWithReferrer
            data-testid="account-menu-payment-methods-btn-menu"
            to={`/account/${AccountPaths.PaymentMethods}`}
            className={linkClasses(active)}
          >
            <Icon name="card" />
            Saved Cards
          </LinkWithReferrer>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <LinkWithReferrer
            data-testid="account-menu-password-btn-menu"
            to={`/account/${AccountPaths.PasswordEdit}`}
            className={linkClasses(active)}
          >
            <Icon name="lock" />
            Password
          </LinkWithReferrer>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            type="button"
            data-testid="account-menu-logout-btn-menu"
            className={clsx(linkClasses(active), 'mb-4')}
            onClick={handleSignOut}
          >
            <Icon name="logout" />
            Log out
          </button>
        )}
      </Menu.Item>
    </Menu>
  );
};

export { AccountMenu };
