const getAvailableHoursForToday = () => {
  const today = new Date();
  let todaysHours = today.getHours();
  const currentMinutes = today.getMinutes();
  const remainingMinutes = 60 - currentMinutes;
  let remainingHours = 24 - todaysHours;

  if (remainingMinutes <= 5) {
    todaysHours += 1;
    remainingHours -= 1;
  }

  return Array.from(Array(remainingHours).keys()).map(i => ({
    id: (todaysHours + i).toString(),
    name: (todaysHours + i).toString().padStart(2, '0'),
  }));
};

const getHours = () =>
  Array.from(Array(24).keys()).map(i => ({
    id: i.toString(),
    name: i.toString().padStart(2, '0'),
  }));

const getAvailableMinutesForCurrentHour = (interval = 15) => {
  const currentMinutes = new Date().getMinutes();
  const remainingMinutes = 60 - currentMinutes;
  const remaining15MinIntervals = Math.floor(remainingMinutes / interval);
  const closest15MinInterval = 60 - remaining15MinIntervals * interval;

  return Array.from(Array(remaining15MinIntervals).keys()).map(i => ({
    id: (closest15MinInterval + i * interval).toString(),
    name: (closest15MinInterval + i * interval).toString().padStart(2, '0'),
  }));
};

const getMinutes = (interval = 15) => {
  const intervalsInHour = 60 / interval;

  return Array.from(Array(intervalsInHour).keys()).map(i => ({
    id: (i * interval).toString(),
    name: (i * interval).toString().padStart(2, '0'),
  }));
};

export {
  getAvailableHoursForToday,
  getHours,
  getMinutes,
  getAvailableMinutesForCurrentHour,
};
