type OptionDetailProps = {
  title: string;
  value: string;
};

export const OptionDetail = ({ title, value }: OptionDetailProps) => (
  <div className="flex justify-between items-center w-full font-lato text-sm font-normal leading-4">
    <p>{title}</p>
    <p className="font-bold">{value}</p>
  </div>
);
