import { useQueryClient } from 'react-query';
import {
  VehicleIAPIResult,
  ApiError,
  OpenAPI,
  Dayinsure as DayInsureAPI,
} from '../../../api/v1';
import { query } from '../queryKeys';

export const useRegistrationSearchQuery = () => {
  const queryClient = useQueryClient();

  return (vehicleReg: string) => {
    return queryClient.fetchQuery<VehicleIAPIResult, ApiError>(
      query.vehicleReg(vehicleReg),
      () => {
        const api = new DayInsureAPI(OpenAPI);
        return api.vehicle.getApiV1Vehicle(vehicleReg);
      }
    );
  };
};
