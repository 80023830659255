import { Icon } from '@dayinsure/components';

type LoginButtonProps = {
  text: string;
  onClick: VoidFunction;
};

const LoginButton = ({ text, onClick }: LoginButtonProps) => (
  <button
    type="button"
    id="header-login-btn"
    className="box-border inline-flex gap-x-2 justify-center items-center px-4 h-8 font-raleway text-sm font-semibold bg-transparent rounded-lg border-2 text-header-content-1 border-header-content-1"
    onClick={onClick}
  >
    <Icon name="user" size="0.75rem" />
    <span>{text}</span>
  </button>
);

export { LoginButton };
