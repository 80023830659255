/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IAPIResult } from '../models/IAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentSessionService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PaymentSession(): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payment-session',
    });
  }

}