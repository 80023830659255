import { Header as TopHeader } from '@dayinsure/components';
import { useNavigateWithReferrer, useSignIn } from '@dayinsure/shared';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useReferrer, usePerson } from '../../../hooks';
import { FullAccountNavigation } from './FullAccountNavigation';
import { GuestNavigation } from './GuestNavigation';
import { QuoteJourneyFormData } from '../../../types';

const loginFlowRoutes = [
  '/forgotten-password',
  '/forgotten-password/reset',
  '/sign-up',
  '/sign-up/verify',
];

const Header = (): JSX.Element => {
  const referrer = useReferrer();
  const navigate = useNavigateWithReferrer();
  const { isFullAccount, isGuest } = usePerson();
  const { signIn } = useSignIn<QuoteJourneyFormData>(referrer.name, isGuest);
  const currentRoute = useLocation();

  const isLoginPage = useMemo(
    () => loginFlowRoutes.includes(currentRoute.pathname),
    [currentRoute]
  );

  const activeLink = isGuest === undefined || isGuest === true;

  const goHomeToPage = () => {
    if (activeLink) {
      navigate('/');
    }
  };

  return (
    <TopHeader
      logo={
        <TopHeader.Logo
          logoSize={referrer.logoSize}
          logo={referrer.logo}
          altLogoText="logo"
          onClick={activeLink ? goHomeToPage : undefined}
        />
      }
    >
      {isFullAccount ? (
        <FullAccountNavigation />
      ) : (
        <GuestNavigation
          customerAccessEnabled={referrer.customerAccessEnabled}
          isLoginPage={isLoginPage}
          onSignIn={signIn}
        />
      )}
    </TopHeader>
  );
};

export { Header };
