import { EndorsementsDto } from '../../../api/v2';

export const filterEndorsementByCodes = (
  endorsements: EndorsementsDto[],
  codesToFilter?: string[]
) => {
  if (!codesToFilter) {
    return endorsements;
  }

  return endorsements.filter(
    endorsement => endorsement.code && codesToFilter.includes(endorsement.code)
  );
};
