import { YesNoDataEntry } from '../../../../../../../types/quoteJourneyForm';
import { getYesNoOptions } from '../../../../../../../helpers/forms/getYesNoOptions';

export const getClaimFaultOptions = (testId: string): YesNoDataEntry[] =>
  getYesNoOptions(`${testId}_claim-fault`);

export const getClaimSettledOptions = (testId: string): YesNoDataEntry[] =>
  getYesNoOptions(`${testId}_claim-settled`);

export const getClaimAmountOptions = (testId: string): YesNoDataEntry[] =>
  getYesNoOptions(`${testId}_claim-amount`);
