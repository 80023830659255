import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { ApiError } from '../api/v1';
import { createOrderError } from '../helpers';

export const useGlobalError = () => {
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);

  const showGlobalError = (error: unknown) => {
    const errorObject = error instanceof ApiError ? error : null;
    const errorDialogCopy = createOrderError(errorObject?.message || '1');
    setGlobalError(error, {
      ...errorDialogCopy,
      message: errorObject?.body?.error || errorDialogCopy.message,
      onClose: clearGlobalError,
    });
  };
  return { showGlobalError };
};
