import { AsideCard } from '@dayinsure/components';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { getCurrentStepFromRoute } from '../../../helpers';
import { QuoteJourneyRoutes } from '../../../routes';
import { VehicleDetailItem } from '../VehicleDetailItem';
import { DriversItem } from './DriversItem';
import { QuoteJourneyFormData } from '../../../types';
import { SummaryPaymentCard } from '../../PaymentCard/SummaryPaymentCard/SummaryPaymentCard';
import { CoverStartDateItem } from './CoverStartDateItem';
import { QuoteJourneyStatusContext } from '../../../contexts';
import { CoverTypeItem } from './CoverTypeItem';
import { EditCardLink } from '../EditCardLink';

const summaryCardAllowedRoutes = [
  QuoteJourneyRoutes.CarDetails,
  QuoteJourneyRoutes.CarUsage,
  QuoteJourneyRoutes.YourDetails,
  QuoteJourneyRoutes.YourHome,
  QuoteJourneyRoutes.YourLicenceAndHistory,
  QuoteJourneyRoutes.Drivers,
  QuoteJourneyRoutes.DriverDetails,
  QuoteJourneyRoutes.DriverLicenceAndHistory,
  QuoteJourneyRoutes.Cover,
  QuoteJourneyRoutes.YourQuote,
  QuoteJourneyRoutes.Review,
  QuoteJourneyRoutes.Payment,
];

const carDetailsEditButtonAllowedRoutes = [
  QuoteJourneyRoutes.YourDetails,
  QuoteJourneyRoutes.YourHome,
  QuoteJourneyRoutes.YourLicenceAndHistory,
  QuoteJourneyRoutes.Drivers,
  QuoteJourneyRoutes.DriverDetails,
  QuoteJourneyRoutes.DriverLicenceAndHistory,
  QuoteJourneyRoutes.Cover,
  QuoteJourneyRoutes.YourQuote,
  QuoteJourneyRoutes.Review,
  QuoteJourneyRoutes.Payment,
];

const driverInfoAllowedRoutes = [
  QuoteJourneyRoutes.Drivers,
  QuoteJourneyRoutes.Cover,
  QuoteJourneyRoutes.YourQuote,
  QuoteJourneyRoutes.Review,
  QuoteJourneyRoutes.Payment,
];

const coverDateAllowedRoutes = [
  QuoteJourneyRoutes.YourQuote,
  QuoteJourneyRoutes.Review,
  QuoteJourneyRoutes.Payment,
];

const coverTypeAllowedRoutes = [
  QuoteJourneyRoutes.YourQuote,
  QuoteJourneyRoutes.Review,
  QuoteJourneyRoutes.Payment,
];
const shouldDisplaySummaryCard = (currentStep: QuoteJourneyRoutes): boolean =>
  summaryCardAllowedRoutes.includes(currentStep);

const shouldDisplayCarDetailsEditButton = (currentStep: QuoteJourneyRoutes): boolean =>
  carDetailsEditButtonAllowedRoutes.includes(currentStep);

const shouldDisplayDriverInfo = (currentStep: QuoteJourneyRoutes): boolean =>
  driverInfoAllowedRoutes.includes(currentStep);

const shouldDisplayCoverDate = (currentStep: QuoteJourneyRoutes): boolean =>
  coverDateAllowedRoutes.includes(currentStep);
const shouldDisplayCoverType = (currentStep: QuoteJourneyRoutes): boolean =>
  coverTypeAllowedRoutes.includes(currentStep);

export const QuoteSummaryCard = () => {
  const { isLoadingState } = useContext(QuoteJourneyStatusContext);
  const { pathname } = useLocation();
  const currentStep = getCurrentStepFromRoute(pathname);
  const {
    values: { drivers, vehicle, cover },
  } = useFormikContext<QuoteJourneyFormData>();

  if (!currentStep || !shouldDisplaySummaryCard(currentStep)) {
    return null;
  }

  const { registrationNumber, model, make } = vehicle;
  const { startDateTimeUtc, type } = cover;
  const vehicleDescription = `${make?.name} ${model?.name} `;

  return (
    <>
      {!isLoadingState && (
        <AsideCard
          className="hidden sticky top-6 mt-24 h-max md:block"
          title="Summary"
          testId="quote-summary-card"
          summaryContent={<SummaryPaymentCard />}
        >
          <VehicleDetailItem
            testId="quote-summary-card_vehicle-details-item"
            title={registrationNumber}
            isSummaryCard
            link={
              shouldDisplayCarDetailsEditButton(currentStep) && (
                <EditCardLink
                  to={QuoteJourneyRoutes.CarDetails}
                  testId="quote-summary-card_edit-drivers-link"
                  className="text-white"
                >
                  Edit
                </EditCardLink>
              )
            }
          >
            {vehicleDescription}
          </VehicleDetailItem>
          {shouldDisplayDriverInfo(currentStep) && (
            <DriversItem drivers={drivers} currentStep={currentStep} />
          )}
          {shouldDisplayCoverDate(currentStep) && startDateTimeUtc && (
            <CoverStartDateItem startDate={startDateTimeUtc} />
          )}
          {shouldDisplayCoverType(currentStep) && startDateTimeUtc && (
            <CoverTypeItem type={type?.name || ''} />
          )}
        </AsideCard>
      )}
    </>
  );
};
