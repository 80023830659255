import { v4 as uuid } from 'uuid';
import { DriverDto } from '../../../../api/v1';
import { Driver, PolicyChangeFormData } from '../../../../types/mtaJourneyForm';
import { driverStateParser, toYesNoDataEntry } from '../../../../helpers';
import { DriverFormData } from '../../../../types';
import { PolicyMtaPaths } from '../../../../routes';

export const parseDriver = (driver: DriverDto): Driver => ({
  ...driver,
  id: driver.id || uuid(),
});

export const parseStateDriverWithId = (driver: DriverFormData): Driver => {
  return {
    ...driverStateParser(driver),
    id: driver.id,
  };
};

export const getDriversBackLink = ({
  changeCar,
  changeReg,
}: PolicyChangeFormData['policyChange']): string => {
  if (changeCar) {
    return `../../${PolicyMtaPaths.NewCarUsage}`;
  }

  if (changeReg) {
    return `../../${PolicyMtaPaths.NewReg}`;
  }

  return `../../${PolicyMtaPaths.PolicyChange}`;
};

export const setMainDriver =
  (mainDriverId?: string) =>
  (driver: DriverFormData): DriverFormData => {
    if (!mainDriverId) {
      return driver;
    }
    const isMainDriver = driver.id === mainDriverId;
    return {
      ...driver,
      isMainDriver: toYesNoDataEntry(isMainDriver),
    };
  };
