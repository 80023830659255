import { useContext } from 'react';
import { GlobalErrorContext } from '@dayinsure/shared';
import { useQuery } from 'react-query';
import {
  ApiError,
  ConfirmAccountResponse,
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useConfirmAccountExistQuery = (email: string, enabled = true) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getConfirmAccountExist = () => {
    const api = new DayinsureAPIv2(OpenAPI);

    return api.person.getApiV2PersonConfirmAccountExists(email);
  };

  return useQuery<ConfirmAccountResponse, ApiError>(
    query.confirmAccountExist(email),
    getConfirmAccountExist,
    {
      enabled,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
