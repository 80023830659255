import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv2,
  OpenAPI,
  VehicleOptionsDto,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useVehicleOptionsQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getVehicleOptions = () => {
    const api = new DayinsureAPIv2(OpenAPI);

    return api.product.getApiV2ProductVehicleOptions('motor', 'latest');
  };

  return useQuery<VehicleOptionsDto, ApiError>(query.vehicleOptions, getVehicleOptions, {
    keepPreviousData: true,
    onError: err => {
      if (err.status !== 404) {
        setGlobalError(err);
      }
    },
  });
};
