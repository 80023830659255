import { VehicleReg } from '@dayinsure/components';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent, useMemo } from 'react';
import { QuoteJourneyFormData } from '../../../../types';

type VehicleRegSearchProps = {
  onClear: VoidFunction;
};

const vehicleFieldsToReset = {
  selfDeclaredVehicleValue: null,
  areDetailsCorrect: false,
  isCarModified: null,
  ownership: {
    isPurchased: null,
    registerdKeeper: null,
    legalOwner: null,
  },
};
export const VehicleRegSearch = ({ onClear }: VehicleRegSearchProps) => {
  const [field, meta] = useField({ name: 'vehicle.registrationNumber' });
  const { onChange, ...fieldProps } = field;
  const {
    setFieldValue,
    values: { vehicle },
  } = useFormikContext<QuoteJourneyFormData>();

  const doCardDetailsAreFilled = useMemo(() => {
    const {
      selfDeclaredVehicleValue,
      areDetailsCorrect,
      isCarModified,
      ownership: { isPurchased, registerdKeeper, legalOwner },
    } = vehicle;
    const carDetailsFieldsValuesArr = [
      selfDeclaredVehicleValue,
      areDetailsCorrect,
      isCarModified,
      isPurchased,
      registerdKeeper,
      legalOwner,
    ];
    return carDetailsFieldsValuesArr.some(value => value !== null);
  }, [vehicle]);

  const resetCarDetails = () => {
    setFieldValue('vehicle', { ...vehicle, ...vehicleFieldsToReset });
  };
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (doCardDetailsAreFilled) {
      resetCarDetails();
    }
    onChange(e);
  };

  return (
    <VehicleReg
      id="new-quote-registration-plate-input"
      error={meta.error}
      touched={meta.touched}
      placeholder="Enter car reg"
      onClear={onClear}
      onChange={handleOnChange}
      {...fieldProps}
    />
  );
};
