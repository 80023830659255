import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  ApiError,
  Dayinsure as DayinsureAPIv2,
  LicenceDurationsDto,
  OpenAPI,
} from '../../../api/v2';
import { query } from '../queryKeys';

export const useLicenceDurationsQuery = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getLicenceDurationsOptions = () => {
    const api = new DayinsureAPIv2(OpenAPI);

    return api.product.getApiV2ProductLicenceDurations('motor', 'latest');
  };

  return useQuery<LicenceDurationsDto, ApiError>(
    query.licenceDurationsOptions,
    getLicenceDurationsOptions,
    {
      keepPreviousData: true,
      onError: err => {
        if (err.status !== 404) {
          setGlobalError(err);
        }
      },
    }
  );
};
