import { PageContainer, Stepper } from '@dayinsure/components';
import { Outlet, useLocation } from 'react-router-dom';
import { getStepperProgressFromRoute } from '../../../helpers';
import { QuoteSummaryCard } from '../../../components';

const steps = ['Car', 'Drivers', 'Cover', 'Review', 'Payment'];

export const QuoteJourney = () => {
  const location = useLocation();
  const stepperProgress = getStepperProgressFromRoute(location.pathname);

  return (
    <PageContainer className="pt-6">
      {stepperProgress && (
        <Stepper
          steps={steps}
          currentStepIndex={stepperProgress[0]}
          currentStepPercentage={stepperProgress[1]}
        />
      )}
      <div className="flex gap-6">
        <Outlet />
        <QuoteSummaryCard />
      </div>
    </PageContainer>
  );
};
