import {
  Button,
  FieldError,
  IconNames,
  Label,
  TextField,
  TextFieldProps,
} from '@dayinsure/components';
import { useField } from 'formik';
import { ReactNode, useRef } from 'react';
import { useFocusOnError } from '../../../hooks';

type FormPostcodeFieldProps = {
  label: {
    icon: IconNames;
    text: ReactNode;
  };
  buttonLabel?: string;
  onClick: () => void;
} & Omit<TextFieldProps, 'label' | 'error' | 'onChange'>;

export const FormPostcodeField = ({
  buttonLabel = 'Find address',
  label,
  onClick,
  ...inputProps
}: FormPostcodeFieldProps) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [field, meta] = useField(inputProps);
  useFocusOnError({ fieldRef, name: inputProps.name });

  return (
    <div className="relative form-field-spacing">
      <div className="flex space-x-2">
        <Label
          name={label.text}
          icon={label.icon}
          htmlFor={inputProps.id}
          disabled={inputProps.disabled}
          className="mb-4"
          testId={`${inputProps.testId}_label`}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 h-max sm:flex">
        <div className="relative w-full sm:w-56 lg:w-80">
          <TextField
            {...inputProps}
            {...field}
            value={field.value || ''}
            error={meta.touched && meta.error !== undefined}
            aria-errormessage={`${inputProps.id}_error`}
            ref={fieldRef}
            upperCase
          />
          {meta.touched && meta.error && (
            <FieldError
              id={`${inputProps.id}_error`}
              error={meta.error}
              className="absolute right-0 mt-2"
            />
          )}
        </div>
        <Button
          id={`${inputProps.id}_submit-button`}
          text={buttonLabel}
          onClick={onClick}
          variant="secondary"
          classNames="w-full sm:w-fit self-endi put shrink-0"
          testId={`${inputProps.testId}_submit-button`}
        />
      </div>
    </div>
  );
};
