import { YesNoAnswer } from '../../../../../types/YesNoAnswer';

export const testId = 'car-details_car-spec-form';

export const trackingDeviceOptions = [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_tracking-device_option_yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_tracking-device_option_no`,
  },
];

export const isImportOptions = [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_is-import_option_yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_is-import_option_no`,
  },
];

const getSeatsNumberOptions = () =>
  [...Array(10).keys()].map(key => {
    const seat = (key + 1).toString();

    return { id: seat, name: seat };
  });

export const seatsNumberOptions = getSeatsNumberOptions();
