import { Card } from '@dayinsure/components';
import {
  isBusinessUsage,
  transformForCommaAndNoCommaValue,
} from '../../../../../helpers';
import { VehicleDto } from '../../../../../api/v1';

type CarUsageCardProps = {
  testId: string;
  vehicle: VehicleDto;
  mta?: boolean;
};

export const CarUsageCard = ({ testId, vehicle, mta }: CarUsageCardProps) => {
  const totalMileageComa = vehicle.usage?.annualMileage
    ? transformForCommaAndNoCommaValue(String(vehicle.usage?.annualMileage))
    : null;
  const businessMileageComa = vehicle.usage?.businessMileage
    ? transformForCommaAndNoCommaValue(String(vehicle.usage?.businessMileage))
    : null;
  return (
    <Card testId={`${testId}_car-usage-card`} classNames="w-full mb-6">
      <h3 className="font-raleway text-lg font-normal">Car usage</h3>
      <Card.CardListItem
        label="Usage"
        icon="cog"
        testId={`${testId}_car-usage-card_list-item_usage-type`}
      >
        {vehicle.usage?.type?.description || vehicle.usage?.type?.code}
      </Card.CardListItem>
      {vehicle.usage?.annualMileage && (
        <Card.CardListItem
          label="Total personal mileage"
          icon="user"
          testId={`${testId}_car-usage-card_list-item_personal-mileage`}
        >
          {totalMileageComa?.valueWithComa}
        </Card.CardListItem>
      )}
      {isBusinessUsage(vehicle.usage?.type?.code) && (
        <Card.CardListItem
          label="Total business mileage"
          icon="briefcase"
          testId={`${testId}_car-usage-card_list-item_business-mileage`}
        >
          {businessMileageComa?.valueWithComa}
        </Card.CardListItem>
      )}
      <Card.CardListItem
        label="Overnight vehicle location"
        icon="home"
        testId={`${testId}_car-usage-card_list-item_at-home-overnight`}
      >
        {vehicle.location?.overnightLocationPostcode}
      </Card.CardListItem>
      <Card.CardListItem
        label={mta ? 'Parked during the day' : 'Kept during the day'}
        icon="sun"
        testId={`${testId}_car-usage-card_list-item_kept-at-day`}
      >
        {vehicle.location?.daytimeLocation?.description ||
          vehicle.location?.daytimeLocation?.code}
      </Card.CardListItem>
      <Card.CardListItem
        label={mta ? 'Parked at night' : 'Kept at night'}
        icon="moon"
        testId={`${testId}_car-usage-card_list-item_kept-at-night`}
      >
        {vehicle.location?.overnightLocation?.description ||
          vehicle.location?.overnightLocation?.code}
      </Card.CardListItem>
    </Card>
  );
};
