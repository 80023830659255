const getErrorMessage = (code: string) => {
  switch (code) {
    case '1':
      return 'There was a problem with payment';
    case '11':
      return 'Transaction declined by provider';
    case '12':
      return 'Invalid payment details';
    case '13':
      return 'Transaction declined';
    default:
      return 'There was a problem with payment';
  }
};
export const createOrderError = (code: string) => {
  const errorMessage = getErrorMessage(code);
  return {
    title: 'Sorry, there was a problem',
    message: errorMessage,
    cta: 'Go back and try again',
    ctaId: 'make-payment-error-dialog_close-button',
  };
};
