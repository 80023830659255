import { Card, Icon } from '@dayinsure/components';
import { LinkWithReferrer, useSignIn } from '@dayinsure/shared';
import clsx from 'clsx';
import { usePerson, useReferrer } from '../../../hooks';

export type LoginCTAProps = {
  className?: string;
  testId?: string;
};

export const LoginCTA = ({ className, testId }: LoginCTAProps) => {
  const { name } = useReferrer();
  const { isGuest } = usePerson();
  const { signIn } = useSignIn(name, isGuest);

  const handleSignIn = () => signIn();

  return (
    <Card
      classNames={clsx('flex items-center', className)}
      paddingLevel="small"
      testId={testId}
    >
      <Icon className="text-main-content-1" size="2rem" name="tip" />
      <div className="ml-6 text-main-content-1">
        Want to get a quicker quote?{' '}
        <button
          type="button"
          data-testid={`${testId}_login-button`}
          className="font-semibold underline text-link"
          onClick={handleSignIn}
        >
          Log in
        </button>{' '}
        or{' '}
        <LinkWithReferrer
          data-testid={`${testId}_sign-up-button`}
          to="/auth/sign-up"
          className="font-semibold underline text-link"
        >
          create an account
        </LinkWithReferrer>{' '}
        so we already have your details.
      </div>
    </Card>
  );
};
