type SinglePropertyPath = string | undefined;
type NestedPropertyPath = SinglePropertyPath[];
type PropertyPath = SinglePropertyPath | NestedPropertyPath;

export const getFormFieldAccessPath = (path: PropertyPath) => {
  if (Array.isArray(path)) {
    return path
      .reduce<string[]>((result: string[], singlePath: SinglePropertyPath) => {
        if (singlePath) {
          return [...result, singlePath];
        }
        return result;
      }, [])
      .join('.');
  }

  return path ?? '';
};
