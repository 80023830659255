import { useContext } from 'react';
import { ReferrerContext } from '../contexts';

const useReferrer = () => {
  const context = useContext(ReferrerContext);

  if (context === undefined) {
    throw new Error('useReferrer used outside of provider');
  }

  return context;
};

export { useReferrer };
