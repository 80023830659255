import { YesNoAnswer } from '../../../types/YesNoAnswer';
import { YesNoDataEntry } from '../../../types/quoteJourneyForm';

export const getAnotherJobRadioOptions = (testId: string): YesNoDataEntry[] => [
  {
    id: YesNoAnswer.YES,
    name: 'Yes',
    testId: `${testId}_another-job-yes`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No',
    testId: `${testId}_another-job-no`,
  },
];
