import { Tooltip } from '@dayinsure/components';
import { FormRadioSelect, FormFieldBox } from '../../../../components';
import { getYesNoOptions } from '../../../../helpers';

const installationProofOptions = getYesNoOptions('installation-proof');

type InstallationProofProps = {
  testId: string;
};

export const InstallationProof = ({ testId }: InstallationProofProps) => (
  <>
    <h3
      className="relative w-full font-raleway font-normal form-subtitle-compact"
      data-testid={`${testId}_installation-proof_title`}
    >
      Can you give us proof of the installation of the immobiliser?
      <div className="inline-block ml-2 sm:!relative">
        <Tooltip
          ariaLabel="hint"
          className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
          position="relative"
          testId={`${testId}_installation-proof_tooltip`}
          variant="annual"
        >
          We accept an invoice, receipt, or certificate. It must show the vehicle it was
          installed on, the date of installation, the provider, and the type of
          immobiliser.
          <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
            Got it
          </Tooltip.Close>
        </Tooltip>
      </div>
    </h3>
    <FormFieldBox>
      <FormRadioSelect
        name="carSecurity.installationProof"
        options={installationProofOptions}
        id={`${testId}_installation-proof_input`}
        testId={`${testId}_installation-proof_input`}
      />
    </FormFieldBox>
  </>
);
