import { isNil } from '@dayinsure/core';
import { v4 as uuidv4 } from 'uuid';
import { getDate, getMonth, getYear, isValid } from 'date-fns';
import {
  AlarmImmobiliserDto,
  GetMotorQuoteResponseDto,
  ProposerDto,
  VehicleFuelType,
  VehicleDto,
  VehicleTransmissionType,
  VehicleType,
  ContactPreferencesDto,
  MarketConsentType,
  DriverDto,
  OccupationModelDto,
  CoverResponseDto,
  AddOnModelDto,
  PaymentPlanDto,
  ExcessesResponseDto,
  ExcessAmountDto,
  AddressDto as AddressDtoV1,
  MotorClaimDto,
  NoClaimsHistoryDto,
  PostOfficeAddressDto,
  ReferenceDescriptionDto,
} from '../../api/v1';
import { AddressDto as AddressDtoV3 } from '../../api/v3';
import { DeepPartial, YesNoAnswer } from '../../types';
import {
  BaseDataEntry,
  DateEntry,
  Proposer as QuoteJourneyProposer,
  QuoteJourneyFormData,
  Vehicle,
  YesNoDataEntry,
  MarketingPreferences,
  DriverFormData,
  Occupations,
  Occupation,
  Cover,
  Excesses,
  VoluntaryAmounts,
  AccidentOrClaimType,
} from '../../types/quoteJourneyForm';
import {
  filterMapParser,
  isAnnualPaymentPlan,
  isEnumMember,
  isMonthlyPaymentPlan,
} from '../utils';
import { PaymentPlansEnum, PaymentTypesEnum } from '../../constants/payments';

export const toBaseDataEntry = <T extends string>(
  value?: T | null
): BaseDataEntry<T> | undefined => {
  if (isNil(value)) {
    return undefined;
  }

  return {
    id: value,
    name: value,
  };
};

export const toNumericDataEntry = (
  value?: number | null
): BaseDataEntry<number> | undefined => {
  if (isNil(value)) {
    return undefined;
  }

  return {
    id: value,
    name: String(value),
  };
};

export const numberToPoundDataEntry = (
  value?: number | null
): BaseDataEntry | undefined => {
  if (isNil(value)) {
    return undefined;
  }
  return {
    id: String(value),
    name: `£${value}`,
  };
};
export const numberToBaseDataEntry = (
  value?: number | null
): BaseDataEntry | undefined => {
  if (isNil(value)) {
    return undefined;
  }

  return toBaseDataEntry(String(value));
};

export const toEnumDataEntry = <T extends Record<string, string>>(
  enumSchema: T,
  maybeMember?: string | null
): BaseDataEntry<T[keyof T]> | undefined => {
  if (isEnumMember<T>(enumSchema, maybeMember)) {
    return toBaseDataEntry<T[keyof T]>(maybeMember);
  }
  return undefined;
};

export const toYesNoDataEntry = (value?: boolean | null): YesNoDataEntry | undefined => {
  if (isNil(value)) {
    return undefined;
  }

  return {
    id: value ? YesNoAnswer.YES : YesNoAnswer.NO,
    name: value ? 'Yes' : 'No',
  };
};

export const toDateEntry = (dateString?: string | null): DateEntry | undefined => {
  if (!dateString || isValid(dateString)) {
    return undefined;
  }

  const dateObj = new Date(dateString);

  return {
    parsedDate: dateString,
    day: String(getDate(dateObj)),
    month: String(getMonth(dateObj) + 1),
    year: String(getYear(dateObj)),
  };
};

export const referenceDescriptionDtoToBaseDataEntry = (
  value?: ReferenceDescriptionDto | null
): BaseDataEntry | undefined => {
  if (isNil(value)) {
    return undefined;
  }

  return {
    id: value.code || '',
    name: value.description || value.code || '',
  };
};

export const trackingDeviceParser = (
  option: ReferenceDescriptionDto | null | undefined
) => {
  if (option?.code) {
    return {
      hasTrackingDevice: toYesNoDataEntry(!!option?.code),
      code: referenceDescriptionDtoToBaseDataEntry(option),
    };
  }
  return {
    hasTrackingDevice: toYesNoDataEntry(!!option?.code),
    code: null,
  };
};
export const alarmImmobiliserParser = (alarmImmobiliser?: AlarmImmobiliserDto) => {
  if (alarmImmobiliser) {
    const { model, type } = alarmImmobiliser;

    if (model && type && type.code) {
      return {
        id: type.code,
        name: model,
      };
    }
  }

  return undefined;
};

export const propertiesMapper = <T, R>(
  object: Record<string, T | null | undefined>,
  parser: (value?: T | null | undefined) => R | undefined
): Record<string, R> =>
  Object.entries(object).reduce<Record<string, R>>((result, [key, value]) => {
    const transformed = parser(value);

    if (!transformed) {
      return result;
    }

    return { ...result, [key]: transformed };
  }, {});

export const vehiclePayloadParser = (
  vehicle?: VehicleDto
): DeepPartial<Vehicle> | undefined => {
  if (!vehicle) {
    return undefined;
  }

  const parsedModifications = filterMapParser(
    vehicle.modifications,
    referenceDescriptionDtoToBaseDataEntry
  );

  const baseDataEntries = propertiesMapper(
    {
      abiCode: vehicle.abiCode,
      make: vehicle.make,
      model: vehicle.model,
      importType: vehicle.importType,
      bodyType: vehicle.bodyType,
      vin: vehicle.vin,
    },
    toBaseDataEntry
  );

  const numericEntries = propertiesMapper(
    {
      manufactureYear: vehicle.manufactureYear,
      manufactureStartYear: vehicle.manufactureStartYear,
      manufactureEndYear: vehicle.manufactureEndYear,
      numberOfDoors: vehicle.numberOfDoors,
      cubicCapicityInLitres: vehicle.cubicCapicityInLitres,
      weightInKg: vehicle.weightInKg,
      numberOfSeats: vehicle.numberOfSeats,
    },
    numberToBaseDataEntry
  );

  const booleanEntries = propertiesMapper(
    {
      isImported: vehicle.isImported,
      isCarModified: (vehicle.modifications || []).length > 0,
    },
    toYesNoDataEntry
  );

  return {
    registrationNumber: vehicle.registrationNumber || undefined,
    modifications: parsedModifications ?? undefined,
    selfDeclaredVehicleValue: vehicle.selfDeclaredVehicleValue?.amount,
    ...baseDataEntries,
    ...numericEntries,
    ...booleanEntries,
    steeringWheelSide: referenceDescriptionDtoToBaseDataEntry(vehicle.steeringWheelSide),
    trackingDevice: trackingDeviceParser(vehicle.trackingDevice),
    primaryFuelType: toEnumDataEntry(VehicleFuelType, vehicle.primaryFuelType?.code),
    transmission: toEnumDataEntry(VehicleTransmissionType, vehicle.transmission?.code),
    type: toEnumDataEntry(VehicleType, vehicle.type?.code),
    alarmImmobiliser: alarmImmobiliserParser(vehicle.alarmImmobiliser),
    usage: {
      type: referenceDescriptionDtoToBaseDataEntry(vehicle.usage?.type),
      annualMileage: vehicle.usage?.annualMileage,
      businessMileage: vehicle.usage?.businessMileage,
    },
    location: {
      daytimeLocation: referenceDescriptionDtoToBaseDataEntry(
        vehicle.location?.daytimeLocation
      ),
      overnightLocation: referenceDescriptionDtoToBaseDataEntry(
        vehicle.location?.overnightLocation
      ),
      overnightLocationPostcode: vehicle.location?.overnightLocationPostcode || undefined,
    },
    ownership: {
      isPurchased: toYesNoDataEntry(vehicle.ownership?.isPurchased),
      selfDeclaredDateOfPurchase: toDateEntry(
        vehicle.ownership?.selfDeclaredDateOfPurchase
      ),
      registerdKeeper: referenceDescriptionDtoToBaseDataEntry(
        vehicle.ownership?.registeredKeeper
      ),
      legalOwner: referenceDescriptionDtoToBaseDataEntry(vehicle.ownership?.legalOwner),
    },
  };
};

export const contactPreferencesParser = (
  contactPreferences?: ContactPreferencesDto
): MarketingPreferences | undefined => {
  if (!contactPreferences) {
    return undefined;
  }

  return Object.entries(contactPreferences).reduce<MarketingPreferences>(
    (result, [key, value]) => {
      if (value === null) {
        return result;
      }

      switch (key) {
        case 'allowedToMarketByEmail':
          return [
            ...result,
            { id: MarketConsentType.EMAIL, checked: value, name: 'Email' },
          ];

        case 'allowedToMarketBySMS':
          return [...result, { id: MarketConsentType.SMS, checked: value, name: 'SMS' }];

        case 'allowedToMarketByPhone':
          return [
            ...result,
            { id: MarketConsentType.TELEPHONE, checked: value, name: 'Telephone' },
          ];

        case 'allowedToMarketByPost':
          return [
            ...result,
            { id: MarketConsentType.POST, checked: value, name: 'Post' },
          ];

        default:
          return result;
      }
    },
    []
  );
};

export const addressParser = (address?: AddressDtoV1): AddressDtoV3 | undefined =>
  address?.postOfficeAddress;

export const proposerParser = (
  proposer?: ProposerDto
): DeepPartial<QuoteJourneyProposer> | undefined => {
  if (!proposer) {
    return undefined;
  }

  return {
    firstName: proposer.firstName || undefined,
    lastName: proposer.lastName || undefined,
    telephoneNumber: {
      mobile: proposer.telephoneNumber?.mobile || undefined,
      landline: proposer.telephoneNumber?.landline || undefined,
    },
    email: proposer.email || undefined,
    homePostcode: proposer.address?.postOfficeAddress?.postcode || undefined,
    address: proposer.address?.postOfficeAddress,
    // homeAddress: toBaseDataEntry(proposer.address?.postOfficeAddress?.displayName), // TODO: BE will provide address.displayAddress property which should be used here
    title: referenceDescriptionDtoToBaseDataEntry(proposer.title),
    maritalStatus: referenceDescriptionDtoToBaseDataEntry(proposer.maritalStatus),
    dateOfBirth: toDateEntry(proposer.dateOfBirth),
    isHomeOwner: toYesNoDataEntry(proposer.isHomeOwner),
    childrenUnder16InHousehold: toNumericDataEntry(proposer.childrenUnder16InHousehold),
    numberOfVehiclesInHousehold: toNumericDataEntry(proposer.numberOfVehiclesInHousehold),
    marketingPreferences: contactPreferencesParser(proposer.contactPreferences),
  };
};

export const occupationParser = ({
  type,
  employmentStatus,
  industry,
  isPrimary,
}: OccupationModelDto): DeepPartial<Occupation> => ({
  type: referenceDescriptionDtoToBaseDataEntry(type),
  ...(employmentStatus?.code
    ? { employmentStatus: referenceDescriptionDtoToBaseDataEntry(employmentStatus) }
    : {}),
  industry: referenceDescriptionDtoToBaseDataEntry(industry),
  isPrimary,
});

export const occupationsParser = (
  occupations?: OccupationModelDto[] | null
): DeepPartial<Occupations> | undefined => {
  if (!occupations) {
    return undefined;
  }

  const parsedOccupations = occupations.map(occupationParser);
  const primaryJobIndex = parsedOccupations.findIndex(({ isPrimary }) => isPrimary);
  parsedOccupations[primaryJobIndex].anotherJob = toYesNoDataEntry(
    parsedOccupations.length > 1
  );

  return parsedOccupations;
};

const claimParser = ({
  type,
  date,
  settled,
  damageAmount,
  atFault,
}: MotorClaimDto): DeepPartial<AccidentOrClaimType> => ({
  selfDeclaredClaimType: referenceDescriptionDtoToBaseDataEntry(type),
  claimDate: toDateEntry(date),
  fault: toYesNoDataEntry(atFault),
  claimSettled: toYesNoDataEntry(settled),
  isSaved: true,
  claimAmount: damageAmount?.amount,
});

const homeAddressParser = (postOfficeAddress: PostOfficeAddressDto | undefined) => {
  if (postOfficeAddress) {
    const { buildingNumber, thoroughfare, town, postcode } = postOfficeAddress;
    const address = `${buildingNumber} ${thoroughfare}, ${town}. ${postcode}`;
    return {
      id: `${buildingNumber} ${thoroughfare}`,
      name: address,
    };
  }
  return undefined;
};
export const driverParser = (driver: DriverDto): DeepPartial<DriverFormData> => {
  const isUseOtherVehileUseCodeDifferentThanNo = !!(
    driver.otherVehicleUse && driver.otherVehicleUse?.code !== YesNoAnswer.NO
  );
  return {
    id: uuidv4(),
    firstName: driver.firstName || undefined,
    lastName: driver.lastName || undefined,
    title: referenceDescriptionDtoToBaseDataEntry(driver.title),
    maritalStatus: referenceDescriptionDtoToBaseDataEntry(driver.maritalStatus),
    dateOfBirth: toDateEntry(driver.dateOfBirth),
    homePostcode: driver.address?.postOfficeAddress?.postcode || undefined,
    address: driver.address?.postOfficeAddress,
    homeAddress: homeAddressParser(driver.address?.postOfficeAddress),
    isProposer: toYesNoDataEntry(driver.isProposer),
    isMainDriver: toYesNoDataEntry(driver.isMainDriver),
    relationshipToProposer: referenceDescriptionDtoToBaseDataEntry(
      driver.relationshipToProposer
    ),
    drivingLicence: {
      number: driver.drivingLicence?.number || undefined,
      type: referenceDescriptionDtoToBaseDataEntry(driver.drivingLicence?.type),
      provideLicence: toYesNoDataEntry(!!driver.drivingLicence?.number),
      yearsHeld: numberToBaseDataEntry(driver.drivingLicence?.yearsHeld),
      monthsHeld: numberToBaseDataEntry(driver.drivingLicence?.monthsHeld),
    },
    occupations: occupationsParser(driver.occupations),
    ukResidencyDetails: {
      isUkResidentSinceBirth: toYesNoDataEntry(
        driver.ukResidencyDetails?.isUkResidentSinceBirth
      ),
      ukResidentSince: toDateEntry(driver.ukResidencyDetails?.ukResidentSince),
    },
    medicalCondition: {
      hasMedicalConditions: toYesNoDataEntry(!!driver.medicalCondition),
      code: referenceDescriptionDtoToBaseDataEntry(driver.medicalCondition),
    },
    advancedDrivingQualifications: {
      hasAdvancedDrivingQualifications: toYesNoDataEntry(
        !!driver.advancedDrivingQualifications?.length
      ),
      advancedDrivingQualifications: driver.advancedDrivingQualifications?.map(
        ({ type }) => referenceDescriptionDtoToBaseDataEntry(type)
      ),
    },
    otherVehicleUse: {
      otherVehicleUse: toYesNoDataEntry(isUseOtherVehileUseCodeDifferentThanNo),
      otherVehicleUseCode: isUseOtherVehileUseCodeDifferentThanNo
        ? referenceDescriptionDtoToBaseDataEntry(driver.otherVehicleUse)
        : null,
    },
    motorConvictions: {
      hasMotorConvictions: toYesNoDataEntry(!!driver.motorConvictions?.length),
      motorConvictions: driver.motorConvictions?.map(
        ({ type, fineAmount, disqualifiedLengthInMonths, date }) => ({
          code: referenceDescriptionDtoToBaseDataEntry(type),
          fineAmount: fineAmount?.amount,
          banReceived: toYesNoDataEntry(!!disqualifiedLengthInMonths),
          date: toDateEntry(date),
          disqualifiedLengthInMonths,
          isSaved: true,
        })
      ),
    },
    hasNonMotoringConvictions: toYesNoDataEntry(driver.hasNonMotoringConvictions),
    accidentsOrClaims: {
      hasAccidentOrClaimInLast5Years: toYesNoDataEntry(
        !!driver.previousMotorClaims?.length
      ),
      selfDeclaredPreviousMotorClaims: driver.previousMotorClaims?.map(claimParser),
    },
  };
};

export const driversParser = (
  drivers?: DriverDto[] | null
): DeepPartial<Array<DriverFormData>> | undefined => {
  if (!drivers) {
    return undefined;
  }
  return drivers.map(driverParser);
};

export const coverParser = (
  cover?: CoverResponseDto,
  paymentPlans?: PaymentPlanDto[] | null,
  addOns?: AddOnModelDto[] | null,
  noClaimsHistory?: NoClaimsHistoryDto | null
): DeepPartial<Cover> => {
  const selectedPaymentPlan = paymentPlans?.find(({ selected }) => selected);

  return {
    type: referenceDescriptionDtoToBaseDataEntry(cover?.type),
    startDateTimeUtc: toDateEntry(cover?.startDateTimeUtc),
    paymentType: toBaseDataEntry(selectedPaymentPlan?.type?.code),
    addOns: addOns?.map(({ type }) => referenceDescriptionDtoToBaseDataEntry(type)),
    breakdownAddon: null,
    noClaimsHistory: {
      yearsNoClaimsExperience: toBaseDataEntry(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        noClaimsHistory?.yearsNoClaimsExperience?.toString()
      ),
      yearsNoClaimsBonus: toBaseDataEntry(
        noClaimsHistory?.yearsNoClaimsBonus?.toString()
      ),
    },
  };
};

export const voluntaryAmountParser = ({
  type,
  total,
}: ExcessAmountDto): VoluntaryAmounts | undefined => {
  const parsedTotal = numberToPoundDataEntry(total);
  if (!type?.code || !parsedTotal) {
    return undefined;
  }

  return {
    type: {
      code: type.code,
    },
    total: parsedTotal,
  };
};

export const excessesParser = (
  excesses?: ExcessesResponseDto
): DeepPartial<Excesses> | undefined => {
  if (!excesses) {
    return undefined;
  }

  const parsedVoluntaryAmounts = filterMapParser(
    excesses.voluntaryAmounts,
    voluntaryAmountParser
  );

  if (!parsedVoluntaryAmounts?.length) {
    return undefined;
  }

  return {
    voluntaryAmounts: parsedVoluntaryAmounts,
  };
};

const getUsualPaymentFrequency = (paymentsPlans?: PaymentPlanDto[] | null) => {
  if (!paymentsPlans) {
    return {
      code: toBaseDataEntry(''),
    };
  }

  let selectedPlan = paymentsPlans?.find(plan => plan.selected);
  const userPrefferedPaymentType = sessionStorage.getItem('userPrefferedPaymentType');
  if (!selectedPlan && userPrefferedPaymentType) {
    const planSymbol =
      userPrefferedPaymentType === 'MON' ? PaymentPlansEnum.MON : PaymentPlansEnum.ANN;
    selectedPlan = paymentsPlans?.find(plan => plan?.type?.code?.includes(planSymbol));
  }

  if (!selectedPlan) {
    selectedPlan = paymentsPlans?.find(plan => plan.default);
  }

  if (isAnnualPaymentPlan(selectedPlan?.type?.code)) {
    return {
      code: toBaseDataEntry(PaymentTypesEnum.ANNUAL),
    };
  }

  if (isMonthlyPaymentPlan(selectedPlan?.type?.code)) {
    return {
      code: toBaseDataEntry(PaymentTypesEnum.MONTHLY),
    };
  }

  return {
    code: toBaseDataEntry(''),
  };
};

export const parseQuoteResponseToFormikState = (
  quote: GetMotorQuoteResponseDto | null,
  correlationId: string | null | undefined
): DeepPartial<QuoteJourneyFormData> | null => {
  if (!quote) {
    return null;
  }
  const policyCorrelationId = correlationId ? { policyCorrelationId: correlationId } : {};
  const proposerDriver = quote.drivers?.find(driver => driver.isProposer);
  return {
    vehicle: vehiclePayloadParser(quote.vehicle),
    proposer: proposerParser(quote.proposer),
    drivers: driversParser(quote.drivers),
    cover: coverParser(
      quote.cover,
      quote.paymentPlans,
      quote.addOns,
      proposerDriver?.noClaimsHistory
    ),
    excesses: excessesParser(quote.excesses),
    hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: toYesNoDataEntry(
      quote.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled
    ),
    endorsements: quote.endorsements,
    usualPaymentFrequency: getUsualPaymentFrequency(quote?.paymentPlans),
    // TODO value not present on api response model
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    protectNoClaimsDiscount: toYesNoDataEntry(quote?.protectNoClaimsDiscount),
    ...policyCorrelationId,
  };
};
