import { ReactNode } from 'react';

export type ContentContainerProps = {
  children?: ReactNode;
  className?: string;
};

export const ContentContainer = ({ children, className }: ContentContainerProps) => {
  return (
    <div
      className={`mx-auto max-w-tablet-container sm:min-w-tablet-container text-main-content-1 lg:max-w-desktop-container lg:min-w-desktop-container ${className}`}
    >
      {children}
    </div>
  );
};
