import { Card, Icon } from '@dayinsure/components';
import { BaseDataEntry } from '../../../../../types';

type DirectDebitDetailsCardProps = {
  paymentDate: BaseDataEntry | null;
  accountHolder: string;
  accountNumber: string;
  sortCode: string;
  testId: string;
  onEdit(): void;
};

export const DirectDebitDetailsCard = ({
  paymentDate,
  accountHolder,
  accountNumber,
  sortCode,
  testId,
  onEdit,
}: DirectDebitDetailsCardProps) => {
  return (
    <Card classNames="flex flex-col" testId={testId}>
      <div className="flex flex-col justify-between md:flex-row">
        <Card.CardListItem
          label="Payment day of month"
          icon="date"
          testId={`${testId}_list-item_payment-day`}
          labelClassName="text-main-content-2"
        >
          {paymentDate?.name}
        </Card.CardListItem>
        <button
          className="flex items-center text-sm font-semibold leading-[14px]"
          data-testid={`${testId}_edit-driver-button`}
          onClick={onEdit}
          type="button"
        >
          <Icon name="edit" className="mr-2" size="12px" />
          Edit
        </button>
      </div>
      <Card.CardListItem
        label="Name of account holder"
        icon="user"
        labelClassName="text-main-content-2"
        testId={`${testId}_list-item_account-holder`}
      >
        {accountHolder}
      </Card.CardListItem>
      <Card.CardListItem
        label="Bank account number"
        icon="bank"
        labelClassName="text-main-content-2"
        testId={`${testId}_list-item_bank-account-number`}
      >
        {accountNumber}
      </Card.CardListItem>
      <Card.CardListItem
        label="Bank sort code"
        icon="bank"
        labelClassName="text-main-content-2"
        testId={`${testId}_list-item_bank-sort-code`}
      >
        {sortCode}
      </Card.CardListItem>
    </Card>
  );
};
