import { useField } from 'formik';
import {
  FieldError,
  PaymentTypeRadio,
  PaymentTypeRadioProps,
  PaymentTypeRadioOption,
} from '@dayinsure/components';
import { useRef } from 'react';
import { useFocusOnError } from '../../../../../hooks';

type FormRadioSelectProps = {
  onChangeOptional?: (option: { id: string; name: string }) => void;
} & Omit<PaymentTypeRadioProps, 'value' | 'onChange' | 'onBlur' | 'error'>;

export const FormPaymentTypeRadio = ({
  testId,
  onChangeOptional,
  ...inputProps
}: FormRadioSelectProps) => {
  const [field, meta] = useField(inputProps);
  const fieldRef = useRef<HTMLDivElement | null>(null);
  useFocusOnError({ fieldRef, name: inputProps.name });

  const onChange = (option: PaymentTypeRadioOption) => {
    const selectedOption = {
      id: option.id,
      name: option.name,
    };
    field.onChange({ target: { value: selectedOption, name: field.name } });
    if (onChangeOptional) {
      onChangeOptional(selectedOption);
    }
  };

  const onBlur = () => {
    field.onBlur({ target: { name: field.name } });
  };

  return (
    <div className="relative form-field-spacing" data-testid={testId} ref={fieldRef}>
      <PaymentTypeRadio
        testId={testId}
        {...inputProps}
        {...field}
        onChange={onChange}
        onBlur={onBlur}
        error={meta.touched && meta.error !== undefined}
        aria-errormessage={`${inputProps.id}_error`}
      />
      {meta.touched && meta.error && (
        <FieldError
          testId={`${inputProps.id}_error`}
          id={`${inputProps.id}_error`}
          error={meta.error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
