/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetCategory } from '../models/AssetCategory';
import type { ConfirmAccountEmailRequest } from '../models/ConfirmAccountEmailRequest';
import type { ConfirmAccountResponse } from '../models/ConfirmAccountResponse';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { IAPIResult } from '../models/IAPIResult';
import type { InsuredAssetBaseIEnumerableIAPIResult } from '../models/InsuredAssetBaseIEnumerableIAPIResult';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PersonRequest } from '../models/PersonRequest';
import type { PersonResponse } from '../models/PersonResponse';
import type { PersonResponseV2 } from '../models/PersonResponseV2';
import type { PolicySummaryListIAPIResult } from '../models/PolicySummaryListIAPIResult';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdatePersonRequestV2 } from '../models/UpdatePersonRequestV2';
import type { VerifyEmailAddressRequest } from '../models/VerifyEmailAddressRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PersonService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets a person given an ID
   * @param id An ID representing a person
   * @returns PersonResponseV2 Success
   * @throws ApiError
   */
  public getApiV2Person(
    id: string,
  ): CancelablePromise<PersonResponseV2> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates an existing person given the id.  Properties given will be added or updated
   * @param id An ID representing a person
   * @param requestBody Request containing a persons details.
   * @returns void
   * @throws ApiError
   */
  public putApiV2Person(
    id: string,
    requestBody?: UpdatePersonRequestV2,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/person/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Deletes an existing person given the id.
   * @param id An ID representing a person
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public deleteApiV2Person(
    id: string,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Creates a new person object given properties of that person
   * @param requestBody Request containing a persons details.
   * @returns PersonResponse Success
   * @throws ApiError
   */
  public postApiV2Person(
    requestBody?: PersonRequest,
  ): CancelablePromise<PersonResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        422: `Client Error`,
      },
    });
  }

  /**
   * Sends a password reset email.
   * @param email The email address to send the password reset email to.
   * @param requestBody Model containing the return Url.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonSendPasswordReset(
    email: string,
    requestBody?: SendPasswordResetRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{email}/send-password-reset',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Uses the password token to confirm request and updates the email
   * @param personId The security id representing the person identification
   * @param requestBody Model containing password and password token
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonConfirmPasswordReset(
    personId: string,
    requestBody?: ConfirmPasswordResetRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/confirm-password-reset',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Sends a new email verification email to the given email address if an unverified person with the email address exists
   * @param email The email address to send the verification to
   * @param requestBody Model containing the return Url
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonSendEmailVerification(
    email: string,
    requestBody?: SendVerificationEmailRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{email}/send-email-verification',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Verifies the email address of the user by security id.
   * @param personId The security id representing the person identification
   * @param requestBody Model containing the email details to be verified
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonVerifyEmailAddress(
    personId: string,
    requestBody?: VerifyEmailAddressRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/verify-email-address',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Sends a new email verification email to the given personId if an unverified person with the email address exists
   * @param personId The email address to send the verification to
   * @param requestBody request containing the return Url
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonConfirmAccountEmail(
    personId: string,
    requestBody?: ConfirmAccountEmailRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/confirm-account-email',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * Sends a new email to check if a confirm account exists
   * @param email
   * @returns ConfirmAccountResponse Success
   * @throws ApiError
   */
  public getApiV2PersonConfirmAccountExists(
    email: string,
  ): CancelablePromise<ConfirmAccountResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{email}/confirm-account-exists',
      path: {
        'email': email,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Get policy summaries by the user id.
   * @param personId An ID representing a person Id
   * @returns PolicySummaryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PersonPolicySummary(
    personId: string,
  ): CancelablePromise<PolicySummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{personId}/policy-summary',
      path: {
        'personId': personId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @param personId
   * @param category
   * @returns InsuredAssetBaseIEnumerableIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PersonInsuredAsset(
    personId: string,
    category?: AssetCategory,
  ): CancelablePromise<InsuredAssetBaseIEnumerableIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{personId}/insured-asset',
      path: {
        'personId': personId,
      },
      query: {
        'category': category,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates the current password with the new password provided
   * @param personId The security id representing the person identification
   * @param requestBody Model containing the current and new password
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV2PersonChangePassword(
    personId: string,
    requestBody?: PasswordChangeRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/person/{personId}/change-password',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

}