import { TestContext } from 'yup';
import { AnyObject } from 'yup/es/types';
import { FromObj } from '../../../types';
import { isTestContextExtended } from '../validation';

export const getIsSameRegPlate = (ctx: TestContext<AnyObject>): boolean | null => {
  if (!isTestContextExtended(ctx)) {
    return null;
  }

  const isSameRegPlateCtx = ctx?.from?.find(
    (from: FromObj) => from?.value?.isSameRegPlate
  ) as { value: { isSameRegPlate: boolean } } | undefined;

  if (!isSameRegPlateCtx) {
    return null;
  }

  return isSameRegPlateCtx.value.isSameRegPlate;
};
