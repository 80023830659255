import Icon from '../../DataDisplay/Icon/Icon';
import Card from '../../Layout/Card/Card';
import Button from '../../Inputs/Button/Button';
import Dialog from '../Dialog/Dialog';

type ErrorDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  error: string;
  ctaId: string;
  ctaMessage: string;
};

const ErrorDialog = ({
  open,
  onClose,
  title,
  error,
  ctaId,
  ctaMessage,
}: ErrorDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} title={title}>
      <Card
        classNames="!bg-popup-background flex items-center gap-4 md:gap-6 text-left"
        paddingLevel="small"
      >
        <Icon name="warning" size="2rem" className="text-popup-content-1" />
        <span id="dialog-error-message" className="text-popup-content-1">
          {error}
        </span>
      </Card>
      <Button
        id={ctaId}
        fullWidth
        onClick={onClose}
        text={ctaMessage}
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};

export { ErrorDialog, type ErrorDialogProps };
