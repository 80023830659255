import { LoadingSpinner } from '@dayinsure/components';
import { postcodeRegex } from '@dayinsure/shared';
import { useFormikContext } from 'formik';
import get from 'lodash.get';
import {
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { useAddressLookupQuery } from '../../../hooks/queries/useAddressLookupQuery';
import { FormListBox, FormPostcodeField } from '../../FormsFields';
import { QuoteJourneyFormData } from '../../../types';

export type AddressSearchProps = {
  id: string;
  testId: string;
  postcodeLabel?: ReactNode;
  addressLabel?: ReactNode;
  postcodeInputName: string;
  addressInputName: string;
  className?: string;
  isFetching?: boolean;
  onFindAddress?: (postcode: string) => void;
};

export const AddressSearch = ({
  className,
  id,
  testId,
  postcodeLabel = 'Postcode',
  addressLabel = 'Address',
  postcodeInputName,
  addressInputName,
  isFetching,
  onFindAddress,
}: AddressSearchProps) => {
  const [lastPostcodeSearched, setLastPostcodeSearched] = useState('');
  const { values, setFieldTouched, setFieldValue, isSubmitting } =
    useFormikContext<QuoteJourneyFormData>();
  const addressInputValue = get(values, addressInputName);
  const postcodeInputValue = get(values, postcodeInputName);
  const initialPostcodeInputValue = useRef(postcodeInputValue);
  const [addressVisible, setAddressVisible] = useState<boolean>(!!addressInputValue);

  const {
    data,
    isFetching: isFetchingAddressesOptions,
    refetch,
  } = useAddressLookupQuery(postcodeInputValue);
  const isFetchingData = isFetching || isFetchingAddressesOptions;

  const handleFindAddressOptions = useCallback(async () => {
    setFieldTouched(postcodeInputName);
    setFieldTouched(addressInputName, false);
    if (
      postcodeInputValue &&
      postcodeInputValue !== lastPostcodeSearched &&
      postcodeRegex.test(postcodeInputValue)
    ) {
      await refetch();
      setFieldValue(addressInputName, null);
      setAddressVisible(true);
      onFindAddress?.(postcodeInputValue);
    } else {
      setLastPostcodeSearched(postcodeInputValue);
      onFindAddress?.(postcodeInputValue);
    }
  }, [
    addressInputName,
    lastPostcodeSearched,
    postcodeInputName,
    postcodeInputValue,
    refetch,
    setFieldTouched,
    setFieldValue,
    onFindAddress,
  ]);

  useEffect(() => {
    if (isSubmitting && postcodeInputValue && !addressVisible) {
      handleFindAddressOptions();
      setFieldTouched(addressInputName, true);
    }
  }, [
    addressInputName,
    addressVisible,
    handleFindAddressOptions,
    isSubmitting,
    postcodeInputValue,
    setFieldTouched,
  ]);
  useLayoutEffect(() => {
    if (initialPostcodeInputValue.current) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (initialPostcodeInputValue.current !== postcodeInputValue) {
      setFieldValue(addressInputName, null);
    }
  }, [addressInputName, postcodeInputValue, refetch, setFieldTouched, setFieldValue]);

  const handleAddressVisibility = useCallback(() => {
    if (addressVisible) {
      setAddressVisible(false);
    }
  }, [addressVisible]);

  const addressOptions = data?.map(option => ({
    id: `${option.buildingNumber} ${option.thoroughfare}` || '',
    name: option.displayText || '',
  }));

  return (
    <div className={className}>
      <FormPostcodeField
        label={{ icon: 'postcode', text: postcodeLabel }}
        id={`${id}_postcode_input`}
        testId={`${testId}_postcode_input`}
        name={postcodeInputName}
        placeholder="e.g. AB1 2CD"
        onClick={handleFindAddressOptions}
        onChangeCapture={handleAddressVisibility}
      />

      {isFetchingData ? (
        <LoadingSpinner
          centered
          size="small"
          className="my-8"
          data-testid={`${testId}_address-loader`}
        />
      ) : (
        addressVisible &&
        addressOptions && (
          <FormListBox
            className="mt-6 md:mt-12"
            icon="location"
            id={`${id}_address-dropdown`}
            testId={`${testId}_address-dropdown`}
            label={addressLabel}
            name={addressInputName}
            options={addressOptions}
            unselectedMessage="Please select"
            noOptionsMessage="No options"
          />
        )
      )}
    </div>
  );
};
