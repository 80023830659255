import { Button, Card } from '@dayinsure/components';
import { Form } from 'formik';
import { DetailsCorrect, QuoteJourneyLayout } from '../../../components';
import { CarModification } from './CarModification';
import { CarSpecs } from './CarSpecs';
import { CarPurchaseDate } from './CarPurchaseDate';
import { CarValuation } from './CarValuation';
import { RegisteredKeeperLegalOwner } from './RegisteredKeeperLegalOwner';
import { CarDetailsCard } from './CarDetailsCard';

const testId = 'your-car';

export const YourCar = () => (
  <QuoteJourneyLayout>
    <h1 className="mb-8 text-xl text-center md:text-left" data-testid={`${testId}_title`}>
      Your car
    </h1>
    <Card
      testId="car-details_check-info_card"
      paddingLevel="small"
      classNames="mb-6 lg:mb-8"
    >
      <span>Please check the car details below.</span>
    </Card>
    <div className="mb-16 sm:mb-24">
      <CarDetailsCard testId={testId} />
      <Form>
        <CarSpecs />
        <DetailsCorrect testId={testId} />
        <CarValuation />
        <CarModification />
        <CarPurchaseDate />
        <RegisteredKeeperLegalOwner testId={testId} />
        <Button
          id="car-details_continue-button"
          testId="car-details_continue-button"
          submit
          fullWidth
          icon={{ name: 'arrow-right', position: 'right' }}
          text="Continue"
        />
      </Form>
    </div>
  </QuoteJourneyLayout>
);
