import { Card } from '@dayinsure/components';
import { VehicleDto } from '../../../../../api/v1';

type ModificationsCardProps = {
  testId: string;
  vehicleModifications: VehicleDto['modifications'];
};

export const ModificationsCard = ({
  testId,
  vehicleModifications,
}: ModificationsCardProps) => {
  return (
    <Card testId={`${testId}_modifications-card`} classNames="w-full mb-6">
      <h3 className="font-raleway text-lg font-normal">Modifications</h3>
      <Card.CardListItem
        label="Modifications"
        icon="spanner"
        testId={`${testId}_modifications-card_list-item_modifications`}
      >
        <ul className="pl-4 list-disc">
          {vehicleModifications?.map(({ code, description }) => (
            <li key={code}>{description || code}</li>
          ))}
        </ul>
      </Card.CardListItem>
    </Card>
  );
};
