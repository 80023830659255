import { YesNoAnswer } from '../../../types';
import { isYes } from '../../utils';

export const getEndorsement208BaseOnEndorsement202 = (
  isEndorsement202: boolean,
  approvedTrackerId?: YesNoAnswer
): boolean => {
  if (!isEndorsement202) {
    return true;
  }

  return !!(isEndorsement202 && isYes(approvedTrackerId));
};
