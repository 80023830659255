import React, { ComponentType, LazyExoticComponent } from 'react';

export type LazyFactory<T> = () => Promise<{ default: T }>;

const lazyWithPreload = <T extends ComponentType<unknown>>(
  factory: LazyFactory<T>
): [LazyExoticComponent<T>, LazyFactory<T>] => {
  const Component = React.lazy(factory);
  return [Component, factory];
};

export { lazyWithPreload };
