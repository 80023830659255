/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MemberTypes {
  CONSTRUCTOR = 'Constructor',
  EVENT = 'Event',
  FIELD = 'Field',
  METHOD = 'Method',
  PROPERTY = 'Property',
  TYPE_INFO = 'TypeInfo',
  CUSTOM = 'Custom',
  NESTED_TYPE = 'NestedType',
  ALL = 'All',
}