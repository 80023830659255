import {
  FieldError,
  RadioSelect,
  RadioSelectProps,
  SelectRadioOption,
} from '@dayinsure/components';
import { useField } from 'formik';
import { useRef } from 'react';
import { useFocusOnError } from '../../../hooks';

type FromRadioSelectExtendedProps = {
  onChangeOptional?: (option: SelectRadioOption) => void;
  isInputChangeEnabled?: boolean;
};

type FormRadioSelectProps = Omit<
  RadioSelectProps,
  'value' | 'onChange' | 'onBlur' | 'error'
> &
  FromRadioSelectExtendedProps;

export const FormRadioSelect = ({
  testId,
  onChangeOptional,
  isInputChangeEnabled = true,
  ...inputProps
}: FormRadioSelectProps) => {
  const [field, meta] = useField(inputProps);
  const fieldRef = useRef<HTMLDivElement | null>(null);
  useFocusOnError({ fieldRef, name: inputProps.name });
  const onChange = (option: SelectRadioOption) => {
    if (onChangeOptional) {
      onChangeOptional(option);
    }

    if (isInputChangeEnabled) {
      field.onChange({ target: { value: option, name: field.name } });
    }
  };

  const onBlur = () => {
    if (isInputChangeEnabled) {
      field.onBlur({ target: { name: field.name } });
    }
  };

  return (
    <div className="relative form-field-spacing" data-testid={testId} ref={fieldRef}>
      <RadioSelect
        testId={testId}
        {...inputProps}
        {...field}
        onChange={onChange}
        onBlur={onBlur}
        error={meta.touched && meta.error !== undefined}
        aria-errormessage={`${inputProps.id}_error`}
      />
      {meta.touched && meta.error && (
        <FieldError
          testId={`${inputProps.id}_error`}
          id={`${inputProps.id}_error`}
          error={meta.error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};
