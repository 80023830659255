import { Tooltip } from '@dayinsure/components';

type ModificationTooltipProps = {
  testId: string;
};

export const ModificationTooltip = ({ testId }: ModificationTooltipProps) => {
  return (
    <div className="inline-block ml-2 sm:!relative">
      <Tooltip
        ariaLabel="hint"
        className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
        position="relative"
        testId={testId}
        variant="annual"
      >
        <h4 className="mb-2 font-bold">What counts as a modification?</h4>
        <p className="mb-2">
          A vehicle has been modified or adapted if it’s been changed from the
          manufacturer’s standard specification in any way. Examples include:
        </p>
        <ul className="pl-4 list-disc">
          <li>Changes to the bodywork, including spoilers and/or body kits</li>
          <li>Changes to the suspension, brakes, engine or exhaust system</li>
          <li>Cosmetic changes such as alloy wheels, paint, stickers or decals</li>
        </ul>
        <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
          Got it
        </Tooltip.Close>
      </Tooltip>
    </div>
  );
};
