import { Tooltip } from '@dayinsure/components';

type VoluntaryExcessTooltipProps = {
  testId: string;
};

export const VoluntaryExcessTooltip = ({ testId }: VoluntaryExcessTooltipProps) => (
  <div className="inline-block ml-2 sm:!relative">
    <Tooltip
      ariaLabel="hint"
      className="inline-flex top-[72px] flex-col xs:top-8 text-popup-content-1"
      position="relative"
      testId={`${testId}_voluntary-excess`}
      variant="annual"
    >
      <h4 className="mb-2 font-bold">Excess</h4>
      <p className="mb-2">
        Excess is what you have to pay towards a claim. There are two parts: compulsory
        and voluntary. Added together you’ll see it called your ‘total excess’. To claim
        on your car insurance your claim needs to be worth more than your total excess.
      </p>
      <h4 className="mb-2 font-bold">Compulsory excess</h4>
      <p className="mb-2">
        The insurer sets this part. It depends on things like your age, the car and your
        driving history.
      </p>
      <h4 className="mb-2 font-bold">Voluntary excess</h4>
      <p className="mb-2">
        You choose this - it’s what you want to pay towards a claim.
      </p>
      <p>
        For example, if your insurer sets a £100 compulsory excess, and you choose to pay
        a £250 voluntary excess, your total excess will be £350.
      </p>
      <Tooltip.Close className="pt-2 font-bold text-left text-popup-link">
        Got it
      </Tooltip.Close>
    </Tooltip>
  </div>
);
