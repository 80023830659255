import { useParams } from 'react-router-dom';
import { useQuoteQuery } from './queries';
import { useUpdateQuoteMutation } from './mutations';
import { AddOn } from '../types';
import { addOnToCode, isChecked } from '../pages/Quote/YourQuote/YourQuote.utils';

export const usePaymentInfo = () => {
  const { quoteId } = useParams<{ quoteId: string }>();
  const { data, refetch } = useQuoteQuery(quoteId);
  const { mutate, isLoading } = useUpdateQuoteMutation();

  const handleAddOnsUpdate = async (addOns: AddOn[]) => {
    if (quoteId) {
      const selectedAddOnTypes = addOns.filter(isChecked).map(addOnToCode);
      mutate(
        {
          id: quoteId,
          selectedAddOnTypes,
        },
        {
          onSuccess: () => refetch(),
        }
      );
    }
  };

  const handleChangePaymentType = (option: { id: string; name: string }) => {
    if (quoteId) {
      mutate({
        id: quoteId,
        paymentPlan: { code: option.id },
      });
    }
  };

  return {
    handleAddOnsUpdate,
    handleChangePaymentType,
    data,
    isLoading,
    quoteId,
  };
};
