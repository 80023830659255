import { Card, Button, PageContainer, PasswordValidation } from '@dayinsure/components';
import { Form, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import {
  GlobalErrorContext,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
  uppercaseRegex,
  useNavigateWithReferrer,
} from '@dayinsure/shared';
import { useContext } from 'react';
import { FormPasswordField } from '../../../../components';
import { resetPasswordCopy, passwordValidationCopy } from './ResetPasswordCopy';
import {
  ResetPasswordFormData,
  resetPasswordFormInitialData,
  resetPasswordValidationSchema,
} from '../../../../forms';
import {
  ConfirmPasswordResetRequestPayload,
  useConfirmPasswordResetMutation,
  useReferrer,
} from '../../../../hooks';
import { ApiError, IAPIResult } from '../../../../api/v2';
import { AuthPaths } from '../../../../routes';

const testId = 'reset-password';

const passwordValidationRegExpsConfig = {
  uppercaseRegex,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
};

export const ResetPassword = () => {
  const [params] = useSearchParams();
  const navigate = useNavigateWithReferrer();
  const referrer = useReferrer();
  const { setGlobalError, clearGlobalError } = useContext(GlobalErrorContext);

  const handleResetPasswordError = (error?: unknown) =>
    setGlobalError(error, {
      onClose: clearGlobalError,
      title: resetPasswordCopy.errorTitle,
      message: resetPasswordCopy.errorMsg,
      cta: resetPasswordCopy.errorCta,
      ctaId: `${testId}_error-cta-button`,
    });
  const handleResetPasswordAPIError = (error?: ApiError) => {
    if (error?.status === 400) {
      navigate(`../${AuthPaths.PasswordSecureLinkExired}`);
    } else {
      handleResetPasswordError();
    }
  };

  const handleResetPasswordSuccess = (data: IAPIResult) => {
    if (data.isSuccess) {
      navigate(`../${AuthPaths.PasswordSuccess}`);
    } else {
      handleResetPasswordError();
    }
  };

  const { mutate, isLoading } = useConfirmPasswordResetMutation(
    handleResetPasswordSuccess,
    handleResetPasswordAPIError
  );

  const handleSubmit = async (values: ResetPasswordFormData) => {
    const personId = params.get('personId');
    const identityProvider = params.get('identityProvider');
    const passwordResetToken = params.get('passwordResetToken');

    if (!personId || !identityProvider || !passwordResetToken) {
      handleResetPasswordError();
    } else {
      const payload: ConfirmPasswordResetRequestPayload = {
        personId,
        requestBody: {
          passwordResetToken,
          newPassword: values.password,
          referrer: referrer.name,
          tenant: referrer.identityProviderName,
        },
      };

      await mutate(payload);
    }
  };

  return (
    <PageContainer className="pt-6">
      <Formik<ResetPasswordFormData>
        initialValues={resetPasswordFormInitialData}
        validationSchema={resetPasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isValid, dirty }) => (
          <Form className="flex flex-col mx-auto mb-16 w-full md:max-w-tablet-container lg:mb-24 lg:max-w-desktop-container text-main-content-1">
            <h1
              className="self-center mt-2 mb-8 text-xl leading-10 text-center sm:mt-6 sm:mb-12"
              data-testid={`${testId}_title`}
            >
              {resetPasswordCopy.title}
            </h1>
            <Card paddingLevel="large" classNames="bg-content-background-prominent">
              <FormPasswordField
                id={`${testId}_new-password-input`}
                testId={`${testId}_new-password-input`}
                name="password"
                ariaLabel={resetPasswordCopy.newPasswordAriaLabel}
                label={{ icon: 'lock', text: resetPasswordCopy.newPasswordLabel }}
                placeholder={resetPasswordCopy.newPasswordPlaceholder}
                spacingClassName="mb-8"
              />
              <FormPasswordField
                id={`${testId}_confirm-new-password-input`}
                testId={`${testId}_confirm-new-password-input`}
                name="confirmPassword"
                ariaLabel={resetPasswordCopy.confirmPasswordAriaLabel}
                label={{ icon: 'lock', text: resetPasswordCopy.confirmPasswordLabel }}
                placeholder={resetPasswordCopy.confirmPasswordPlaceholder}
                spacingClassName="mb-8"
              />

              <PasswordValidation
                password={values.password}
                confirmPassword={values.confirmPassword}
                copy={passwordValidationCopy}
                regexpsConfig={passwordValidationRegExpsConfig}
              />

              <Button
                id={`${testId}_cta-button`}
                testId={`${testId}_cta-button`}
                submit
                text={resetPasswordCopy.ctaButton}
                fullWidth
                classNames="md:mt-12 mt-8"
                loading={isLoading}
                disabled={!isValid || !dirty}
              />
            </Card>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};
