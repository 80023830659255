import { Card } from '@dayinsure/components';
import { Accordion } from '../../Common/Accordion';

type MonthlyImportantInformationProps = {
  className?: string;
  testId: string;
};

const items = [
  {
    title: '<h4 class="font-bold">What can this agreement be used for?</h4>',
    description: `
      <p class="mb-4">
          The credit agreement is designed for the repayment of insurance premiums and
          isn’t suitable for any other use. If you are a company/partnership consisting of
          four or more persons, you will not benefit from protections under the Consumer
          Credit Act 1974 or the Consumer Sourcebook of the Financial Conduct Authority.
        </p>
    `,
  },
  {
    title: '<h4 class="font-bold">What will it cost me?</h4>',
    description: `
      <p class="mb-4">
          You will have to pay back the money you draw down under the agreement and pay
          interest, a facility fee (if charged) and any credit arrangement fee you may
          have been charged by your broker (which will be included in the amount drawn
          down if funded by us). 
        </p>
        <p class="mb-4">Your minimum monthly payments are calculated by adding
          interest to the amount you’ve drawn down and dividing that total by the number
          of months over which the money is to be repaid.</p>
          <p class="mb-4">
          These have already been provided
          to you and will be confirmed in your Welcome Pack sent by Close Brothers Premium
          Finance on completion of your application.
</p>
    `,
  },
  {
    title:
      '<h4 class="font-bold">What will happen if I do not pay my minimum monthly payments on time?</h4>',
    description: `
      <p class="mb-4">
          If you fail to make a minimum monthly payment you will be charged a default fee
          which will be collected at the same time as the missed payment. Your credit
          rating may be affected, and you may find it harder to borrow from CBPF or
          another lender. Your credit agreement may be cancelled which could result in you
          being charged a cancellation fee and could lead to your insurance policy being
          cancelled. You will have to pay back any money you owe. All charges are set out
          in the Interest and Charges section of your credit agreement.
        </p>
    `,
  },
  {
    title:
      '<h4 class="font-bold">Can I cancel or withdraw from this agreement and what will happen if I do?</h4>',
    description: `
      <p class="mb-4">
          You have the right under the Consumer Credit Act 1974 to withdraw from your
          credit agreement without giving any reason before the end of 14 days beginning
          on the day after (a) the day you sign a credit agreement that has already been
          signed by Close Brothers Premium Finance or (b) when you sign the credit
          agreement before Close Brothers Premium Finance your right starts when you
          receive a copy of the agreement signed by both of us. 
        </p>
        <p class="mb-4">
        If you wish to exercise
          your right to withdraw, you must give Close Brothers Premium Finance notice by
          one of the following methods: (i) by telephone on 0333 3218 566; (ii) by e-mail
          to <a class="font-semibold underline text-link" href="mailto:customerservicespf@closebrothers.com">customerservicespf@closebrothers.com</a>; or (iii) by post or delivery to Close
          Brothers Premium Finance, Wimbledon Bridge House, 1 Hartfield Road, London, SW19
          3RU.
        </p>
        <p class="mb-4">
        If you withdraw from the credit agreement as described, the credit
          agreement is treated as if it was never entered into and you must repay the
          amount of credit within 30 days beginning with the day after the day you
          notified Close Brothers Premium Finance of your intention to withdraw. You will
          not have to pay Close Brothers Premium Finance any interest or charges, but you
          will have to make alternative arrangements to pay for your insurance.
        </p>
    `,
  },
  {
    title:
      '<h4 class="font-bold">What do I need to read before entering into the credit agreement?</h4>',
    description: `
      <p class="mb-4">
          It is important that you read all the information presented in this online
          journey, in particular the Pre-contract Credit Information and the credit
          agreement, and take time to consider it carefully. This information will help
          you make an informed decision about whether this is the right product for you.
        </p>
    `,
  },
  {
    title: '<h4 class="font-bold">Is this finance product right for me?</h4>',
    description: `
      <h4 class="font-bold mb-4">Important information about your options</h4>
        <p class="mb-4">
          We understand that managing insurance premiums can sometimes be challenging. Our
          premium finance option allows you to spread the cost of your insurance over
          time, making it more manageable. However, it’s important to consider all your
          options before committing to this financing arrangement.
        </p>
        <h4 class="font-bold mb-4">Alternative credit options</h4>
        <p class="mb-4">
          Before proceeding with premium finance, we encourage you to explore other forms
          of credit that might be more cost effective. These could include:
        </p>
        <ul class="pl-4 mb-6 font-lato text-sm leading-normal list-disc text-main-content-1">
          <li class="mb-4">
            <span class="font-bold">Credit Cards:</span> Depending on the interest
            rate and your repayment habits, using a credit card could be a cheaper option
          </li>
          <li class="mb-4">
            <span class="font-bold">Personal Loans:</span> If you have access to
            personal loans with lower interest rates, this could be a more affordable way
            to finance your premium
          </li>
          <li class="mb-4">
            <span class="font-bold">Overdraft Facilities:</span> Some bank accounts
            offer low interest or interest free overdraft facilities which could be used
            to cover the premium cost
          </li>
        </ul>
        <h4 class="font-bold mb-4">Making an informed decision</h4>
        <p class="mb-4">
          We want you to make the best financial decision for your circumstances. Be sure
          to compare the total cost of premium finance with these alternative credit
          options.
        </p>
    `,
  },
];

export const MonthlyImportantInformation = ({
  className,
  testId,
}: MonthlyImportantInformationProps) => {
  return (
    <Card classNames={className} testId={`${testId}_monthly-important-information_card`}>
      <h3 className="mb-8 font-raleway font-normal text-main-content-1">
        Important information
      </h3>

      <div className="font-lato text-sm font-normal leading-5 text-main-content-1">
        <p className="pb-4 border-b-2 border-main-content-5">
          Please make sure you read these questions carefully before accepting your
          policy.
        </p>

        <Accordion items={items} />
      </div>
    </Card>
  );
};
