import { Card, Icon } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { MotorConviction } from '../../../../../../../types';
import { isYes, transformForCommaAndNoCommaValue } from '../../../../../../../helpers';

type ConvictionCardProps = {
  conviction: MotorConviction;
  testId: string;
  onRemove: VoidFunction;
};

export const ConvictionCard = ({
  testId,
  onRemove,
  conviction: {
    code,
    date,
    banReceived,
    disqualifiedLengthInMonths,
    penaltyPoints,
    fineAmount,
  },
}: ConvictionCardProps) => {
  const fineAmountComma = fineAmount
    ? transformForCommaAndNoCommaValue(String(fineAmount))
    : null;
  return (
    <Card classNames="mb-8 md:mb-12" testId={`${testId}_list-item`}>
      <div className="flex justify-between items-start">
        <Card.CardListItem
          className="mt-0"
          label="Conviction code"
          icon="code"
          iconSize="0.75rem"
          testId={`${testId}_conviction-card_code`}
        >
          {code?.name}
        </Card.CardListItem>
        <button
          className="flex items-center mr-4 text-sm font-semibold leading-[14px]"
          data-testid={`${testId}_conviction-card_remove-button`}
          onClick={onRemove}
          type="button"
        >
          <Icon name="remove" className="mr-2" size="12px" />
          Remove
        </button>
      </div>
      <Card.CardListItem
        label="Date of conviction"
        icon="date"
        iconSize="0.75rem"
        testId={`${testId}_conviction-card_date`}
      >
        {formatInUKLocalTime('dd/MM/yyyy', date.parsedDate)}
      </Card.CardListItem>
      <Card.CardListItem
        label="Penalty points"
        icon="penalty"
        iconSize="0.75rem"
        testId={`${testId}_conviction-card_penalty-points`}
      >
        {penaltyPoints}
      </Card.CardListItem>
      <Card.CardListItem
        label="Fine amount"
        icon="wallet"
        iconSize="0.75rem"
        testId={`${testId}_conviction-card_fine-amount`}
      >
        {`£${fineAmountComma?.valueWithComa || 0}`}
      </Card.CardListItem>
      <Card.CardListItem
        label="Ban received"
        icon="ban"
        iconSize="0.75rem"
        testId={`${testId}_conviction-card_ban-received`}
      >
        {banReceived?.name}
      </Card.CardListItem>
      {isYes(banReceived?.id) && (
        <Card.CardListItem
          label="Length of ban"
          icon="date"
          iconSize="0.75rem"
          testId={`${testId}_conviction-card_ban-length`}
        >
          {disqualifiedLengthInMonths} months
        </Card.CardListItem>
      )}
    </Card>
  );
};
