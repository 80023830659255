import clsx from 'clsx';
import Icon from '../../../DataDisplay/Icon/Icon';

type PasswordValidationRowProps = {
  copy: string;
  isValid: boolean;
  variant?: 'default' | 'dialog';
};

export const PasswordValidationRow = ({
  isValid,
  copy,
  variant,
}: PasswordValidationRowProps) => {
  const tickColour = clsx({
    'text-main-content-1': variant === 'default',
    'text-popup-content-1': variant === 'dialog',
  });

  const crossColour = clsx({
    'text-main-content-3': variant === 'default',
    'opacity-10 text-popup-content-1': variant === 'dialog',
  });

  return (
    <div className="inline-flex items-center space-x-2">
      {isValid ? (
        <Icon name="tick" size="0.75rem" className={tickColour} />
      ) : (
        <Icon name="cross" size="0.75rem" className={crossColour} />
      )}
      <p>{copy}</p>
    </div>
  );
};
