import React, { useMemo, useState } from 'react';
import { MarketingPreference } from '../../api/v1';
import { Person } from '../../api/v2';

type PersonAuthDetails = {
  id: string;
  isFullAccount: boolean;
  isGuest: boolean;
};

export type PersonContextType = {
  personId?: string;
  isFullAccount?: boolean;
  isGuest?: boolean;
  isSignedOff?: boolean;
  setIsSignedOff?: (isSignedOff: boolean) => void;
  setPersonAuthDetails?: (authDetails?: PersonAuthDetails) => void;
  person?: Person | null;
  setPerson: (person?: Person | null) => void;
  marketingPreferences?: Array<MarketingPreference> | null;
  setMarketingPreferences?: (
    preferences: Array<MarketingPreference> | null | undefined
  ) => void;
  logOutIsInProgress?: boolean;
  setLogOutIsInProgress?: (logOutIsInProgress: boolean) => void;
};

export const PersonContext = React.createContext<PersonContextType>({
  isGuest: false,
  isFullAccount: false,
  isSignedOff: true,
  logOutIsInProgress: false,
  setPerson: () => null,
});

export const PersonProvider = ({ children }: { children: React.ReactNode }) => {
  const [personAuthDetails, setPersonAuthDetails] = useState<
    PersonAuthDetails | undefined
  >();
  const [person, setPerson] = useState<Person | undefined | null>();
  const [marketingPreferences, setMarketingPreferences] = useState<
    Array<MarketingPreference> | null | undefined
  >();
  const [isSignedOff, setIsSignedOff] = useState<boolean>(true);
  const [logOutIsInProgress, setLogOutIsInProgress] = useState<boolean>(false);
  const context = useMemo(
    () => ({
      personId: personAuthDetails?.id,
      isGuest: personAuthDetails?.isGuest,
      isFullAccount: personAuthDetails?.isFullAccount,
      isSignedOff,
      setIsSignedOff,
      setPersonAuthDetails,
      person,
      setPerson,
      marketingPreferences,
      setMarketingPreferences,
      logOutIsInProgress,
      setLogOutIsInProgress,
    }),
    [
      personAuthDetails?.id,
      personAuthDetails?.isGuest,
      personAuthDetails?.isFullAccount,
      isSignedOff,
      person,
      marketingPreferences,
      logOutIsInProgress,
    ]
  );

  return <PersonContext.Provider value={context}>{children}</PersonContext.Provider>;
};
