import { Occupation as OccupationStateEntry } from '../../../types';
import { OccupationModelDto } from '../../../api/v1';

export const STUDENT_EMPLOYMENT_STATUS_CODE = 'F';

export const getStudentOccupationModelDto = ({
  type,
  isPrimary,
  employmentStatus,
}: OccupationStateEntry): OccupationModelDto => ({
  type: { code: type?.id },
  employmentStatus: { code: employmentStatus?.id },
  industry: { code: '950' },
  isPrimary,
});
