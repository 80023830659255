import { Button, onEnter } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  FormTextField,
  Loader,
  SameRegistrationPlateCard,
} from '../../../../../components';
import { usePolicyQuery } from '../../../../../hooks';
import { MtaJourneyFormData } from '../../../../../types/mtaJourneyForm';
import { capitalizeMask } from '../../../../../helpers';

type RegistrationPlateProps = {
  onSearchCar: () => Promise<void>;
  isLoading: boolean;
  testId: string;
};

export const RegistrationPlate = ({
  testId,
  isLoading,
  onSearchCar,
}: RegistrationPlateProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: policy } = usePolicyQuery(id);
  const { values, setFieldValue } = useFormikContext<MtaJourneyFormData>();

  useEffect(() => {
    setFieldValue(
      'isSameRegPlate',
      policy?.vehicle?.registrationNumber === values.vehicle.regPlate
    );
  }, [setFieldValue, values.vehicle.regPlate, policy?.vehicle?.registrationNumber]);

  return (
    <div className="my-12">
      <h3
        className="mb-6 font-raleway text-lg font-normal"
        data-testid={`${testId}_reg-plate-subtitle`}
      >
        What is the reg for the new car?
      </h3>

      <div className="relative form-field-spacing">
        <div className="grid grid-cols-2 gap-6 h-max sm:flex">
          <FormTextField
            label={{ text: 'Car reg', icon: 'registration' }}
            testId={`${testId}_car-reg-input`}
            id={`${testId}_car-reg-input`}
            name="vehicle.regPlate"
            placeholder="Enter car reg"
            onInput={capitalizeMask}
            onKeyDown={onEnter(onSearchCar)}
          />
          <Button
            id={`${testId}_submit-button`}
            text="Find car"
            onClick={onSearchCar}
            variant="secondary"
            classNames="w-full sm:w-fit self-end put shrink-0"
            testId={`${testId}_find-car-button`}
          />
        </div>
      </div>

      {values.isSameRegPlate && <SameRegistrationPlateCard testId={testId} />}
      {isLoading && <Loader />}
    </div>
  );
};
