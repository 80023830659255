import { isNull } from '@dayinsure/core';
import { EndorsementDto } from '../../../api/v1';
import { BaseDataEntry } from '../../../types';

const getEndorsementByCode =
  (endorsementCode: '202' | '208') => (endorsements?: EndorsementDto[] | null) =>
    endorsements?.find(endorsement => endorsement.code === endorsementCode);

export const getEndorsement202 = getEndorsementByCode('202');

export const getEndorsement208 = getEndorsementByCode('208');

const noneOfAboveOptionId = '5';
export const displayInstallationProof = (
  immobiliserOption?: BaseDataEntry | null
): boolean => !isNull(immobiliserOption) && immobiliserOption?.id !== noneOfAboveOptionId;
