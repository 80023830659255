import clsx from 'clsx';
import { Icon } from '@dayinsure/components';

export type CoverOption = {
  id: string;
  name: string;
  testId?: string;
  tooltip?: boolean;
  onTooltipClick: (option: CoverOption) => void;
};

type CoverTypeOptionProps = {
  checked: boolean;
  index: number;
  option: CoverOption;
  variant: 'primary' | 'secondary';
  disabled?: boolean;
  error?: boolean;
  onClick: (option: CoverOption) => void;
};

export const CoverTypeOption = ({
  checked,
  index,
  option,
  variant,
  disabled,
  error,
  onClick,
}: CoverTypeOptionProps) => {
  const handleClick = () => {
    if (!checked) {
      onClick(option);
    }
  };

  return (
    <div
      data-testid={`${option.testId}-${index}`}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="option"
      tabIndex={index}
      aria-selected={checked}
      data-checked={checked}
      className={clsx(
        'gap-3 rounded-lg border-2 transition-colors selector-base',
        {
          'border-error-border text-main-content-1': error && variant === 'primary',
        },
        {
          'border-error-border text-popup-content-1': error && variant === 'secondary',
        },
        {
          'border-main-content-3 text-main-content-1':
            !checked && !error && variant === 'primary',
        },
        {
          'border-popup-selector-border text-popup text-popup-content-1':
            !checked && !error && variant === 'secondary',
        },
        {
          'bg-selector-active border-selector-border-1 text-selector-active-text':
            checked && variant === 'primary',
        },
        {
          'bg-popup-selector-active border-popup-selector-active-border text-popup-selector-active-text':
            checked && variant === 'secondary',
        },
        { 'opacity-40': disabled },
        { 'cursor-pointer': !disabled }
      )}
    >
      <div
        className={clsx(
          'flex shrink-0 justify-center items-center w-6 h-6 rounded-full border-2 transition-colors',
          { 'border-selector-border-1': variant === 'primary' && !checked },
          {
            'border-popup-selector-active': variant === 'secondary' && !checked,
          },
          {
            'bg-transparent': !checked,
            'bg-white border-white': checked,
          }
        )}
      >
        <Icon
          name="check"
          className={clsx(
            { 'text-selector-active': variant === 'primary' },
            { 'text-popup-selector-active': variant === 'secondary' },
            {
              invisible: !checked,
            }
          )}
        />
      </div>

      <p className="font-semibold truncate">{option.name}</p>

      {option.tooltip && (
        <button
          aria-label="tooltip"
          type="button"
          onClick={() => option.onTooltipClick(option)}
        >
          <Icon
            name="info"
            className={clsx(
              { 'text-main-content-1': !checked },
              { 'text-selector-active-text': checked }
            )}
          />
        </button>
      )}
    </div>
  );
};
