import { useState } from 'react';
import { Card, Icon } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import { StandardDetailsModal } from './StandardDetailsModal';
import { QuoteJourneyFormData } from '../../../../types';
import { useGetProductSchemaCover } from '../../../../hooks';
import { coverTypes } from '../../../../constants/cover';

type StandardIncludedProps = {
  testId?: string;
};

export const StandardIncluded = ({
  testId = 'package-included-as-standard',
}: StandardIncludedProps) => {
  const {
    values: {
      cover: { type },
    },
  } = useFormikContext<QuoteJourneyFormData>();
  const coverType = type?.id || '';
  const { data } = useGetProductSchemaCover(coverTypes[coverType]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const packageInformationDetailed = data?.packageInformationDetailed || [];
  const coverOverviewItems = data?.coverOverviewItems || [];

  return (
    <section>
      <h2
        data-testid={`${testId}_title`}
        className="flex relative w-full font-raleway font-normal form-subtitle-compact"
      >
        Included as standard
      </h2>

      <Card
        testId={`${testId}_cover-overview_items`}
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6 flex flex-col"
      >
        {coverOverviewItems?.map((item, index) => (
          <div
            key={`item-${index.toString()}`}
            className={`flex gap-3 items-center ${
              index !== coverOverviewItems.length - 1 ? 'mb-2' : ''
            }`}
          >
            {item.isIncluded ? (
              <Icon name="green-check" size="1.1rem" />
            ) : (
              <Icon name="reject" size="1.1rem" />
            )}
            <div className={clsx({ 'text-main-content-3': !item.isIncluded })}>
              {item?.title}
            </div>
          </div>
        ))}
        <button
          type="button"
          data-testid={testId}
          className="flex gap-2 items-center mt-6 text-sm font-semibold leading-[14px]"
          onClick={() => setModalOpen(true)}
        >
          <Icon name="info" className="text-main-content-1" />
          <div>View more information</div>
        </button>
      </Card>
      <StandardDetailsModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        testId={testId}
        content={packageInformationDetailed}
      />
    </section>
  );
};
