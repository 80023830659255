import { Card, ContentContainer } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { useRef, useContext, useEffect } from 'react';
import { PaymentCard } from '../../../../components/PaymentCard';
import { QuoteJourneyFormData } from '../../../../types';
import { NewMonthlyPayments, PayByDebitCard } from '../../../Quote';
import { PaymentTypesEnum } from '../../../../constants/payments';
import { usePaymentInfo, useQuoteQuery } from '../../../../hooks';
import {
  BackButton,
  FormCheckbox,
  ImportantInformationMta,
  MtaChangeButtons,
} from '../../../../components';
import { PolicyMtaPaths } from '../../../../routes';
import { PaymentContext } from '../../../../contexts/PaymentContext';
import { TimeIsApproachingCard } from './TimeIsApproachingCard';
import { isLessThanTimeToStart } from '../../../../helpers';

const testId = 'payments-mta';
const FIVE_MINUTES = 5 * 60 * 1000;

export const PaymentsMta = () => {
  const { quoteId } = useParams<{ quoteId: string }>();
  const quote = useQuoteQuery(quoteId);
  const { cardFormVisible } = useContext(PaymentContext);
  const {
    values: { usualPaymentFrequency, isAnnualImportantInformationConfirmed, isRefund },
    submitForm,
    setFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const isPlanAnnual = usualPaymentFrequency?.code?.id === PaymentTypesEnum.ANNUAL;
  const { data, isLoading } = usePaymentInfo();
  const hasSubmitted = useRef(false);

  useEffect(() => {
    if (
      data?.insurancePrice?.netPremium?.amount &&
      data?.insurancePrice?.netPremium?.amount < 0 &&
      !hasSubmitted.current
    ) {
      setFieldValue('isRefund', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.insurancePrice?.netPremium?.amount]);

  useEffect(() => {
    if (isRefund) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefund]);

  return (
    <div className="font-raleway">
      <BackButton customBackLink={`../${PolicyMtaPaths.Review}`} />
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_form-title`}
      >
        Payment
      </h1>
      <ContentContainer>
        <Card
          testId={`${testId}_cards_info-card`}
          paddingLevel="small"
          classNames="mb-8 lg:mb-12"
        >
          <span>
            {isPlanAnnual
              ? 'Please enter your details below.'
              : 'Please check the details below.'}
          </span>
        </Card>

        {isLessThanTimeToStart(quote?.data?.effectiveDateTimeUtc, FIVE_MINUTES) && (
          <TimeIsApproachingCard testId={testId} />
        )}

        <Form>
          <div className="my-6 md:my-12">
            {data && (
              <>
                {!isPlanAnnual && (
                  <NewMonthlyPayments
                    usualPaymentFrequency={usualPaymentFrequency}
                    testId={testId}
                    paymentPlans={data?.paymentPlans}
                    insurancePrice={data?.insurancePrice}
                  />
                )}
                <PaymentCard
                  addOns={data.addOns}
                  insurancePrice={data.insurancePrice}
                  paymentPlans={data.paymentPlans}
                  cover={data.cover}
                  totalPriceIncAddOns={data.totalPriceIncAddOns}
                  excesses={data.excesses}
                  disabled={isLoading}
                  testId={testId}
                  mta
                  usualPaymentFrequency={usualPaymentFrequency}
                />
              </>
            )}
          </div>
          {isPlanAnnual ? (
            <>
              <PayByDebitCard mta />
              <ImportantInformationMta className="mt-12 md:mt-16" testId={testId} />
              <FormCheckbox
                displayText="I confirm I have read and accept all the information"
                name="isAnnualImportantInformationConfirmed"
                id={`${testId}_confirm-information_input`}
                testId={`${testId}_confirm-information_input`}
              />
            </>
          ) : (
            <span />
          )}
          {cardFormVisible && (
            <MtaChangeButtons
              disabled={isPlanAnnual && !isAnnualImportantInformationConfirmed}
              isOneOffPayment={isPlanAnnual}
              isPaymentPage
            />
          )}
        </Form>
      </ContentContainer>
    </div>
  );
};
