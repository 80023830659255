import { Card, Icon } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { QuoteJourneyFormData } from '../../../../../types';
import { isYes } from '../../../../../helpers';
import { MtaJourneyFormData } from '../../../../../types/mtaJourneyForm';

const testId = 'car-details_car-spec-card';

type CarSpecCardProps = {
  handleEditing: () => void;
};

export const CarSpecsCard = ({ handleEditing }: CarSpecCardProps) => {
  const {
    values: {
      vehicle: {
        steeringWheelSide,
        numberOfSeats,
        trackingDevice,
        alarmImmobiliser,
        isImported,
      },
    },
  } = useFormikContext<QuoteJourneyFormData | MtaJourneyFormData>();

  return (
    <Card testId={`${testId}_car-spec-card`} classNames="bg-content-background-prominent">
      <div className="flex justify-between mb-6 ">
        <h3 className="font-raleway text-lg font-normal" data-testid={`${testId}_title`}>
          Car specs
        </h3>
        <button
          className="flex items-center text-sm font-semibold leading-[14px] text-cta-secondary-text"
          data-testid={`${testId}_edit-button`}
          onClick={handleEditing}
          type="button"
        >
          <Icon name="edit" className="mr-2" size="12px" />
          Edit
        </button>
      </div>
      <Card.CardListItem
        label="Steering wheel side"
        icon="steering-wheel"
        testId={`${testId}_steerling-wheel-side-item`}
        labelClassName="text-main-content-2"
      >
        {steeringWheelSide?.name}
      </Card.CardListItem>
      <Card.CardListItem
        label="Number of seats"
        icon="seat"
        testId={`${testId}_seats-item`}
        labelClassName="text-main-content-2"
      >
        {numberOfSeats?.name}
      </Card.CardListItem>
      <Card.CardListItem
        label="Tracking device"
        icon="location"
        testId={`${testId}_tracking-device-item`}
        labelClassName="text-main-content-2"
      >
        {isYes(trackingDevice?.hasTrackingDevice?.id)
          ? trackingDevice?.code?.name
          : trackingDevice?.hasTrackingDevice?.name}
      </Card.CardListItem>
      <Card.CardListItem
        label="Alarm / immobiliser"
        icon="alarm"
        testId={`${testId}_alarm-immobiliser-item`}
        labelClassName="text-main-content-2"
      >
        {alarmImmobiliser?.name}
      </Card.CardListItem>
      <Card.CardListItem
        label="Imported vehicle"
        icon="container"
        testId={`${testId}_imported-vehicle-item`}
        labelClassName="text-main-content-2"
      >
        {isImported?.name}
      </Card.CardListItem>
    </Card>
  );
};
