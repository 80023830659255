import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useCoverOptionsQuery } from '../../../../hooks';
import { CoverTypesDto } from '../../../../api/v2';
import { CoverFormData } from '../../../../types';
import { CoverOption } from './FormCoverTypeRadio/CoverTypeOptions';
import { FormCoverTypeRadio } from './FormCoverTypeRadio';
import { excessesInitialFormData } from '../../../../forms';

type CoverTypeProps = {
  testId: string;
};

const coverOptionToRadioSelectOption =
  (onTooltipClick: (selectedOption: CoverOption) => void, testId: string) =>
  (option: CoverTypesDto): CoverOption => ({
    id: option.code || '',
    name: option.description || '',
    tooltip: false,
    onTooltipClick,
    testId: `${testId}_cover-type-option-${option.code}`,
  });

export const CoverType = ({ testId }: CoverTypeProps) => {
  const { data } = useCoverOptionsQuery();
  const { setFieldValue, setFieldTouched } = useFormikContext<CoverFormData>();

  const selectCoverType = useCallback(
    (option: CoverOption) => {
      setFieldValue('cover.type', option);
      setFieldValue(
        'excesses.voluntaryAmounts',
        excessesInitialFormData.excesses.voluntaryAmounts
      );
      setFieldTouched('excesses.voluntaryAmounts[0].total', false);
    },
    [setFieldTouched, setFieldValue]
  );

  const coverTypeOptions = useMemo(() => {
    return (
      data?.coverTypes?.map(coverOptionToRadioSelectOption(selectCoverType, testId)) || []
    );
  }, [data?.coverTypes, selectCoverType, testId]);

  return (
    <div className="mb-6 md:mb-12">
      <h2
        className="flex relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_cover-type_title`}
      >
        What type of cover do you need?
      </h2>
      <FormCoverTypeRadio
        id={`${testId}_cover-type-radio`}
        isInputChangeEnabled={false}
        testId={`${testId}_cover-type-radio`}
        name="cover.type"
        options={coverTypeOptions}
        cols={{ tablet: 1, desktop: 1, mobile: 1 }}
        onChangeOptional={selectCoverType}
      />
    </div>
  );
};
