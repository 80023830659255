/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptanceCriteriaResponseListIAPIResult } from '../models/AcceptanceCriteriaResponseListIAPIResult';
import type { Agreement } from '../models/Agreement';
import type { AssumptionsType } from '../models/AssumptionsType';
import type { IAPIResult } from '../models/IAPIResult';
import type { Underwriter } from '../models/Underwriter';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AcceptanceCriteriaService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the Acceptance Criteria for the selected product
   * @param quoteId
   * @param product
   * @param assetType
   * @param type
   * @param underwriter
   * @param myLicenceChecksPassed
   * @param vehicleRegistration
   * @param vehicleAbiCode
   * @returns AcceptanceCriteriaResponseListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1AcceptanceCriteria(
    quoteId?: string,
    product?: string,
    assetType?: string,
    type?: Array<string>,
    underwriter?: Underwriter,
    myLicenceChecksPassed?: boolean,
    vehicleRegistration?: string,
    vehicleAbiCode?: string,
  ): CancelablePromise<AcceptanceCriteriaResponseListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/acceptance-criteria',
      query: {
        'QuoteId': quoteId,
        'Product': product,
        'AssetType': assetType,
        'Type': type,
        'Underwriter': underwriter,
        'MyLicenceChecksPassed': myLicenceChecksPassed,
        'VehicleRegistration': vehicleRegistration,
        'VehicleAbiCode': vehicleAbiCode,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * save agreement in db
   * @param type
   * @param requestBody
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1AcceptanceCriteriaAgreement(
    type: AssumptionsType,
    requestBody?: Agreement,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/acceptance-criteria/{type}/agreement',
      path: {
        'type': type,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }

}