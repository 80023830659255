import { Card, Icon } from '@dayinsure/components';

type PreContractCreditInformationProps = {
  testId: string;
};

export const PreContractCreditInformation = ({
  testId,
}: PreContractCreditInformationProps) => {
  return (
    <Card
      classNames="flex items-center"
      testId={`${testId}_pre-contract-credit-information_card`}
    >
      <Icon className="text-main-content-1" size="2rem" name="info" />
      <div className="ml-6 text-main-content-1">
        Please read your{' '}
        <a
          className="font-semibold underline text-link"
          href="/Pre-Contract-Credit-Information.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pre-Contract Credit information
        </a>{' '}
        before continuing. As you’ve chosen to pay monthly, you’re entering into a credit
        agreement. It’s really important you read this document to make sure you’re happy
        with the terms of agreement before you buy.
      </div>
    </Card>
  );
};
