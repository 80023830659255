import { addDays } from 'date-fns';
import { getFormFieldAccessPath } from '../../helpers';
import { FormDateField, FormFieldBox } from '../FormsFields';
import { CoverStartDateTooltip } from './CoverStartDateTooltip';

type CoverStartDateProps = {
  testId: string;
  formNamespace?: string;
  title?: string;
  maxDate?: string;
  startDate?: string;
};
export const CoverStartDate = ({
  testId,
  title,
  formNamespace = 'cover',
  maxDate = '',
  startDate,
}: CoverStartDateProps) => {
  const provideCoverStartDatePath = getFormFieldAccessPath([
    formNamespace,
    'startDateTimeUtc',
  ]);
  const dateFrom = startDate ? new Date(startDate) : new Date();
  const futureDate = addDays(dateFrom, 30);
  let pickerMaxDate = futureDate;

  if (maxDate) {
    const maxDateLimit = new Date(maxDate);
    if (futureDate > maxDateLimit) {
      pickerMaxDate = maxDateLimit;
    }
  }

  const pickerProps = {
    fromDate: dateFrom,
    toDate: pickerMaxDate,
  };

  return (
    <div className="my-6 md:my-12">
      <h2
        className="flex relative w-full font-raleway font-normal form-subtitle-compact"
        data-testid={`${testId}_cover-start-date-label`}
      >
        {title || 'When do you need cover to start?'}
      </h2>
      <FormFieldBox>
        <FormDateField
          label={{
            icon: 'date',
            text: (
              <div className="inline-flex gap-2 items-center">
                Start date <CoverStartDateTooltip testId={`${testId}_start-date`} />
              </div>
            ),
          }}
          testId={`${testId}_cover-start-date-input`}
          id={`${testId}_cover-start-date-input`}
          name={provideCoverStartDatePath}
          configuration={{
            day: {
              enabled: true,
              placeholder: 'DD',
            },
            month: {
              placeholder: 'MM',
            },
            year: {
              placeholder: 'YYYY',
            },
          }}
          picker={{
            buttonId: `${testId}_cover-start-date-picker-button`,
            buttonText: 'Pick date',
            props: pickerProps,
          }}
        />
      </FormFieldBox>
    </div>
  );
};
